.FilterRadioCell-container {
    height: 55px;
    border-bottom: 1px solid var(--wgray_semisoft);
    cursor: pointer;
    
    .FRC-title-label {
        font-size: 17px;
        color: var(--wgray_darker);
    }

    .FRC-buttlet-img {
        max-width: 30px;
        max-height: 30px;
        img {
            width: 100%;
            height: 30px;
        }
    }
}
