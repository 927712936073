.Talent-container {
    padding-top: 20px;
    padding-bottom: 24px;

    .Talent-title-container {
        font-weight: bold;
        font-size: 22px;
        color: var(--wblue_dark);
        padding-bottom: 10px;

        &.desktop {
            display: flex;
            justify-content: space-between;
            padding-bottom: 25px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .Talent-subtitle-container {
        font-size: 17px;
        color: var(--wblue_dark);
        line-height: 19px;
        padding-bottom: 10px;
    }

    .Talent-form-container {
        padding-left: 20px;
        padding-right: 20px;

        .Talent-form-group {
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            align-items: end;

            textarea {
                width: 100%;
                resize: none;
                border: 1px solid #B7BBC0;
                border-radius: 3px;
                padding: 12px;
                font-size: 17px;
                line-height: 20px;
                height: 269px;

                &.recomendation {
                    height: 197px;
                }
            }

            small {
                color: var(--wgray_dark);
            }

            label {
                margin: 0;
                width: 55px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;

                img {
                    object-fit: cover;
                    width: 35px;
                    height: 35px;
                    object-position: 0 0;

                    &.Twitter {
                        object-position: 0 0;
                    }

                    &.Facebook {
                        object-position: 0 -35px;
                    }

                    &.LinkedIn {
                        object-position: 0 calc(-35px * 2);
                    }

                    &.Instagram {
                        object-position: 0 calc(-35px * 3);
                    }

                    &.YouTube {
                        object-position: 0 calc(-35px * 4);
                    }

                    &.Spotify {
                        object-position: 0 calc(-35px * 5);
                    }
                }
            }

            input {
                height: 55px;
                width: 100%;
                border-radius: 5px;
                border: 1px solid var(--wgray);
                padding: 0 55px;
                outline: none !important;
                font-size: 17px;
                font-weight: 600;
                color: var(--wgray_darker);

                &::placeholder {
                    color: rgba(60, 60, 67, 0.3);
                }
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                width: 55px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 35px;
                    width: 35px;
                }
            }
        }
    }

    .Talent-footer-container {
        padding-left: 20px;
        padding-right: 20px;
        button {
            width: 100%;
            height: 50px;
            background: var(--wgray_soft);
            font-size: 18px;
            font-weight: 600;
            cursor: not-allowed;

            &.active {
                background: var(--wblue);
                color: #fff;
                cursor: pointer;
            }
        }
    }
}
