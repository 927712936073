.NotificationsSheet-container {
    background: #fff;
    max-width: 350px;
    min-width: 350px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    border-radius: 10px;

    .NotificationsSheet-no-results-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 38px;

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            object-position: 0 -50px;
        }

        span {
            padding-top: 10px;
            font-size: 18px;
            text-align: center;
            line-height: 20px;
            color: var(--wgray_dark);
        }
    }

    .NotificationsSheet-result-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .NotificationsSheet-top-badge {
            text-align: end;
            font-size: 12px;
            color: var(--wgray_dark);
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .NotificationsSheet-icon-box {
            padding: 0;
            max-width: 50px;
            max-height: 50px;

            img {
                width: 50px;
                height: 50px;
                object-fit: cover;
                object-position: 0 0;
            }
        }

        .NotificationsSheet-body-container {
            max-width: calc(100% - 50px);
            
            .NotificationsSheet-titleBox {
                font-size: 18px;
                font-weight: bold;
                color: var(--wblue_dark);
            }

            .NotificationsSheet-subtitleBox {
                font-size: 18px;
                color: var(--wblue_dark);
                line-height: 20px;
            }
        }

        .NotificationsSheet-footer-content {
            margin-top: 18px;
            border-top: 1px solid var(--wgray_semisoft);

            button {
                height: 50px;
                width: 100%;
                font-size: 18px;
                font-weight: 600;
                color: var(--wblue);
            }
        }
    }

    // .NotificationsSheet-photo-container {
    //     padding: 0;
    //     max-width: 350px;
    //     height: 350px;
    //
    //     img {
    //         width: 350px;
    //         height: 350px;
    //         object-fit: cover;
    //         border-radius: 10px;
    //         box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    //     }
    // }
    //
    // .NotificationsSheet-info-container {
    //     background: #fff;
    //     max-width: 350px;
    //     min-width: 350px;
    //     box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    //     border-radius: 10px 10px 5px 5px;
    //     margin-top: -20px;
    //     padding: 30px 20px;
    //
    //     .NotificationsSheet-name-container {
    //         font-size: 24px;
    //         font-weight: bold;
    //         color: var(--wblue_dark);
    //     }
    //
    //     .NotificationsSheet-bm-icon {
    //         padding: 0;
    //         max-width: 20px;
    //         max-height: 20px;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //
    //         img {
    //             width: 20px;
    //             height: 20px;
    //         }
    //     }
    //
    //     .NotificationsSheet-details-container {
    //         .icnBox {
    //             width: 30px;
    //             height: 30px;
    //             max-width: 30px;
    //             max-height: 30px;
    //
    //             img {
    //                 width: 30px;
    //                 height: 30px;
    //                 object-position: 0px -0px;
    //                 object-fit: cover;
    //
    //                 $positions: 8;
    //
    //                 @for $i from 0 through $positions {
    //                     &.pos-#{$i + 1} {
    //                         object-position: 0px calc(-30px * #{$i});
    //                     }
    //                 }
    //             }
    //         }
    //
    //         .txtBox {
    //             font-size: 18px;
    //             color: var(--wgray_darker);
    //         }
    //     }
    // }
}
