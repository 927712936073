// .container-register {
//     background-color: #0FB855;
//     height: 100%;
//     width: 100vw;
//     position: absolute;
//     overflow: hidden;
//
//     &-title {
//         color: white;
//
//     }
//
//     &-fondo {
//         object-fit: cover;
//         position: absolute;
//         height: 100%;
//         // width: 100%;
//         right: -110px!important;
//         top: -100px;
//     }
//
//     &-title {
//         width: 100%;
//         height: 100%;
//         text-align: center;
//         margin-top: auto;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//
//         h2 {
//             font-weight: bold;
//         }
//
//         p {
//             font-size: 17px;
//             padding:  0 30px 0 30px;
//         }
//     }
//
//     &-button {
//         position: fixed;
//         bottom: 30px;
//         width: 90%;
//         display: block;
//         left: 20px;
//         height: 55px;
//         border: none;
//         background-color: #0D458D;
//         color:#fff;
//         font-weight: bold;
//         border-radius: 5px;
//         max-width: 400px;
//         left: 50%;
//         transform: translateX(-50%);
//     }
// }
//
//
// .container-steps {
//     max-width: 700px;
//     margin: 0 auto;
//
//     h2 {
//         font-weight: bold;
//         text-align: center;
//         margin-top: 50px;
//     }
//
//     .subtitle {
//         font-weight: 17px;
//         font-weight: bolder;
//         text-align: center;
//         margin-bottom: 50px;
//     }
//
//     .button-register {
//         background-color: #0D458D;
//         width: 95%;
//         height: 55px;
//         position: fixed;
//         border-radius: 5px;
//         color: white;
//         font-weight: bold;
//         bottom: 20px;
//         border: none;
//         max-width: 700px;
//         left: 50%;
//         transform: translateX(-50%);
//
//     }
//
//     .disabled {
//         background-color: #ADB0BE;
//     }
//
//
//
//     &-options {
//         padding: 0px;
//         padding: 0 10px 0 10px;
//         &-option {
//
//           display: flex;
//           align-items: center;
//           padding: 0;
//           padding: 10px 15px 10px 15px;
//           height: 95px;
//           margin-bottom: 20px;
//           border-radius: 5px;
//           background-color: #F8F8FB;
//             border: 1px solid #CDCED4;
//
//           img {
//               display: none;
//           }
//
//           .number-option {
//             display: block;
//             min-width: 30px!important;
//             text-align: center;
//         }
//
//           &-body {
//             margin-left: 20px;
//              p {
//                  margin: 0;
//              }
//           }
//
//           h5 {
//               margin: 0;
//               margin-bottom: 8px;
//           }
//         }
//
//         .active {
//             background-color: #E8FAF0;
//             border: 1px solid #0FB855;
//
//             .number-option {
//                 display: none;
//             }
//
//             img {
//                 display: block;
//             }
//         }
//     }
// }
//
// .modal-register {
//     max-width: 80%!important;
//     max-height: 20%!important;
//     border-radius: 10px!important;
//     position: absolute!important;
//     top: 50%;
//     left: 10%;
//
//     h1 {
//         color: #0FB855;
//         text-align: center;
//         margin-top: 40px;
//     }
//
//     p {
//         font-size: 17px;
//         color: black;
//         text-align: center;
//     }
//
//     img {
//         position: absolute;
//         top: 0;
//         right: 0;
//         margin: 10px;
//         cursor: pointer;
//     }
// }

.RegisterSteps-modal-discount {
    .modal-dialog {
        padding: 24px;

        .modal-content {
            margin: 0 auto;
            max-width: 327px;
            max-height: 300px;
            height: 300px;
            border-radius: 10px;

            .modal-body {
                padding: 24px;

                .RegisterSteps-modal-img-box {
                    padding-top: 36px;
                    text-align: center;
                }

                .RegisterSteps-modal-txt-box {
                    font-size: 17px;
                    font-weight: bold;
                    color: var(--wblue);
                    text-align: center;
                    line-height: 20px;
                    padding-top: 30px;
                }

                .RegisterSteps-modal-btns-box {
                    padding-top: 40px;

                    button {
                        height: 50px;
                        width: 100%;
                        font-size: 15px;
                        font-weight: bold;
                        border-radius: 5px;
                        background: var(--wgray_soft);
                        color: var(--wgray_darker);

                        &.isSuccess {
                            background: var(--wgreen);
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.RegisterSteps-container {
    width: 100vw;
    height: 100vh;

    .RegisterSteps-welcome-container {
        background: var(--wgreen);
        width: 100vw;
        height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: 110% 0;

        .RegisterSteps-welcome-text-container {
            color: #fff;

            .title {
                font-size: 31px;
                font-weight: 600;
            }

            .subtitle {
                font-size: 17px;
            }

            .subtitle-min{
                margin-top: 100px;
                font-weight: 500;
            }
        }

        .RegisterSteps-welcome-submit-container {
            max-width: 400px;
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);

            button {
                width: 100%;
                height: 55px;
                border-radius: 5px;
                font-size: 16px;
                font-weight: 600;
                background: var(--wblue);
                color: #fff;
            }
        }
    }

    .RegisterSteps-steps-container {
        width: 100vw;
        height: 100vh;
        position: relative;

        .RegisterSteps-steps-submit-container {
            text-align: center;
            position: fixed;
            max-width: 400px;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);

            button {
                height: 55px;
                background: var(--wgray_dark);
                font-size: 17px;
                font-weight: bold;
                color: #fff;
                border-radius: 4px;
                width: 100%;
                cursor: not-allowed;

                &.active {
                    background: var(--wgreen);
                    cursor: pointer;
                }
            }
        }

        .RegisterSteps-steps-main-container {
            padding-top: 75px;
            max-width: 700px;
            margin: 0 auto;

            .title {
                font-size: 28px;
                font-weight: bold;
                text-align: center;
                color: var(--wblue_dark);
            }

            .subtitle {
                font-size: 17px;
                font-weight: 600;
                color: var(--wgray_darker);
                text-align: center;
            }

            .RegisterSteps-steps-items-container {
                padding-top: 30px;

                .RegisterSteps-steps-item {
                    padding: 8px 16px;

                    .RegisterSteps-steps-item-row {
                        height: 95px;
                        display: flex;
                        align-items: center;
                        border: 1px solid #fff;
                        border-radius: 5px;
                        background: #fff;
                        box-shadow: 0 2px 10px rgba(0,0,0,0.1);
                        cursor: pointer;

                        .item-title {
                            font-size: 17px;
                            font-weight: bold;
                            color: var(--wblue);
                        }

                        .item-subtitle {
                            font-size: 14px;
                            color: var(--wgray_darker);
                            line-height: 18px;
                        }

                        &.success {
                            border: 1px solid var(--wgreen);
                            background: var(--wgreen_softer);
                            box-shadow: none !important;
                            cursor: default;
                        }

                        .icon-box {
                            max-width: calc(30px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));
                            align-items: center;
                            justify-content: center;
                            display: flex;

                            img {
                                width: 30px;
                                height: 30px;
                                object-fit: cover;
                                object-position: 0 -210px;
                            }

                            span {
                                font-size: 17px;
                                font-weight: 600;
                                color: var(--wblue);
                            }
                        }
                    }

                    .disbled-mem{
                        cursor: not-allowed;
                    }
                }


            }
        }
    }
}
