.Home-container {
  background: var(--wgray_softer);
  width: 100%;
  height: 100%;
  max-width: 700px;
  margin: 0 auto;
  border-left: 1px solid var(--wgray_soft);
  border-right: 1px solid var(--wgray_soft);

  .Home-post-container {
    width: 100%;
    padding: 14px;
    // height: calc(100% - 65px - 61px);
    height: calc(100% - 65px - 67px - 87px);
    overflow: auto;
    background: var(--wgray_softer);

    padding-bottom: 14px;

    &.showName {
      // height: calc(100% - 65px - 103px);
      height: calc(100vh - 174px);
      padding-bottom: 84px;
    }

    .Home-loaderBox {
      background: #fff;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
      border-radius: 5px;
      margin-bottom: 14px;
      padding: 14px;
    }

    .Home-creators-content {
      border-top: 1px solid var(--wgray_semisoft);

      .creator-item {
        padding-top: 10px;
        .user-image-box {
          max-width: 60px;
          height: 60px;
          width: 60px;
          position: relative;

          .avatar {
            width: 100%;
            height: 60px;
            border-radius: 30px;
            border: 1px solid #EBEBEB;
            object-fit: cover;
            position: relative;
          }

          .bmIcon {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }

        .creator-details {
          
          .creator-name {
            font-size: 17px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: -0.40799999237060547px;
            text-align: left;
            color: var(--wblue_dark);
          }

          .creator-contenttype {
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.36000001430511475px;
            text-align: left;
            color: var(--wgray_darker);
          }

          .creator-contents {
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.36000001430511475px;
            text-align: left;
            color: var(--wgray_dark);
            padding-bottom: 10px;
            border-bottom: 1px solid var(--wgray_semisoft);
          }
        }
      }
    }
  }
}

.letter-container {
  .title-letter {
    color: var(--wblue);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin-top: 27px;
  }

  .subtitle-letter {
    margin-top: 17px;
    margin-right: 15px;
    margin-left: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  .btn-letter {
    border: 1px solid #cdced4;
    box-sizing: border-box;
    border-radius: 5px;
    color: #adb0be;
    background-color: transparent;
    height: 50px;
    width: 95px;
  }

  .btn-letter-active{
    background: #DDEAFB;
    border: 1px solid var(--wblue);
    box-sizing: border-box;
    border-radius: 5px;
    color: var(--wblue);
  }

  .text-test {
    margin-left: 15px;
    margin-right: 15px;

    .test-input {
      background: #f4f4f6;
      border: 1px solid #cdced4;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      width: 100%;
    }
  }
  .save-letter-container{

    margin-top: 30px;
    text-align: center;

    .save-letter {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        width: 300px;
        margin-bottom: 20px;
      }

}
}

@media screen and (min-width: 1450px) {
  .Home-container {
    background: var(--wgray_softer);
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0 auto;

    .Home-box-container {
      padding-top: 25px;
      height: calc(100vh - 100px);
      overflow: auto;

      .Home-lateral-content {
        max-width: calc(
          350px + calc(var(--bs-gutter-x) * 0.5) +
            calc(var(--bs-gutter-x) * 0.5)
        );

        .left {
        }

        .right {
        }
      }

      .Home-post-box {
        max-width: 700px;
        min-width: 700px;
        border-left: 1px solid var(--wgray_semisoft);
        border-right: 1px solid var(--wgray_semisoft);

        .Home-post-container {
          width: 100%;
          padding: 14px 0;
          // height: calc(100% - 65px - 61px);
          height: auto;
          overflow: visible;
          background: var(--wgray_softer);

          &.isDesktop {
            &.noresults {
              height: calc(
                100% - 64px - 50px - 133px - 25px - 70px - 100px - 115px
              );
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .Home-loaderBox {
            background: #fff;
            box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
            border-radius: 5px;
            margin-bottom: 14px;
            padding: 14px;
          }
        }
      }
    }
  }
}
