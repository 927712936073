.container-details {

    background-color: white;
    height: 100vh;
    padding: 0;

    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);

    h5 {
        margin-left: 20px;
        color: #0D458D!important;
    }

    .container_header {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        padding: 30px 15px 10px 15px;
        align-items: center;
        background-color: #F8F8FB;
        margin-bottom: 30px;
        position: relative;


        h5 {
            color: black;
            width: 100vw;
            text-align: center;
            display: block;
            font-size: 17dp;
        }

        img {
            position: absolute;
            left: 14px;
        }
    }

    .container_subtitulo {
        margin-left: 20px!important;
    }

    .container_data {

        img {
            display: block;
            margin: 0 auto;
        }

        p {
            font-size: 17px;
            color: #272C40;
            text-align: center;
            padding: 15px;
        }

        .text1 {
            margin-top: 10px;
            padding-left: 25px;
        }

        .text2 {
            margin-top: -20px;
        }


        &_option_detail {
            display: flex;
            justify-content: start!important;
            align-items: center;
            padding: 10px;
            margin: 15px;
            background-color: #F8F8FB;
            border: 1px solid #CDCED4;
            border-radius: 10px;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 20px;

            &_description {
                margin-left: 10px;
                h5 {
                    color: #0D458D;
                    margin: 0;
                }

                p {
                    margin: 0;
                    padding: 0!important;
                    text-align: left!important;
                    font-size: 15px;
                    color: #585C6E;
                }

            }

            img {
                margin-left: 0px;
                align-content: flex-end;
                margin-right: 0px!important;
                visibility: visible!important;
            }


        }
    }

    .button_detail {
        background-color: #0FB855;
        margin-top: 100px;
        width: calc(100% - 14px - 14px);
        margin-left: 14px;
        margin-right: 14px;
        height: 50px;
        font-size: 18px;
        font-weight: 600;
    }
}
