.container_profile {
    width: 100%;
    height: 100%;
    padding-bottom: 60px;

    .image_user {
        background-color: green;
        margin: 0;

        .image_profile {
            width: 100%;
            min-height: 375px;
            margin: 0;
            object-fit: cover;
        }

        .btn_configuration {
            position: absolute;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 700px;
            left: 50%;
            transform: translateX(-50%);
            padding: 25px 10px;

            img {
                max-height: 30px;
                max-width: 30px;
            }
        }
    }

    .data_user {
        margin-top: -5%;
        position: relative;
        border-radius: 15px 15px;
        height: 100%;
        background-color: #F8F8FB;

        .name-user {
            display: block;
            align-items: center;

            img {
                width: 30px;
                height: 30px;
                margin-left: 10px;
            }
        }

        h1 {
            border-radius: 15px 15px;
            background-color: white;
            width: 100%;
            padding: 15px;
            padding-bottom: 0;
        }

        .board-media {
            display: flex;
            align-items: center;
            color: #dadae4;
            margin-left: 20px;
            font-size: 17px;
            background-color: white;
            padding-left: 20px;
            margin: 0 0 20px 0px;
            padding-bottom: 10px;

            p {
                margin: 0;
                padding: 0;
                margin-left: 10px;
            }
        }
        .data_user_info {
            background-color: white;
            padding: 15px;
            &_fill {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                img {
                    width: 25px;
                    height: 20px;
                }

                p {
                    margin: 0;
                    margin-left: 10px;
                }

                &_phone {
                    color: #0FB855;
                }
                &_email {
                    color: #0FB855;
                }
            }

            &_btn_edit {
                width: 100%;
                height: 50px;
                border-radius: 5px;
                background-color: #E8FAF0;
                border: none;
                color: #0FB855;
                font-size: 17px;
                margin-top: 20px;
            }
        }


        .data_company {
            background-color: white;
            margin-top: 15px;
            padding: 15px;

            &_header {
                display: flex;

                img {
                    object-fit: cover;
                }

                &_title {
                    margin-left: 20px;

                    h6 {
                        font-weight: bold;
                    }

                    p {
                        margin: 0;
                    }
                }
            }

            h5 {
                margin-top: 20px;
                font-weight: bold;
            }

            h3 {
                margin: 0;
                color: black;
                padding: 0;
                margin: 10px 0 10px 0 ;
                font-weight: bold;
            }
            p {
                font-size: 17px;
                color: #585C6E;
                line-height: 20px;
            }

            &_info_fill {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                img {
                    width: 25px;
                    height: 20px;
                }

                p {
                    margin: 0;
                    margin-left: 10px;
                }

                &_web {
                    color: #0FB855!important;
                }

                &_phone {
                    color: #0FB855!important;
                }
            }

            &_btn_edit {
                width: 100%;
                height: 50px;
                border-radius: 5px;
                background-color: #E8FAF0;
                border: none;
                color: #0FB855;
                font-size: 17px;
                margin-top: 20px;
            }
        }

        .data_folleto {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            padding: 15px;
        }

        h3 {
            padding: 15px;
            font-size: 20px;
            color: #9A9BA0;
        }

        .data_account {

            background-color: white;
            padding: 5px 15px 0px 15px;
            margin-top: 5px;
            margin-bottom: 5px;
            align-items: center;

            &.noLink {
                padding: 5px 15px;
                display: flex;
                min-height: 45px;
            }

            &_option {
                display: flex;
                justify-content: space-between;

                p {
                    font-size: 17px;
                    color: #272C40;
                    text-align: center;
                    margin-top: 10px;

                    &.invitation-label {
                        display: flex;
                        align-items: center;

                        span {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            max-width: calc(100% - 30px - 50px);

                            .invitation-miniature {
                                width: 20px;
                                height: 20px;
                                border-radius: 10px;
                                margin: 0 5px;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
    }

    .data-network-container {
        background: #fff;
        margin: 10px 0 0px;
        padding: 20px 0;

        .data-network-header {
            font-size: 17px;
            font-weight: bold;
            color: var(--wblue_dark);
            padding-bottom: 14px;
        }

        .data-networks-box {

            .text-content {
                font-size: 17px;
                color: var(--wgray_darker);
                line-height: 22px;
            }

            .col-12 {
                height: 50px;
                background: #F4F4F6;
                display: flex;
                align-items: center;
                border-radius: 25px;
                padding: 10px;
                font-size: 17px;
                color: var(--wgray_darker);
                margin-bottom: 10px;

                img {
                    min-width: 35px;
                    width: 35px;
                    height: 35px;
                    border-radius: 17.5px;
                    margin-right: 10px;
                    object-fit: cover;

                    &.Twitter {
                        object-position: 0 0;
                    }

                    &.Facebook {
                        object-position: 0 -35px;
                    }

                    &.LinkedIn {
                        object-position: 0 calc(-35px * 2);
                    }

                    &.Instagram {
                        object-position: 0 calc(-35px * 3);
                    }

                    &.YouTube {
                        object-position: 0 calc(-35px * 4);
                    }

                    &.Spotify {
                        object-position: 0 calc(-35px * 5);
                    }
                }
            }
        }

        .data-network-adder-container {
            button {
                width: 100%;
                height: 50px;
                border-radius: 5px;
                background-color: #E8FAF0;
                border: none;
                color: #0FB855;
                font-size: 17px;
                margin-top: 20px;
                font-weight: 600;
            }
        }
    }

    .UserProfile-discount-container {
        padding-top: 24px;

        .UserProfile-discount-col {
            height: 80px;
            background: #DDEAFB;
            border-radius: 5px;
            display: flex;
            align-items: center;

            .discount-number {
                max-width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                font-weight: bolder;
                color: var(--wblue);
            }

            .discount-text {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: bold;
                color: var(--wblue);
            }
        }
    }

    .UserProfile-asociations-container {
        margin-top: 10px;
        background: #fff;
        padding: 24px 15px;

        .colTitle {
            font-size: 17px;
            font-weight: bold;
            color: var(--wblue_dark);
            padding-bottom: 14px;
        }

        .asociationBox {
            padding: 12px 14px;
            background: var(--wgray_softer);
            border-radius: 2px;
            margin-bottom: 10px;

            .asocTitle {
                font-size: 17px;
                font-weight: bold;
                color: var(--wblue);
            }

            .asocDetails {
                font-size: 18px;
                color: var(--wgray_darker);
            }
        }

    }
}


.hidden {
    overflow-y: scroll;
}

.text-green {
    color: green;
}

@media screen and (min-width: 1450px) {
    .UserProfile-container {
        background: var(--wgray_softer);
        width: 100%;
        height: 100%;
        max-width: 100%;
        margin: 0 auto;

        .UserProfile-box-container {
            padding-top: 25px;
            height: calc(100vh - 100px);
            overflow: auto;

            .UserProfile-lateral-content {
                max-width: calc(350px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));
                padding-right: calc(calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5) - 9px);
                padding-left: 9px;

                .left {

                }

                .right {

                }
            }

            .UserProfile-post-box {
                max-width: 1100px;
                padding-right: calc(calc(var(--bs-gutter-x) * .5) - 9px);

                .UserProfile-main-row {
                    border-right: 1px solid var(--wgray_soft);

                    .UserProfile-small-col {
                        max-width: calc(334px + 12px + 12px);
                    }
                }
                // .UserProfile-post-container {
                //     width: 100%;
                //     padding: 14px 0;
                //     // height: calc(100% - 65px - 61px);
                //     height: auto;
                //     overflow: visible;
                //     background: var(--wgray_softer);
                //
                //     .UserProfile-loaderBox {
                //         background: #fff;
                //         box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
                //         border-radius: 5px;
                //         margin-bottom: 14px;
                //         padding: 14px;
                //     }
                // }
            }


        }
    }
}
