.MessagesDesktopChat-container {
    background: var(--wgray_semisoft);
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    height: calc(100% - 16px);

    .MessagesDesktopChat-header-container {
        padding: 0 14px;
        height: 91px;
        background: #fff;
        border-bottom: 1px solid var(--wgreay_soft);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        .MessagesDesktopChat-header-btn-container {
            max-width: 30px;
            max-height: 30px;
        }

        .user-details-container {
            max-width: calc(100% - 60px);
        }
        .user-details-container,
        .MessagesDesktopChat-header-details-box {
            .avatar-image-box-60 {
                position: relative;

                .avatar {
                    position: relative;
                }

                .miembro {
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    bottom: 0;
                    right: -5px;
                }
            }
        }
    }

    .MessagesDesktopChat-bubble-container {
        padding: 14px;

        // max-height: calc(100vh - 46px - 50px - 24px);
        overflow: auto;
        height: 100%;
        background: var(--wgray_semisoft);

        .col-bubble {
            position: relative;


            &.mine {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .MessagesDesktopChat-bubble {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 2px;
                    background: var(--wgreen_soft);
                    padding-top: 30px;

                    .bubble-timesince {
                        text-align: right;
                    }

                    .sendingMessage{
                        height: 10px;
                        width: 10px;
                        margin-left: 5px;
                    }
                }

            }
            
            .MessagesDesktopChat-bubble {
                display: inline-block;
                border-radius: 10px;
                border-bottom-left-radius: 2px;
                background: #fff;
                margin: 0 0 12px 0;
                padding: 12px;
                max-width: 365px;
                padding-top: 25px;
                position: relative;

                &.is-reply {
                    padding-top: 85px !important;
                    min-width: 170px;
                }

                .Message-reply-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #F5F5F5;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    padding: 12px 10px 5px 10px;

                    .reply-name {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 22px;
                        /* identical to box height, or 129% */

                        letter-spacing: -0.408px;

                        color: #0FB855;
                    }

                    .reply-details {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 22px;
                        letter-spacing: -0.408px;
                        color: #898A8D;
                        min-height: 40px;
                        line-height: 40px;
                        
                        img {
                            width: 22px;
                            height: 22px;
                            min-width: 22px;
                            object-fit: cover;
                            object-position: 0 0;
                            margin-right: 5px;
            
                            &.link {
                                object-position: 0 0;
                            }
            
                            &.doc {
                                object-position: 0 -22px;
                            }
            
                            &.contact {
                                object-position: 0 -44px;
                            }
            
                            &.img {
                                object-position: 0 -66px;
                            }
            
                            &.audio {
                                object-position: 0 -88px;
                            }
            
                            &.video {
                                object-position: 0 -110px;
                            }
                        }
                    }

                    .reply-attachment-container {
                        padding: 0 !important;
                        max-width: 40px;
                        max-height: 40px;
                        object-fit: cover;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                        }
                    }
                }

                &.isContact {
                    padding-bottom: 0;
                }

                .bubbleMention {
                    font-weight: 600;
                    color: var(--wgreen);
                    cursor: pointer;
                }

                .MessagesDesktopChat-bubble-btn-container {
                    max-width: 30px;
                    max-height: 30px;
                    margin-bottom: 12px;
                    position: absolute;
                    top: 0;
                    right: 4px;
                    padding-left: 0px;
                    padding-right: 0px;

                    .dropdown {
                        .dropdown-toggle {
                            background: transparent;
                            border: none;
                            outline: none !important;
                            padding: 0;
                            box-shadow: none !important;
                            &::after {
                                display: none;
                            }
                        }
                    }
                }

                .MessagesDesktopChat-bubble-name-container {
                    padding-bottom: 5px;
                    cursor: pointer;
                    .name {
                        font-size: 17px;
                        font-weight: 600;
                        color: var(--wblue);
                    }

                    .details {
                        font-weight: 600;
                        color: var(--wgray_dark);
                        font-size: 13px;
                    }
                }

                .attachment-box {
                    padding: 0 0 5px 0;

                    .pdf-file-container {

                        .name-box {
                            a {
                                text-decoration: none;
                                color: var(--wblue);
                                font-size: 17px;
                                display: flex;
                                max-width: 350px;
                                align-items: center;

                                .pdf-icon {
                                    width: 30px;
                                    height: 30px;
                                    margin-right: 5px;
                                }
                            }

                        }

                        .donwloader-box {
                            border-top: 1px solid var(--wgray_soft);
                            padding-top: 4px;
                            text-align: center;

                            a {
                                text-decoration: none;
                                color: var(--wgray);
                                font-size: 17px;
                                font-weight: bold;
                                &.mine {
                                    color: var(--wgreen);
                                }
                            }
                        }
                    }

                    .image-file-container {
                        .message-img {
                            max-width: 350px;
                            width: 100%;
                            max-height: 400px;
                            object-fit: cover;
                            border-radius: 4px;
                        }

                        a {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            .icon-img {
                                width: 25px;
                                height: 25px;
                            }
                        }
                    }

                    .audio-file-container {

                        // audio {
                        //     &::-webkit-media-controls-play-button,
                        //     &::-webkit-media-controls-panel {
                        //         background-color: #fff;
                        //
                        //         &.isMine {
                        //             background-color: var(--wgreen_soft);
                        //         }
                        //         // background-color: transparent;
                        //         // color: #000;
                        //     }
                        //
                        //     // &::-webkit-media-controls-toggle-closed-captions-button,
                        //     // &::-webkit-media-controls-current-time-display {
                        //     //     color: red;
                        //     //     background: red;
                        //     //     background-color: red;
                        //     // }
                        //     //
                        //     // &::-webkit-media-controls-play-button  {
                        //     //     color: blue;
                        //     //     background-color: green;
                        //     // }
                        //
                        //     &::-webkit-media-controls-panel,
                        //
                        //     &::-webkit-media-controls-mute-button,
                        //
                        //     &::-webkit-media-controls-play-button,
                        //
                        //     &::-webkit-media-controls-timeline-container,
                        //
                        //     &::-webkit-media-controls-current-time-display,
                        //
                        //     &::-webkit-media-controls-time-remaining-display,
                        //
                        //     &::-webkit-media-controls-timeline,
                        //
                        //     &::-webkit-media-controls-volume-slider-container,
                        //
                        //     &::-webkit-media-controls-volume-slider,
                        //
                        //     &::-webkit-media-controls-seek-back-button,
                        //
                        //     &::-webkit-media-controls-seek-forward-button,
                        //
                        //     &::-webkit-media-controls-fullscreen-button,
                        //
                        //     &::-webkit-media-controls-rewind-button,
                        //
                        //     &::-webkit-media-controls-return-to-realtime-button,
                        //
                        //     &::-webkit-media-controls-toggle-closed-captions-button {
                        //         color: blue;
                        //         background-color: green;
                        //         background: yellow;
                        //     }
                        // }
                        // audio::-webkit-media-controls-panel
                        //
                        // audio::-webkit-media-controls-mute-button
                        //
                        // audio::-webkit-media-controls-play-button
                        //
                        // audio::-webkit-media-controls-timeline-container
                        //
                        // audio::-webkit-media-controls-current-time-display
                        //
                        // audio::-webkit-media-controls-time-remaining-display
                        //
                        // audio::-webkit-media-controls-timeline
                        //
                        // audio::-webkit-media-controls-volume-slider-container
                        //
                        // audio::-webkit-media-controls-volume-slider
                        //
                        // audio::-webkit-media-controls-seek-back-button
                        //
                        // audio::-webkit-media-controls-seek-forward-button
                        //
                        // audio::-webkit-media-controls-fullscreen-button
                        //
                        // audio::-webkit-media-controls-rewind-button
                        //
                        // audio::-webkit-media-controls-return-to-realtime-button
                        //
                        // audio::-webkit-media-controls-toggle-closed-captions-button
                    }
                }

                .text-box {
                    padding: 5px 0 0px 0;
                    font-size: 17px;
                    color: var(--wblue_dark);
                    word-break: break-word;

                    &.deleted {
                        color: var(--wgray_dark);
                        font-style: italic;
                        font-size: 15px;
                    }
                    .bubble-showmore {
                        text-decoration: none !important;
                        outline: none !important;
                        color: var(--wblue);
                        font-size: 13px;
                        // display: block;
                        text-align: right;
                    }

                    &.isContact {
                        .contactName {
                            font-size: 17px;
                            color: var(--wblue);
                            display: flex;
                            align-items: center;
                        }

                        .bubble-timesince {
                            font-size: 13px;
                            color: var(--wgray_dark);
                            padding: 4px 0 !important;
                            text-align: right;
                        }

                        .callBtn {
                            padding: 0;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-top: 1px solid var(--wgray_soft);
                            font-size: 17px;
                            font-weight: 600;
                            color: var(--wgreen);
                        }
                    }
                }

                .bubble-timesince {
                    font-size: 13px;
                    color: var(--wgray_dark);
                    text-align: right;
                }

            }

            
            .icn_send_failed {
                cursor: pointer;
                height: 32px;
                width: 32px;
                margin-left: 5px;
            }
        }

        .MessagesDesktopChat-div-scrolled {
            height: 0;
            width: 100%;
        }
    }

    .ChatBanned-container {
        background: var(--wgray_soft);
        position: absolute;
        bottom: 0;
        left: 0;
        min-height: 50px;
        width: 100%;
        border-top: 1px solid var(--wgray_semisoft);
        padding: 14px 20px;
        line-height: 19px;
        text-align: center;
        font-size: 17px;
        color: var(--wgray_darker);
        font-weight: 600;
    }

    .container-crop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
    }
}
