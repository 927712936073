.RecomendationsList-container {

    .RecomendationsList-header-container {
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 8px solid var(--wgray_softer);
        font-size: 17px;
        font-weight: 600;
        color: var(--wgray_darker);
    }

    .RecomendationsList-items-container {

        .recomendation-item {
            padding: 0 calc(var(--bs-gutter-x) * .5);
            margin-bottom: 24px;

            .recomendation-item-row {
                background: #F4F4F6;
                padding: 12px;
                border-radius: 5px;

                .recomendation-details-box {
                    line-height: 20px;
                }

                .recomendation-text-box {
                    padding-top: 10px;
                    font-size: 17px;
                    color: var(--wgray_darker);
                    line-height: 20px;
                }

                .avatar-image-box-60 {

                    .likeIcn {
                        position: absolute;
                        border: none;
                        background: #fff;
                        width: 30px;
                        height: 30px;
                        bottom: 0;
                        right: -6px;
                    }
                }

                .recomendation-options {
                    max-width: 30px;
                    max-height: 30px;
                    top: 0;
                    right: 0;
                    z-index: 2;
                    position: absolute;

                    &.isDetails {
                        position: absolute;
                        top: 20px;
                        right: calc(var(--bs-gutter-x) * .5);
                    }

                    .dropdown {
                        .dropdown-toggle {
                            background: transparent;
                            border: none;
                            outline: none !important;
                            padding: 0;
                            box-shadow: none !important;
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
