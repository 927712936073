.Directory-container {
    max-width: 700px;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);
}

@media screen and (min-width: 1450px) {
    .Directory-container {
        background: var(--wgray_softer);
        width: 100%;
        height: 100%;
        max-width: 100%;
        margin: 0 auto;

        .Directory-box-container {
            padding-top: 25px;
            height: calc(100vh - 100px);
            overflow: auto;

            .Directory-lateral-content {
                max-width: calc(350px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));

                .left {

                }

                .right {

                }
            }

            .Directory-post-box {
                max-width: 700px;
                min-width: 700px;
                border-left: 1px solid var(--wgray_semisoft);
                border-right: 1px solid var(--wgray_semisoft);

                .Directory-post-container {
                    width: 100%;
                    padding: 14px 0;
                    // height: calc(100% - 65px - 61px);
                    height: auto;
                    overflow: visible;
                    background: var(--wgray_softer);

                    .Directory-loaderBox {
                        background: #fff;
                        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
                        border-radius: 5px;
                        margin-bottom: 14px;
                        padding: 14px;
                    }
                }
            }


        }


    }
}
