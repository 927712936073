.AsociationsSheet-container {
    margin: 24px 12px 0 12px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
    padding: 24px;


    .AsociationsSheet-asociations-container {
        margin-top: 10px;
        background: #fff;
        padding: 24px 15px;

        .colTitle {
            font-size: 17px;
            font-weight: bold;
            color: var(--wblue_dark);
            padding-bottom: 14px;
        }

        .asociationBox {
            padding: 12px 14px;
            background: var(--wgray_softer);
            border-radius: 2px;
            margin-bottom: 10px;

            .asocTitle {
                font-size: 17px;
                font-weight: bold;
                color: var(--wblue);
            }

            .asocDetails {
                font-size: 18px;
                color: var(--wgray_darker);
            }
        }

    }
}
