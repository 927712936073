.NotificationBirthday-container {
    background: #fff;
    width: 100%;
    height: 100vh;
    overflow: auto;
    top: 0;
    z-index: 3;
    padding: 0px 0px 90px 0px;
    max-width: 700px;

    .NotificationBirthday-logo-container {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 150px;
            height: 50px;
            object-fit: contain;
        }
    }

    .NotificationBirthday-title-container {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        color: #0D458D;

        padding-top: 50px;
    }

    .NotificationBirthday-subtitle-container {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #0FB855;
        line-height: 20px;
    }

    .NotificationBirthday-users-container {
        padding: 24px 0;

        .NotificationBirthday-user-item {
            border: 1px solid #CDCED4;
            border-radius: 5px;
            padding: 14px;
            background: #F4F4F6;
            margin-bottom: 14px;

            .description-box {
                font-size: 18px;
                color: #272C40;
                line-height: 20px;
                padding-top: 20px;
            }

            .btn-container {
                text-align: end;

                button {
                    color: #0FB855;
                    font-weight: bold;
                    font-size: 16px;
                    padding-top: 20px;
                }
            }
        }
    }

    .NotificationBirthday-footer-container {
        padding: 15px 0;
        width: 100%;
        position: fixed;
        bottom: 0;
        max-width: 700px;
        background: #fff;

        left: 50%;
        transform: translateX(-50%);

        button {
            height: 55px;
            width: 100%;
            background: #0D458D;
            color: #fff;
            font-size: 17px;
            font-weight: 600;
        }
    }
}


@media screen and (min-width: 1450px) {
    .NotificationsList-container {
        background: var(--wgray_softer);
        width: 100%;
        height: 100%;
        max-width: 100%;
        margin: 0 auto;

        .NotificationsList-box-container {
            padding-top: 25px;
            height: calc(100vh - 100px);
            overflow: auto;

            .NotificationsList-lateral-content {
                max-width: calc(350px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));

                .left {

                }

                .right {

                }
            }

            .NotificationsList-post-box {
                max-width: 700px;
                min-width: 700px;
                border-left: 1px solid var(--wgray_semisoft);
                border-right: 1px solid var(--wgray_semisoft);

                .NotificationsList-post-container {
                    width: 100%;
                    padding: 14px 0;
                    // height: calc(100% - 65px - 61px);
                    height: auto;
                    overflow: visible;
                    background: var(--wgray_softer);

                    .NotificationsList-loaderBox {
                        background: #fff;
                        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
                        border-radius: 5px;
                        margin-bottom: 14px;
                        padding: 14px;
                    }
                }
            }


        }


    }
}
