.DirectoryList-container {
    background: var(--wgray_softer);
    .DirectoryList-tabs-box {

        .nav.nav-tabs {
            border: none;
            height: 50px;
            // margin-top: 15px;
            // margin-bottom: 10px;

            .nav-item.nav-link {
                width: 33%;
                text-align: center;
                border: none;
                font-size: 16px;
                font-weight: 600;
                border-bottom: 4px solid var(--wgray_soft);
                color: var(--wgray_dark);
                text-transform: capitalize;
                display: flex;
                align-items: center;
                justify-content: center;

                &.active {
                    color: var(--wblue_dark);
                    border-bottom: 4px solid var(--wgreen);
                    background: var(--wgray_softer);
                }

            }
        }

        .tab-content {
            background: #fff;
            height: 100%;

            .tab-pane {
                height: 100%;
            }
        }

        // .Login-step-btn-box {
        //     padding-top: 16px;
        //
        //     button {
        //         height: 55px;
        //         border-radius: 4px;
        //         background: var(--wblue);
        //         color: #fff;
        //         font-size: 16px;
        //         font-weight: bold;
        //         border: none;
        //         outline: none !important;
        //         width: 100%;
        //         padding: 0;
        //     }
        // }
    }
}

@media screen and (min-width: 1450px) {
    .DirectoryList-container {

        .DirectoryList-tabs-box {

            .tab-content {
                height: calc(100vh - 107px - 50px);

                .tab-pane {
                    height: 100%;
                }
            }
        }
    }
}
