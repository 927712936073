.ProfileSheet-container {

    .ProfileSheet-photo-container {
        padding: 0;
        max-width: 350px;
        height: 350px;

        img {
            width: 350px;
            height: 350px;
            object-fit: cover;
            border-radius: 10px;
            box-shadow: 0 1px 10px rgba(0,0,0,0.14);
        }
    }

    .ProfileSheet-info-container {
        background: #fff;
        max-width: 350px;
        min-width: 350px;
        box-shadow: 0 1px 10px rgba(0,0,0,0.14);
        border-radius: 10px;
        margin-top: -20px;
        padding: 30px 20px;

        .ProfileSheet-name-container {
            font-size: 24px;
            font-weight: bold;
            color: var(--wblue_dark);
        }

        .ProfileSheet-bm-icon {
            padding: 0;
            max-width: 20px;
            max-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 20px;
                height: 20px;
            }
        }

        .ProfileSheet-details-container {
            .icnBox {
                width: 30px;
                height: 30px;
                max-width: 30px;
                max-height: 30px;

                img {
                    width: 30px;
                    height: 30px;
                    object-position: 0px -0px;
                    object-fit: cover;

                    $positions: 8;

                    @for $i from 0 through $positions {
                        &.pos-#{$i + 1} {
                            object-position: 0px calc(-30px * #{$i});
                        }
                    }
                }
            }

            .txtBox {
                font-size: 18px;
                color: var(--wgray_darker);
            }
        }
    }
}
