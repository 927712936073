.BottomSheetShared-header-box {
    position: relative;

    button {
        background: transparent;
        color: var(--wgreen);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        border: none;
        padding: 0;
        outline: none !important;
        &.left {
            left: 0px;
        }

        &.right {
            right: 0px;
        }
    }

    p {
        font-size: 17px;
        font-weight: 600;
        color: var(--wblue_dark);
        text-align: center;
        margin: 0;
        width: 100%;
    }

    .likesCounter {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: var(--wgray_darker);

        img {
            object-fit: cover;
            object-position: 0 0;
            margin-left: 7px;
        }
    }
}

.BottomSheetShared-footer-box {
    position: relative;
    background: #fff;
    button {
        background: var(--wgray_semisoft);
        height: 45px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        border-radius: 5px;
        width: 100%;
        outline: none !important;

        &.active {
            background: var(--wgreen);
            &.red {
                background: var(--wred);
            }
        }
    }
}
