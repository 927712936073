.AudioRecorder-container {

    .audio-react-recorder {
        canvas {
            display: none;
        }
    }

    .AudioRecorder-buttons-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .emptyCounter {
            height: 25.5px;
        }

        button {
            width: 90px;
            height: 90px;
            img {
                width: 90px;
                height: 90px;
                object-fit: cover;

                &.record {
                    object-position: 0 0;
                }

                &.stop {
                    object-position: 0 -90px;
                }

                &.play {
                    object-position: 0 -180px;
                }

                &.playing {
                    object-position: 0 -270px;
                }
            }
        }

        .statusLabel {
            font-size: 18px;
            font-weight: 600;
            padding-top: 15px;
        }

        .Timer-container {
            font-size: 17px;
            text-align: center;
            color: var(--wblue);
        }

        .AudioRecorder-timer-container {
            font-size: 17px;
            text-align: center;

            &.record {
                color: var(--wgray);
            }

            &.playing,
            &.play {
                color: var(--wblue);
            }

            &.stop {
                color: var(--wgreen);
            }
        }
    }

    .AudioRecorder-actions-container {
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        max-width: 375px;


        &.cancelBox {
            align-items: center;
            justify-content: center;

            button {
                font-size: 18px;
                height: 40px;
                width: 100px;
                color: var(--wred);
            }
        }

        &.submitBox {
            justify-content: space-between;

            button {
                display: flex;
                flex-direction: column;

                img {
                    width: 52px;
                    height: 52px;
                    object-fit: cover;

                    &.cancel {
                        object-position: 0 0;
                    }

                    &.submit {
                        object-position: 0 -52px;
                    }
                }

                span {
                    font-size: 16px;
                    padding-top: 10px;
                }
            }
        }
    }

    .AudioRecorder-nopermission-container {
        padding: 20px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            margin: 0;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }

        .subTxt {
            font-size: 16px;
            color: var(--wgray_dark);
        }

        .cancelBtn,
        .submitBtn {
            height: 45px;
            font-size: 16px;
            font-weight: 600;
            background: rgb(226, 226, 230);
            width: 150px;
            margin-top: 10px;
        }

        .submitBtn {
            color: var(--wblue);
            margin-right: 5px;
        }

        .cancelBtn {
            color: var(--wred);
            margin-left: 5px;
        }
    }
}
