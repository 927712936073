.HomeHeader-container {
  padding-bottom: 5px;

  .HomeHeader-name-box {
    font-size: 28px;
    font-weight: bold;
    color: var(--wblue);
  }

  .HomeHeader-postTypes-container {
    padding: 16px 15px 0 15px;

    .HomeHeader-postType-item {
      background: transparent;
      color: #585c6e;
      border-bottom: 5px solid var(--wgray_dark);

      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 30px;
      padding-left: 30px;
      padding-bottom: 10px;
      padding-top: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;

      text-align: center;
      letter-spacing: -0.36px;

      color: #585c6e;
      max-width: 33%;

      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }

      &.selected {
        color: var(--wgreen);
        border-bottom: 5px solid var(--wgreen);
        /* border-radius:  0 0 5px 5px; */
      }
    }
    // .border-line {
    //   border-radius: 0 0 0 5px;
    // }

    // .last-border {
    //   border-radius: 0 0 5px 0;
    // }
  }
}
