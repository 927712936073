.container-crop {
    // border-left: 1px solid var(--wgray_soft);
    // border-right: 1px solid var(--wgray_soft);
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300000000;

    .mainBox {
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        overflow: hidden;

        .react-crop {

            width: fit-content;
            margin-top: 8%!important;
            max-height:350!important;
            overflow: hidden!important;
            margin: 0 auto;
            display: block;

            img {
                width: auto;
                height: 350px!important;
                margin: 0 auto!important;
            }
        }

        h2 {
            text-align: center!important;
            width: 100%;
            margin-top: 40px;
        }

        .reactEasyCrop_Container {
            max-height: 100vh;
            overflow: hidden;
        }

        .zoom {
            bottom: 40px;
            position: fixed;
            z-index: 2;
            width: 100%;
            height: 120px;


            h4 {
                z-index: 3;
                text-align: center;
                color: white;
            }

            .buttons-bottom {
                margin-top: 0;
                align-items: center;
                width: 100%;
                display: flex;
                justify-content: center;

                .zoom-plus {
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    background-color: var(--wgray_dark);
                    border: none;
                    color: white;
                    font-size: 17px;
                    margin: 0 5px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .zoom-minus {
                    width: 40px;
                    height: 40px;
                    margin: 0 5px;
                    border-radius: 20px;
                    background-color: var(--wgray_dark);
                    border: none;
                    color: white;
                    font-size: 17px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .buttons-top {
            width: auto;
            margin: 6% auto;
            display: flex;
            position: fixed;
            z-index: 2;
            top: 25px;
            justify-content: center;

            .button-cancel {
                width: 100px;
                background-color: red;
                color: white;
                border: none;
                font-weight: bold;
                padding: 10px;
                border-radius: 5px;
                margin-right: 20px;
            }

            .button-cut {
                width: 100px;
                background-color: green;
                color: white;
                border: none;
                font-weight: bold;
                padding: 10px;
                margin-left: 20px;
                border-radius: 5px;
            }
        }
    }

}
