.PostDetailsComments-container {
    padding: 20px 14px;

    .PostDetailsComments-comment-container {

        .PostDetailsComments-user-image-container {
            max-width: 50px;
            max-height: 50px;
            padding: 0;
            position: relative;

            img {
                width: 50px;
                height: 50px;
                border-radius: 25px;
                object-fit: cover;
                border: 2px solid var(--wgray_dark);
            }

            .avatar {
                position: relative;
            }

            .miembro {
                position: absolute;
                width: 25px;
                height: 25px;
                bottom: 0;
                right: -5px;
            }
        }

        .PostDetailsComments-comment-content {
            padding-bottom: 14px;
            padding-right: 0;
            max-width: calc(100% - 50px);

            .PostDetailsComments-comment-row {
                background: var(--wgray_soft);
                border-radius: 5px;
                padding: 14px;

                .PostDetailsComments-user-details {
                    line-height: 17px;

                    .title {

                        .name {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            font-size: 17px;
                            font-weight: 600;
                            color: var(--wblue_dark);

                            .timesince {
                                font-size: 11px;
                                color: var(--wblue);
                                text-align: right;
                                max-width: 110px;
                                min-width: 50px;
                                display: inline-block;
                            }

                            .label-Mentor{
                                background-color: var(--wblue);
                                color: white;
                                padding-left: 8px;
                                padding-right: 8px;
                                padding-top: 2px;
                                padding-bottom: 2px;
                                font-size: 13px;
                                letter-spacing:-0.31px ;
                                margin-left: 10px;
                            }
                        }

                    }

                    .subtitle {
                        font-size: 13px;
                        color: var(--wgray_darker);
                    }

                    .PostDetailsComments-options-container {
                        max-width: 30px;
                        max-height: 30px;

                        .dropdown {
                            .dropdown-toggle {
                                background: transparent;
                                border: none;
                                outline: none !important;
                                padding: 0;
                                box-shadow: none !important;
                                img {
                                    object-fit: cover;
                                }
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .PostDetailsComments-comment-box{
                    font-size: 15px;
                    color: var(--wblue_dark);
                    word-break: break-word;
                    padding-top: 10px;
                    padding-bottom: 10px;


                    div {
                        padding: 0;
                    }

                    .PostDetailsComments-comment-showmore {
                        text-decoration: none !important;
                        outline: none !important;
                        color: var(--wblue);
                        font-size: 13px;
                        display: block;
                        text-align: right;
                    }

                    .bubbleMention {
                        font-weight: 600;
                        color: var(--wgreen);
                        cursor: pointer;
                    }
                }

                .dinamycSmall{
                    div{
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
                .dinamycMedium{
                    div{
                        font-size: 22px;
                        line-height: 24px;
                    }
                }
                .dinamycBig{
                    div{
                        font-size: 28px;
                        line-height: 32px;
                    }
                }
            }
        }

        .PostDetailsComments-files-row {
            .PostDetailsComments-pdf-container {
                border-radius: 5px;
                background: var(--wgray_softer);
                padding: 5px 0 0 0;
                text-align: center;

                a {
                    text-decoration: none !important;
                    outline: none !important;
                    color: var(--wblue_dark);
                }

                img {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 5px;
                }
                div {
                    text-align: left;
                    padding: 5px;
                    background: var(--wgray_semisoft);
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            .PostDetailsComments-image-container {
                position: relative;
                padding: 0;


                a {
                    img {
                        position: absolute;
                        width: 25px;
                        height: 25px;
                        top: 7px;
                        right: 7px;
                    }
                }

                &.noComments {
                    padding-top: 10px;
                    a {
                        img {
                            top: 15px;
                        }
                    }
                }

                .image-container {
                    width: 100%;
                    height: auto;
                    border-radius: 5px;
                }

                audio {
                    width: 100%;

                    &::-webkit-media-controls-play-button,
                    &::-webkit-media-controls-panel {
                        background-color: var(--wgray_soft);
                    }
                }
            }

            .isContact {
                padding-top: 10px;

                .contactName {
                    font-size: 17px;
                    color: var(--wblue);
                    display: flex;
                    align-items: center;
                }

                .bubble-timesince {
                    font-size: 13px;
                    color: var(--wgray_dark);
                    padding: 4px 0 !important;
                    text-align: end;
                }

                .callBtn {
                    padding: 0;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-top: 1px solid #e1e1e1;
                    font-size: 17px;
                    font-weight: 600;
                    color: var(--wgreen);
                    margin-top: 10px;
                }
            }
        }
    }
}
