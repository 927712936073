.container-card {

    background-color: white;
    height: 100vh;
    padding: 0;

    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);

    .container_header {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        padding: 30px 15px 10px 15px;
        align-items: center;
        background-color: #F8F8FB;
        margin-bottom: 30px;


        h3 {
            color: black;
            width: 100vw;
            text-align: center;
            display: block;
            font-size: 17dp;
        }
    }

    .container_form {
        padding: 20px;
        margin-top: 20px;

        &_input {
            width: 100%;
            margin-bottom: 15px;
            p {
                margin: 0;
                margin-bottom: 5px;
                font-size: 15px;
            }

            input {
                width: 100%;
                height: 45px;
                padding: 10px;
            }
        }

        &_column {
            display: flex;
            justify-content: space-between;
            overflow: hidden;

            p {
                margin-left: 5px;
            }

            input {
               text-align: center;
               width: 95%;
               margin: 0 auto;
               display: block;
            }

        }
    }

    button {
        background-color: #0D458D;
        height: 45px;
        border: none;
        border-radius: 5px;
        color: white;
        width: 88%;
        margin-top: 40px;
        margin: 0 auto;
        display: block;
    }
}
