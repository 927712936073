.Loader-container {
    &.Loader-animated-in {
        animation: .3s ease-in-out both fade-in;
    }

    &.Loader-animated-out {
        animation: .3s ease-in-out both fade-out;
    }


    background: var(--wblue_dark);
    width: 100%;
    height: 100vh;


    .Loader-image {
        width: 150px;
        height: 50px;
        object-fit: contain;
    }

    .Loader-text-container {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        padding-top: 20px;
    }

    .Loader-svg-container {

        .pl {
        	display: block;
        	width: 6.25em;
        	height: 6.25em;
        }
        .pl__ring, .pl__ball {
        	animation: ring 2s ease-out infinite;
        }
        .pl__ball {
        	animation-name: ball;
        }

        /* Dark theme  */
        @media (prefers-color-scheme: dark) {
        	:root {
        		--bg: hsl(var(--hue),10%,10%);
        		--fg: hsl(var(--hue),10%,90%);
        	}
        }

        /* Animation */
        @keyframes ring {
        	from {
        		stroke-dasharray: 0 257 0 0 1 0 0 258;
        	}
        	25% {
        		stroke-dasharray: 0 0 0 0 257 0 258 0;
        	}
        	50%, to {
        		stroke-dasharray: 0 0 0 0 0 515 0 0;
        	}
        }
        @keyframes ball {
        	from, 50% {
        		animation-timing-function: ease-in;
        		stroke-dashoffset: 1;
        	}
        	64% {
        		animation-timing-function: ease-in;
        		stroke-dashoffset: -109;
        	}
        	78% {
        		animation-timing-function: ease-in;
        		stroke-dashoffset: -145;
        	}
        	92% {
        		animation-timing-function: ease-in;
        		stroke-dashoffset: -157;
        	}
        	57%, 71%, 85%, 99%, to {
        		animation-timing-function: ease-out;
        		stroke-dashoffset: -163;
        	}
        }
    }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}
