.FilterTable-container {

    .FilterTable-cell-header {
        height: 55px;
        font-size: 17px;
        color: var(--wblue_dark);
        font-weight: bold;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--wgray_semisoft);
    }
}
