.HomePostType-container {
  .HomePostType-postTypes-container {
    padding-top: 16px;
    overflow: auto;

    .HomePostType-postType-item {
      background: transparent;
      color: #585c6e;
      border-bottom: 4px solid #dcdbdb;

      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 30px;
      padding-left: 30px;
      padding-bottom: 10px;
      padding-top: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;

      text-align: center;
      letter-spacing: -0.36px;

      color: #585c6e;

      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }

      &.selected {
        color: var(--wgreen);
        border-bottom: 4px solid var(--wgreen);
        /* border-radius:  0 0 5px 5px; */
      }
    }

    // .border-line {
    //   border-radius: 0 0 0 5px;
    // }

    // .last-border {
    //   border-radius: 0 0 5px 0;
    // }
  }
}
