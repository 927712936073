.InputBox-container {
    background: var(--wgray_soft);
    // position: fixed;
    position: absolute;
    bottom: 0;
    left: 0;
    min-height: 50px;
    width: 100%;
    border-top: 1px solid var(--wgray_semisoft);
    z-index: 1;

    &.isRecording {
        background: #fff;
        border-top: none;
        border-radius: 10px;
        box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    }

    .input-reply-container {
        padding-top: 5px;
        padding-bottom: 5px;

        .name-container {
            font-weight: 600;
            font-size: 17px;
            line-height: 22px;
            letter-spacing: -0.408px;
            color: #0FB855;
        }

        .details-container {
            font-style: normal;
            font-weight: 300;
            font-size: 17px;
            line-height: 22px;

            letter-spacing: -0.408px;

            color: #898A8D;
            display: flex;
            align-items: center;

            img {
                width: 22px;
                height: 22px;
                object-fit: cover;
                object-position: 0 0;
                margin-right: 5px;

                &.link {
                    object-position: 0 0;
                }

                &.doc {
                    object-position: 0 -22px;
                }

                &.contact {
                    object-position: 0 -44px;
                }

                &.img {
                    object-position: 0 -66px;
                }

                &.audio {
                    object-position: 0 -88px;
                }

                &.video {
                    object-position: 0 -110px;
                }
            }
        }

        .btn-closer-container {
            padding: 0;
            max-width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                width: 40px;
                height: 40px;
                padding: 0;
                background: transparent;
                border: none;
                outline: none !important;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .InputBox-editor-container {
        padding: 5px;

        .Input-attachment-btn-container,
        .InputBox-send-container {
            max-width: 30px;
            max-height: 30px;

            img {
                width: 30px;
                height: 30px;
                object-fit: cover;
            }
        }

        .Input-input-container {
            padding: 0 10px;
            max-width: calc(100% - 30px - 30px);
            position: relative;
            textarea {
                resize: none;
                outline: none !important;
                width: 100%;
                border: none;
                overflow: hidden;
                min-height: 30px;
                height: 30px;
                line-height: 20px;
                font-size: 17px;
                background: #fff;
                border-radius: 15px;
                padding-left: 10px;
                padding-right: 10px;
            }

            .DraftEditor-root {
                width: 100%;
                border: none;
                outline: none !important;
                min-height: 30px;
                line-height: 20px;
                font-size: 17px;
                background: #fff;
                border-radius: 15px;
                padding: 2px 10px;
                max-height: 64px;
                overflow: auto;

                a {
                    color: var(--wgreen);
                    font-weight: 600;
                }
            }

            .DraftEditor-root + div,
            .InputBox-mentions-container {
                background: #f9f9f9;
                box-shadow: 0 2px 10px rgba(0,0,0,0.14);
                border-radius: 8px;
                cursor: pointer;
                padding: 8px;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                // transform-origin: 50% 0%;
                // transform: scaleY(0);
                // margin: -16px;
                position: absolute;
                bottom: calc(100% + 5px);
                max-height: 200px;
                overflow: auto;

                .InputBox-mention-box {
                    padding: 5px;
                    &:hover {
                        background: var(--wgray_soft);
                    }
                    .InputBox-mention-main {

                        .InputBox-mention-left {
                            position: relative;
                            .bmIcon {
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                right: 0;
                                bottom: 0;
                            }
                        }

                        .InputBox-mention-right {
                            max-width: calc(100% - 45px);

                            .InputBox-mention-name {
                                font-size: 18px;
                                font-weight: 600;
                                color: var(--wblue_dark);
                            }

                            .InputBox-mention-title {
                                font-size: 14px;
                                color: var(--wgray_darker);
                            }
                        }
                    }
                }
            }
        }
    }

    .Input-file-container {
        padding: 4px 8px;

        .InputBox-file-pdf {
            .Input-inner-div {
                box-shadow: 0 1px 10px rgba(0,0,0,0.05);
                background: #fff;
                display: inline-block;
                font-size: 15px;
                padding-left: 10px;
                padding-right: 25px;
                border-radius: 12.5px;
                max-width: 100%;
                position: relative;
                .text-div {
                    width: 100%;
                    display: inline-block;
                }
                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 25px;
                    width: 25px;
                }
            }

        }

        .InputBox-file-image {
            padding: 5px;

            .image-container {
                height: 60px;
                width: 80px;
                border-radius: 5px;
                object-fit: cover;
            }
            button {
                width: 35px;
                height: 35px;
                img {
                    width: 35px;
                    height: 35px;
                }
            }

            .videoContainer-box {
                position: relative;
                height: 60px;
                width: 80px;

                .videoOverlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    background: linear-gradient(to bottom, rgba(0,0,0,0), #383838);
                }

                .videoPlayIcon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 35px;
                    height: 35px;
                    transform: translate(-50%, -50%);
                }

                .durationSpan {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                    font-size: 12px;
                    color: #d1d1d1;
                }
            }
        }
    }
}

// .AddPost-textfield-container {
//     position: relative;
//     padding: 24px 14px;
//     text-align: right;
//
//     textarea {
//         // resize: none;
//         // height: 195px;
//         // border: none;
//         // outline: none !important;
//         // width: 100%;
//         // font-size: 17px;
//         // color: var(--wgray_darker);
//         max-height: 250px;
//         display: block;
//         width: 100%;
//         overflow: hidden;
//         resize: none;
//         min-height: 40px;
//         line-height: 20px;
//         border: none;
//         outline: none !important;
//         font-size: 17px;
//         color: var(--wgray_darker);
//     }
//     span {
//         font-size: 13px;
//         color: var(--wgray_dark);
//     }
// }
