
.conatainer_password {

    width: 100%;
    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);

    .container_header {
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        display: flex;
        padding: 30px 15px 10px 15px;
        align-items: center;
        background-color: #F8F8FB;


        h3 {
            color: black;
            width: 100%;
            text-align: center;
            display: block;
            font-size: 17dp;
        }

        img {
            position: absolute;
            left: 15px;
            cursor: pointer;
        }
    }

    &_input {
        padding: 15px;

    }

    .jump_top {
        margin-top: 40px;
    }

    button {
        width: 92%;
        margin: 0 auto;
        display: block;
        border: none;
        background-color: #0D458D;
        height: 50px;
        color: white;
        font-weight: bold;
        margin-top: 50px;
        border-radius: 5px;
    }

    .button-recover {
        border: 1px solid #0FB855;
        color: #0FB855;
        background-color: #F8F8FB;
    }
}

.RegisterCode-input-box {
    padding: 0 15px;
    .passwordDisclaimer {
        line-height: 15px;
        font-size: 0.875em;
        margin: 0;
        padding-top: 5px;
        color: var(--wgray_darker);
    }
}

.conatainer_password.isDesktop {

    width: 100%;
    height: calc(100% - 16px);
    color: #9A9BA0;
    font-size: 16px;
    position: relative;
    padding-bottom: 40px;
    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);

    .container_header {
        margin: 0;
        background-color: #fff!important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 30px 15px 10px 15px;
        height: 60px;

        h3 {
            font-size: 20px !important;
            color: var(--wblue_dark);
            text-align: left;
        }

        &_bloqued {
            width: 100%;
            text-align: center;
            color: gray;
            margin-top: 20px;
            padding: 0 14px;
        }

    }

    &_input {
        padding: 15px;

    }

    .jump_top {
        margin-top: 0px;
    }

    button {
        width: 92%;
        margin: 0 auto;
        display: block;
        border: none;
        background-color: #0D458D;
        height: 50px;
        color: white;
        font-weight: bold;
        margin-top: 50px;
        border-radius: 5px;
    }

    .button-recover {
        border: 1px solid #0FB855;
        color: #0FB855;
        background-color: #fff;
    }
}
