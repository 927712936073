
.container_membership {
    max-width: 700px!important;
    margin: 0 auto;
    height: 100vh;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);
    background: #fff;

    .container_header_membership {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        padding: 30px 15px 10px 15px;
        align-items: center;
        background-color: #fff;
        max-width: 700px!important;
        margin: 0 auto;
        border-left: 1px solid var(--wgray_soft);
        border-right: 1px solid var(--wgray_soft);


        p {
            margin: 0;
            height: auto;
            text-align: center;
            margin: 0;
            text-align: center;
            color: green;
        }

        h3 {
            width: 100%;
            color: black;
            text-align: center;
        }
    }

    h5 {
        width: 90%;
        margin-left: 20px;
        color: #0D458B;
        margin-top: 20px;
        max-width: 700px!important;
    }

    .container_data_option_detail_member {
        background-color: #F8F8FB;
        border-radius: 10px;
        padding: 15px;
        margin: 15px;
        max-width: 700px!important;
        margin: 0 20px;
        border-left: 1px solid var(--wgray_soft);
        border-right: 1px solid var(--wgray_soft);

        h5 {
            color: #0D458D;
            margin-left: 0;
        }

        &.flex {
            display: flex;
            justify-content: flex-start;
            padding: 25px;

            p {
                margin: 0;
            }

            h5 {
                margin: 0;
            }
        }

        &_description {
            margin-left: 20px;
        }
    }

    .buttom_membership_profile {
        color: var(--wblue);
        border-radius: 5px;
        width: calc(100% - 20px - 20px);
        height: 55px;
        display: block;
        margin: 140px 20px 140px 20px;
        border: 1px solid var(
        --wblue);
        background: transparent;
        font-size: 18px;
        font-weight: 600;

        &:hover {
            color: #fff;
            background: var(--wblue);
        }
    }
}
