.AddPostCarousel-container {
  padding-top: 26px;
  height: calc(26px + 35px);
  overflow: auto;

  .AddPostCarousel-postType-item {
    background: transparent;
    color: var(--wgray_darker);

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;

    height: 35px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 5px;

    .icn-select {
      margin-right: 15px;
    }

    &:first-child {
      margin-left: 16px;
    }
    &:last-child {
      margin-right: 16px;
    }

    &.selected {
      color: var(--wgreen);
    }
  }
}

.AddPostCarousel-subcategory-container {
  padding-top: 16px;
  overflow: auto;
  display: flex;
  min-height: calc(40px + 30px);
  margin-left: 20px!important;
  margin-right: 20px!important;

  &::-webkit-scrollbar {
    width: 10px;
    height: 4px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--wgreen);
  }

  &::-webkit-scrollbar-track {
    background: #dcdbdb;
  }

  .Home-filter-ment-item {
    background: transparent;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: auto;


    border: 1px solid #dcdbdb;
    border-radius: 6px;
    margin-right: 10px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */

    text-align: center;
    letter-spacing: -0.32px;

    color: #9a9ba0;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }

    &.selected {
      background: #ddeafb;
      border: 1px solid #0d458d;
      border-radius: 6px;
      color: #0d458d;
    }
  }
}
