.HomeFilterToggler-container {
    margin-top: 20px;

    .HomeFilterToggler-box {
        position: relative;
        display: flex;
        justify-content: flex-end;

        .line-span {
            background: var(--wgray_semisoft);
            position: absolute;
            width: 50%;
            height: 1px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        .btnToggler {
            background: var(--wgray_softer);
            z-index: 2;
            padding-left: 18px;
            font-size: 18px;
            font-weight: 600;
            color: var(--wblue);

            span {
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        .letterSize{
            margin-right: 60px;

            .text-letter-size{
                margin-left: 10px;
            }
        }
    }
}
