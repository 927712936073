.Searcher-container {
    padding: 20px 14px 5px 14px;
    width: 100%;

    .Searcher-logo-box {

        img {
            height: 20px;
            width: auto;
        }
    }
    .Searcher-input-box {
        padding-left: 0;
        padding-right: calc(var(--bs-gutter-x) * .5);
        position: relative;

        label {
            position: absolute;
            height: 40px;
            width: 40px;
            margin: 0;
            top: 0;
            left: 0;

            img {
                width: 100%;
                height: 40px;
                padding: 3px;
            }
        }

        input {
            height: 40px;
            padding-left: 40px;
            padding-right: 40px;
            background: var(--wgray_soft);
            border: none;
            outline: none !important;
            border-radius: 5px;
            width: 100%;
        }
        button {
            position: absolute;
            height: 40px;
            width: 40px;
            top: 0;
            right:calc(var(--bs-gutter-x) * .5);

            img {
                width: 100%;
                height: 40px;
                padding: 3px;
            }
        }
    }

    .Searcher-toggle-box {
        padding: 0;
        max-width: 40px;

        button {
            font-size: 14px;
            color: var(--wgray_darker);
            width: 40px;
            height: 40px;

            img {
                width: 100%;
                height:40px;
            }
        }
        &.toggled {
            max-width: 68px;
            button {
                width: 100%;
            }
        }
    }
    .Searcher-filter-box {
        max-width: 40px;
        position: relative;
        .dotSpan {
            background: red;
            height: 8px;
            width: 8px;
            position: absolute;
            border-radius: 4px;
            top: 4px;
            right: 0px;
        }

        button {
            width: 40px;
            height: 40px;

            img {
                width: 100%;
                height: 40px;
                padding: 3px;
            }
        }
    }
}
