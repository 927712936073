.AddPost-container {
    background: #fff;
    // position: absolute;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    z-index: 3;
    height: 100vh;

    .AddPost-textfield-container {
        position: relative;
        padding: 24px 14px;
        text-align: right;
        overflow: visible;

        textarea {
            // resize: none;
            // height: 195px;
            // border: none;
            // outline: none !important;
            // width: 100%;
            // font-size: 17px;
            // color: var(--wgray_darker);
            max-height: 150px;
            display: block;
            width: 100%;
            overflow: auto;
            resize: none;
            min-height: 40px;
            line-height: 20px;
            border: none;
            outline: none !important;
            font-size: 18px;
            color: var(--wgray_darker);
        }

        .DraftEditor-root {
            max-height: 150px;
            display: block;
            width: 100%;
            overflow: auto;
            resize: none;
            min-height: 40px;
            line-height: 20px;
            border: none;
            outline: none !important;
            font-size: 18px;
            color: var(--wgray_darker);
            text-align: left;

            a {
                color: var(--wgreen);
                font-weight: 600;
            }
        }

        .counterSpan {
            font-size: 13px;
            color: var(--wgray_dark);
        }

        .DraftEditor-root + div,
        .AddPost-mentions-container {
            background: #f9f9f9;
            box-shadow: 0 2px 10px rgba(0,0,0,0.14);
            border-radius: 8px;
            cursor: pointer;
            padding: 8px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            // transform-origin: 50% 0%;
            // transform: scaleY(0);
            // margin: -16px;
            position: absolute !important;
            bottom: calc(100% + -20px) !important;
            max-height: 185px;
            overflow: auto;
            max-width: 300px;
            z-index: 2;
            top: unset !important;
            transform: unset !important;

            .AddPost-mention-box {
                padding: 5px;
                &:hover {
                    background: var(--wgray_soft);
                }
                .AddPost-mention-main {

                    .AddPost-mention-left {
                        position: relative;
                        .bmIcon {
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }
                    }

                    .AddPost-mention-right {
                        max-width: calc(100% - 45px);
                        text-align: start;

                        .AddPost-mention-name {
                            font-size: 18px;
                            font-weight: 600;
                            color: var(--wblue_dark);
                        }

                        .AddPost-mention-title {
                            font-size: 14px;
                            color: var(--wgray_darker);
                        }
                    }
                }
            }
        }
    }

    .AddPost-image-container {
        position: relative;
        border-radius: 5px;
        margin-left: 14px;
        margin-right: 14px;
        display: inline-block;
        height: 85px;
        width: 85px;
        box-shadow: 0 1px 10px rgba(0,0,0,0.14);

        .image-container {
            width: 85px;
            height: 85px;
            border-radius: 5px;
            object-fit: cover;

            &.isVideo {

            }
        }

        .videoOverlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            background: linear-gradient(to bottom, rgba(0,0,0,0), #383838);
        }

        .videoPlayIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 35px;
            height: 35px;
            transform: translate(-50%, -50%);
        }

        .durationSpan {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            font-size: 12px;
            color: #d1d1d1;
        }

        button {
            position: absolute;
            top: -14px;
            right: -14px;
            img {
                width: 30px;
                height: 30px;
            }
        }

    }

    .AddPost-file-container {
        position: relative;
        border: 1px solid var(--wgray_soft);
        border-radius: 5px;
        margin-left: 14px;
        margin-right: 14px;
        width: 100%;

        .icon-box {
            max-width: 50px;

            .image-container {
                width: 50px;
                height: 50px;
                object-fit: cover;
                padding: 4px;
            }

        }


        .extradata-container {
            line-height: 15px;

            .title {
                color: var(--wgray_darker);
                font-size: 15px;
            }
            .subtitle {
                color: var(--wgray_dark);
                font-size: 12px;
            }
        }


        button {
            position: absolute;
            top: -14px;
            right: -14px;
            width: 30px;
            height: 30px;

            img {
                width: 30px;
                height: 30px;
            }
        }
    }

    .AddPost-camera-container {
        position: absolute;
        top: 0;
        background: black;
        bottom: 0;
        width: 100%;
        height: 93%;
        overflow: hidden;

        .react-html5-camera-photo {
            height: 100%;
        }

        .closer-btn {
            position: absolute;
            right: 0;
            top: 0;
            img {
                filter: brightness(0) invert(1) drop-shadow(0px 0px 10px #000);
            }
        }
    }

}

@media screen and (min-width: 1450px) {
    .AddPost-container-desktop {

        .AddPost-desktop-header {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            padding-top: 24px;
            padding-bottom: 24px;

            span {
                font-size: 20px;
                font-weight: 600;
                color: var(--wblue_dark);
            }

            button {
                width: 35px;
                height: 35px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 16px;

                img {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        .AddPost-desktop-user-container {

            padding-left: 20px;
            padding-right: 20px;

            .AddPost-desktop-img-container {
                max-width: 60px;
                max-height: 60px;
                width: 60px;
                height: 60px;
                position: relative;

                img {
                    width: 100%;
                    height: 60px;
                    border-radius: 30px;
                    object-fit: cover;
                    border: 2px solid var(--wgray_dark);
                }

                .avatar {
                    position: relative!important;
                }

                .miembro {
                    position: absolute!important;
                    width: 25px;
                    height: 25px;
                    bottom: 0;
                    right: -5px;
                }
            }

            .AddPost-desktop-info-container {
                padding-left: 14px;
                padding-right: 14px;
                line-height: 18px;

                .name {
                    font-size: 17px;
                    color: var(--wblue_dark);
                    font-weight: 600;
                }

                .subtext {
                    font-size: 13px;
                    color: var(--wgray_darker);
                }
            }
        }

        .AddPost-textfield-container {
            position: relative;
            padding: 24px 14px;
            text-align: right;
            overflow: visible;

            textarea {
                // resize: none;
                // height: 195px;
                // border: none;
                // outline: none !important;
                // width: 100%;
                // font-size: 17px;
                // color: var(--wgray_darker);
                max-height: 150px;
                display: block;
                width: 100%;
                overflow: auto;
                resize: none;
                min-height: 40px;
                line-height: 20px;
                border: none;
                outline: none !important;
                font-size: 18px;
                color: var(--wgray_darker);
            }

            .DraftEditor-root {
                max-height: 150px;
                display: block;
                width: 100%;
                overflow: auto;
                resize: none;
                min-height: 40px;
                line-height: 20px;
                border: none;
                outline: none !important;
                font-size: 18px;
                color: var(--wgray_darker);
                text-align: left;

                a {
                    color: var(--wgreen);
                    font-weight: 600;
                }
            }

            .counterSpan {
                font-size: 13px;
                color: var(--wgray_dark);
            }

            .DraftEditor-root + div,
            .AddPost-mentions-container {
                background: #f9f9f9;
                box-shadow: 0 2px 10px rgba(0,0,0,0.14);
                border-radius: 8px;
                cursor: pointer;
                padding: 8px;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                // transform-origin: 50% 0%;
                // transform: scaleY(0);
                // margin: -16px;
                position: absolute !important;
                bottom: calc(100% + -20px) !important;
                max-height: 185px;
                overflow: auto;
                max-width: 300px;
                z-index: 2;
                top: unset !important;
                transform: unset !important;

                .AddPost-mention-box {
                    padding: 5px;
                    &:hover {
                        background: var(--wgray_soft);
                    }
                    .AddPost-mention-main {

                        .AddPost-mention-left {
                            position: relative;
                            .bmIcon {
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                right: 0;
                                bottom: 0;
                            }
                        }

                        .AddPost-mention-right {
                            max-width: calc(100% - 45px);
                            text-align: start;

                            .AddPost-mention-name {
                                font-size: 18px;
                                font-weight: 600;
                                color: var(--wblue_dark);
                            }

                            .AddPost-mention-title {
                                font-size: 14px;
                                color: var(--wgray_darker);
                            }
                        }
                    }
                }
            }
        }

        .AddPost-image-container {
            position: relative;
            border-radius: 5px;
            margin-left: 14px;
            margin-right: 14px;
            display: inline-block;
            height: 85px;
            width: 85px;
            box-shadow: 0 1px 10px rgba(0,0,0,0.14);

            .image-container {
                width: 85px;
                height: 85px;
                border-radius: 5px;
                object-fit: cover;

                &.isVideo {

                }
            }

            .videoOverlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                background: linear-gradient(to bottom, rgba(0,0,0,0), #383838);
            }

            .durationSpan {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                font-size: 12px;
                color: #d1d1d1;
            }

            .videoPlayIcon {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 35px;
                height: 35px;
                transform: translate(-50%, -50%);
            }

            button {
                position: absolute;
                top: -14px;
                right: -14px;
                z-index: 1;
                img {
                    width: 30px;
                    height: 30px;
                }
            }

        }

        .AddPost-file-container {
            position: relative;
            border: 1px solid var(--wgray_soft);
            border-radius: 5px;
            margin-left: 14px;
            margin-right: 14px;
            width: 100%;

            .icon-box {
                max-width: 50px;

                .image-container {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    padding: 4px;
                }

            }


            .extradata-container {
                line-height: 15px;

                .title {
                    color: var(--wgray_darker);
                    font-size: 15px;
                }
                .subtitle {
                    color: var(--wgray_dark);
                    font-size: 12px;
                }
            }


            button {
                position: absolute;
                top: -14px;
                right: -14px;
                width: 30px;
                height: 30px;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .AddPost-camera-container {
            position: absolute;
            top: 0;
            background: black;
            bottom: 0;
            width: 100%;
            height: 93%;
            overflow: hidden;

            .react-html5-camera-photo {
                height: 100%;
            }

            .closer-btn {
                position: absolute;
                right: 0;
                top: 0;
                img {
                    filter: brightness(0) invert(1) drop-shadow(0px 0px 10px #000);
                }
            }
        }

        .AddPost-desktop-bottom-container {
            padding-top: 15px;

            .AddPost-desktop-bottom-row {
                height: 60px;
                padding-left: 14px;
                padding-right: 14px;
                cursor: pointer;

                .itmCol {
                    height: 60px;
                    border: 1px solid var(--wblue);
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .firstLabel {
                        font-size: 18px;
                        color: var(--wgray_dark);
                        font-weight: 600;
                        padding: 0 20px;
                    }

                    .secondLabel {
                        color: var(--wblue);
                        font-size: 18px;
                        font-weight: 600;
                        padding-right: 10px;
                        display: flex;
                        align-items: center;
                    }
                    // .firstLabel {
                    //     font-size: 18px;
                    //     color: var(--wgray_dark);
                    //     font-weight: 600;
                    //     padding: 0 20px;
                    // }
                }
            }

            .AddPost-desktop-bottom-rol-filter-container {


                .first-label,
                .second-label {
                    font-size: 17px;
                }
                .first-label {
                    color: var(--wgray_darker);
                }

                .second-label {
                    color: var(--wgreen);
                    font-weight: 600;
                }

                .second-label,
                button {
                    float: right;
                }
            }

            .AddPost-desktop-bottom-option-box {
                display: flex;
                align-items: center;

                span {
                    font-weight: 600;
                    font-size: 17px;
                    color: var(--wblue);
                    padding-left: 14px;
                }
            }
        }

        .AddPost-desktop-file-container {
            margin: 10px 14px;
            border-radius: 5px;
            background: var(--wgray_softer);
            height: 60px;

            .AddPost-desktop-file-item {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                img {
                    width: 30px;
                    height: 30px;
                }

                span {
                    padding-left: 4px;
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--wblue);
                }
            }
        }

        .AddPost-desktop-submit-container {
            padding: 24px 14px;

            button {
                background: var(--wgray_semisoft);
                height: 50px;
                border-radius: 5px;
                width: 100%;
                color: #fff;
                font-size: 20px;
                font-weight: 600;

                &.active {
                    background: var(--wblue);
                }
            }
        }

    }
}
