.UserProfilePersonalInfo-container {
    margin: 0 12px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
    padding: 20px;
    display: flex;
    justify-content: space-between;



    .btnCol {
        max-width: calc(calc(var(--bs-gutter-x) * .5) + 94px);

        button {
            span {
                font-size: 18px;
                font-weight: 500;
                color: var(--wgreen);
                padding-right: 5px;
            }
        }
    }
    .invitation-label {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        span {
            display: flex;
            align-items: center;
            cursor: pointer;
            max-width: calc(100% - 30px - 50px);

            .invitation-miniature {
                width: 20px;
                height: 20px;
                border-radius: 10px;
                margin: 0 5px;
                object-fit: cover;
            }
        }
    }

    .UserProfilePersonalInfo-detail-container {
        padding-top: 4px;

        .colDtls {
            max-width: calc(100% - 264px);
        }
        .nameCol {
            display: flex;
            align-items: center;
            padding-bottom: 20px;

            .titleDiv {
                padding-right: 10px;
                font-weight: bold;
                font-size: 30px;
                color: var(--wblue_dark);
            }

            img {
                width: 20px;
                height: 20px;
            }
        }

        .btnMor {
            position: absolute;
            top: 0px;
            right: -12px;
            background: #fff;
            width: 40px;
            z-index: 2;

            img {
                width: 40px;
                height: 40px;
            }
        }

        .imgCol {
            max-width: 264px;

            img {
                max-width: 264px;
                max-height: 264px;
                width: 264px;
                height: 264px;
                border-radius: 10px;
                object-fit: cover;
            }

            button {
                margin-top: 16px;
                background: var(--wgreen);
                border-radius: 10px;
                color: #fff;
                font-size: 17px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                width: 100%;

                img {
                    max-width: 30px;
                    max-height: 30px;
                    width: 30px;
                    height: 30px;
                    object-fit: cover;
                }
            }
        }

        .detailsCol {
            span {
                font-size: 18px;
                color: var(--wgray_darker);
                padding-left: 20px;

                &.placeholderTxt {
                    color: var(--wgray);
                }
            }

            .mentoringSpan {
                max-width: calc(100% - 30px - 20px);
                padding-left: 20px;
            }

            img {
                width: 30px;
                height: 30px;
                object-fit: cover;
                object-position: 0 0;

                &.roleIcon {
                    object-position: 0 0;
                }

                &.phoneIcon {
                    object-position: 0 -30px;
                }

                &.emailIcon {
                    object-position: 0 -60px;
                }

                &.pinIcon {
                    object-position: 0 -90px;
                }

                &.hobbyIcon {
                    object-position: 0 -120px;
                }

                &.outIcon {
                    object-fit: contain;
                    object-position: center center;
                }
            }

            &.isDiv {
                display: flex;

                .detailsDv {
                    max-width: calc(100% - 30px);

                    font-size: 18px;
                    color: var(--wgray_darker);
                    padding-left: 20px;

                    &.placeholderTxt {
                        color: var(--wgray);
                    }
                }
            }

            .anchorDetails {
                width: 100%;
                max-width: calc(100% - 30px);
            }
        }

        .redesCol {
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                margin-bottom: 24px;
                font-size: 18px;
                font-weight: bold;
                color: var(--wblue_dark);
            }

            img {
                width: 35px;
                height: 35px;
                object-fit: cover;
                object-position: 0 0;
                margin-right: 20px;

                &.Twitter {
                    object-position: 0 0;
                }

                &.Facebook {
                    object-position: 0 -35px;
                }

                &.LinkedIn {
                    object-position: 0 calc(-35px * 2);
                }

                &.Instagram {
                    object-position: 0 calc(-35px * 3);
                }

                &.YouTube {
                    object-position: 0 calc(-35px * 4);
                }

                &.Spotify {
                    object-position: 0 calc(-35px * 5);
                }
            }
        }
    }
}
