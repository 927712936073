body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.miembro {
    border: none!important;
}

// LIBS //
@import "bootstrap";
@import 'react-spring-bottom-sheet/dist/style.css';
// Utilities //
@import "./_assets/scss/utilities.scss";
