.VideoPlayer-container {
    position: relative;
    video {
        width: 100%;
        height: auto;
        // max-height: 250px;
        max-height: 674px;
        aspect-ratio: 1/1;
        background: #000;

        &:before, &:after {
            -moz-box-sizing: border-box;
        }

        // &:not(.isPlaying)::-webkit-media-controls {
        //     display: none;
        // }

        &::-webkit-media-controls-fullscreen,
        &::-webkit-media-controls-full-screen,
        &::-webkit-media-controls-fullscreen-button,
        &::-webkit-full-screen,
        &::-webkit-fullscreen {
            display: none !important;
        }
    }

    button {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .imgIcon,
        img {
            width: 70px !important;
            height: 70px !important;
            max-width: 70px !important;
            max-height: 70px !important;
        }
    }

    .VideoPlayer-poster-img {
        width: 100%;
        height: auto;
        max-height: 674px;
        aspect-ratio: 1/1;
        object-fit: cover;
    }
}
