.MessagesChat-container {
    background: var(--wgray_chatbg);
    position: relative;

    .MessagesChat-header-container {
        padding: 0 14px;
        height: 50px;
        background: #fff;
        border-bottom: 1px solid var(--wgreay_soft);

        .MessagesChat-header-btn-container {
            max-width: 30px;
            max-height: 30px;
        }

        .user-details-container,
        .MessagesChat-header-details-box {
            max-width: calc(100% - 30px);

            .avatar-image-box-30 {
                position: relative;

                .avatar {
                    position: relative;
                }

                .miembro {
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    bottom: 0;
                    right: -5px;
                }
            }
        }
    }

    .MessagesChat-update-container {
        background: var(--wgreen);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: #fff;
        font-weight: bold;
    }

    .MessagesChat-bubble-container {
        padding: 14px;

        max-height: calc(100vh - 46px - 50px - 24px);
        overflow: auto;
        height: 100%;

        .col-bubble {
            position: relative;


            &.mine {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .MessagesChat-bubble {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 2px;
                    background: var(--wgreen_soft);
                    padding-top: 20px;

                    .bubble-timesince {
                        text-align: end;
                    }
                    .sendingMessage{
                        height: 10px;
                        width: 10px;
                        margin-left: 5px;
                    }
                }

            }
            .MessagesChat-bubble {
                display: inline-block;
                border-radius: 10px;
                border-bottom-left-radius: 2px;
                background: #fff;
                margin: 0 0 12px 0;
                padding: 12px;
                max-width: 365px;
                position: relative;
                padding-top: 15px;

                &.is-reply {
                    padding-top: 85px !important;
                }

                .Message-reply-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #F5F5F5;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    padding: 12px 10px 5px 10px;

                    .reply-name {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 22px;
                        /* identical to box height, or 129% */

                        letter-spacing: -0.408px;

                        color: #0FB855;
                    }

                    .reply-details {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 22px;
                        letter-spacing: -0.408px;
                        color: #898A8D;
                        min-height: 40px;
                        line-height: 40px;
                        
                        img {
                            width: 22px;
                            height: 22px;
                            min-width: 22px;
                            object-fit: cover;
                            object-position: 0 0;
                            margin-right: 5px;
            
                            &.link {
                                object-position: 0 0;
                            }
            
                            &.doc {
                                object-position: 0 -22px;
                            }
            
                            &.contact {
                                object-position: 0 -44px;
                            }
            
                            &.img {
                                object-position: 0 -66px;
                            }
            
                            &.audio {
                                object-position: 0 -88px;
                            }
            
                            &.video {
                                object-position: 0 -110px;
                            }
                        }
                    }

                    .reply-attachment-container {
                        padding: 0 !important;
                        max-width: 40px;
                        max-height: 40px;
                        object-fit: cover;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                        }
                    }
                }

                .MessagesChat-bubble-btn-container {
                    max-width: 30px;
                    max-height: 30px;
                    margin-bottom: 12px;
                    position: absolute;
                    top: 0;
                    right: 4px;
                    padding-left: 0;
                    padding-right: 0;

                    .dropdown {
                        .dropdown-toggle {
                            background: transparent;
                            border: none;
                            outline: none !important;
                            padding: 0;
                            box-shadow: none !important;
                            &::after {
                                display: none;
                            }
                        }
                    }
                }

                &.isContact {
                    padding-bottom: 0;
                }

                .bubbleMention {
                    font-weight: 600;
                    color: var(--wgreen);
                    cursor: pointer;
                }

                .MessagesChat-bubble-name-container {
                    padding-bottom: 5px;
                    cursor: pointer;
                    .name {
                        font-size: 17px;
                        font-weight: 600;
                        color: var(--wblue);
                    }

                    .details {
                        font-weight: 600;
                        color: var(--wgray_dark);
                        font-size: 13px;
                    }
                }

                .attachment-box {
                    padding: 0 0 5px 0;

                    .pdf-file-container {

                        .name-box {
                            a {
                                text-decoration: none;
                                color: var(--wblue);
                                font-size: 17px;
                                display: flex;
                                max-width: 350px;
                                align-items: center;

                                .pdf-icon {
                                    width: 30px;
                                    height: 30px;
                                    margin-right: 5px;
                                }
                            }

                        }

                        .donwloader-box {
                            border-top: 1px solid var(--wgray_soft);
                            padding-top: 4px;
                            text-align: center;

                            a {
                                text-decoration: none;
                                color: var(--wgray);
                                font-size: 17px;
                                font-weight: bold;
                                &.mine {
                                    color: var(--wgreen);
                                }
                            }
                        }
                    }

                    .image-file-container {
                        .message-img {
                            max-width: 350px;
                            width: 100%;
                            max-height: 400px;
                            object-fit: cover;
                            border-radius: 4px;
                        }

                        a {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            .icon-img {
                                width: 35px;
                                height: 35px;
                            }
                        }
                    }

                    .audio-file-container {}
                }

                .text-box {
                    padding: 5px 0 0px 0;
                    font-size: 17px;
                    color: var(--wblue_dark);
                    word-break: break-word;

                    &.deleted {
                        color: var(--wgray_dark);
                        font-style: italic;
                        font-size: 15px;
                    }
                    .bubble-showmore {
                        text-decoration: none !important;
                        outline: none !important;
                        color: var(--wblue);
                        font-size: 13px;
                        // display: block;
                        text-align: right;
                    }

                    &.isContact {
                        .contactName {
                            font-size: 17px;
                            color: var(--wblue);
                            display: flex;
                            align-items: center;
                        }

                        .bubble-timesince {
                            font-size: 13px;
                            color: var(--wgray_dark);
                            padding: 4px 0 !important;
                            text-align: end;
                        }

                        .callBtn {
                            padding: 0;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-top: 1px solid var(--wgray_soft);
                            font-size: 17px;
                            font-weight: 600;
                            color: var(--wgreen);
                        }
                    }


                }

                .bubble-timesince {
                    font-size: 13px;
                    color: var(--wgray_dark);
                    text-align: end;
                }
            }

            .icn_send_failed {
                cursor: pointer;
                height: 32px;
                width: 32px;
                margin-left: 5px;
            }

        }

        .MessagesChat-div-scrolled {
            height: 0;
            width: 100%;
        }
    }

    .ChatBanned-container {
        background: var(--wgray_soft);
        position: absolute;
        bottom: 0;
        left: 0;
        min-height: 50px;
        width: 100%;
        border-top: 1px solid var(--wgray_semisoft);
        padding: 14px 20px;
        line-height: 19px;
        text-align: center;
        font-size: 17px;
        color: var(--wgray_darker);
        font-weight: 600;
    }
}

@media screen and (min-width: 768px) {
    .MessagesChat-container {

        .MessagesChat-header-container {

            .MessagesChat-header-btn-container {

                &.left {
                    display: none;
                }
            }
        }

        .MessagesChat-bubble-container {
            max-height: calc(100vh - 46px - 50px - 24px);
        }
    }
}

@media screen and (min-width: 1450px) {
    .MessagesChat-container {

        .MessagesChat-bubble-container {
            max-height: calc(100vh - 46px - 50px - 24px);
        }
    }
}
