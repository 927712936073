body {
    width: 100vw!important;
}

.container-Support {

    height: 100vh;
    max-width: 700px;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);


    .container_header {
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        display: flex;
        padding: 30px 15px 10px 15px;
        align-items: center;
        background-color: #F8F8FB;


        h3 {
            color: black;
            width: 100%;
            text-align: center;
            display: block;
            font-size: 17dp;
        }

        img {
            position: absolute;
            left: 15px;
            cursor: pointer;
        }
    }

    .container_body {
        padding-top: 20px;
        width: 100%;
        background-color: white;
        img {
            display: block;
            margin: 0 auto;
            margin-top: 20px;
        }

        &_text_blue {
            font-size: 20px;
            color: #0D458D;
            font-weight: bold;
            text-align: center;
            width: 100%;
            margin-top: 10px;
        }

        &_text_black {
            font-size: 17px;
            color: #212639;
            width: 100%;
            text-align: center;
            margin-bottom: 50px;
        }

        a {
            color: #fff;
        }

        &_card {
            background-color: #0D458D;
            width: 90%;
            height: 100px;
            border-radius: 5px;
            margin: 0 auto;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            cursor: pointer;

            &_content_left {
                p {
                    color: white;
                }

                a {
                    text-decoration: none;
                    color: #fff;
                }

                &_correo {
                    display: flex;

                    p {
                        margin-left: 10px;
                        font-weight: bold;
                    }
                }
            }

            img {
                margin: 0;
            }

        }

    }

}

.container-Support.container-support.isDesktop {
    width: 100%;
    height: calc(100% - 16px);
    color: #9A9BA0;
    font-size: 16px;
    position: relative;
    padding-bottom: 40px;
    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);


    .container_header {
        margin: 0;
        background-color: #fff!important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 60px;

        h3 {
            font-size: 20px !important;
            color: var(--wblue_dark);
            text-align: left;
        }
    }

    &_body {

        width: 100%;
        background-color: white;
        img {
            display: block;
            margin: 0 auto;
            margin-top: 20px;
        }

        &_text_blue {
            font-size: 20px;
            color: #0D458D;
            font-weight: bold;
            text-align: center;
            width: 100%;
            margin-top: 10px;
        }

        &_text_black {
            font-size: 17px;
            color: #212639;
            width: 100%;
            text-align: center;
            margin-bottom: 50px;
            padding: 0 14px;
        }

        a {
            color: #fff;
        }

        &_card {
            background-color: #0D458D;
            width: 90%;
            height: 100px;
            border-radius: 5px;
            margin: 0 auto;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            cursor: pointer;

            &_content_left {
                p {
                    color: white;
                }

                a {
                    text-decoration: none;
                    color: #fff;
                }

                &_correo {
                    display: flex;

                    p {
                        margin-left: 10px;
                        font-weight: bold;
                    }
                }
            }

            img {
                margin: 0;
            }

        }

    }

}
