.RegisterCode-container {
    padding: 0 24px;

    .RegisterData-close-btn {
        width: 30px;
        height: 30px;
        padding: 0;
        outline: none !important;

        background: transparent;
        border: none;

        img {
            width: 100%;
            height: 30px;
        }
    }

    .RegisterData-titles-box {
        padding-top: 30px;

        display: flex;
        align-items: center;
        justify-content: center;

        .title {
            color: var(--wblue_dark);
            font-size: 28px;
            font-weight: bold;
        }

        .subtitle {
            color: var(--wgray_darker);
            font-size: 17px;
            padding: 24px 0;
        }



        .btns-container {

            display: flex;
            align-items: center;
            justify-content: center;

            .sender-btn {
                border: 1px solid var(--wgreen);
                width: 187px;
                height: 45px;
                border-radius: 22.5px;
                font-size: 17px;
                color: var(--wgreen);
            }

            &>div {
                background: var(--wgray_soft);
                height: 45px;
                border-radius: 22.5px;
                width: 187px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .RegisterData-input-box {
        position: relative;
        label.icon {
            margin: 0;
            width: 55px;
            height: 55px;
            position: absolute;
            top: 0;
            left: 0;

            img {
                width: 100%;
                height: 55px;
            }
        }

        input {
            height: 55px;
            width: 100%;
            border: 1px solid var(--wgray_semisoft);
            padding: 2px 6px;
            color: var(--wblue_dark);
            border-radius: 5px;
            font-size: 17px;
            font-weight: 600;
            outline: none !important;

            &::placeholder {
                color: var(--wgray_semisoft);
            }
            &.iconned {
                padding-left: 55px;
            }
        }

        &.input-alert {
            input {
                border: 1px solid red;
                background: #ffefef;
            }
        }
    }

    .RegisterData-input-box-lada {
        height: 55px;
        border: 1px solid var(--wgray_semisoft);
        border-radius: 5px;
        padding: 10px;

        background: transparent;
        &.input-alert {
            border: 1px solid red;
            background: #ffefef;
        }

        .lada-btn-box {
            max-width: 100px;
            width: 100px;

            button {
                width: 100%;
                background: transparent;
                outline: none !important;
                border: none;
                border-right: 1px solid var(--wgray_dark);
                font-size: 17px;
                font-weight: 600;
                color: var(--wgreen);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .input-box {
            input {
                width: 100%;
                border: none;
                color: var(--wblue_dark);
                font-size: 17px;
                font-weight: 600;
                outline: none !important;
                padding: 6px;
                background: transparent;
                &::placeholder {
                    color: var(--wgray_semisoft);
                }
            }
        }


    }

    .RegisterData-step-btn-box {
        padding-top: 16px;

        button {
            height: 55px;
            border-radius: 4px;
            background: var(--wblue);
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            border: none;
            outline: none !important;
            width: 100%;
            padding: 0;
        }
    }

    .RegisterData-password-container {
        padding-top: 80px;
        .RegisterData-password-box {
            position: relative;

            button,
            label {
                position: absolute;
                top: 0;
                margin: 0;
                width: 55px;
                height: 55px;
                padding: 0;
                background: transparent;
                border: none;
                outline: none !important;
                img {
                    width: 100%;
                    height: 55px;
                }
            }
            button {
                right: 0;

            }

            label {
                left: 0;
            }

            input {
                height: 55px;
                width: 100%;
                border: 1px solid var(--wgray_semisoft);
                background: transparent;
                color: var(--wblue_dark);
                font-size: 17px;
                font-weight: 600;
                padding: 4px 55px;
                border-radius: 5px;
                outline: none !important;
                &::placeholder {
                    color: var(--wgray_semisoft);
                }
            }

            &.input-alert {
                input {
                    border: 1px solid red;
                    background: #ffefef;
                }
            }
        }



        .RegisterData-error-box {
            background: #ff6d6d;

            color: #fff;
            border-radius: 5px;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 16px;
        }
    }

    .RegisterData-codes-container {

        .col {
            max-width: 50px;
            padding: 0 5px;
            input {
                width: 100%;
                height: 55px;
                border: 1px solid var(--wgray);
                background: var(--wgray_softer);
                border-radius: 3px;
                outline: none !important;
                text-align: center;
                font-size: 17px;
                font-weight: bold;
                color: var(--wblue_dark);
            }
        }
    }
}
