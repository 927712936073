.AddPostBottom-container {
    padding-top: 15px;
    
    .AddPostBottom-row {
        height: 45px;
        padding-left: 14px;
        padding-right: 14px;
        border-bottom: 1px solid var(--wgray_soft);
        cursor: pointer;
    }

    .AddPostBottom-rol-filter-container {


        .first-label,
        .second-label {
            font-size: 17px;
        }
        .first-label {
            color: var(--wgray_darker);
        }

        .second-label {
            color: var(--wgreen);
            font-weight: 600;
        }

        .second-label,
        button {
            float: right;
        }
    }

    .AddPostBottom-option-box {
        display: flex;
        align-items: center;

        span {
            font-weight: 600;
            font-size: 17px;
            color: var(--wblue);
            padding-left: 14px;
        }
    }
}
