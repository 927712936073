.FilterOptionPicker-container {

    .FilterOptionPicker-header-box {
        height: 55px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-size: 17px;
            font-weight: 600;
            color: var(--wblue_dark);
        }
        button {
            color: var(--wgreen);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: calc(var(--bs-gutter-x) * .5);
        }
    }
    .FilterOptionPicker-item-box,
    .FilterOptionPicker-searcher-container {
        height: 55px;
        border-bottom: 1px solid var(--wgray_soft);
        padding-left: 14px;
        padding-right: 14px;

    }

    .FilterOptionPicker-searcher-box {
        position: relative;

        input {
            width: 100%;
            height: 35px;
            border: none !important;
            outline: none !important;
            border-radius: 17.5px;
            background: var(--wgray_soft);
            padding-left: 35px;
            padding-right: 35px;
        }

        button,
        label {
            position: absolute;
            width: 35px;
            height: 35px;
            top: 0;

            img {
                width: 35px;
                height: 35px;
            }
        }

        label {
            left: 0;
            img {
                padding: 5px;
            }
        }

        button {
            right: 0;
        }

    }

    .FilterOptionPicker-items-container {
        max-height: calc(100vh - 55px - 55px - 73px);
        overflow: auto;
        .FilterOptionPicker-item-box {
            font-size: 17px;
            color: var(--wgray_darker);

            .FilterOptionPicker-selector {
                max-width: 30px;

                button {
                    width: 30px;
                    height: 30px;

                    img {
                        width: 100;
                        height: 30px;
                    }
                }
            }
        }

    }


}

.FilterOptionPicker-footer-container {
    border-top: 1px solid var(--wgray_soft);
    background: #fff;
    width: 100%;
    max-width: 700px;
    height: calc(45px + 14px + 14px);
    padding: 14px;
    z-index: 4;
    position: fixed;
    bottom: 0;

    button {
        background: var(--wgray_semisoft);
        width: 100%;
        height: 45px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;

        &.active {
            background: var(--wblue);
        }
    }
}
