.AudioPlayer-container {
    --player-button-width: 3em;
    --sound-button-width: 2em;
    --space: .5em;
    // width: 15rem;
    width: 100%;
    height: 42px;
    position: relative;

    audio {
        &.isChat {
            max-width: 100%;
            &::-webkit-media-controls-play-button,
            &::-webkit-media-controls-panel {
                background-color: #fff;
            }
            &.isMine {
                &::-webkit-media-controls-play-button,
                &::-webkit-media-controls-panel {
                    background-color: var(--wgreen_soft);
                }
            }
        }

    }


    &.hasAvatar {
        height: 50px;
    }

    .icon-container {
        width: 100%;
        height: 100%;
        background-color: var(--bs-green);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .audio-icon {
        width: 90%;
        height: 90%;
    }

    .controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: 0;
    }

    .controlsBox.isAvatar {
        width: calc(100% - 50px);
        padding: 0 0.5rem !important;
    }

    .player-button {
        background-color: transparent;
        border: 0;
        width: var(--player-button-width);
        height: var(--player-button-width);
        cursor: pointer;
        padding: 0;
    }

    .timeline {
        -webkit-appearance: none;
        width: calc(100% - (var(--player-button-width) + var(--sound-button-width) + var(--space)));
        height: .5em;
        background-color: #e5e5e5;
        border-radius: 5px;
        background-size: 0% 100%;
        // background-image: linear-gradient(#DE5E97, #DE5E97);
        background-image: linear-gradient(#adadad, #bdbdbd);
        background-repeat: no-repeat;
        margin-right: var(--space);

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            cursor: pointer;
            opacity: 0;
            transition: all .1s;
            background-color: var(--bs-teal);
        }

        &::-moz-range-thumb {
            -webkit-appearance: none;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            cursor: pointer;
            opacity: 0;
            transition: all .1s;
            background-color: var(--bs-teal);
        }

        &::-ms-thumb {
            -webkit-appearance: none;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            cursor: pointer;
            opacity: 0;
            transition: all .1s;
            background-color: var(--bs-teal);
        }

        &::-webkit-slider-thumb:hover {
            background-color: var(--bs-green);
        }

        &:hover::-webkit-slider-thumb {
            opacity: 1;
        }

        &::-moz-range-thumb:hover {
            background-color: var(--bs-green);
        }

        &:hover::-moz-range-thumb {
            opacity: 1;
        }

        &::-ms-thumb:hover {
            background-color: var(--bs-green);
        }

        &:hover::-ms-thumb {
            opacity: 1;
        }

        &::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }

        &::-moz-range-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }

        &::-ms-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }

    }

    .sound-button {
        background-color: transparent;
        border: 0;
        width: var(--sound-button-width);
        height: var(--sound-button-width);
        cursor: pointer;
        padding: 0;
    }

    .counter {
        font-size: 15px;
        text-align: end;
        color: var(--wgray_dark);
        position: absolute;
        bottom: calc(0px - 26px);
    }
}

// .AudioPlayer-container {
//     --player-button-width: 3em;
//     --sound-button-width: 2em;
//     --space: .5em;
//     // width: 15rem;
//     width: 100%;
//     height: 42px;
//     position: relative;
//
//     &.hasAvatar {
//         height: 50px;
//     }
//
//     .icon-container {
//         width: 100%;
//         height: 100%;
//         background-color: var(--bs-green);
//         color: #fff;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }
//
//     .audio-icon {
//         width: 90%;
//         height: 90%;
//     }
//
//     .controls {
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         width: 100%;
//         margin-top: 0;
//     }
//
//     .controlsBox.isAvatar {
//         width: calc(100% - 50px);
//     }
//
//     .player-button {
//         background-color: transparent;
//         border: 0;
//         width: var(--player-button-width);
//         height: var(--player-button-width);
//         cursor: pointer;
//         padding: 0;
//         margin-right: 5px;
//     }
//
//     .timeline {
//         -webkit-appearance: none;
//         width: calc(100% - (var(--player-button-width) + var(--sound-button-width) + var(--space)));
//         height: .5em;
//         background-color: #e5e5e5;
//         border-radius: 5px;
//         background-size: 0% 100%;
//         // background-image: linear-gradient(#DE5E97, #DE5E97);
//         background-image: linear-gradient(#adadad, #bdbdbd);
//         background-repeat: no-repeat;
//         margin-right: var(--space);
//
//         &::-webkit-slider-thumb {
//             -webkit-appearance: none;
//             width: 1em;
//             height: 1em;
//             border-radius: 50%;
//             cursor: pointer;
//             opacity: 0;
//             transition: all .1s;
//             background-color: var(--bs-teal);
//         }
//
//         &::-moz-range-thumb {
//             -webkit-appearance: none;
//             width: 1em;
//             height: 1em;
//             border-radius: 50%;
//             cursor: pointer;
//             opacity: 0;
//             transition: all .1s;
//             background-color: var(--bs-teal);
//         }
//
//         &::-ms-thumb {
//             -webkit-appearance: none;
//             width: 1em;
//             height: 1em;
//             border-radius: 50%;
//             cursor: pointer;
//             opacity: 0;
//             transition: all .1s;
//             background-color: var(--bs-teal);
//         }
//
//         &::-webkit-slider-thumb:hover {
//             background-color: var(--bs-green);
//         }
//
//         &:hover::-webkit-slider-thumb {
//             opacity: 1;
//         }
//
//         &::-moz-range-thumb:hover {
//             background-color: var(--bs-green);
//         }
//
//         &:hover::-moz-range-thumb {
//             opacity: 1;
//         }
//
//         &::-ms-thumb:hover {
//             background-color: var(--bs-green);
//         }
//
//         &:hover::-ms-thumb {
//             opacity: 1;
//         }
//
//         &::-webkit-slider-runnable-track {
//             -webkit-appearance: none;
//             box-shadow: none;
//             border: none;
//             background: transparent;
//         }
//
//         &::-moz-range-track {
//             -webkit-appearance: none;
//             box-shadow: none;
//             border: none;
//             background: transparent;
//         }
//
//         &::-ms-track {
//             -webkit-appearance: none;
//             box-shadow: none;
//             border: none;
//             background: transparent;
//         }
//
//     }
//
//     .sound-button {
//         background-color: transparent;
//         border: 0;
//         width: var(--sound-button-width);
//         height: var(--sound-button-width);
//         cursor: pointer;
//         padding: 0;
//         margin-left: 10px;
//     }
//
//     .counter {
//         font-size: 15px;
//         text-align: end;
//         color: var(--wgray_dark);
//         position: absolute;
//         bottom: calc(0px - 26px);
//     }
// }
