.PostDetails-container {
    height: 100vh;
    overflow: auto;
    background: #fff;

    .PostDetails-back-container {
        // position: fixed;
        // top: 0;
        // left: 0;
        // width: 100%;
        // background: #fff;
        padding-top: 20px;
        padding-bottom: 0;
        padding: 20px calc(var(--bs-gutter-x) * .5) 0 calc(var(--bs-gutter-x) * .5);

        .PostDetails-user-options {
            max-width: 30px;
            max-height: 30px;

            .dropdown {
                .dropdown-toggle {
                    background: transparent;
                    border: none;
                    outline: none !important;
                    padding: 0;
                    box-shadow: none !important;
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .PostDetails-post-box {
        // padding-top: 50px;
    }
}
