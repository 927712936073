.hasUnread {
    max-width: calc(100% - 30px);
}
.MessagesList-container {
    // background: var(--wgray_soft);

    .MessagesList-noresults-container {
        padding-top: 70px;
    }

    .MessagesList-results-container {

        overflow: auto;
        // screenHeight - headerHeight - tabHeight
        max-height: calc(100vh - 161px - 7px);

        .MessagesList-result-item {
            padding: 14px 0;
            border-bottom: 1px solid var(--wgray_soft);
            padding-right: calc(var(--bs-gutter-x) * .5);
            padding-left: calc(var(--bs-gutter-x) * .5);

            &.active {
                background-color: var(--wgray_softer);
            }

            .avatar-image-box-50{
                position: relative;
                .avatar {
                    position: relative;
                }

                .miembro {
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    bottom: 0;
                    right: -5px;
                }
            }

            .user-details-box {
                padding-right: 0;
                max-width: calc(100% - 50px);
            }
            .MessagesList-result-timesince {
                font-size: 13px;
                color: var(--wgray_dark);
                max-width: 75px;
                text-align: right;
            }

            .MessagesList-result-deleter {
                max-width: 30px;
                max-height: 30px;
                margin-top: -5px;

                .dropdown {
                    .dropdown-toggle {
                        background: transparent;
                        border: none;
                        outline: none !important;
                        padding: 0;
                        box-shadow: none !important;
                        &::after {
                            display: none;
                        }
                    }
                }
            }
            .MessagesList-result-unread-container {
                padding: 0;
                background: var(--wgreen);
                max-width: 30px;
                max-height: 30px;
                height: 30px;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                color: #fff;
            }
        }

        .MessageList-contacts-header {
            font-size: 17px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: -0.4px;
            text-align: left;
            height: 45px;
            display: flex;
            align-items: center;
            padding: 12px;
            border-bottom: 1px solid var(--wgray_soft);
        }
    }
}
