.ProfileNetworkInfo-container {
    margin: 0 12px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    padding: 24px 20px;

    .ProfileNetworkInfo-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;

        .titleSpan {
            font-size: 20px;
            font-weight: bold;
            color: var(--wblue_dark);
        }

        button {
            font-size: 18px;
            font-weight: 500;
            color: var(--wgreen);

            span {
                padding-right: 5px;
            }
        }
    }

    .ProfileNetworkInfo-item {
        background: #F4F4F6;
        height: 50px;
        display: flex;
        align-items: center;
        border-radius: 25px;
        padding: 5px;
        margin-bottom: 10px;

        .redName {
            padding-left: 10px;
            font-size: 18px;
            color: var(--wgray_darker);
        }
        img {
            width: 35px;
            height: 35px;
            object-fit: cover;
            object-position: 0 -35px;

            &.Twitter {
                object-position: 0 0;
            }

            &.Facebook {
                object-position: 0 -35px;
            }

            &.LinkedIn {
                object-position: 0 calc(-35px * 2);
            }

            &.Instagram {
                object-position: 0 calc(-35px * 3);
            }

            &.YouTube {
                object-position: 0 calc(-35px * 4);
            }

            &.Spotify {
                object-position: 0 calc(-35px * 5);
            }
        }
    }
}
