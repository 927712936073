.Filters-container {
    background: #fff;
    width: 100%;
    height: calc(100vh - 73px);
    overflow: auto;
    top: 0;
    z-index: 3;
    padding: 30px 0px 20px 0px;
    max-width: 700px;

    &.hasSubtitle {
        padding-top: 0;
        .row.hasSubtitle {
            padding-top: 30px;
            background: var(--wgray_softer);
        }
    }

    .Filters-subtitle-box {
        padding: 25px 14px;
        color: var(--wgray_darker);
        font-size: 17px;
    }

    .Filters-header-box {
        padding-left: 14px;
        padding-right: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        p {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: var(--wblue_dark);

            &.large-title{
                font-size: 17px;
            }
        }
        .Filters-btn-close,
        .Filters-btn-clear {
            position: absolute;
            top: 0;
        }

        .Filters-btn-close {
            height: 30px;
            width: 30px;
            left: 14px;

            img {
                width: 100%;
                height: 30px;
            }
        }

        .Filters-btn-clear {
            height: 22px;
            color: var(--wred);
            font-size: 15px;
            right: 14px;
        }
    }
}


.Filters-footer-container {
    border-top: 1px solid var(--wgray_soft);
    background: #fff;
    width: 100%;
    height: calc(45px + 14px + 14px);
    padding: 14px;
    z-index: 3;
    position: absolute;
    bottom: 0;

    &.isDesktop {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    button {
        background: var(--wgray_semisoft);
        width: 100%;
        height: 45px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;

        &.active {
            background: var(--wblue);
        }
    }
}
