.AddPostHeader-container {

    .AddPostHeader-nav-container {
        padding: 20px 14px;

        .AddPostHeader-close-btn,
        .AddPostHeader-post-btn {
            position: absolute;
            top: 0;
        }

        .AddPostHeader-close-btn {
            width: 30px;
            height: 30px;
            left: 0;
            img {
                width: 30px;
                height: 30px;
            }
        }

        .AddPostHeader-post-btn {
            right: 0;
            font-size: 17px;
            color: var(--wgray_darker);

            &.active {
                color: var(--wgreen);
            }
        }

        p {
            margin: 0;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: var(--wblue_dark);
        }

    }

    .AddPostHeader-user-container {

        padding-left: 14px;
        padding-right: 14px;

        .AddPostHeader-img-container {
            max-width: 60px;
            max-height: 60px;
            width: 60px;
            height: 60px;
            position: relative;

            img {
                width: 100%;
                height: 60px;
                border-radius: 30px;
                object-fit: cover;
                border: 2px solid var(--wgray_dark);
            }

            .avatar {
                position: relative!important;
            }

            .miembro {
                position: absolute!important;
                width: 25px;
                height: 25px;
                bottom: 0;
                right: -5px;
            }
        }

        .AddPostHeader-info-container {
            padding-left: 14px;
            padding-right: 14px;
            line-height: 18px;

            .name {
                font-size: 17px;
                color: var(--wblue_dark);
                font-weight: 600;
            }

            .subtext {
                font-size: 13px;
                color: var(--wgray_darker);
            }
        }
    }
}
