.UniversalPicker-list-container {
    .UniversalPicker-list-item {
        cursor: pointer;
        height: 54px;
        border-bottom: 1px solid var(--wgray_soft);

        .row {
            display: flex;
            align-items: center;
            height: 100%;

            .element {
                font-size: 17px;
            }

            .element-left {
                color: var(--wblue_dark);
                text-align: left;
            }

            .element-right {
                color: var(--wgray_dark);
                text-align: right;
                max-width: 100px;
            }

        }

        .text-red {
            color: Red!important;
            font-size: 22px!important;
            font-weight: bold;
            text-align: center!important;
            max-width: 100%!important;

        }
    }
}
