.container_folleto {
    
    &_header {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        padding: 30px 15px 10px 15px;
        align-items: center;
        background-color: #F8F8FB!important;
        max-width: 700px!important;
        margin: 0 auto;
        border-left: 1px solid var(--wgray_soft);
        border-right: 1px solid var(--wgray_soft);
      

        p {
            margin: 0;
            height: auto;
            text-align: center;
            margin: 0;
            text-align: center;
            color: green;
        }

        h3 {
            color: black;
            width: 100%;
            text-align: center;
        }
    }

    .container_body {
        padding: 20px;
        max-width: 700px!important;
        margin: 0 auto;
        border-left: 1px solid var(--wgray_soft);
        border-right: 1px solid var(--wgray_soft);

        p {
            color: black;
        }
    }

    .container_document_no_select {
        width: 100%;
        height: 75px;
        border: 1px solid black;
        background-color: white;
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-top: 40px;

    

        label {
            text-align: center;
            margin: 0;
            display: block;
            margin: auto auto;
            color: black;
            font-weight: bold;
        }
    }

    .container_document_select {
        display: flex;
        align-items: center;
        border: 1px solid black;
        padding: 20px;
        height: 80px;
        position: relative;

        p {
            margin: 0;
            margin-left: 20px;
            font-size: 20px;
            color: #0D458D;
            font-weight: bold;
        }

        img {
            margin: 0;
        }

        .img_close {
            position: absolute;
            right: 0;
            top: 0;
            margin: 10px;
        }

        
    }

    .btn_folleto {
        width: 100%;
        height: 55px;
        color: white;
        border: none;
        background-color: #0FB855;
        margin-top: 30px;
        font-weight: bold;

        a {
            text-decoration: none;
            color: white;
        }
    }
}