.TalentSheet-container {
    margin: 24px 12px 0 12px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    padding: 24px;

    .TalentSheet-header {
        font-size: 17px;
        font-weight: bold;
        color: var(--wblue_dark);
        padding-bottom: 14px;


        display: flex;
        align-items: center;
        justify-content: space-between;

        .titleSpan {
            font-size: 20px;
            font-weight: bold;
            color: var(--wblue_dark);
        }

        button {
            font-size: 18px;
            font-weight: 500;
            color: var(--wgreen);

            span {
                padding-right: 5px;
            }
        }


    }

    .TalentSheets-box {

        .text-content {
            font-size: 17px;
            color: var(--wgray_darker);
            line-height: 22px;
            padding-bottom: 24px;
        }

        .col-12 {
            height: 50px;
            background: #F4F4F6;
            display: flex;
            align-items: center;
            border-radius: 25px;
            padding: 10px;
            font-size: 17px;
            color: var(--wgray_darker);
            margin-bottom: 10px;

            img {
                min-width: 35px;
                width: 35px;
                height: 35px;
                border-radius: 17.5px;
                margin-right: 10px;
                object-fit: cover;

                &.Twitter {
                    object-position: 0 0;
                }

                &.Facebook {
                    object-position: 0 -35px;
                }

                &.LinkedIn {
                    object-position: 0 calc(-35px * 2);
                }

                &.Instagram {
                    object-position: 0 calc(-35px * 3);
                }

                &.YouTube {
                    object-position: 0 calc(-35px * 4);
                }

                &.Spotify {
                    object-position: 0 calc(-35px * 5);
                }
            }
        }
    }

    .TalentSheet-adder-container {
        button {
            width: 100%;
            height: 50px;
            border-radius: 5px;
            background-color: #E8FAF0;
            border: none;
            color: #0FB855;
            font-size: 17px;
            margin-top: 20px;
            font-weight: 600;
        }
    }

    .TalentSheet-recomendation-container {
        .recomendation-header {
            padding: 20px 0 10px;

            .recomendation-cant-box {
                font-size: 18px;
                font-weight: 600;
                color: var(--wblue);
            }

            .recomendation-showmore-box {
                padding: 0 0 0 5px;
                max-width: 85px;
                text-align: end;

                button {
                    color: var(--wblue);
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }

        .recomendation-items-container {
            overflow: auto;

            .recomendation-item {
                padding: 0 7px;
                max-width: 266px;
                min-width: 266px;

                .recomendation-item-row {
                    background: #F4F4F6;
                    padding: 12px;
                    border-radius: 5px;
                    height: 175px;

                    .recomendation-details-box {
                        line-height: 20px;
                    }

                    .recomendation-text-box {
                        padding-top: 10px;
                        font-size: 17px;
                        color: var(--wgray_darker);
                        line-height: 20px;
                    }

                    .avatar-image-box-60 {

                        .likeIcn {
                            position: absolute;
                            border: none;
                            background: #fff;
                            width: 30px;
                            height: 30px;
                            bottom: 0;
                            right: -6px;
                        }
                    }
                }
            }
        }
    }
}
