.MessagesDesktopList-container {
    background: #fff;
    max-width: 350px;
    min-width: 350px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    border-radius: 10px;

    .MessagesDesktopList-header-container {
        padding-top: 10px;
        padding-bottom: 10px;
        height: 91px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--wgray_soft);

        .MessagesDesktopList-header-title {
            font-size: 20px;
            font-weight: bold;
            color: var(--wblue_dark);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .MessagesDesktopList-header-btn {
            height: 40px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            z-index: 2;

            button {
                height: 40px;
                width: 40px;
                border-radius: 0;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;

                &:hover {
                    background: var(--wgray_soft);
                }

                img {
                    height: 30px;
                    width: 30px;
                }
            }

            &.first {
                max-width: 40px;

                button {

                    img {
                        object-fit: cover;
                        object-position: 0 0;
                    }
                }
            }

            &.second {
                max-width: calc(40px + calc(var(--bs-gutter-x) * .5));

                button {

                    img {
                        object-fit: cover;
                        object-position: 0 -60px;

                        &.collapsed {
                            object-position: 0 -60px;
                        }

                        &.expanded {
                            object-position: 0 -30px;
                        }
                    }
                }
            }
        }
    }

    .MessagesDesktopList-main-container {

        &.expanded {
            height: 100%;
            overflow: auto;
        }

        &.collapsed {
            height: 0;
            overflow: hidden;
        }

        .MessagesDesktopList-searcher-container {
            padding: 10px 0;

            label {
                height: 30px;
                width: 30px;
                position: absolute;
                top: 50%;
                left: 5px;
                padding: 0px;
                transform: translateY(-50%);

                img {
                    height: 30px;
                    width: 30px;
                    object-fit: cover;
                    object-position: 0 -90px;
                }
            }

            input {
                height: 40px;
                width: 100%;
                border: none;
                background: var(--wgray_soft);
                border-radius: 5px;
                padding: 0 40px;
            }

            button {
                position: absolute;
                width: 40px;
                height: 40px;
                right: 0;

                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .MessagesDesktopList-users-list {
            // max-height: calc(100vh - 100px - 10px - 60px - 60px - 100px);
            max-height: calc(100vh - 25px - 100px - 91px - 16px);
            overflow: auto;
            // padding-top: 20px;

            .MessagesDesktopList-user-container {
                border-left: 5px solid transparent;
                padding-left: 9px;
                padding-right: 14px;
                padding-top: 10px;

                &.active {
                    border-left: 5px solid var(--wgreen);
                    background: var(--wgreen_softer);
                }

                .MessagesDesktopList-user-col {
                    max-width: calc(100% - 60px);
                    min-height: 75px;

                    .MessagesDesktopList-user-row {
                        padding-bottom: 14px;
                        border-bottom: 1px solid var(--wgray_soft);

                        .nameCol {
                            font-size: 18px;
                            font-weight: 600;
                            color: var(--wblue_dark);
                        }

                        .timeSinceCol {
                            font-size: 13px;
                            color: var(--wgray_dark);
                            max-width: 75px;
                            text-align: right;
                        }

                        .MessagesDesktopList-result-deleter {
                            max-width: 30px;
                            max-height: 30px;
                            margin-top: -5px;

                            .dropdown {
                                .dropdown-toggle {
                                    background: transparent;
                                    border: none;
                                    outline: none !important;
                                    padding: 0;
                                    box-shadow: none !important;
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .MessagesDesktopList-result-unread-container {
                            padding: 0;
                            background: var(--wgreen);
                            max-width: 30px;
                            max-height: 30px;
                            height: 30px;
                            border-radius: 15px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 13px;
                            color: #fff;
                            margin-left: auto;
                        }

                        .subtitleCol {
                            font-size: 16px;
                            color: var(--wblue_dark);
                        }

                        .contentCol {
                            font-size: 16px;
                            color: var(--wgray_dark);
                            line-height: 20px;
                            min-height: 40px;
                            height: 40px;
                        }
                    }
                }

            }

            .MessagesDesktopList-contacts-header {
                font-size: 17px;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: -0.4px;
                text-align: left;
                height: 45px;
                display: flex;
                align-items: center;
                padding: 12px;
                border-bottom: 1px solid var(--wgray_soft);
            }
        }
    }
}
