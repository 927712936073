.MessagesBubble-container {
    position: fixed;
    background: #fff;
    z-index: 4;
    bottom: 0;
    right: calc(50% - 700px - 187px - 9px);
    transform: translateX(-50%);
    width: 350px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    border-radius: 10px 10px 5px 5px;

    &.isHome {
        right: calc(50% - 700px - 187px);
    }

    &.expanded {
        box-shadow: 0 -4px 10px rgba(0,0,0,0.14);
    }

    .MessagesBubble-header-container {
        padding-top: 10px;
        padding-bottom: 10px;

        .MessagesBubble-header-title {
            font-size: 20px;
            font-weight: bold;
            color: var(--wblue_dark);
            display: flex;
            align-items: center;
        }

        .MessagesBubble-header-btn {
            height: 40px;

            button {
                height: 40px;
                width: 40px;
                border-radius: 0;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;

                &:hover {
                    background: var(--wgray_soft);
                }

                img {
                    height: 30px;
                    width: 30px;
                }
            }

            &.first {
                max-width: 40px;

                button {

                    img {
                        object-fit: cover;
                        object-position: 0 0;
                    }
                }
            }

            &.second {
                max-width: calc(40px + calc(var(--bs-gutter-x) * .5));

                button {

                    img {
                        object-fit: cover;
                        object-position: 0 -60px;

                        &.collapsed {
                            object-position: 0 -60px;
                        }

                        &.expanded {
                            object-position: 0 -30px;
                        }
                    }
                }
            }
        }
    }

    .MessagesBubble-main-container {

        &.expanded {
            height: 100%;
            overflow: auto;
        }

        &.collapsed {
            height: 0;
            overflow: hidden;
        }

        .MessagesBubble-searcher-container {
            padding: 10px 0;

            label {
                height: 30px;
                width: 30px;
                position: absolute;
                top: 50%;
                left: 5px;
                padding: 0px;
                transform: translateY(-50%);

                img {
                    height: 30px;
                    width: 30px;
                    object-fit: cover;
                    object-position: 0 -90px;
                }
            }

            input {
                height: 40px;
                width: 100%;
                border: none;
                background: var(--wgray_soft);
                border-radius: 5px;
                padding: 0 40px;
            }

            button {
                position: absolute;
                width: 40px;
                height: 40px;
                right: 0;

                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .MessagesBubble-users-list {
            max-height: calc(100vh - 100px - 10px - 60px - 60px - 100px - 100px - 14px);
            overflow: auto;
            padding-top: 20px;
            min-height: 250px;

            .MessagesBubble-user-container {

                .MessagesBubble-user-col {
                    max-width: calc(100% - 60px);
                    min-height: 75px;

                    .MessagesBubble-user-row {
                        padding-bottom: 8px;
                        border-bottom: 1px solid var(--wgray_soft);
                        margin-bottom: 10px;

                        .nameCol {
                            font-size: 18px;
                            font-weight: 600;
                            color: var(--wblue_dark);
                        }

                        .timeSinceCol {
                            font-size: 13px;
                            color: var(--wgray_dark);
                            max-width: 75px;
                            text-align: right;
                        }

                        .subtitleCol {
                            font-size: 16px;
                            color: var(--wblue_dark);
                        }

                        .contentCol {
                            font-size: 16px;
                            color: var(--wgray_dark);
                            line-height: 20px;
                        }
                    }
                }

            }
        }
    }
}
