.container-membership {

    background-color: white;
    height: 100vh;
    padding: 0;

    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);

    .container_header {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        padding: 30px 15px 10px 15px;
        align-items: center;
        background-color: #F8F8FB;
        margin-bottom: 30px;


        h3 {
            color: black;
            width: 100vw;
            text-align: center;
            display: block;
            font-size: 17dp;
        }
    }

    .container_data {

        img {
            display: block;
            margin: 0 auto;

        }

        .logo {
            width: 243px;
            height: 57px;
        }

        p {
            font-size: 17px;
            color: #272C40;
            text-align: center;
            padding: 15px;
        }

        .text1 {
            margin-top: 10px;
            padding-left: 25px;
        }

        .text2 {
            margin-top: -20px;
        }

        &_option {
            cursor: pointer;
            display: flex;
            justify-content: space-between!important;
            align-items: center;
            padding: 10px;
            margin: 15px;
            background-color: #F8F8FB;
            border: 1px solid #CDCED4;
            border-radius: 10px;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 20px;

            &_description {

                h4 {
                    color: #0D458D;
                    font-weight: bold;
                }

                p {
                    margin: 0;
                    padding: 0!important;
                    text-align: left!important;
                    font-size: 15px;
                    color: #585C6E;
                }

            }

            img {
                margin-left: 10px!important;
                align-content: flex-end!important;
                margin-right: 5px!important;
                visibility: hidden!important;
            }


            &.active {
                background-color: #E8FAF0!important;
                border: 1px solid #0FB855!important;

                img {
                    visibility: visible!important;
                }
            }

        }

        button {
            margin-top: 40px;
            background-color: #0D458D;

            margin-left: 14px;
            margin-right: 14px;
            width: calc(100% - 14px - 14px);
            height: 55px;
            color: #fff;
            font-size: 18px;
        }
    }
}
