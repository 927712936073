.DirectoryDesktopList-container {
    padding: 0 calc(calc(var(--bs-gutter-x) * .5) / 2);

    &.noresults {
        max-height: calc(100% - 78px - 60px);
        display: flex;
        align-items: center;

        .DirectoryDesktopList-placeholder-container {
            text-align: center;

            p {
                font-size: 18px;
                font-weight: 600;
                color: var(--wblue_dark);
                margin: 0;
                text-align: center;
            }

            span {
                text-align: center;
                color: var(--wgray_dark);
            }
        }
    }

    .DirectoryDesktopList-item {
        margin-bottom: 24px;
        padding: 0 calc(calc(var(--bs-gutter-x) * .5) / 2);
        cursor: pointer;

        .mainRow {
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 1px 10px rgba(0,0,0,0.14);

            .DirectoryDesktopList-img-box {
                padding-top: 20px;
                position: relative!important;
                .avatar {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    border: 1px solid var(--wgray_dark);
                    object-fit: cover !important;
                    min-width: 80px;
                    min-height: 80px;
                    position: relative!important;
                }

                .miembro {
                    position: absolute!important;
                    width: 25px;
                    height: 25px;
                    bottom: 0;
                    right: 30%;
                }
            }

            .DirectoryDesktopList-name-box {
                font-size: 18px;
                font-weight: 600;
                padding-top: 14px;
                padding-bottom: 14px;
                color: var(--wblue_dark);
                min-height: 55px;
            }

            .DirectoryDesktopList-details-box {
                min-height: 88px;

                .DirectoryDesktopList-details-title {
                    font-size: 16px;
                    color: var(--wblue_dark);
                }

                .DirectoryDesktopList-details-subtitle {
                    font-size: 16px;
                    color: var(--wgray_dark);
                }
            }


            .DirectoryDesktopList-footer-btn-box {
                button {
                    height: 50px;
                    background: var(--wblue);
                    width: 100%;
                    border-radius: 0 0 10px 10px;
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;
                }
            }
        }
    }
}
