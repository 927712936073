.ContactList-container {

    height: calc(100% - 50px);
    overflow: auto;
    position: absolute;
    top: calc(53px + 69px);
    width: 100%;

    background: #fff;

    &.switcher {
        top: calc(53px + 69px + 80px + 81px);
        height: calc(100% - 53px - 69px - 80px - 80px);

        &.hide-group {
            top: calc(53px + 69px + 80px);
            height: calc(100% - 53px - 69px - 80px);
        }
    }

    &.invitation {
        height: calc(100% - 53px - 69px - 77px);
    }

    &.massive {
        top: calc(53px + 69px + 50px);
        height: calc(100% - 53px - 69px - 50px - 77px);
    }

    .ContactList-results-container {
        padding: 20px 14px;

        .ContactList-result-checker-container {
            max-width: calc(25px + 15px);
            padding: 0;
            display: flex;
            align-items: center;
            padding-bottom: 14px;
            padding-right: 15px;

            img {
                width: 25px;
                height: 25px;
                border-radius: 12.5px;
                object-fit: cover;
            }

            &.active-blocked {
                img {
                    filter: grayscale(1);
                }
            }
        }
        .ContactList-result-img-container {
            max-width: 50px;
            max-height: 50px;
            padding: 0;
            position: relative;

            img {
                width: 50px;
                height: 50px;
                border-radius: 25px;
                object-fit: cover;
                border: 2px solid var(--wgray_dark);
            }

            .avatar {
                position: relative;
            }

            .miembro {
                position: absolute;
                width: 25px;
                height: 25px;
                bottom: 0;
                right: -5px;
            }
        }

        .ContactList-result-name-container {

            padding-bottom: 14px;
            min-height: 70px;

            .row {
                border-bottom: 1px solid var(--wgray_soft);
                padding-bottom: 8px;
            }
            .title {
                font-size: 17px;
                color: var(--wblue_dark);
                font-weight: bold;
            }

            .subtitle {
                font-size: 15px;
                color: var(--wblue_dark);
            }
        }
    }

    .ContactList-noresults-container {
        padding-top: 70px;
        height: 100%;
    }
}

.ContactList-group-container {
    height: 108px;

    .ContactList-group-header-row {
        padding: 14px;

        .image-col {
            max-width: 60px;

            .edit-col {
                text-align: center;
                visibility: hidden;
                button {
                    color: var(--wblue);
                    font-size: 14px;
                    font-weight: 600;
                }

                &.showing {
                    visibility: visible;
                }
            }
            .camera-col {
                max-width: 60px;
                max-height: 60px;
                width: 60px;
                height: 60px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 60px;
                    background: var(--wgray_softer);
                    border-radius: 30px;
                    border: 1px solid var(--wgray_semisoft);
                    object-fit: cover;
                }
            }
        }

        .input-col {

            input {
                border: none;
                border-bottom: 1px solid var(--wgray_semisoft);
                width: 100%;
                padding: 17px 0;
                outline: none !important;
                font-size: 17px;
                font-weight: 600;

                color: var(--wblue_dark);

                &::placeholder {
                    color: var(--wgray_semisoft);
                }
            }
        }
    }

    .ContactList-group-members-row {

        .title-header {
            border-top: 1px solid var(--wgray_soft);
            background: var(--wgray_softer);
            height: 44px;
            display: flex;
            align-items: center;
            font-size: 17px;
            font-weight: 600;
            color: var(--wgray);
            padding-left: 14px !important;
            padding-right: 14px !important;
        }

        .ContactList-group-members-container {
            padding: 14px;

            .ContactList-group-member {
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 14px;
                max-width: 82px;

                .member-name {
                    font-size: 14px;
                    color: var(--wgray_darker);
                    font-weight: 600;
                    text-align: center;
                    padding-right: 10px;
                    padding-left: 0;
                }

                .img-user {
                    max-width: 60px;
                    max-height: 60px;
                    width: 60px;
                    height: 60px;
                    border-radius: 30px;
                    border: 1px solid var(--wgray_semisoft);
                    object-fit: cover;
                    position: relative;
                }

                .miembro {
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    bottom: 40px;
                    right: 10px;
                }

                .deleter-btn {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    top: 0;
                    right: 0;

                    img {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

    }
}

.Contact-searcher-container {
    padding: 14px;
    border-bottom: 1px solid var(--wgray_soft);
    position: relative;

    input {
        width: 100%;
        height: 40px;
        border: none;
        outline: none !important;
        background: var(--wgray_soft);
        border-radius: 5px;
        font-size: 17px;
        color: var(--wblue_darker);
        padding: 0 40px;
    }

    label,
    button {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 14px;

        img {
            padding: 4px;
            width: 40px;
            height: 40px;
        }
    }

    label {
        left: 14px;
    }

    button {
        right: 14px;
    }
}

.ContactList-separator-line {
    margin: 0;
    width: calc(100% - 90px);
    float: right;
    color: var(--wgray_soft);
    opacity: 1;
}

.Contact-searcher-massive-switcher {
    font-size: 15px;
    color: var(--wblue);
    padding: 10px 14px;
    font-weight: 600;

    img {
        background-color: var(--wgray_soft);
        height: 60px;
        width: 60px;
        border-radius: 30px;
        margin-right: 15px;
    }

    &.second {
        border-bottom: 1px solid var(--wgray_soft);
    }
}

.Contact-searcher-allpicker-container {
    padding: 10px 14px;

    .chekbox-col {
        max-width: 30px;
    }

    .text-col {
        font-size: 15px;
        font-weight: 600;
        color: var(--wblue_dark);
    }

    .cancel-btn-col {
        max-width: 90px;
        text-align: right;
    }
}


.ContantList-headerView-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;

    span {
        font-size: 20px;
        font-weight: 600;
        color: var(--wblue_dark);
    }

    button {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;

        img {
            width: 30px;
            height: 30px;
        }

        &.filters {
            right: 60px;
        }
    }
}

.ContactList-container.isDesktop {

    height: calc(100% - 50px);
    overflow: auto;
    position: absolute;
    top: calc(53px + 69px);
    width: 100%;

    background: #fff;

    &.switcher {
        top: calc(53px + 69px + 80px + 81px + 22px);
        height: calc(100% - 53px - 69px - 80px - 80px - 25px);

        &.hide-group {
            top: calc(53px + 69px + 80px);
            height: calc(100% - 53px - 69px - 80px);
        }
    }

    &.invitation {
        height: calc(100% - 53px - 69px - 77px - 22px);
        top: calc(53px + 69px + 22px);
    }

    &.massive {
        top: calc(53px + 69px + 50px + 28px);
        height: calc(100% - 53px - 69px - 50px - 77px + 47px - 77px);
    }

    .ContactList-results-container {
        padding: 20px 14px;

        .ContactList-result-checker-container {
            max-width: calc(25px + 15px);
            padding: 0;
            display: flex;
            align-items: center;
            padding-bottom: 14px;
            padding-right: 15px;

            img {
                width: 25px;
                height: 25px;
                border-radius: 12.5px;
                object-fit: cover;
            }

            &.active-blocked {
                img {
                    filter: grayscale(1);
                }
            }
        }
        .ContactList-result-img-container {
            max-width: 50px;
            max-height: 50px;
            padding: 0;
            position: relative;

            img {
                width: 50px;
                height: 50px;
                border-radius: 25px;
                object-fit: cover;
                border: 2px solid var(--wgray_dark);
            }

            .avatar {
                position: relative;
            }

            .miembro {
                position: absolute;
                width: 25px;
                height: 25px;
                bottom: 0;
                right: -5px;
            }
        }

        .ContactList-result-name-container {

            padding-bottom: 14px;
            min-height: 70px;

            .row {
                border-bottom: 1px solid var(--wgray_soft);
                padding-bottom: 8px;
            }
            .title {
                font-size: 17px;
                color: var(--wblue_dark);
                font-weight: bold;
            }

            .subtitle {
                font-size: 15px;
                color: var(--wblue_dark);
            }
        }
    }

    .ContactList-noresults-container {
        padding-top: 70px;
        height: 100%;
    }
}

.ContactList-footer-box {
    position: absolute;
    background: #fff;
    /* height: 75px; */
    width: 100%;
    bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 14px;

    button {
        height: 50px;
        width: 100%;

        height: 50px;
        background: var(--wgray_soft);
        color: var(--wgray);
        font-size: 20px;
        font-weight: 600;
        cursor: not-allowed;

        &.active {
            background: var(--wblue);
            color: #fff;
            cursor: pointer;
        }
    }
}

@media screen and (min-width: 1450px) {
    .ContactList-container {

        height: calc(100% - 50px);
        overflow: auto;
        position: absolute;
        top: calc(53px + 69px);
        width: 100%;

        background: #fff;

        &.switcher {
            top: calc(53px + 69px + 80px + 81px + 22px);
            height: calc(100% - 53px - 69px - 80px - 80px - 22px);

            &.hide-group {
                top: calc(53px + 69px + 80px);
                height: calc(100% - 53px - 69px - 80px);
            }
        }

        &.invitation {
            height: calc(100% - 53px - 69px - 77px);
        }

        &.massive {
            top: calc(53px + 69px + 50px + 22px);
            height: calc(100% - 53px - 69px - 50px - 77px - 22px);
        }

        .ContactList-results-container {
            padding: 20px 14px;

            .ContactList-result-checker-container {
                max-width: calc(25px + 15px);
                padding: 0;
                display: flex;
                align-items: center;
                padding-bottom: 14px;
                padding-right: 15px;

                img {
                    width: 25px;
                    height: 25px;
                    border-radius: 12.5px;
                    object-fit: cover;
                }

                &.active-blocked {
                    img {
                        filter: grayscale(1);
                    }
                }
            }
            .ContactList-result-img-container {
                max-width: 50px;
                max-height: 50px;
                padding: 0;
                position: relative;

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 25px;
                    object-fit: cover;
                    border: 2px solid var(--wgray_dark);
                }

                .avatar {
                    position: relative;
                }

                .miembro {
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    bottom: 0;
                    right: -5px;
                }
            }

            .ContactList-result-name-container {

                padding-bottom: 14px;
                min-height: 70px;

                .row {
                    border-bottom: 1px solid var(--wgray_soft);
                    padding-bottom: 8px;
                }
                .title {
                    font-size: 17px;
                    color: var(--wblue_dark);
                    font-weight: bold;
                }

                .subtitle {
                    font-size: 15px;
                    color: var(--wblue_dark);
                }
            }
        }

        .ContactList-noresults-container {
            padding-top: 70px;
            height: 100%;
        }
    }
}
