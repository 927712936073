.CompanySheet-container {
    background: #fff;
    max-width: 350px;
    min-width: 350px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    border-radius: 10px;
    padding: 20px 20px 30px;

    .Company-header-container {

        .Company-logo-container {
            padding: 0;
            max-width: 100px;
            max-height: 78px;

            img {
                width: 100px;
                height: 78px;
                object-fit: cover;
            }
        }

        .Company-main-details {

            .title {
                font-size: 17px;
                font-weight: bold;
                color: var(--wblue_dark);
            }

            .subtitle {
                font-size: 15px;
                color: var(--wgray_darker);
            }
        }
    }

    .CompanySheet-description-container {
        padding-top: 24px;

        .CompanySheet-description-title {
            font-size: 17px;
            font-weight: bold;
            color: var(--wblue_dark);
            padding-bottom: 8px;
        }

        .CompanySheet-description-content {
            font-size: 17px;
            color: var(--wgray_darker);
            line-height: 20px;
        }
    }

    .CompanySheet-details-container {
        .icnBox {
            width: 30px;
            height: 30px;
            max-width: 30px;
            max-height: 30px;

            img {
                width: 30px;
                height: 30px;
                object-position: 0px -0px;
                object-fit: cover;

                $positions: 8;

                @for $i from 0 through $positions {
                    &.pos-#{$i + 1} {
                        object-position: 0px calc(-30px * #{$i});
                    }
                }
            }
        }

        .txtBox {
            font-size: 18px;
            color: var(--wgray_darker);

            a {
                color: var(--wgray_darker);
            }
        }
    }

    .CompanySheet-specialities-container {
        .CompanySheet-specialities-title {
            padding-top: 24px;
            font-size: 17px;
            font-weight: bold;
            color: var(--wblue_dark);
            padding-bottom: 12px;
        }

        .CompanySheet-specialities-options {
            button {
                height: 40px;
                background: var(--wgray_softer);
                border: 5px;
                font-size: 17px;
                color: var(--wgray_darker);
                padding: 17px;
                cursor: default;
            }
        }
    }

    // .CompanySheet-info-container {
    //     background: #fff;
    //     max-width: 350px;
    //     min-width: 350px;
    //     box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    //     border-radius: 10px 10px 5px 5px;
    //     margin-top: -20px;
    //     padding: 30px 20px;
    //
    //     .CompanySheet-name-container {
    //         font-size: 24px;
    //         font-weight: bold;
    //         color: var(--wblue_dark);
    //     }
    //
    //     .CompanySheet-bm-icon {
    //         padding: 0;
    //         max-width: 20px;
    //         max-height: 20px;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //
    //         img {
    //             width: 20px;
    //             height: 20px;
    //         }
    //     }
    //
    //     .CompanySheet-details-container {
    //         .icnBox {
    //             width: 30px;
    //             height: 30px;
    //             max-width: 30px;
    //             max-height: 30px;
    //
    //             img {
    //                 width: 30px;
    //                 height: 30px;
    //                 object-position: 0px -0px;
    //                 object-fit: cover;
    //
    //                 $positions: 8;
    //
    //                 @for $i from 0 through $positions {
    //                     &.pos-#{$i + 1} {
    //                         object-position: 0px calc(-30px * #{$i});
    //                     }
    //                 }
    //             }
    //         }
    //
    //         .txtBox {
    //             font-size: 18px;
    //             color: var(--wgray_darker);
    //         }
    //     }
    // }
}
