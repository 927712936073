.Rules-container {
    background: #fff;
    width: 100%;
    height: 100%;
    padding: 20px 0px 65px 0px;
    position: relative;

    .Rules-header-view {
        padding-bottom: 17px;

        .Rules-header-container {
            padding-left: 14px;
            padding-right: 14px;
            .Rules-header-close-btn,
            .Rules-header-post-btn {
                position: absolute;
                top: 0;
            }

            .Rules-header-close-btn {
                width: 30px;
                height: 30px;
                left: 0;
                img {
                    width: 30px;
                    height: 30px;
                }
            }

            .Rules-header-post-btn {
                right: 0;
                font-size: 17px;
                color: var(--wgray_darker);

                &.active {
                    color: var(--wgreen);
                }
            }

            p {
                margin: 0;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                color: var(--wblue_dark);
            }

        }
    }

    .iframe {
        height: 100vh;
    }

    .Rules-item-container {
        min-height: 55px;

        .Rules-item-title {
            padding-left: 14px;
            padding-right: 14px;
            height: 55px;
            border-bottom: 1px solid var(--wgray_soft);
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 17px;
            font-weight: 600;
            color: var(--wblue_dark);
        }

        .Rules-item-content {
            padding: 0;
            height: 0;
            overflow: hidden;
            border-bottom: none;

            &.showing {
                padding: 16px 24px;
                height: 100%;
                overflow: visible;
                border-bottom: 1px solid var(--wgray_soft);
            }
        }
    }

    .Rules-contact-container {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        color: #fff;
        background: var(--wblue);
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        max-width: 700px;

        img {
            width: 45px;
            height: 45px;
        }
    }
}
