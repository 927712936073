.FilterTagCell-container {
    border-bottom: 1px solid var(--wgray_semisoft);

    .FilterTagCell-title-box {
        padding-top: 14px;
        padding-bottom: 14px;
        d-flex: flex;
        align-items: center;

        .title {
            font-size: 17px;
            font-weight: bold;
            color: var(--wblue_dark);
        }

        .selector {
            max-width: 40px;
            max-height: 40px;
        }
    }

    .FilterTagCell-tags-box {

        .FilterTagCell-tag-item {
            display: inline-block;
            width: fit-content;
            padding-left: 0 !important;
            padding-bottom: 12px;

            div {
                font-size: 15px;
                font-weight: 600;
                color: var(--wgray_darker);
                height: 35px;
                position: relative;
                display: flex;
                background: var(--wgreen_soft);
                width: fit-content;
                padding: 0 5px 0 12px;
                align-items: center;
                border-radius: 17.5px;


                img {
                    width: 28px;
                    height: 28px;
                    max-width: 28px;
                    max-height: 28px;
                }
            }
        }
    }
}
