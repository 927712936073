.Register-container {

    padding: 24px 0;
    width: 100%;
    height: 100vh;
    position: relative;

    max-width: 500px;
    margin: 0 auto;

}
