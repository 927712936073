.SocialNetworks-container {
    padding-top: 24px;
    padding-bottom: 24px;

    .SocialNetworks-title-container {
        font-weight: bold;
        font-size: 22px;
        color: var(--wblue_dark);
        padding-bottom: 24px;
    }

    .SocialNetworks-form-container {

        .SocialNetworks-form-group {
            margin-bottom: 10px;

            label {
                margin: 0;
                width: 55px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;

                img {
                    object-fit: cover;
                    width: 35px;
                    height: 35px;
                    object-position: 0 0;

                    &.Twitter {
                        object-position: 0 0;
                    }

                    &.Facebook {
                        object-position: 0 -35px;
                    }

                    &.LinkedIn {
                        object-position: 0 calc(-35px * 2);
                    }

                    &.Instagram {
                        object-position: 0 calc(-35px * 3);
                    }

                    &.YouTube {
                        object-position: 0 calc(-35px * 4);
                    }

                    &.Spotify {
                        object-position: 0 calc(-35px * 5);
                    }
                }
            }

            input {
                height: 55px;
                width: 100%;
                border-radius: 5px;
                border: 1px solid var(--wgray);
                padding: 0 55px;
                outline: none !important;
                font-size: 17px;
                font-weight: 600;
                color: var(--wgray_darker);

                &::placeholder {
                    color: rgba(60, 60, 67, 0.3);
                }
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                width: 55px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 35px;
                    width: 35px;
                }
            }
        }
    }
}

.SocialNetworks-container.isDesktop {
    padding: 20px;

    .SocialNetworks-title-container {
        font-weight: bold;
        font-size: 22px;
        color: var(--wblue_dark);
        padding-bottom: 24px;
        position: relative;
        padding-left: 0;
        padding-right: 0;

        button {
            position: absolute;
            top: 0;
            right: 0;
            img {
                width: 30px;
                height: 30px;
            }
        }
    }

    .SocialNetworks-form-container {
        padding-left: 0;
        padding-right: 0;

        .SocialNetworks-form-group {
            margin-bottom: 10px;

            label {
                margin: 0;
                width: 55px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;

                img {
                    object-fit: cover;
                    width: 35px;
                    height: 35px;
                    object-position: 0 0;

                    &.Twitter {
                        object-position: 0 0;
                    }

                    &.Facebook {
                        object-position: 0 -35px;
                    }

                    &.LinkedIn {
                        object-position: 0 calc(-35px * 2);
                    }

                    &.Instagram {
                        object-position: 0 calc(-35px * 3);
                    }

                    &.YouTube {
                        object-position: 0 calc(-35px * 4);
                    }

                    &.Spotify {
                        object-position: 0 calc(-35px * 5);
                    }
                }
            }

            input {
                height: 55px;
                width: 100%;
                border-radius: 5px;
                border: 1px solid var(--wgray_semisoft);
                padding: 0 55px;
                outline: none !important;
                font-size: 17px;
                font-weight: 600;
                color: var(--wgray_darker);

                &::placeholder {
                    color: rgba(60, 60, 67, 0.3);
                }
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                width: 55px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 35px;
                    width: 35px;
                }
            }
        }
    }

    .SocialNetworks-footer-container {
        button {
            width: 100%;
            height: 50px;
            background: var(--wgray_soft);
            font-size: 18px;
            font-weight: 600;
            cursor: not-allowed;

            &.active {
                background: var(--wblue);
                color: #fff;
                cursor: pointer;
            }
        }
    }
}
