.Home-filter-ment {
  padding-top: 16px;
  overflow: auto;
  white-space: nowrap;
  min-height: calc(40px + 30px);
  margin-left: 20px;
  margin-right: 20px;

  &::-webkit-scrollbar {
    height: 4px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--wblue);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #dcdbdb;
  }

  .Home-filter-ment-item {
    background: transparent;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border: 1px solid #dcdbdb;
    border-radius: 6px;
    margin-right: 10px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */

    text-align: center;
    letter-spacing: -0.32px;

    color: #9a9ba0;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }

    &.selected {
      background: #ddeafb;
      border: 1px solid #0d458d;
      border-radius: 6px;
      color: #0d458d;
    }
  }
}
