.AudioPlayerSimple-container {
    audio {
        width: 100%;

        &::-webkit-media-controls-play-button,
        &::-webkit-media-controls-panel {
            background-color: var(--wgray_soft);
        }
    }
}
