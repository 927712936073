.ReportPost-container {
    background: #fff;
    // height: 100%;

    .ReportPost-header-container {
        padding: 20px 14px;
        background: var(--wgray_softer);
        height: 70px;
        border-bottom: 1px solid var(--wgray_soft);

        .col-12 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            button {
                position: absolute;
                color: var(--wgreen);
                height: 40px;
                font-size: 16px;
                top: 0;
                left: 0;
            }
            p {
                margin: 0;
                font-size: 17px;
                font-weight: bold;
                color: var(--wblue_dark);
            }
        }
    }

    .ReportPost-body {
        padding: 24px 14px;

        .ReportPost-select-box,
        .ReportPost-input-box {
            label {
                margin-top: 16px;
                margin-bottom: 5px;
                font-size: 17px;
                font-weight: bold;
                color: var(--wblue_dark);
                display: block;
            }
        }

        .ReportPost-select-box {
            select {
                font-size: 17px;
                font-weight: bold;
                outline: none !important;
                border: 1px solid var(--wgray);
                border-radius: 3px;
                padding-left: 16px;
                padding-right: 55px;
                background-color: transparent;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 100%;
                height: 55px;
                background-image: url('../../../_assets/media/images/icons/icn_arrow_down.png');
                background-position: 98% 50%;
                background-size: 40px;
                background-repeat: no-repeat;
            }

            &.isError {
                select {
                    border: 1px solid var(--wred);
                    background-color: var(--wred_soft);
                }
            }
        }

        .ReportPost-input-box {

            textarea {
                resize: none;
                height: 150px;
                width: 100%;
                font-size: 17px;
                border-radius: 3px;
                outline: none !important;
                border: 1px solid var(--wgray);
                color: var(--wblue_dark);
                padding: 14px;

                &::placeholder {
                    color: var(--wgray_dark);
                }
            }

            &.isError {
                textarea {
                    border: 1px solid var(--wred);
                    background-color: var(--wred_soft);
                }
            }
        }

        .ReportPost-submit-box {
            padding-top: 20px;

            button {
                height: 55px;
                border-radius: 4px;
                background: var(--wblue);
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                width: 100%;
            }
        }
    }
}
