.LandingIndex-container {
	@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,400i,500,600,700,800');

	font: 18px/22px "Raleway", Arial, Helvetica, sans-serif;
    color: #6e6e6e;
    background-color: #222;
    padding: 0 !important;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	.wrapper {
	  overflow: hidden;
	  background-color: #fff;
	}

	.section-overlay {
	  background-color: rgba(0, 0, 0, 0.7);
	  padding: 90px 0 120px;
	}

	.services .container,
	.blog .container,
	.pricing .container {
	  padding: 90px 15px !important;
	}

	.about .container,
	.team .container {
	  padding: 90px 15px 112px !important;
	}

	.portfolio .container {
	  padding: 90px 0 !important;
	}

	.unstyled {
	  list-style: none;
	  margin: 0;
	  padding: 0;
	}

	@media(min-width:1200px) {
	  &.boxed header {
	    max-width: 1220px;
	  }
	  &.boxed {
	    background: url("../../../_assets/media/images/new-landing//backgrounds/az_subtle.png");
	    background-attachment: fixed;
	  }
	  &.boxed .wrapper {
	    max-width: 1220px;
	    margin: 0 auto;
	    background: #fff;
	    box-shadow: 0px 3px 8px 7px #ccc;
	  }
	}

	hr {
	  margin-top: 25px;
	}


	/* Dark Body */

	&.dark {
	  color: #ccc;
	}

	&.dark p,
	&.dark h1,
	&.dark h2,
	&.dark h4,
	&.dark h5,
	&.dark h6,
	&.dark .portfolio .caption h3,
	&.dark .portfolio .nav-pills li {
	  color: #eee;
	}

	&.dark.boxed .wrapper,
	&.dark .wrapper,
	&.dark ul.nav li.dropdown ul.dropdown-menu,
	&.dark.auth-page,
	&.dark .faq,
	&.dark .shop-cart,
	&.dark .shop-checkout,
	&.dark .terms {
	  background-color: #161A2C;
	}

	&.dark ul.nav li.dropdown ul.dropdown-menu li:first-child {
		padding-top:1px;
	}

	&.dark .panel.panel-default,
	&.dark .panel.panel-default {
	  border-color: #343434;
	}

	&.dark h4.panel-title a.collapsed,
	&.dark .terms h3,
	&.dark ul.nav li.dropdown ul.dropdown-menu li a,
	&.dark .header.header-fixed ul.nav li.dropdown ul.dropdown-menu li a,
	&.dark .shop-checkout .checkout table.products td.text-price,
	&.dark .header-fixed .navbar-nav .fa-search,
	&.dark.blog-page article h4,
	&.dark .sidebar .widget-title,
	&.dark .widget.recent-posts .entry-title a,
	&.dark .shop-cart table.order thead tr,
	&.dark .shop-cart h4.title-totals,
	&.dark .shop-cart .table h6,
	&.dark .shop-cart .table td span.price,
	&.dark .shop-cart .table .icon-delete-product,
	&.dark .shop-cart .price,
	&.dark .shop-cart .table.cart-total th.total .price,
	&.dark .shop-checkout h3,
	&.dark .about .about-content .title-about,
	&.dark ul.navbar-nav>li>a,
	&.dark .header-fixed ul.navbar-nav li a,
	&.dark .comments-heading,
	&.dark .comments-list .comment-author,
	&.dark .project .project-details h3,
	&.dark .latest-post .post-title a,
	&.dark .price-content .value {
	  color: #fff;
	}

	&.dark .panel-default>.panel-heading+.panel-collapse>.panel-body {
	  border-top-color: #343434;
	}

	&.dark ul.nav li.dropdown ul.dropdown-menu {
	  border: 1px solid #343434;
	  box-shadow: none;
	  border-top: 0;
	}

	&.dark ul.nav.nav-tabs li a,
	ul.nav.nav-tabs li.active a {
	  color: #ddd;
	}

	&.dark .header.header-fixed {
	  background-color: #161A2C;
	  border-bottom: 1px solid #343434;
	}

	&.dark .header.header-fixed #icon-toggler span {
	  background-color: #fff;
	}

	&.dark .shop-checkout .form-group select.form-control:focus {
	  border: 1px solid #333;
	}

	&.dark .sidebar ul.nav.nav-tabs li a {
	  color: #ddd;
	}

	&.dark.blog-page .meta {
	  border-top: 1px solid #343434;
	  border-bottom: 1px solid #343434;
	}

	&.dark .sidebar ul.nav-tabs li,
	&.dark .widget.recent-posts ul li {
	  border-bottom: 1px solid #444;
	}

	&.dark .sidebar ul.nav-tabs li:last-child,
	&.dark.blog-page .widget.recent-posts ul li:last-child {
	  border-bottom: 0;
	}

	&.dark .shop-checkout select {
	  background: #343434;
	  border: 1px solid #444;
	}

	&.dark .widget,
	&.dark .comments-list .comment,
	&.dark .shop-checkout .checkout .payment .cheque,
	&.dark .shop-checkout .checkout .payment .paypal,
	&.dark .shop-checkout .checkout table.products th.with-bg,
	&.dark .shop-checkout .checkout table.products thead tr,
	&.dark .shop-cart .qty,
	&.dark .shop-cart input.qtyplus,
	&.dark .shop-cart input.qtyminus,
	&.dark.blog-page .pagination li a:hover,
	&.dark .shop-cart table.order thead tr,
	&.dark .shop-cart .quantity,
	&.dark .custom-form input[type=text],
	&.dark .custom-form input[type=email],
	&.dark .custom-form input[type=password],
	&.dark .custom-form textarea {
	  background-color: #343434;
	}

	&.dark.blog-page .pagination li a {
	  border: 1px solid #343434;
	  color: #fff;
	}

	&.dark .shop-cart .table tbody>tr,
	&.dark .shop-checkout .table tbody>tr,
	&.dark .shop-cart .table.cart-total .section-border {
	  border-bottom: 1px solid #343434;
	}

	&.dark .custom-form input[type=text],
	&.dark .custom-form input[type=email],
	&.dark .custom-form input[type=password],
	&.dark .custom-form textarea {
	  border: 1px solid #444;
	  color: #fff;
	}

	&.dark .custom-form input[type=text]:focus,
	&.dark .custom-form input[type=text]:active,
	&.dark .custom-form input[type=email]:focus,
	&.dark .custom-form input[type=email]:active,
	&.dark .custom-form input[type=password]:focus,
	&.dark .custom-form input[type=password]:active,
	&.dark .custom-form textarea:focus,
	&.dark .custom-form textarea:active {
	  border: 1px solid #555;
	  background-color: #343434;
	}

	&.dark .about ul.nav.nav-tabs li>a {
	  border-left: 1px solid #aaa;
	}

	&.dark .about ul.nav.nav-tabs li:first-child>a {
	  border-left: 0;
	}

	&.dark .about,
	&.dark .services,
	&.dark .pricing,
	&.dark .portfolio,
	&.dark .team,
	&.dark .blog,
	&.dark .contact,
	&.dark .project,
	&.dark .project .bx-wrapper,
	&.dark .preloader,
	&.dark .user-auth>div:nth-child(2),
	&.dark .logos {
	  background-color: #161A2C;
	}

	&.dark .project .bx-wrapper {
	  border: 5px solid #161A2C;
	}

	&.dark .services-box-item-cover,
	&.dark .services-box-item-content {
	  background: #272C40;
	  box-shadow: none;
	}

	&.dark .about .about-content .about-right-side img {
	  border: 0;
	}

	&.dark hr {
	  border-top: 1px solid #343434;
	  background-color: transparent;
	}

	&.dark .caption p,
	&.dark .services-box-item-content p,
	&.dark .info-map-boxes-item p {
	  color: #ccc;
	}

	&.dark .team-member {
	  box-shadow: none;
	}

	&.dark .team-member-caption {
	  background-color: #272C40;
	}

	&.dark .info-map-boxes-item {
	  background-color: #343434;
	  box-shadow: none;
	}

	&.dark .info-map-boxes-item:before {
	  background-color: #343434;
	}

	&.dark .info-map-boxes-item h1 {
	  border-bottom: 1px solid #444;
	}

	&.dark .gmap_container {
	  color: #6e6e6e;
	}

	&.dark .pricing-wrapper>li {
	  background: #171717;
	}

	&.dark .price-content .currency {
	  color: #666;
	}

	&.dark .pricing-features li {
	  border-bottom: 1px solid #161A2C;
	}

	&.dark.reversed-skew .logos {
	  margin-top: -50px;
	  padding-top: 27px;
	  margin-bottom: -115px;
	  padding-bottom: 95px;
	}

	&.dark.skew .logos {
	  margin-top: -50px;
	  padding-top: 50px;
	  margin-bottom: -82px;
	  padding-bottom: 80px;
	}

	&.dark.big-triangle .projectmanager:after,
	&.dark.big-triangle .projectmanager:before,
	&.dark.big-triangle .mainslider:after,
	&.dark.big-triangle .mainslider:before,
	&.dark.big-triangle .testimonials:after,
	&.dark.big-triangle .testimonials:before,
	&.dark.big-triangle .newsletter:after,
	&.dark.big-triangle .newsletter:before,
	&.dark.big-triangle .facts:after,
	&.dark.big-triangle .facts:before,
	&.dark.big-triangle .videopromotion:after,
	&.dark.big-triangle .videopromotion:before,
	&.dark.big-triangle .contactform:after,
	&.dark.big-triangle .contactform:before,
	&.dark.big-triangle .banner:after,
	&.dark.big-triangle .banner:before,
	&.big-triangle.about-page.dark .testimonials:after,
	&.big-triangle.about-page.dark .testimonials:before {
	  border-bottom: 100px solid #161A2C;
	}

	&.dark.big-triangle .projectmanager:after,
	&.dark.big-triangle .projectmanager:before,
	&.dark.big-triangle .testimonials:after,
	&.dark.big-triangle .testimonials:before,
	&.dark.big-triangle .newsletter:after,
	&.dark.big-triangle .newsletter:before,
	&.dark.big-triangle .facts:after,
	&.dark.big-triangle .facts:before,
	&.dark.big-triangle .call-to-action:after,
	&.dark.big-triangle .call-to-action:before,
	&.dark.big-triangle .videopromotion:after,
	&.dark.big-triangle .videopromotion:before,
	&.about-page.big-triangle.dark .call-to-action:after,
	&.about-page.big-triangle.dark .call-to-action:before {
	  border-top: 100px solid #161A2C;
	}

	&.dark.big-triangle .footer.top-logos:after,
	&.dark.big-triangle .footer.top-logos:before {
	  border-top: 100px solid #161A2C;
	}

	&.dark.big-triangle .footer:after,
	&.dark.big-triangle .footer:before {
	  border-top: 100px solid #161A2C;
	}

	&.dark.big-triangle .call-to-action:after,
	&.dark.big-triangle .call-to-action:before,
	&.dark.big-triangle .contactform:after,
	&.dark.big-triangle .contactform:before {
	  border-bottom: 100px solid #161A2C;
	}

	&.dark .divider .outer-line {
	  border-bottom: 1px solid #888;
	}

	&.dark .pricing-switcher>p {
	  background: #444;
	}

	&.dark .footer {
	  background: #111;
	}

	/* Normal */

	&.normal .videopromotion .section-overlay {
	  padding: 90px 0 106px;
	}

	&.normal .newsletter .section-overlay {
	  padding: 90px 0 112px;
	}

	&.normal .facts .section-overlay {
	  padding: 90px 0 84px;
	}

	&.normal .call-to-action .section-overlay {
	  padding: 90px 0 110px;
	}

	&.normal .testimonials .section-overlay {
	  padding: 90px 0 137px;
	}

	&.normal .services .container {
	  padding: 90px 15px 72px !important
	}

	&.normal .portfolio .container {
	  padding: 90px 0 96px !important;
	}

	&.normal .pricing .container {
	  padding: 90px 15px 96px !important;
	}

	&.normal .blog .container {
	  padding: 90px 15px 112px !important;
	}

	&.normal.blog-page .blog .container {
	  padding: 100px 15px 70px !important;
	}

	&.normal .contactform .section-overlay {
	  padding: 90px 0 60px !important;
	}

	&.normal .project .container {
	  padding: 120px 15px;
	}

	&.normal .shop-cart .container,
	&.normal .shop-checkout .container {
	  padding: 100px 15px;
	}

	&.normal .terms .container {
	  padding: 70px 15px;
	}


	/* Skew */

	&.skew .about,
	&.skew .faq,
	&.skew.portfolio-page .portfolio,
	&.skew .terms,
	&.skew .shop-cart,
	&.skew .shop-checkout,
	&.skew.pricing-page .pricing,
	&.skew .project,
	&.skew .projectmanager,
	&.skew .services,
	&.skew .pricing,
	&.skew .testimonials,
	&.skew .team,
	&.skew .blog,
	&.skew .facts,
	&.skew .call-to-action,
	&.skew .newsletter,
	&.skew .contactform,
	&.skew .videopromotion {
	  -webkit-transform: skewY(-3deg);
	  transform: skewY(-3deg);
	}

	&.skew .about .container,
	&.skew .faq .container,
	&.skew.portfolio-page .portfolio .container,
	&.skew .terms .container,
	&.skew .shop-cart .container,
	&.skew .shop-checkout .container,
	&.skew.pricing-page .pricing .container,
	&.skew .project .container,
	&.skew .projectmanager .container,
	&.skew .services .container,
	&.skew .pricing .container,
	&.skew .testimonials .container,
	&.skew .team .container,
	&.skew .blog .container,
	&.skew .facts .container,
	&.skew .call-to-action .container,
	&.skew .newsletter .container,
	&.skew .contactform .container,
	&.skew .videopromotion .container {
	  -webkit-transform: skewY(3deg);
	  transform: skewY(3deg);
	  -webkit-backface-visibility: hidden;
	  backface-visibility: hidden;
	}

	&.skew .about,
	&.skew .faq,
	&.skew.portfolio-page .portfolio,
	&.skew .terms,
	&.skew .shop-cart,
	&.skew .shop-checkout,
	&.skew .project,
	&.skew.pricing-page .pricing {
	  margin-top: -55px;
	}

	&.skew .portfolio {
	  margin-top: -20px;
	  padding-top: 17px;
	}

	&.skew .contact {
	  margin-bottom: 100px;
	}

	&.skew .contactform {
	  margin-top: -150px;
	}

	&.skew .about .container {
	  padding: 70px 15px 150px;
	}

	&.skew .faq .container,
	&.skew .terms .container {
	  padding: 140px 15px 70px;
	}

	&.skew .shop-cart .container,
	&.skew .shop-checkout .container {
	  padding: 140px 15px 100px;
	}

	&.skew .project .container {
	  padding: 140px 15px 100px;
	}

	&.skew .services .container {
	  padding: 70px 15px 90px;
	}

	&.skew .pricing .container {
	  padding: 100px 15px 94px !important;
	}

	&.skew .portfolio .container {
	  padding: 70px 15px 100px;
	}

	&.skew .team .container {
	  padding: 70px 15px 120px;
	}

	&.skew .blog .container {
	  padding: 90px 15px 112px !important;
	}

	&.skew.blog-page .blog .container {
	  padding: 70px 15px 30px !important;
	}

	&.skew.about-page .about .container,
	&.skew.about-page .team .container {
	  padding: 100px 15px 120px !important;
	}

	&.skew.about-page .services .container {
	  padding: 100px 15px !important;
	}

	&.skew.blog-page .blog {
	  margin-top: -55px;
	  padding-top: 55px;
	}

	&.skew .bx-wrapper {
	  margin: 90px 0 60px;
	}

	&.skew .videopromotion .section-overlay {
	  padding: 90px 0 112px;
	}

	&.skew .testimonials .section-overlay {
	  padding: 90px 0 135px;
	}

	&.skew .facts .section-overlay {
	  padding: 90px 0;
	}

	&.skew .contactform .section-overlay {
	  padding: 90px 0 60px !important;
	}

	&.skew .info-map-boxes {
	  margin-top: -285px;
	}

	&.skew .footer {
	  -webkit-transform: skewY(-3deg);
	  transform: skewY(-3deg);
	  margin-top: 30px;
	  margin-bottom: -50px;
	  padding-bottom: 50px;
	  border: none;
	}

	&.skew .footer .container {
	  -webkit-transform: skewY(3deg);
	  transform: skewY(3deg);
	  -webkit-backface-visibility: hidden;
	  backface-visibility: hidden;
	  padding: 120px 15px 0;
	}

	&.reversed-skew .about {
	  margin-top: -55px;
	  margin-bottom: 120px;
	}

	&.reversed-skew .faq,
	&.reversed-skew.portfolio-page .portfolio,
	&.reversed-skew .terms,
	&.reversed-skew .shop-cart,
	&.reversed-skew .shop-checkout,
	&.reversed-skew.pricing-page .pricing {
	  margin-top: -55px;
	}

	&.reversed-skew .about .container {
	  padding: 70px 15px 100px;
	}

	&.reversed-skew .faq .container,
	&.reversed-skew.portfolio-page .portfolio .container {
	  padding: 140px 15px 100px;
	}

	&.reversed-skew .terms .container {
	  padding: 140px 15px 70px;
	}

	&.reversed-skew .shop-cart .container,
	&.reversed-skew .shop-checkout .container {
	  padding: 140px 15px 100px;
	}

	&.reversed-skew.pricing-page .pricing .container {
	  padding: 100px 15px 120px !important;
	}

	&.reversed-skew .services .container {
	  padding: 70px 15px 90px;
	}

	&.reversed-skew .pricing .container {
	  padding: 70px 15px 100px !important;
	}

	&.reversed-skew .portfolio .container {
	  padding: 70px 0 100px !important;
	}

	&.reversed-skew .project .container {
	  padding: 140px 15px 100px !important;
	}

	&.reversed-skew .team .container {
	  padding: 70px 15px 120px;
	}

	&.reversed-skew .blog .container {
	  padding: 70px 15px 30px !important;
	}

	&.reversed-skew .about,
	&.reversed-skew .faq,
	&.reversed-skew.portfolio-page .portfolio,
	&.reversed-skew .terms,
	&.reversed-skew .shop-cart,
	&.reversed-skew .shop-checkout,
	&.reversed-skew.pricing-page .pricing,
	&.reversed-skew .call-to-action,
	&.reversed-skew .testimonials,
	&.reversed-skew .newsletter,
	&.reversed-skew .project,
	&.reversed-skew .contactform .container,
	&.reversed-skew.blog-page .blog {
	  -webkit-transform: skewY(-3deg);
	  transform: skewY(-3deg);
	}

	&.reversed-skew .projectmanager,
	&.reversed-skew .about .container,
	&.reversed-skew .faq .container,
	&.reversed-skew.portfolio-page .portfolio .container,
	&.reversed-skew .terms .container,
	&.reversed-skew .shop-cart .container,
	&.reversed-skew .shop-checkout .container,
	&.reversed-skew.pricing-page .pricing .container,
	&.reversed-skew .videopromotion,
	&.reversed-skew .facts,
	&.reversed-skew .call-to-action,
	&.reversed-skew .project .container,
	&.reversed-skew .testimonials .container,
	&.reversed-skew .newsletter .container,
	&.reversed-skew .contactform,
	&.reversed-skew.blog-page .blog .container {
	  -webkit-transform: skewY(3deg);
	  transform: skewY(3deg);
	}

	&.reversed-skew.blog-page .blog {
	  margin-top: -55px;
	  padding-top: 55px;
	  padding-bottom: 0;
	}

	&.reversed-skew .project {
	  margin-top: -55px;
	}

	&.reversed-skew .projectmanager {
	  margin-top: -100px;
	}

	&.reversed-skew.about-page .services .container {
	  padding: 0 !important;
	}

	&.reversed-skew.about-page .about .container,
	&.reversed-skew.about-page .team .container {
	  padding: 100px 15px 120px !important;
	}

	&.reversed-skew .about .container,
	&.reversed-skew .faq .container,
	&.reversed-skew.portfolio-page .portfolio .container,
	&.reversed-skew .terms .container,
	&.reversed-skew .shop-cart .container,
	&.reversed-skew .shop-checkout .container {
	  backface-visibility: hidden;
	}

	&.reversed-skew .services,
	&.reversed-skew .pricing,
	&.reversed-skew .team {
	  padding-bottom: 100px;
	  margin-top: -50px;
	  border-top: 60px solid #efefef;
	}

	&.reversed-skew .services {
		margin-top: -72px;
	}

	&.reversed-skew.about-page:not(.dark) .services {
	  padding-bottom: 170px;
	  margin-top: -150px;
	  border-top: 160px solid #efefef;
	  margin-bottom: -60px;
	}

	&.reversed-skew.portfolio-page .portfolio .container {
	  padding: 100px 15px 70px !important;
	}

	&.reversed-skew.pricing-page .pricing {
	  border-top: 0;
	  padding-bottom: 0;
	}

	&.reversed-skew .blog {
	  padding-bottom: 100px;
	  margin-top: -88px;
	}

	&.dark.reversed-skew .services,
	&.dark.reversed-skew .pricing,
	&.dark.reversed-skew .team {
	  border-top: 60px solid #161A2C;
	}

	&.reversed-skew .testimonials,
	&.reversed-skew .newsletter {
	  margin-top: -100px;
	  margin-bottom: 100px;
	}

	&.reversed-skew .videopromotion {
	  margin-top: -100px;
	}

	&.reversed-skew .portfolio {
	  margin-top: -88px;
	}

	&.reversed-skew .contact {
	  margin-bottom: 100px;
	}

	&.reversed-skew .contactform {
	  margin-top: -150px;
	}

	&.reversed-skew .contactform .section-overlay {
	  padding: 90px 0 60px !important;
	}

	&.reversed-skew .videopromotion .section-overlay {
	  padding: 90px 0 112px;
	}

	&.reversed-skew .testimonials .section-overlay {
	  padding: 90px 0 135px !important;
	}

	&.reversed-skew .facts .section-overlay {
	  padding: 90px 0 !important;
	}

	&.reversed-skew .bx-wrapper {
	  margin: 120px 0 80px;
	}

	&.reversed-skew .info-map-boxes {
	  margin-top: -285px;
	}

	&.reversed-skew .footer {
	  -webkit-transform: skewY(-3deg);
	  transform: skewY(-3deg);
	  margin-top: 28px;
	  margin-bottom: -50px;
	  padding-bottom: 50px;
	  border: none;
	}

	&.reversed-skew .footer .container {
	  -webkit-transform: skewY(3deg);
	  transform: skewY(3deg);
	  -webkit-backface-visibility: hidden;
	  backface-visibility: hidden;
	  padding: 120px 15px 0;
	}

	&.reversed-skew .projectmanager .container,
	&.reversed-skew .facts .container,
	&.reversed-skew .call-to-action .container,
	&.reversed-skew .videopromotion .container {
	  -webkit-transform: skewY(-3deg);
	  transform: skewY(-3deg);
	  -webkit-backface-visibility: hidden;
	  backface-visibility: hidden;
	}


	/* Double diagonal */

	&.double-diagonal .services,
	&.double-diagonal .pricing,
	&.double-diagonal .team,
	&.double-diagonal .blog,
	&.double-diagonal .contact {
	  padding-bottom: 50px;
	}

	&.double-diagonal.pricing-page .pricing {
		padding-bottom:30px;
	}

	&.double-diagonal.about-page .call-to-action {
	  margin-top: -55px;
	}

	&.double-diagonal .testimonials,
	&.double-diagonal .newsletter {
	  margin-top: -50px;
	}

	&.double-diagonal .contact {
	  margin-bottom: -50px;
	}

	&.double-diagonal .projectmanager,
	&.double-diagonal .testimonials,
	&.double-diagonal .facts,
	&.double-diagonal .call-to-action,
	&.double-diagonal .videopromotion,
	&.double-diagonal .newsletter,
	&.double-diagonal .contactform {
	  -webkit-transform: skewY(-3deg);
	  transform: skewY(-3deg);
	}

	&.double-diagonal .about .container {
		padding: 50px 15px 150px !important;
	}
	&.double-diagonal .pricing .container {
		padding: 50px 15px 135px !important;
	}
	&.double-diagonal .team .container {
		padding: 50px 15px 150px !important;
	}
	&.double-diagonal .portfolio .container,
	&.double-diagonal .blog .container {
	  padding: 50px 15px 110px !important;
	}

	&.double-diagonal.pricing-page .pricing .container {
	  padding: 30px 15px 60px !important;
	}

	&.double-diagonal .faq .container,
	&.double-diagonal .terms .container {
	  padding: 90px 15px 70px !important;
	}

	&.double-diagonal .shop-cart .container,
	&.double-diagonal .shop-checkout .container {
	  padding: 90px 15px !important;
	}

	&.double-diagonal .project .container {
	  padding: 90px 15px 60px !important;
	}

	&.double-diagonal .services .container {
	  padding: 50px 15px 110px !important;
	}

	&.double-diagonal .portfolio .container {
		padding: 50px 0 135px !important;
	}

	&.double-diagonal.portfolio-page .portfolio .container {
		padding: 50px 15px 70px !important;
	}

	&.double-diagonal .blog .container {
	  padding: 50px 15px 100px !important;
	}

	&.double-diagonal.blog-page .blog .container {
	  padding: 90px 15px 60px !important;
	}

	&.double-diagonal.blog-page.blog-post .blog .container {
	  padding: 90px 15px 20px !important;
	}

	&.double-diagonal.blog-page .blog {
	  padding-bottom: 0;
	}

	&.double-diagonal .testimonials .section-overlay {
	  padding: 110px 0 180px;
	}

	&.double-diagonal .newsletter .section-overlay,
	&.double-diagonal .call-to-action .section-overlay {
	  padding: 110px 0 180px;
	}

	&.double-diagonal .facts .section-overlay {
	  padding: 110px 0 180px;
	}

	&.double-diagonal .projectmanager .section-overlay {
	  padding: 110px 0 180px;
	}

	&.double-diagonal section {
	  position: relative;
	  z-index: 1;
	}

	&.double-diagonal .logos {
	  padding: 1px 0;
	}

	&.double-diagonal section::before,
	&.double-diagonal section::after {
	  position: absolute;
	  content: "";
	  pointer-events: none;
	}

	&.double-diagonal .about::before,
	&.double-diagonal .about::after,
	&.double-diagonal .faq::before,
	&.double-diagonal .faq::after,
	&.double-diagonal .terms::before,
	&.double-diagonal .terms::after,
	&.double-diagonal .shop-cart::before,
	&.double-diagonal .shop-cart::after,
	&.double-diagonal .shop-checkout::before,
	&.double-diagonal .shop-checkout::after,
	&.double-diagonal .project::before,
	&.double-diagonal .project::after,
	&.double-diagonal .services::before,
	&.double-diagonal .services::after,
	&.double-diagonal .pricing::before,
	&.double-diagonal .pricing::after,
	&.double-diagonal .portfolio::before,
	&.double-diagonal .portfolio::after,
	&.double-diagonal .team::before,
	&.double-diagonal .team::after,
	&.double-diagonal .blog::before,
	&.double-diagonal .blog::after,
	&.double-diagonal .contact::before,
	&.double-diagonal .contact::after,
	&.double-diagonal .logos::before,
	&.double-diagonal .logos::after {
	  top: 0;
	  left: -25%;
	  z-index: -1;
	  width: 150%;
	  height: 75%;
	  background: inherit;
	  -webkit-transform: rotate(-2deg);
	  transform: rotate(-2deg);
	  -webkit-transform-origin: 0 0;
	  transform-origin: 0 0;
	}

	&.double-diagonal .about::before,
	&.double-diagonal .faq::before,
	&.double-diagonal .terms::before,
	&.double-diagonal .shop-cart::before,
	&.double-diagonal .shop-checkout::before,
	&.double-diagonal .project::before,
	&.double-diagonal .services::before,
	&.double-diagonal .pricing::before,
	&.double-diagonal .portfolio::before,
	&.double-diagonal .team::before,
	&.double-diagonal .blog::before,
	&.double-diagonal .contact::before,
	&.double-diagonal .logos::before {
	  height: 50%;
	  -webkit-transform: rotate(-3deg);
	  transform: rotate(-3deg);
	  -webkit-transform-origin: 3% 0;
	  transform-origin: 3% 0;
	}

	&.double-diagonal .projectmanager .container,
	&.double-diagonal .testimonials .container,
	&.double-diagonal .facts .container,
	&.double-diagonal .call-to-action .container,
	&.double-diagonal .videopromotion .container,
	&.double-diagonal .newsletter .container,
	&.double-diagonal .contactform .container {
	  -webkit-transform: skewY(3deg);
	  transform: skewY(3deg);
	  -webkit-backface-visibility: hidden;
	  backface-visibility: hidden;
	}

	&.double-diagonal .videopromotion .section-overlay {
	  padding: 120px 0 180px;
	}

	&.double-diagonal .bx-wrapper {
	  margin: 50px 0;
	}

	&.double-diagonal .contactform {
	  margin-top: -100px;
	}

	&.double-diagonal .info-map-boxes {
	  margin-top: -295px;
	}

	&.double-diagonal .footer {
	  -webkit-transform: skewY(-2deg);
	  transform: skewY(-2deg);
	  margin-top: 30px;
	  margin-bottom: -50px;
	  padding-bottom: 50px;
	  border: none;
	}

	&.double-diagonal .footer .container {
	  -webkit-transform: skewY(2deg);
	  transform: skewY(2deg);
	  -webkit-backface-visibility: hidden;
	  backface-visibility: hidden;
	  padding: 120px 15px 0;
	}


	/* Big Triangle */

	&.big-triangle .projectmanager,
	&.big-triangle .mainslider,
	&.big-triangle .testimonials,
	&.big-triangle .facts,
	&.big-triangle .call-to-action,
	&.big-triangle .contactform,
	&.big-triangle .newsletter,
	&.big-triangle .videopromotion,
	&.big-triangle .footer,
	&.big-triangle .banner {
	  position: relative;
	  -webkit-backface-visibility: hidden;
	}

	&.big-triangle .projectmanager .container,
	&.big-triangle .mainslider .container,
	&.big-triangle .testimonials .container,
	&.big-triangle .facts .container,
	&.big-triangle .call-to-action .container,
	&.big-triangle .contactform .container,
	&.big-triangle .newsletter .container,
	&.big-triangle .videopromotion .container {
	  position: relative;
	  z-index: 999;
	}

	&.big-triangle .projectmanager:after,
	&.big-triangle .projectmanager:before,
	&.big-triangle .mainslider:after,
	&.big-triangle .mainslider:before,
	&.big-triangle .testimonials:after,
	&.big-triangle .testimonials:before,
	&.big-triangle .newsletter:after,
	&.big-triangle .newsletter:before,
	&.big-triangle .facts:after,
	&.big-triangle .facts:before,
	&.big-triangle .call-to-action:after,
	&.big-triangle .call-to-action:before,
	&.big-triangle .contactform:after,
	&.big-triangle .contactform:before,
	&.big-triangle .videopromotion:after,
	&.big-triangle .videopromotion:before,
	&.big-triangle .footer:after,
	&.big-triangle .footer:before,
	&.big-triangle .banner:after,
	&.big-triangle .banner:before {
	  content: "";
	  position: absolute;
	  bottom: -1px;
	  top: 0;
	  width: 50%;
	  z-index: 100;
	  border-top: 100px solid #fff;
	  border-bottom: 100px solid #fff;
	  -webkit-transform: rotate(0.000001deg);
	  transform: rotate(0.000001deg);
	}

	&.about-page.big-triangle .call-to-action:after,
	&.about-page.big-triangle .call-to-action:before {
	  border-top: 100px solid #efefef;
	}

	&.big-triangle.about-page .testimonials:after,
	&.big-triangle.about-page .testimonials:before {
	  border-bottom: 100px solid #efefef;
	}

	&.big-triangle .mainslider:after,
	&.big-triangle .mainslider:before,
	&.big-triangle .banner:after,
	&.big-triangle .banner:before {
	  top: initial;
	  border-top: none;
	}

	&.big-triangle .testimonials:after,
	&.big-triangle .testimonials:before,
	&.big-triangle .newsletter:after,
	&.big-triangle .newsletter:before,
	&.big-triangle .contactform:after,
	&.big-triangle .contactform:before {
	  border-top: 100px solid #efefef;
	}

	&.big-triangle .contactform:after,
	&.big-triangle .contactform:before {
	  border-top: 0;
	}

	&.big-triangle .services .container {
		padding: 90px 15px 72px !important;
	}

	&.big-triangle .testimonials .section-overlay {
		padding: 90px 0 135px;
	}

	&.big-triangle .facts .section-overlay {
		padding: 90px 0;
	}

	&.big-triangle .newsletter .section-overlay {
		padding: 90px 0 112px !important;
	}

	&.big-triangle .portfolio .container {
	    padding: 90px 0 96px !important;
	}

	&.big-triangle .team .container {
	  padding: 70px 15px 100px;
	}

	&.big-triangle .testimonials .carousel-indicators {
	  z-index: 111;
	}

	&.big-triangle .projectmanager:after,
	&.big-triangle .projectmanager:before,
	&.big-triangle .facts:after,
	&.big-triangle .facts:before,
	&.big-triangle .videopromotion:after,
	&.big-triangle .videopromotion:before,
	&.big-triangle .footer:after,
	&.big-triangle .footer:before {
	  border-bottom: 100px solid #efefef;
	}

	&.big-triangle .mainslider:before,
	&.big-triangle .projectmanager:before,
	&.big-triangle .testimonials:before,
	&.big-triangle .newsletter:before,
	&.big-triangle .facts:before,
	&.big-triangle .call-to-action:before,
	&.big-triangle .contactform:before,
	&.big-triangle .videopromotion:before,
	&.big-triangle .footer:before,
	&.big-triangle .banner:before {
	  right: 50%;
	  border-right: 1000px solid transparent;
	  border-left: 1000px solid;
	}

	&.big-triangle .mainslider:after,
	&.big-triangle .projectmanager:after,
	&.big-triangle .testimonials:after,
	&.big-triangle .newsletter:after,
	&.big-triangle .facts:after,
	&.big-triangle .call-to-action:after,
	&.big-triangle .contactform:after,
	&.big-triangle .videopromotion:after,
	&.big-triangle .footer:after,
	&.big-triangle .banner:after {
	  left: 50%;
	  border-left: 1000px solid transparent;
	  border-right: 1000px solid;
	}

	&.big-triangle .blog .container {
	  padding: 90px 15px !important;
	}

	&.big-triangle.blog-page .blog .container {
	  padding: 100px 15px 50px !important;
	}

	&.big-triangle .call-to-action .section-overlay {
		padding: 90px 0 112px;
	}
	&.big-triangle .videopromotion .section-overlay {
	  padding: 90px 0 106px;
	}

	&.big-triangle .project .container {
	  padding: 100px 15px;
	}

	&.big-triangle .shop-cart .container,
	&.big-triangle .shop-checkout .container,
	&.big-triangle .terms .container {
	  padding: 100px 15px;
	}

	&.big-triangle .contactform .section-overlay {
	  padding: 90px 0;
	}

	&.big-triangle .footer:after,
	&.big-triangle .footer:before {
	  bottom: initial;
	  border-bottom: none;
	}

	&.big-triangle .footer {
	  border-top: none;
	}

	&.big-triangle .footer .container {
	  padding: 120px 15px 0;
	}

	&.big-triangle .bx-wrapper {
	  margin: 70px 0 60px;
	}

	/* Heading */

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
	  color: #555;
	  text-transform: uppercase;
	  font-family: "Raleway", sans-serif;
	}

	h1 {
	  font-size: 48px;
	  line-height: 48px;
	  font-weight: 800;
	  margin: 15px 0;
	}

	h2 {
	  font-size: 36px;
	  line-height: 36px;
	  font-weight: 800;
	}

	h3 {
	  font-size: 24px;
	  line-height: 24px;
	}

	h4 {
	  font-size: 18px;
	  line-height: 18px;
	}

	h5 {
	  font-size: 18px;
	  line-height: 14px;
	}

	h6 {
	  font-size: 12px;
	  line-height: 12px;
	}

	.projectmanager h2,
	.facts h2,
	.facts h4,
	.call-to-action h1,
	.call-to-action h4,
	.testimonials h2,
	.testimonials h4,
	.newsletter h2,
	.newsletter h4,
	.contactform h2,
	.contactform h4,
	.projectmanager h1,
	.testimonials h1,
	.facts h1,
	.newsletter h1,
	.contactform h1,
	.videopromotion h1,
	.videopromotion h4 {
	  color: #fff;
	}

	/* Paragraph */

	p {
	  font-family: "Raleway", sans-serif;
	  font-size: 18px;
	  line-height: 1.8;
	  font-weight: 400;
	  margin-bottom: 15px;
	  margin-top: 0px;
	  color: #666;
	}

	.top-text h4 {
	  margin: 23px 0 25px;
	  font-weight: 400;
	}

	a,
	a:hover {
	  text-decoration: none !important;
	}


	/* Divider */

	.divider span {
	  display: inline-block;
	  vertical-align: middle;
	}

	.divider .fa {
	  font-size: 20px;
	  margin: 0 20px;
	}

	.divider .outer-line {
	  width: 25%;
	  border-bottom: 1px solid #ddd;
	}


	/* [ HEADER ] */


	/*================================================== */

	.header {
	  position: absolute;
	  text-align: center;
	  top: 0px;
	  z-index: 1000;
	  color: #fff;
	  width: 100%;
	  height: 99px;
	  transition: background-color 200ms ease-in-out 0s;
	  -o-transition: background-color 200ms ease-in-out 0s;
	  -moz-transition: background-color 200ms ease-in-out 0s;
	  -webkit-transition: background-color 200ms ease-in-out 0s;
	}

	.header.header-fixed {
	  display: block;
	  margin-top: 0 !important;
	  position: fixed;
	  height: 65px;
	  background-color: #fff;
	  border-bottom: 1px solid #f1f1f1;
	}

	.header-inner {
	  position: relative;
	  width: 100%;
	}

	.header.header-fixed .header-inner .navbar .logo .navbar-brand {
		margin-top: 5px !important;
	}

	.header-fixed .logo a {
	  height: initial;
	  margin-top: 5px;
	  transition: all 0.3s ease 0s;
	  -o-transition: all 0.3s ease 0s;
	  -moz-transition: all 0.3s ease 0s;
	  -webkit-transition: all 0.3s ease 0s;
	}

	.header-fixed ul.navbar-nav li a {
	  padding: 26px 12px;
	  color: #555;
	}

	.header:before {
	  content: "";
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  width: 100%;
	  height: 120px;
	  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(252, 252, 252, 0) 99%, rgba(255, 255, 255, 0) 100%);
	  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(252, 252, 252, 0) 99%, rgba(255, 255, 255, 0) 100%);
	  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a6000000", endColorstr="#00ffffff", GradientType=0);
	}

	.header-fixed:before {
	  background: none;
	  height: 0;
	}


	/* Logo */

	.logo {
	  float: left;
	  text-align: left;
	  display: inline-block;
	}

	.logo a {
	  width: 150px;
	  display: block;
	  margin: 35px 0 0 30px;
	  transition: all 0.3s ease 0s;
	  -o-transition: all 0.3s ease 0s;
	  -moz-transition: all 0.3s ease 0s;
	  -webkit-transition: all 0.3s ease 0s;
	}

	.logo a img {
	  width: 100%;
	  transition: all 0.3s ease 0s;
	  -o-transition: all 0.3s ease 0s;
	  -moz-transition: all 0.3s ease 0s;
	  -webkit-transition: all 0.3s ease 0s;
	}

	.header .logo a img.logo-light {
	  opacity: 1;
	}

	.header .logo a img.logo-dark {
	  opacity: 0;
	}

	.header.header-fixed .logo a img.logo-light {
	  opacity: 0;
	}

	.header.header-fixed .logo a img.logo-dark {
	  opacity: 1;
	}

	.logo {
	  float: left;
	  text-align: left;
	  display: inline-block;
	}

	.logo a {
	  max-width: 100%;
	  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
	  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
	  -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
	  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
	  position: relative;
	}

	.logo a img {
	  width: 100%;
	  position: absolute;
	  top: 0;
	  left: 0;
	  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
	  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
	  -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
	  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
	}


	/* Navigation Menu */

	.navbar {
	  border-radius: 0;
	  border: 0;
	  margin-bottom: 0;
	}

	.navbar-toggle {
	  float: right;
	  border-radius: 0;
	  margin-right: 0;
	}

	.nav>li>a:focus,
	.nav>li>a:hover {
	  background: none;
	}

	.nav-tabs>li>a {
	  border: 0;
	}

	ul.navbar-nav {
	  float: right;
	  display: block;
	}

	ul.navbar-nav>li {
	  float: none;
	  display: inline-block;
	}

	ul.navbar-nav>li:not(.search):hover,
	ul.navbar-nav>li.active {
	  color: #fff;
	  position: relative;
	}

	ul.navbar-nav>li {
	  padding: 0;
	  position: relative;
	}

	ul.navbar-nav>li:last-child:after {
	  background: none;
	}

	ul.navbar-nav>li>a {
	  color: #fff;
	  font-size: 18px;
	  line-height: 1;
	  padding: 43px 12px;
	  font-weight: 600;
	  text-transform: uppercase;
	  font-family: "Raleway", sans-serif;
	  transition: .1s;
	}

	ul.navbar-nav li a i:not(.icon-angle) {
	  font-size: 11px;
	  padding-right: 3px;
	}

	ul.navbar-nav li a i.icon-angle {
	  padding-left: 3px;
	}

	ul.navbar-nav>li:hover>a:after,
	ul.navbar-nav>li.active>a:after {
	  position: absolute;
	  content: '';
	  left: 0;
	  bottom: 0;
	  width: 100%;
	  height: 3px;
	}

	ul.nav.nav-tabs {
	  border-bottom: 0;
	}

	ul.nav.nav-tabs li a,
	ul.nav.nav-tabs li.active a {
	  border-top: 0;
	  border-bottom: 0;
	  border-right: 0;
	  border-left: 1px solid #555;
	  border-radius: 0;
	  font-size: 19px;
	  color: #777;
	  padding: 0 30px;
	  margin: 15px 0 25px;
	  text-transform: uppercase;
	}

	ul.nav.nav-tabs li.active a {
	  font-weight: 600;
	}

	ul.nav.nav-tabs li:first-child a {
	  border-left: 0;
	  padding-left: 0;
	}

	ul.nav.nav-tabs li a:hover,
	ul.nav.nav-tabs li.active a:hover {
	  border-bottom: 0 !important;
	  border-top: 0 !important;
	}


	/* Dropdown */

	ul.navbar-nav>li.open>a,
	ul.navbar-nav>li.open>a:hover,
	ul.navbar-nav>li.open>a:focus,
	ul.navbar-nav>li.open>a:active {
	  background: transparent;
	}

	ul.nav li.dropdown ul.dropdown-menu {
	  background: #fff;
	}

	ul.nav li.dropdown ul.dropdown-menu li a {
	  color: #555;
	}

	ul.nav li.dropdown ul.dropdown-menu li.active a {
	  color: #fff;
	}

	.dropdown-menu {
	  text-align: left;
	  border: 0;
	  box-shadow: none;
	  z-index: 100;
	  min-width: 200px;
	  border-radius: 0;
	  padding: 0;
	}

	.navbar-nav>li .dropdown-menu li:last-child a {
	  border-bottom: 0 !important;
	}

	.navbar-nav>li>.dropdown-menu a {
	  background: none;
	  text-transform: uppercase;
	}

	.dropdown-menu li a {
	  display: block;
	  font-size: 12px;
	  font-weight: 600;
	  line-height: normal;
	  text-decoration: none;
	  padding: 15px 20px;
	  color: #999;
	}

	.header-fixed ul.navbar-nav li.dropdown ul.dropdown-menu li a {
	  padding: 15px 20px;
	}

	ul.nav li.dropdown ul.dropdown-menu li a:hover,
	ul.nav li.dropdown ul.dropdown-menu li a:focus,
	ul.nav li.dropdown ul.dropdown-menu li a:active {
	  color: #fff;
	}

	.dropdown-menu li:last-child>a {
	  border-bottom: 0;
	}

	.open>.dropdown-menu {
	  -webkit-transform: scale(1, 1);
	  transform: scale(1, 1);
	}

	.open>.dropdown-menu li a {
	  color: #000;
	}

	.dropdown-menu li a {
	  color: #fff;
	}

	.dropdown-menu {
	  -webkit-transform-origin: top;
	  transform-origin: top;
	  -webkit-animation-fill-mode: forwards;
	  animation-fill-mode: forwards;
	  -webkit-transform: scale(1, 0);
	  display: block;
	  box-shadow: 0px 0px 1px #ccc;
	  transition: all 0.2s ease-out;
	  -webkit-transition: all 0.2s ease-out;
	}


	/*** Search ***/

	.site-search {
	  text-align: center;
	  background: rgba(0, 0, 0, .7);
	  position: absolute;
	  z-index: 100;
	  width: 100%;
	}

	@-moz-document url-prefix() {
	  .site-search {
	    margin-top: -1px;
	  }
	}

	.navbar-nav .fa-search {
	  background: none;
	  border: none;
	  color: #fff;
	  font-size: 18px;
	  outline: none;
	  padding: 17px;
	}

	.header-fixed .navbar-nav .fa-search {
	  color: #555;
	}

	.navbar-nav .fa-shopping-cart {
	  font-weight: 300;
	  padding: 0;
	}

	.site-search .search-container {
	  height: 0;
	  overflow: hidden;
	  position: relative;
	  -webkit-transition: height 0.3s;
	  transition: height 0.3s;
	}

	.site-search .search-container.open {
	  height: 100px;
	}

	.site-search input[type="text"] {
	  background: none;
	  border: none;
	  color: #fff;
	  font-size: 19px;
	  text-transform: uppercase;
	  margin: 37px 0;
	  padding-right: 20px;
	  width: 100%;
	  font-weight: 500;
	  outline: 0;
	  text-align: center;
	}

	.site-search input[type="text"]::-webkit-input-placeholder {
	  color: rgba(255, 255, 255, 0.45);
	}

	.site-search input[type="text"]:-moz-placeholder {
	  color: rgba(255, 255, 255, 0.45);
	}

	.site-search input[type="text"]::-moz-placeholder {
	  color: rgba(255, 255, 255, 0.45);
	}

	.site-search input[type="text"]:-ms-input-placeholder {
	  color: rgba(255, 255, 255, 0.45);
	}

	.site-search .close {
	  color: rgba(255, 255, 255, 0.25);
	  cursor: pointer;
	  font-size: 35px;
	  margin-top: -23px;
	  position: absolute;
	  top: 50%;
	  right: 30px;
	  font-weight: 300;
	}

	.site-search .close:hover {
	  color: #fff;
	}


	/* Dark Header */

	&.dark .header .logo a img.logo-light {
	  opacity: 1;
	}

	&.dark .header .logo a img.logo-dark {
	  opacity: 0;
	}

	&.dark .header.header-fixed .logo a img.logo-light {
	  opacity: 1;
	}

	&.dark .header.header-fixed .logo a img.logo-dark {
	  opacity: 0;
	}


	/* Toggler Icon */

	.navbar-toggler {
	  outline: none !important;
	  padding: 8px 0 0;
	  border: 0;
	  box-shadow: none !important;
	}

	#icon-toggler {
	  width: 20px;
	  height: 21px;
	  position: relative;
	  margin: 7px auto;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: .5s ease-in-out;
	  -moz-transition: .5s ease-in-out;
	  -o-transition: .5s ease-in-out;
	  transition: .5s ease-in-out;
	  cursor: pointer;
	  display: block;
	}

	#icon-toggler span {
	  display: block;
	  position: absolute;
	  height: 2px;
	  width: 100%;
	  background-color: #fff;
	  border-radius: 9px;
	  opacity: 1;
	  left: 0;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: .25s ease-in-out;
	  -moz-transition: .25s ease-in-out;
	  -o-transition: .25s ease-in-out;
	  transition: .25s ease-in-out;
	}

	.header.header-fixed #icon-toggler span {
	  background-color: #555;
	}

	#icon-toggler span:nth-child(1) {
	  top: 0;
	}

	#icon-toggler span:nth-child(2),
	#icon-toggler span:nth-child(3) {
	  top: 6px;
	}

	#icon-toggler span:nth-child(4) {
	  top: 12px;
	}

	#icon-toggler.open span:nth-child(1) {
	  top: 18px;
	  width: 0%;
	  left: 50%;
	}

	#icon-toggler.open span:nth-child(2) {
	  -webkit-transform: rotate(45deg);
	  -moz-transform: rotate(45deg);
	  -o-transform: rotate(45deg);
	  transform: rotate(45deg);
	}

	#icon-toggler.open span:nth-child(3) {
	  -webkit-transform: rotate(-45deg);
	  -moz-transform: rotate(-45deg);
	  -o-transform: rotate(-45deg);
	  transform: rotate(-45deg);
	}

	#icon-toggler.open span:nth-child(4) {
	  top: 18px;
	  width: 0%;
	  left: 50%;
	}


	/* [ CONTENT ] */

	/* Custom Button */

	.custom-button {
	  border: none;
	  color: #fff;
	  text-decoration: none;
	  background: #00bbda;
	  cursor: pointer;
	  display: inline-block;
	  outline: none;
	  box-shadow: none;
	  text-shadow: none;
	  position: relative;
	  letter-spacing: 0;
	  -webkit-transition: all 0.3s ease 0s;
	  transition: all 0.3s ease 0s;
	  -webkit-transition: all 0.3s;
	  transition: all 0.3s;
	  margin-top: 10px;
	  text-transform: uppercase;
	  padding: 12px 33px;
	  border-radius: 40px;
	  font-size: 18px;
	  font-weight: 600;
	}

	.custom-button,
	.custom-button:hover,
	.custom-button:focus {
	  color: #fff;
	  text-decoration: none;
	}

	// .custom-button:before {
	//   content: "\f1d8";
	//   font: 15px/1.2em FontAwesome;
	//   color: #fff;
	//   position: absolute;
	//   height: 100%;
	//   right: 18px;
	//   top: 50%;
	//   margin-top: -10px;
	//   -webkit-transition: all 0.3s ease 0s;
	//   transition: all 0.3s ease 0s;
	//   -webkit-transition: all 0.3s;
	//   transition: all 0.3s;
	//   opacity: 0;
	//   filter: alpha(opacity=0);
	//   -webkit-transform: translateX(-20px);
	//   transform: translateX(-20px);
	// }

	.custom-button span {
	  position: relative;
	  display: block;
	  -webkit-transition: all 0.3s ease 0s;
	  transition: all 0.3s ease 0s;
	  -webkit-transition: all 0.3s;
	  transition: all 0.3s;
	}

	.custom-button:hover span {
	  // -webkit-transform: translateX(-10px);
	  // transform: translateX(-10px);
	}

	.custom-button:hover:before {
	  opacity: 1;
	  filter: alpha(opacity=100);
	  -webkit-transform: translateX(0);
	  transform: translateX(0);
	}


	/* Preloader
	---------------------------------------- */

	.preloader {
	  position: fixed;
	  left: 0;
	  top: -100%;
	  right: 0;
	  height: 100%;
	  z-index: 999999999999999999;
	  background: #fff;
	  text-align: center;
	  overflow: hidden;
	  opacity: 1;
	  -webkit-transform: translate(0, 100%);
	  transform: translate(0, 100%);
	}

	.preloader:before {
	  content: "";
	  height: 100%;
	  width: 0;
	  display: inline-block;
	  vertical-align: middle;
	}

	.preloader .logopreloader {
	  vertical-align: middle;
	  -webkit-transform: translate(0, 500px);
	  transform: translate(0, 500px);
	  position: relative;
	  white-space: nowrap;
	  display: inline-block;
	  top: -50px;
	  opacity: 0;
	  -webkit-transition: -moz-transform 1s ease, -webkit-transform 1s ease, -ms-transform 1s ease, -o-transform 1s ease, transform 1s ease, opacity 1s ease;
	  transition: -webkit-transform 1s ease, transform 1s ease, opacity 1s ease;
	}

	.preloader .loader {
	  width: 85px;
	  height: 85px;
	  background-size: cover;
	  position: absolute;
	  left: 50%;
	  top: 50%;
	  margin: 15px 0 0 -42px;
	  -webkit-transform: translate(0, 500px);
	  transform: translate(0, 500px);
	  opacity: 0;
	  -webkit-transition: opacity 1s ease .3s, -webkit-transform 1s ease .3s;
	  transition: opacity 1s ease .3s, -webkit-transform 1s ease .3s;
	  transition: transform 1s ease .3s, opacity 1s ease .3s;
	  transition: transform 1s ease .3s, opacity 1s ease .3s, -webkit-transform 1s ease .3s;
	}

	.preloader.loading .loader,
	.preloader.loading .logopreloader {
	  -webkit-transform: translate(0, 0px);
	  transform: translate(0, 0px);
	  opacity: 1;
	}

	.preloader.ended {
	  -webkit-transform: translate(0, 0);
	  transform: translate(0, 0);
	  opacity: 0;
	  -webkit-transition: -webkit-transform 1s ease 0.5s, -moz-transform 1s ease 0.5s, -ms-transform 1s ease 0.5s, -o-transform 1s ease 0.5s, transform 1s ease 0.5s, opacity 1s ease 0.5s;
	  transition: -webkit-transform 1s ease 0.5s, transform 1s ease 0.5s, opacity 1s ease 0.5s;
	}

	.preloader.ended .loader,
	.preloader.ended .logopreloader {
	  -webkit-transform: translate(0, -500px);
	  transform: translate(0, -500px);
	  opacity: 0;
	}


	/* Main Slider (Revolution Slider)
	---------------------------------------- */

	.tp-simpleresponsive .slider-button {
	  color: #fff;
	  border-radius: 40px;
	  font-size: 18px;
	  font-weight: 600;
	}

	.tp-simpleresponsive .slider-button:before {
	  // content: "\f063";
	  // margin-top: -9px;
	}

	.dark-slider .rev_slider .slotholder:after {
	  width: 100%;
	  height: 100%;
	  content: "";
	  position: absolute;
	  left: 0;
	  top: 0;
	  pointer-events: none;
	  z-index: 1111;
	  background: rgba(0, 0, 0, .5);
	}

	.scroll-effects-text {
	  color: #eee !important;
	}


	/* About
	---------------------------------------- */

	.about {
	  background-color: #fff;
	}

	.about .about-content .title-about {
	  font-size: 28px;
	  margin-bottom: 10px;
	  margin-top: 0;
	}

	.about .about-content {
	  margin-top: 40px;
	}

	.about .about-content .custom-button {
	  border-radius: 40px;
	  font-size: 18px;
	  font-weight: 600;
	}

	.about .about-content .custom-button:before {
	  content: "\f061";
	  margin-top: -10px;
	}

	.about .about-content .full-image-overlay h3 {
	  text-transform: Capitalize;
	  color: #ffffff;
	  margin-top: 20px;
	}

	.about .about-content .about-right-side img {
	  border: 1px solid #ddd;
	  border-radius: 4px;
	  margin: 0 auto;
	  margin-left: auto;
	  display: block;
	}

	.about .nav-tabs>li.active>a,
	.about .nav-tabs>li.active>a:hover,
	.about .nav-tabs>li.active>a:focus {
	  background: none;
	}

	.about ul.nav.nav-tabs li>a {
	  border-left: 1px solid #ddd;
	  line-height: 14px;
	}

	.about ul.nav.nav-tabs li:first-child>a {
	  border-left: 0;
	}

	.about .tab-content p {
	  padding: 0 0 30px;
	  margin: 0;
	}

	.list-why-choose-us {
	  display: inline;
	  list-style: none;
	  margin-top: 30px;
	  text-align: left;
	}

	.list-why-choose-us li {
	  position: relative;
	  padding-left: 22px;
	  font-size: 19px;
	  line-height: 18px;
	}

	.list-why-choose-us li:before {
	  content: '\f061';
	  position: absolute;
	  left: 0;
	  top: 0;
	  font-weight: 400;
	  font-size: 19px;
	  font-family: "FontAwesome";
	}

	.list-why-choose-us li+li {
	  margin-top: 14px;
	}

	.full-text-container+.full-image-container:before {
	  right: auto;
	  left: 0;
	}

	.about-right-side:hover .full-text-container+.full-image-container:before,
	.about-right-side .full-text-container+.full-image-container.hovered:before {
	  right: auto;
	  left: 5%;
	}

	.about-right-side {
	  position: relative;
	  display: table;
	  table-layout: fixed;
	}

	.full-image-container {
	  width: 50%;
	  display: table-cell;
	  position: relative;
	  overflow: hidden;
	}

	.full-image-container:before {
	  content: '';
	  position: absolute;
	  top: 5%;
	  right: 0%;
	  width: 50%;
	  height: 90%;
	  background-color: rgba(0, 0, 0, 0.5);
	  opacity: 0;
	  filter: alpha(opacity=0);
	  -webkit-transition: all 0.3s ease-in-out;
	  -o-transition: all 0.3s ease-in-out;
	  transition: all 0.3s ease-in-out;
	  -webkit-transition-delay: 0.3s;
	  transition-delay: 0.3s;
	}

	.full-text-container+.full-image-container:before {
	  right: auto;
	  left: 0;
	}

	.about-right-side:hover .full-image-container:before,
	.about-right-side .full-image-container.hovered:before {
	  width: 90%;
	  right: 5%;
	  opacity: 1;
	  filter: alpha(opacity=100);
	  -webkit-transition-delay: 0s;
	  transition-delay: 0s;
	}

	.about-right-side:hover .full-text-container+.full-image-container:before,
	.about-right-side .full-text-container+.full-image-container.hovered:before {
	  right: auto;
	  left: 5%;
	}

	.about-right-side:hover .full-image-overlay,
	.about-right-side .hovered .full-image-overlay {
	  text-align: left;
	  position: absolute;
	  width: 100%;
	  top: 50%;
	  padding: 0 10%;
	  margin-top: -133px;
	  color: #ffffff;
	  z-index: 1;
	  -webkit-transition: all 0.3s ease-in-out;
	  -o-transition: all 0.3s ease-in-out;
	  transition: all 0.3s ease-in-out;
	  display: block;
	  opacity: 1;
	  filter: alpha(opacity=100);
	  -webkit-transition-delay: 0.3s;
	  transition-delay: 0.3s;
	}

	.full-text-container {
	  display: table-cell;
	  vertical-align: middle;
	  width: 50%;
	  padding: 0 50px;
	  position: static;
	}

	.full-text-container * {
	  max-width: 700px;
	}

	.full-text-container.left {
	  text-align: right;
	}

	.full-text-container.left * {
	  margin-left: auto;
	}

	.full-text-container p {
	  margin-bottom: 10px;
	}


	/* Project Manager
	---------------------------------------- */

	.projectmanager {
	  color: #fff;
	  background-image: url("../../../_assets/media/images/new-landing/sections/projectmanager-bg.jpg");
	  background-attachment: fixed;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: auto 110%;
	}

	.projectmanager .section-overlay {
	  padding: 120px 0 100px;
	}

	.projectmanagerpicture {
	  border-radius: 50%;
	}

	.projectmanager h1 {
	  margin-bottom: 20px;
	}

	.projectmanager h3 {
	  margin-top: 10px;
	  font-size: 30px;
	  line-height: 30px;
	  font-weight: 500;
	}

	.projectmanager p {
	  color: #fff;
	}

	.projectmanager blockquote {
	  padding-top: 0;
	  padding-bottom: 0;
	  color: #cecece;
	  font-size: 18px;
	  border-left: 5px solid #eee;
	  padding-left: 20px;
	}

	.projectmanager p,
	.projectmanager blockquote {
	  margin-top: 30px;
	  font-style: italic;
	}

	.projectmanager li:hover,
	.projectmanager li:hover a,
	.projectmanager li a:hover {
	  color: #fff;
	}


	/* Services
	---------------------------------------- */

	.services {
	  background-color: #efefef;
	}

	.services .services-box {
	  margin: 25px -15px;
	}

	.services .services-box:before {
	  content: "";
	  display: table;
	}

	.services .services-box:after {
	  content: "";
	  display: table;
	  clear: both;
	}

	.services .services-box-item {
	  position: relative;
	  color: #717c8e;
	  text-decoration: none;
	  -webkit-perspective: 750px;
	  -ms-perspective: 750px;
	  -o-perspective: 750px;
	  perspective: 750px;
	  margin: 15px 0;
	  float: left;
	  text-decoration: none;
	  min-height: 220px;
	}

	// .services .services-box-item:hover,
	// .services .services-box-item:focus,
	// .services .services-box-item.hover {
	//   text-decoration: none;
	// }

	.services .services-box-item,
	.services .services-box-item,
	.services .services-box-item.hover {
	  text-decoration: none;
	}

	// .services .services-box-item:hover>.services-box-item-cover,
	// .services .services-box-item:focus>.services-box-item-cover,
	// .services .services-box-item.hover>.services-box-item-cover {
	//   -webkit-transform: rotateY(180deg);
	//   transform: rotateY(180deg);
	// }

	.services .services-box-item>.services-box-item-cover,
	.services .services-box-item>.services-box-item-cover,
	.services .services-box-item>.services-box-item-cover {
	  -webkit-transform: rotateY(180deg);
	  transform: rotateY(180deg);
	}

	// .services .services-box-item:hover>.services-box-item-content,
	// .services .services-box-item:focus>.services-box-item-content,
	// .services .services-box-item.hover>.services-box-item-content {
	//   -webkit-transform: rotateY(360deg);
	//   transform: rotateY(360deg);
	// }

	.services .services-box-item>.services-box-item-content,
	.services .services-box-item>.services-box-item-content,
	.services .services-box-item>.services-box-item-content {
	  -webkit-transform: rotateY(360deg);
	  transform: rotateY(360deg);
	}

	.services .services-box-item-cover,
	.services .services-box-item-content {
	  position: relative;
	  border-radius: 3px;
	  background: #272C40;
	  -webkit-transition: -webkit-transform 0.3s;
	  transition: -webkit-transform 0.3s;
	  transition: transform 0.3s;
	  transition: transform 0.3s, -webkit-transform 0.3s;
	  -webkit-transform: rotateY(0deg);
	  transform: rotateY(0deg);
	  -webkit-backface-visibility: hidden;
	  backface-visibility: hidden;
	  min-height: 220px;
	  height: 220px;
	}

	.services .services-box-item-cover {
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  margin: 0px 15px;
	  box-shadow: 0px 0px 3px 1px #ddd;
	  border-radius: 7px;
	}

	.services .services-box-item-cover:before,
	.services .services-box-item-cover:after {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	}

	.services .services-box-item-cover:before {
	  margin: -40px 0px 0px -20px;
	  font-size: 40px;
	}

	.services .services-box-item-cover:after {
	  content: attr(data-headline);
	  font-family: "Raleway";
	  left: 0;
	  width: 100%;
	  margin: 20px 0 0;
	  font-size: 18px;
	  text-align: center;
	  font-weight: 500;
	}

	.services .services-box-item-content {
	  overflow: hidden;
	  margin: -16px -6px;
	  padding: 30px 40px;
	  font-size: 18px;
	  -webkit-transform: rotateY(180deg);
	  transform: rotateY(180deg);
	  border-radius: 7px;
	}

	.services .services-box-item-content:before {
	  position: absolute;
	  top: 0;
	  right: 0;
	  margin: -60px;
	  font-size: 228px;
	  opacity: 0.2;
	}

	.services .services-box-item-content h2 {
	  position: relative;
	  margin: 8px 0;
	  font-size: 19px;
	  font-weight: 500;
	  text-transform: Capitalize;
	}

	.services .services-box-item-content p {
	  line-height: 23px;
	  position: relative;
	  margin: 12px 0;
	  font-size: 18px;
	}

	.services[data-icon]:before,
	.services[class^="icon-"]:before,
	.services[class*=" icon-"]:before {
	  -webkit-font-smoothing: antialiased;
	  font-smoothing: antialiased;
	  text-rendering: geometricPrecision;
	  text-indent: 0;
	  display: inline-block;
	  position: relative;
	}

	.services[data-icon]:before {
	  content: attr(data-icon);
	}

	.services[data-icon].after:before {
	  content: none;
	}

	.services[data-icon].after:after {
	  content: attr(data-icon);
	  -webkit-font-smoothing: antialiased;
	  font-smoothing: antialiased;
	  text-rendering: geometricPrecision;
	  text-indent: 0;
	  display: inline-block;
	  position: relative;
	  margin-left: 4px;
	}


	/* Testimonials
	---------------------------------------- */

	.testimonials {
	  background: url("../../../_assets/media/images/new-landing/sections/testimonials-bg.jpg");
	  background-attachment: fixed;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: auto 110%;
	}

	.testimonials #quote-carousel {
	  margin-top: 50px;
	}

	.testimonials #quote-carousel hr {
	  border-top: 1px solid #fff;
	}

	.testimonials #quote-carousel,
	.testimonials #quote-carousel h6 {
	  color: #fff;
	}

	.testimonials #quote-carousel blockquote {
	  padding: 0;
	  text-align: center;
	  font-size: 19px;
	  border: none;
	}

	.testimonials #quote-carousel p {
	  font-style: italic;
	  color: #fff;
	}

	// .testimonials #quote-carousel p:before,
	// .testimonials #quote-carousel p:after {
	//   font-family: "FontAwesome";
	// }

	.testimonials #quote-carousel p:before {
	  margin-right: 20px;
	  // content: "\f10d";
	}

	.testimonials #quote-carousel p:after {
	  margin-left: 20px;
	  // content: "\f10e";
	}

	.testimonials #quote-carousel h5 {
	  margin: 20px 0 10px;
	  font-size: 20px;
	  font-weight: 500;
	}

	.testimonials #quote-carousel h6 {
	  margin: 0;
	  font-size: 19px;
	  text-transform: capitalize;
	  font-weight: 500;
	}

	.testimonials #quote-carousel .img-responsive {
	  margin: 0 auto 25px;
	  border: 6px solid #f9f9f9;
	  background: #f9f9f9;
	}

	.img-responsive {
		display: block;
		max-width: 100%;
		height: auto;
	}

	.img-responsive.mobilee {
		display: block;
		max-width: 100%;
		height: auto;
		object-fit: contain;
		width: 100%;
	}

	.testimonials #quote-carousel .carousel-indicators {
	  bottom: -40px;
	}

	.testimonials #quote-carousel .carousel-indicators li {
	  background: #fff;
	  display: inline-block;
	  width: 10px;
	  height: 10px;
	  margin: 0 2px;
	  text-indent: -999px;
	  cursor: pointer;
	  border-radius: 50%;
	}

	.testimonials #quote-carousel .carousel-indicators li.active {
	  width: 12px;
	  height: 12px;
	  margin-bottom: -1px;
	}


	/* Portfolio
	---------------------------------------- */

	.portfolio {
	  background-color: #fff;
	}

	.portfolio .nav-pills {
	  overflow: hidden;
	  margin: 35px 15px 30px;
	  text-align: center;
	}

	.portfolio .nav-pills li {
	  margin-right: 15px;
	  border-radius: 40px;
	  padding: 8px 30px;
	  color: #555;
	  font-weight: 600;
	  -webkit-transition: 0.2s;
	  transition: 0.2s;
	  float: none;
	  text-transform: uppercase;
	}

	.portfolio .nav-pills li:hover,
	.portfolio .nav-pills li.active {
	  color: #fff;
	}

	.portfolio .caption h3 {
	  font-size: 18px;
	  margin-top: 20px;
	  text-decoration: none;
	  font-weight: 600;
	  padding-top: 2px;
	}

	.portfolio .caption p {
	  font-size: 18px;
	}

	.portfolio .caption .custom-button {
	  border-radius: 40px;
	  font-size: 18px;
	  font-weight: 600;
	}

	.portfolio .caption .custom-button:before {
	  content: "\f061";
	  margin-top: -10px;
	}

	.portfolio .filtr-container {
	  position: relative;
	  overflow: hidden;
	}

	.portfolio .filtr-container .filtr-item {
	  display: block;
	  float: left;
	  box-sizing: border-box;
	}

	.portfolio .filtr-container .filtr-item_holder {
	  overflow: hidden;
	}

	.portfolio .filtr-container .image-wrap {
	  position: relative;
	  display: block;
	  overflow: hidden;
	  outline: none;
	  border-radius: 4px;
	}

	.portfolio .thumbnail__portfolio {
	  margin: 0;
	  padding: 0;
	  border: 0;
	  border-radius: 4px;
	}

	.filtr-item {
	  padding: 15px;
	}


	/* Filter controls */

	.simplefilter li,
	.multifilter li,
	.sortandshuffle li {
	  color: white;
	  cursor: pointer;
	  display: inline-block;
	  padding: 1rem 2rem;
	}

	.simplefilter li.active {
	  background-color: #212121;
	}

	.multifilter li {
	  background-color: #4b9eff;
	}

	.multifilter li.active {
	  background-color: #2265b4;
	}


	/* Zoom icons */

	.zoom-icon {
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  display: block;
	  background: rgba(0, 0, 0, 0.5);
	  opacity: 0;
	  cursor: pointer;
	  -webkit-transition: 0.2s;
	  transition: 0.2s;
	}

	.zoom-icon:before {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  display: block;
	  margin: -13px 0 0 -13px;
	  width: 25px;
	  height: 26px;
	  background: url("../../../_assets/media/images/new-landing/projects/icons/zoom-icon.png") 50% 50% no-repeat;
	  content: "";
	}

	.video-icon:before {
	  background: url("../../../_assets/media/images/new-landing/projects/icons/video-icon.png") 50% 50% no-repeat;
	}

	.gallery-icon:before {
	  background: url("../../../_assets/media/images/new-landing/projects/icons/gallery-icon.png") 50% 50% no-repeat;
	}

	.external-icon:before {
	  background: url("../../../_assets/media/images/new-landing/projects/icons/external-icon.png") 50% 50% no-repeat;
	}

	.image-wrap:hover .zoom-icon,
	.featured-thumbnail a:hover .zoom-icon {
	  opacity: 1;
	}

	.featured-thumbnail a:hover .zoom-icon {
	  visibility: visible;
	}


	/*** Project Page ***/

	.project {
	  background: #fff;
	}

	.project .project-main-content img {
	  border-radius: 7px;
	}

	.project .project-navigation nav ul {
	  margin-top: 5px;
	}

	.project .project-navigation nav ul li {
	  display: inline-block;
	}

	.project .project-navigation nav ul li.next-project {
	  float: right;
	}

	.project .project-navigation nav ul li a {
	  text-transform: uppercase;
	  font-size: 12px;
	  font-weight: 500;
	}


	/* Embed Video */

	.project video.responsive-video {
	  max-width: 100%;
	  height: auto;
	}

	.videocontainer {
	  position: relative;
	  padding-bottom: 54.55%;
	  padding-top: 0;
	  height: 0;
	  overflow: hidden;
	}

	.videocontainer iframe,
	.videocontainer object,
	.videocontainer embed {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  border: 0;
	}


	/* Gallery */

	.grid_gallery {
	  position: relative;
	  overflow: hidden;
	  padding: 0;
	}

	.grid_gallery-inner {
	  position: relative;
	  overflow: hidden;
	  width: 100%;
	}

	.gallery-item {
	  float: left;
	  width: 33%;
	  margin: 0;
	  padding: 0 1px 1px 0;
	}

	.gallery-item .featured-thumbnail {
	  float: none;
	}

	.featured-thumbnail a {
	  position: relative;
	  display: block;
	  outline: none !important;
	}

	.project .project-main-content .featured-thumbnail a img {
	  border-radius: 0;
	}

	.single-gallery-item {
	  margin: 0 !important;
	}


	/* Slider */

	.project .bx-wrapper {
	  margin: 0;
	}

	.project .bx-wrapper .bx-viewport {
	  border-radius: 7px;
	}

	.bx-wrapper .bx-prev,
	.bx-wrapper .bx-next {
	  background: none;
	}

	.bx-wrapper .bx-controls-direction a {
	  text-indent: inherit;
	  border-radius: 50%;
	  opacity: .8;
	}

	.bx-wrapper .bx-controls-direction a:hover {
	  opacity: 1;
	}

	.bx-wrapper .bx-controls-direction a i {
	  width: 32px;
	  text-align: center;
	  font-size: 21px;
	  color: #fff;
	  line-height: 31px;
	}

	.bx-wrapper .bx-controls-direction a.bx-prev i {
	  padding-right: 2px;
	}

	.bx-wrapper .bx-controls-direction a.bx-next i {
	  padding-left: 3px;
	}


	/* Project Details */

	.project .project-details h3 {
	  margin-top: 0;
	  font-weight: 600;
	  margin-bottom: 20px;
	}

	.project .project-details ul li {
	  padding-bottom: 10px;
	}

	.project .project-details ul li:last-child {
	  padding-bottom: 0;
	}

	.project .project-details ul li span.detail-name {
	  font-weight: 500;
	}

	.project .project-details ul li span.detail-value {
	  text-transform: uppercase;
	}

	.project .project-details ul li i {
	  margin-right: 5px;
	}

	.project .project-details .custom-button:before {
	  content: "\f08e";
	  margin-top: -9px;
	}


	/* Team
	---------------------------------------- */

	.team {
	  background-color: #efefef;
	}

	.team .container {
	  padding: 70px 15px 100px;
	}

	.team-members {
	  margin-top: 40px;
	}

	.team-member {
	  position: relative;
	  display: inline-block;
	  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
	  border-radius: 7px;
	}

	.team-member-img-wrap {
	  position: relative;
	  display: block;
	  outline: 0 !important;
	  border-top-left-radius: 7px;
	  border-top-right-radius: 7px;
	}

	.team-member-img-wrap:before {
	  content: "";
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  opacity: 0;
	  height: 100%;
	  background: rgba(0, 0, 0, 0.6);
	  -webkit-transition: .3s all ease;
	  transition: .3s all ease;
	  border-top-left-radius: 7px;
	  border-top-right-radius: 7px;
	}

	.team-member-caption {
	  text-align: center;
	  background: #fff;
	  -webkit-transition: .3s all ease;
	  transition: .3s all ease;
	  border-bottom-left-radius: 7px;
	  border-bottom-right-radius: 7px;
	}

	.team-member .team-member-caption h4 {
	  line-height: 0;
	  margin-top: 35px;
	  text-transform: uppercase;
	  margin: 35px 0 25px;
	  font-weight: 700;
	}

	.team-member-caption p {
	  padding: 0px 0 18px;
	  font-style: italic;
	  margin: 0;
	}

	.team-member-caption .list {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  width: 100%;
	  opacity: 0;
	  margin-top: -45px;
	  -webkit-transform: translate(-50%, -50%);
	  transform: translate(-50%, -50%);
	  -webkit-transition: .3s all ease;
	  transition: .3s all ease;
	  will-change: content;
	}

	.team-member-caption .list li {
	  padding: 0;
	}

	.team-member-caption .list li a {
	  color: #fff;
	}

	.team-member-caption .list li+li {
	  margin-left: 5px;
	}

	.team-member-img-wrap img {
	  border-top-left-radius: 7px;
	  border-top-right-radius: 7px;
	  max-width: 100%;
	  height: auto;
	  display: block;
	  margin: 0 auto;
	}

	.team-member-caption a:hover {
	  text-decoration: none;
	}

	.team-member:hover .team-member-img-wrap:before {
	  opacity: 1;
	}

	.team-member:hover .team-member-caption h4,
	.team-member:hover .team-member-caption p {
	  color: #fff;
	}

	.team-member:hover .team-member-caption .list {
	  opacity: 1;
	}

	.team ul.social li a {
	  border: 2px solid #fff;
	  width: 40px;
	  height: 40px;
	  line-height: 36px;
	}

	.team ul.social li a:before {
	  color: #fff;
	  font-size: 20px;
	}

	.team .social-icons ul.social li a:hover:before {
	  font-size: 20px;
	}

	.team .social-icons ul.social li a:hover {
	  background: #fff;
	}


	/* Facts
	---------------------------------------- */

	.facts {
	  background: url("../../../_assets/media/images/new-landing/sections/facts-bg.jpg");
	  background-attachment: fixed;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: auto 110%;
	}

	.fact-badges {
	  margin-top: 65px;
	  text-align: center;
	}

	.fact-badges h2 {
	  margin-top: 20px;
	}

	.fact-badges h2 span {
	  font-weight: bold;
	  font-size: 48px;
	}

	.fact-badges h2 strong {
	  font-weight: 800;
	  padding-right: 3px;
	}

	.fact-badges .fa {
	  font-size: 70px;
	  color: #fff;
	}

	.facts .top-text h4 {
	  margin: 15px 0 25px;
	  font-weight: 400;
	}

	.facts h4 {
	  margin: 25px 0;
	  font-weight: 500;
	}


	/* Call To Action
	---------------------------------------- */

	.call-to-action {
	  background: url("../../../_assets/media/images/new-landing/sections/call-to-action-bg.jpg");
	  background-attachment: fixed;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: auto 110%;
	}

	.call-to-action .call-to-action-content {
	  margin-top: 40px;
	}

	.call-to-action .call-to-action-content ul {
	  padding: 0;
	  margin: 0;
	  list-style-type: none;
	  color: #fff;
	  text-align: left;
	  max-width: 260px;
	  margin: 0 auto;
	}

	.call-to-action .call-to-action-content ul li {
	  padding-left: 15px;
	  margin-bottom: 10px;
	}

	.call-to-action .call-to-action-content ul li:before {
	  content: "\f00c";
	  font-family: FontAwesome;
	  display: inline-block;
	  margin-left: -15px;
	  margin-right: 12px;
	}

	.call-to-action .call-to-action-content .custom-button {
	  padding: 15px 30px;
	  border-radius: 40px;
	  font-size: 18px;
	  font-weight: 600;
	  display: block;
	  text-align: center;
	  width: 260px;
	  margin: 0 auto;
	  margin-top: 40px;
	}

	.call-to-action .call-to-action-content .custom-button:before {
	  content: "\f234";
	  margin-top: -10px;
	  right: 55px;
	}


	/* Newsletter
	---------------------------------------- */

	.newsletter {
	  color: #fff;
	  background: url("../../../_assets/media/images/new-landing/sections/testimonials-bg.jpg");
	  background-attachment: fixed;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: auto 110%;
	}

	.newsletter p {
	  color: #fff;
	}

	.newsletter-content {
	  margin-top: 40px;
	}

	.newsletter form .custom-input-label::before {
	  height: 52px;
	}

	.newsletter form {
	  position: relative;
	  max-width: 600px;
	  margin: 0 auto;
	}

	.newsletter .custom-form-group {
	  margin-top: 20px;
	  margin-bottom: 30px;
	}

	.newsletter form .custom-button {
	  margin-top: 0;
	  margin: 0 auto;
	  display: block;
	  padding: 15px 30px;
	  border-radius: 40px;
	  width: 260px;
	  outline: none;
	}

	.newsletter form .custom-button span {
	  font-size: 19px;
	}

	.newsletter form .custom-button:before {
	  font-size: 18px;
	  content: "\f0e0";
	  right: 48px;
	}


	/* Blog
	---------------------------------------- */

	.blog {
	  background-color: #fff;
	}


	/*** Latest Posts ***/

	.latest-posts-content {
	  margin-top: 40px;
	}

	.latest-post {
		position:relative;
	}

	.latest-post .post-title {
	  font-size: 18px;
	  position: relative;
	  margin-top: 25px;
	  margin-bottom: 15px;
	}

	.latest-post .post-title a {
	  color: #555;
	  transition: .1s;
	  font-size: 18px;
	  line-height: 18px;
	  font-weight: 600;
	  text-decoration: none;
	  transition: 0.2s;
	}

	.latest-post a.img-thumb {
	  position: relative;
	  display: inline-block;
	  max-width: 100%;
	  overflow: hidden;
	  border-radius: 4px;
	}

	.latest-post a.img-thumb img {
	  -webkit-transition: all 0.5s ease;
	  -moz-transition: all 0.5s ease;
	  -o-transition: all 0.5s ease;
	  transition: all 0.5s ease;
	}

	.latest-post a.img-thumb:hover img {
	  transform: scale(1.1) !important;
	  -o-transform: scale(1.1) !important;
	  -moz-transform: scale(1.1) !important;
	  -webkit-transform: scale(1.1) !important;
	  -ms-transform: scale(1.1) !important;
	  opacity: 1 !important;
	}

	.latest-post .custom-button {
	  border-radius: 40px;
	  font-size: 18px;
	  font-weight: 600;
	}

	.latest-post .custom-button:before {
	  content: "\f061";
	  margin-top: -10px;
	}

	.latest-post .post-date {
	    width: 40px;
	    height: 50px;
	    position: absolute;
	    top: 4px;
	    right: 4px;
	    text-align: center;
	    padding-top: 3px;
		border-radius:4px
	}

	.latest-post .post-date span {
	  display: block;
	  color: #fff;
	}

	.latest-post .post-date span:first-child {
	  font-weight: 600;
	  font-size: 19px;
	  padding-top: 1px;
	}

	.latest-post .post-date span:nth-child(2) {
	  font-size: 12px;
	}


	/*** Blog Page ***/

	&.blog-page article {
	  margin-bottom: 60px;
	}

	&.blog-page figure {
	  margin: 0;
	}

	&.blog-page figure a img {
	  width: 100%;
	  border-radius: 4px;
	}

	&.blog-page article h4 {
	  color: #555;
	  text-transform: uppercase;
	  font-weight: 700;
	  font-size: 28px;
	  margin: 0 0 20px;
	  line-height: 34px;
	}

	&.blog-page.grid-no-sidebar article h4 {
	  font-size: 23px;
	  line-height: 31px;
	}

	&.blog-page p.excerpt-blog,
	&.blog-page p.content-article {
	  margin: 22px 0;
	}

	&.blog-page article .custom-button {
	  border-radius: 40px;
	  font-size: 18px;
	  font-weight: 600;
	}

	&.blog-page article .custom-button:before {
	  content: "\f061";
	  margin-top: -10px;
	}

	&.blog-page .meta {
	  clear: both;
	  padding: 10px 0;
	  width: 100%;
	  border-top: 1px solid #ddd;
	  border-bottom: 1px solid #ddd;
	  font-size: 18px;
	  margin-top: 30px;
	}

	&.blog-page .banner-area .meta {
	  border-top: 0;
	  border-bottom: 0;
	}

	&.blog-page .meta span {
	  margin-right: 15px;
	  display: inline-block;
	}

	&.blog-page .meta span i {
	  padding-right: 4px;
	}


	/* Pagination */

	&.blog-page .pagination li:first-child a {
	  border-top-left-radius: 4px;
	  border-bottom-left-radius: 4px;
	}

	&.blog-page .pagination li:last-child a {
	  border-top-right-radius: 4px;
	  border-bottom-right-radius: 4px;
	}

	&.blog-page .pagination li a:hover {
	  background: #ddd;
	}

	&.blog-page .pagination li a {
	  background: transparent;
	  border: 1px solid #ddd;
	  border-radius: 0;
	  padding: 9px 16px;
	  font-size: 19px;
	}

	&.blog-page .pagination li.active a {
	  color: #fff;
	}


	/* Comments */

	.comments-heading {
	  margin: 40px 0 25px;
	  text-transform: uppercase;
	  color: #555;
	}

	.comments-list {
	  list-style: none;
	  margin: 0;
	  padding: 20px 0;
	}

	.comments-list .comment {
	  margin-bottom: 30px;
	  background: #efefef;
	  padding: 25px;
	  border-radius: 7px;
	}

	.comments-list img.comment-avatar {
	  width: 82px;
	  height: 82px;
	  border-radius: 100%;
	  margin-right: 25px;
	}

	.comments-list .comment-body {
	  margin-left: 110px;
	}

	.comments-list .comment-author {
	  font-size: 19px;
	  color: #555;
	  font-weight: 500;
	}

	.comments-list .comment-content {
	  font-size: 18px;
	}

	.comments-list .comment-date {
	  font-size: 12px;
	}

	.comments-list .comment-content {
	  margin: 15px 0;
	}

	.comments-list .comment-reply {
	  text-transform: uppercase;
	  font-weight: 600;
	}

	.comments-reply {
	  list-style: none;
	  margin: 0 0 0 96px;
	}


	/* Comments Form */

	.comments-heading.add-comment {
	  margin: 0 0 50px;
	}

	.comments-form {
	  margin-bottom: 0;
	}

	.comments-form .title-normal {
	  margin-top: 0;
	  line-height: normal;
	  margin-bottom: 25px;
	}

	.comments-form .form-group {
	  margin-bottom: 30px;
	}

	.custom-form textarea {
	  padding: 15px;
	  height: 150px;
	  box-shadow: none;
	  border: 1px solid #ddd;
	  background: #f2f2f2;
	  transition: .2s;
	}

	.custom-form input[type=text],
	.custom-form input[type=password],
	.custom-form input[type=email] {
	  box-shadow: none;
	  height: 42px;
	  padding-left: 15px;
	  border: 1px solid #ddd;
	  background: #f2f2f2;
	  font-size: 18px;
	  transition: .2s;
	}

	&.blog-page article .comments-form .custom-button:before {
	  content: "\f075";
	}

	.custom-form input[type=text]:focus,
	.custom-form input[type=text]:active,
	.custom-form input[type=email]:focus,
	.custom-form input[type=email]:active,
	.custom-form input[type=password]:focus,
	.custom-form input[type=password]:active,
	.custom-form textarea:focus,
	.custom-form textarea:active {
	  border: 1px solid #ccc;
	  box-shadow: none;
	  background: #ece4e4;
	}

	.comments-form.custom-form .custom-button {
	  margin-top: 0;
	}


	/*** Sidebar ***/

	.sidebar .widget-title {
	  font-size: 18px;
	  font-weight: 600;
	  position: relative;
	  margin-bottom: 25px;
	  margin-top: 0;
	  line-height: normal;
	  text-transform: uppercase;
	  color: #333;
	}

	.widget {
	  background: #efefef;
	  padding: 30px;
	  margin-bottom: 30px;
	  border-radius: 7px;
	}


	/* Recent Posts Widget */

	.widget.recent-posts ul li {
	  border-bottom: 1px solid #ddd;
	  padding: 20px 0;
	}

	.widget.recent-posts ul li:first-child {
	  padding-top: 0;
	}

	.widget.recent-posts ul li:last-child {
	  border: 0;
	  border-bottom:0 !important;
	  padding-bottom: 0;
	  margin-bottom: 0;
	}

	.widget.recent-posts .posts-thumb {}

	.widget.recent-posts .posts-thumb img {
	  margin-right: 15px;
	  width: 90px;
	  height: 70px;
	  border-radius: 3px;
	}

	.widget.recent-posts .post-info .entry-title {
	  font-size: 18px;
	  font-weight: 600;
	  line-height: 20px;
	  margin: 0;
	}

	.widget.recent-posts .post-info .post-meta {
	  margin-bottom: 0;
	}

	.widget.recent-posts .entry-title a {
	  color: #777;
	  font-weight: 600;
	}

	.widget.recent-posts .post-date {
	  font-weight: 400;
	  color: #999;
	  text-transform: capitalize;
	  font-size: 12px;
	}


	/* Navigation Widget */

	.sidebar ul.nav-tabs {
	  border: 0;
	}

	.sidebar ul.nav-tabs li a {
	  color: #777;
	  border-radius: 0;
	  padding: 0;
	  padding-left: 0;
	  font-weight: 600;
	  display: inline-block;
	  border-left: 0;
	  margin: 0;
	  font-size: 18px;
	}

	.sidebar ul.nav-tabs li {
	  line-height: normal;
	  font-weight: 600;
	  border-bottom: 1px solid #ddd;
	  padding: 15px 0;
	  float: none;
	  text-align: left;
	}

	.sidebar ul.nav-tabs li:first-child {
	  padding-top: 0;
	}

	.sidebar ul.nav-tabs li:last-child {
	  border-bottom: 0;
	}


	/* Tags Widget */

	.widget-tags ul>li {
	  display: inline-block;
	  margin: 6px 6px 6px 0;
	}

	.widget-tags ul>li a {
	  color: #999;
	  border: 1px solid #999;
	  display: block;
	  font-size: 18px;
	  padding: 5px 15px;
	  font-weight: 600;
	  border-radius: 3px;
	  transition: 0.1s;
	}

	.widget-tags ul>li a:hover {
	  color: #fff;
	}


	/* Pricing
	---------------------------------------- */

	.pricing {
	  background: #efefef;
	}

	.pricing-page .pricing {
	  background: #fff;
	}

	.pricing-tables-content {
	  margin-top: 25px;
	}

	.pricing-page .pricing h1.monthly {
	  padding-bottom: 25px;
	}

	.pricing-page .pricing h1.yearly {
	  padding: 70px 0 30px;
	}

	.pricing-tables-content.pricing-page {
	  margin-top: 0;
	}

	.pricing-tables-content .header {
	  height: 100px;
	  line-height: 170px;
	  position: relative;
	}

	.pricing h3 {
	  color: #fff;
	  text-transform: uppercase;
	}

	.pricing h3.sell-title {
	  margin-top: 50px;
	}

	.pricing-switcher {
	  text-align: center;
	}

	.pricing-switcher>p {
	  display: inline-block;
	  position: relative;
	  padding: 0;
	  margin: 15px 0 -5px;
	  padding-right: 4px;
	  background: #aba8a8;
	  border-radius: 40px;
	}

	.pricing-switcher span.switch {
	  border-radius: 40px;
	}

	.pricing-switcher input[type="radio"] {
	  position: absolute;
	  opacity: 0;
	}

	.pricing-switcher label {
	  position: relative;
	  z-index: 1;
	  display: inline-block;
	  float: left;
	  width: 105px;
	  height: 42px;
	  line-height: 49px;
	  padding-left: 8px;
	  cursor: pointer;
	  font-size: 18px;
	  color: #fff;
	  font-weight: 600;
	}

	.pricing-switcher label.switch-1.active,
	.pricing-switcher label.switch-2.active {
	  color: #fff;
	}

	.pricing-switcher .switch {
	  position: absolute;
	  top: 2px;
	  left: 2px;
	  height: 43px;
	  width: 105px;
	  border-radius: 0;
	  -webkit-transition: -webkit-transform 0.5s;
	  -moz-transition: -moz-transform 0.5s;
	  transition: transform 0.5s;
	}

	.pricing-switcher input[type="radio"]:checked+label+.switch,
	.pricing-switcher input[type="radio"]:checked+label:nth-of-type(n)+.switch {
	  -webkit-transform: translateX(105px);
	  -moz-transform: translateX(105px);
	  -ms-transform: translateX(105px);
	  -o-transform: translateX(105px);
	  transform: translateX(105px);
	}

	.no-js .pricing-switcher {
	  display: none;
	}

	.pricing-list {
	  margin: 32px 0 0;
	  list-style-type: none;
	}

	.pricing-list .badge-popular {
	  position: absolute;
	  top: 0;
	  overflow: hidden;
	  height: 130px;
	  width: 143px;
	  text-align: center;
	  z-index: 0;
	  right: 0;
	  border-top-right-radius: 7px;
	}

	.pricing-list .popular {
	  font-size: 18px;
	  color: #fff;
	  text-transform: uppercase;
	  padding: 10px 0px 10px 60px;
	  width: 280px;
	  text-align: center;
	  display: block;
	  position: absolute;
	  left: -60px;
	  top: 0;
	  -moz-transition: all 0.5s ease;
	  -webkit-transition: all 0.5s ease;
	  transition: all 0.5s ease;
	  -webkit-transform: rotate(-44deg);
	  -ms-transform: rotate(-44deg);
	  transform: rotate(44deg);
	}

	.pricing-list>li {
	  position: relative;
	  margin-bottom: 16px;
	}

	.pricing-wrapper {
	  position: relative;
	  list-style-type: none;
	  padding: 0;
	}

	.touch .pricing-wrapper {
	  -webkit-perspective: 2000px;
	  -moz-perspective: 2000px;
	  perspective: 2000px;
	}

	.pricing-wrapper.is-switched .is-visible {
	  -webkit-transform: rotateY(180deg);
	  -moz-transform: rotateY(180deg);
	  -ms-transform: rotateY(180deg);
	  -o-transform: rotateY(180deg);
	  transform: rotateY(180deg);
	  -webkit-animation: rotate 0.5s;
	  -moz-animation: rotate 0.5s;
	  animation: rotate 0.5s;
	}

	.pricing-wrapper.is-switched .is-hidden {
	  -webkit-transform: rotateY(0);
	  -moz-transform: rotateY(0);
	  -ms-transform: rotateY(0);
	  -o-transform: rotateY(0);
	  transform: rotateY(0);
	  -webkit-animation: rotate-inverse 0.5s;
	  -moz-animation: rotate-inverse 0.5s;
	  animation: rotate-inverse 0.5s;
	  opacity: 0;
	}

	.pricing-wrapper.is-switched .is-selected {
	  opacity: 1;
	}

	.pricing-wrapper.is-switched.reverse-animation .is-visible {
	  -webkit-transform: rotateY(-180deg);
	  -moz-transform: rotateY(-180deg);
	  -ms-transform: rotateY(-180deg);
	  -o-transform: rotateY(-180deg);
	  transform: rotateY(-180deg);
	  -webkit-animation: rotate-back 0.5s;
	  -moz-animation: rotate-back 0.5s;
	  animation: rotate-back 0.5s;
	}

	.pricing-wrapper.is-switched.reverse-animation .is-hidden {
	  -webkit-transform: rotateY(0);
	  -moz-transform: rotateY(0);
	  -ms-transform: rotateY(0);
	  -o-transform: rotateY(0);
	  transform: rotateY(0);
	  -webkit-animation: rotate-inverse-back 0.5s;
	  -moz-animation: rotate-inverse-back 0.5s;
	  animation: rotate-inverse-back 0.5s;
	  opacity: 0;
	}

	.pricing-wrapper.is-switched.reverse-animation .is-selected {
	  opacity: 1;
	}

	.pricing-wrapper>li {
	  background-color: #fff;
	  -webkit-backface-visibility: hidden;
	  backface-visibility: hidden;
	  outline: 1px solid transparent;
	}

	.pricing-page .pricing-wrapper>li {
	  background-color: #f2f2f2;
	}

	.pricing-wrapper>li::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  right: 0;
	  height: 100%;
	  width: 50px;
	  pointer-events: none;
	  background: -webkit-linear-gradient( right, #FFFFFF, rgba(255, 255, 255, 0));
	  background: linear-gradient(to left, #FFFFFF, rgba(255, 255, 255, 0));
	}

	.pricing-wrapper>li.is-ended::after {
	  display: none;
	}

	.pricing-wrapper .is-visible {
	  position: relative;
	  z-index: 5;
	  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
	  border-radius: 7px;
	}

	.pricing-wrapper .is-hidden {
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 100%;
	  width: 100%;
	  z-index: 1;
	  -webkit-transform: rotateY(180deg);
	  -moz-transform: rotateY(180deg);
	  -ms-transform: rotateY(180deg);
	  -o-transform: rotateY(180deg);
	  transform: rotateY(180deg);
	}

	.pricing-wrapper .is-selected {
	  z-index: 3 !important;
	}

	.no-js .pricing-wrapper .is-hidden {
	  position: relative;
	  -webkit-transform: rotateY(0);
	  -moz-transform: rotateY(0);
	  -ms-transform: rotateY(0);
	  -o-transform: rotateY(0);
	  transform: rotateY(0);
	  margin-top: 16px;
	}

	.price {
	  margin-top: 45px;
	  color: #fff;
	}

	.pricing-header {
	  position: relative;
	  z-index: 1;
	  height: 80px;
	  padding: 16px;
	  pointer-events: none;
	  background-color: #3aa0d1;
	  color: #FFFFFF;
	}

	.pricing-header h2 {
	  margin-bottom: 3px;
	  font-weight: 600;
	  text-transform: uppercase;
	}

	.currency,
	.value {
	  font-size: 120px;
	  font-weight: 300;
	}

	.pricing-body {
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	}

	.is-switched .pricing-body {
	  overflow: hidden;
	}

	.pricing-features {
	  padding: 0;
	}

	.pricing-features:after {
	  content: "";
	  display: table;
	  clear: both;
	}

	.pricing-features li {
	  width: 100px;
	  float: left;
	  padding: 25px 16px;
	  font-size: 19px;
	  text-align: center;
	  white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  list-style: none;
	}

	.pricing-features em {
	  display: block;
	  margin-bottom: 5px;
	  font-weight: 600;
	}

	.pricing-footer {
	  position: absolute;
	  z-index: 1;
	  top: 0;
	  left: 0;
	  height: 80px;
	  width: 100%;
	}

	.pricing-footer::after {
	  content: '';
	  position: absolute;
	  right: 16px;
	  top: 50%;
	  bottom: auto;
	  -webkit-transform: translateY(-50%);
	  -moz-transform: translateY(-50%);
	  -ms-transform: translateY(-50%);
	  -o-transform: translateY(-50%);
	  transform: translateY(-50%);
	  height: 20px;
	  width: 20px;
	}

	.pricing-footer a {
	  display: block;
	  margin: 0 30px;
	}

	.select {
	  position: relative;
	  z-index: 1;
	  display: block;
	  height: 100%;
	  overflow: hidden;
	  text-indent: 100%;
	  white-space: nowrap;
	  color: transparent;
	}

	.pricing-tables-content .header {
	  height: 160px;
	  line-height: 280px;
	}

	.pricing-list {
	  margin: 48px 0 0;
	  padding: 0;
	}

	.pricing-list:after {
	  content: "";
	  display: table;
	  clear: both;
	}

	.pricing-list>li {
	  float: left;
	  padding: 0 15px;
	}

	.pricing-wrapper>li::before {
	  content: '';
	  position: absolute;
	  z-index: 6;
	  left: -1px;
	  top: 50%;
	  bottom: auto;
	  -webkit-transform: translateY(-50%);
	  -moz-transform: translateY(-50%);
	  -ms-transform: translateY(-50%);
	  -o-transform: translateY(-50%);
	  transform: translateY(-50%);
	  height: 50%;
	  width: 1px;
	}

	.pricing-wrapper>li::after {
	  display: none;
	}

	.pricing-header {
	  height: auto;
	  padding: 30px;
	  pointer-events: auto;
	  text-align: center;
	  color: #173d50;
	  background-color: transparent;
	}

	.popular .pricing-header {
	  color: #e97d68;
	  background-color: transparent;
	}

	.pricing-header h2 {
	  font-size: 1.8rem;
	  margin-top: 15px;
	  margin-bottom: 45px;
	  line-height: 1.8rem;
	  color: #666;
	  letter-spacing: 1px;
	  font-weight: 300;
	}

	.price-content .value {
	  font-size: 6rem;
	  font-weight: 400;
	  color: #555;
	}

	.price-content .duration {
	  font-size: 1.4rem;
	  font-weight: 700;
	  font-size: 1.3rem;
	  color: #8dc8e4;
	  text-transform: uppercase;
	}

	.price-content .duration:before {
	  content: '/';
	  margin-right: 2px;
	}

	.price-content .currency {
	  display: inline-block;
	  margin-top: 0;
	  vertical-align: top;
	  font-size: 2rem;
	  font-weight: 700;
	  color: rgba(23, 61, 80, 0.4);
	  font-size: 20px;
	}

	.pricing-body {
	  overflow-x: visible;
	}

	.pricing-features {
	  width: auto;
	}

	.pricing-features li {
	  float: none;
	  width: auto;
	  padding: 14px;
	  border-bottom: 1px solid #eee;
	}

	.pricing-page .pricing-features li {
	  border-bottom: 1px solid #ddd;
	}

	.pricing-footer .custom-button {
	  padding: 10px 25px;
	  border-radius: 40px;
	  font-size: 18px;
	  font-weight: 600;
	}

	.pricing-footer .custom-button:before {
	  content: "\f07a";
	  margin-top: -10px;
	  right: 86px;
	}

	.pricing-features em {
	  display: inline-block;
	  margin-bottom: 0;
	}

	.pricing-footer {
	  position: relative;
	  height: auto;
	  padding: 30px 0;
	  text-align: center;
	}

	.pricing-footer::after {
	  display: none;
	}

	.select {
	  position: static;
	  display: inline-block;
	  height: auto;
	  padding: 20px 48px;
	  color: #FFFFFF;
	  border-radius: 2px;
	  background-color: #0c1f28;
	  font-size: 1.4rem;
	  text-indent: 0;
	  text-transform: uppercase;
	  letter-spacing: 2px;
	}

	.no-touch .select:hover {
	  background-color: #112e3c;
	}


	/* Keyframes */

	@-webkit-keyframes rotate {
	  0% {
	    -webkit-transform: perspective(2000px) rotateY(0);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -webkit-transform: perspective(2000px) rotateY(200deg);
	  }
	  100% {
	    -webkit-transform: perspective(2000px) rotateY(180deg);
	  }
	}

	@-moz-keyframes rotate {
	  0% {
	    -moz-transform: perspective(2000px) rotateY(0);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -moz-transform: perspective(2000px) rotateY(200deg);
	  }
	  100% {
	    -moz-transform: perspective(2000px) rotateY(180deg);
	  }
	}

	@keyframes rotate {
	  0% {
	    -webkit-transform: perspective(2000px) rotateY(0);
	    -moz-transform: perspective(2000px) rotateY(0);
	    -ms-transform: perspective(2000px) rotateY(0);
	    -o-transform: perspective(2000px) rotateY(0);
	    transform: perspective(2000px) rotateY(0);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -webkit-transform: perspective(2000px) rotateY(200deg);
	    -moz-transform: perspective(2000px) rotateY(200deg);
	    -ms-transform: perspective(2000px) rotateY(200deg);
	    -o-transform: perspective(2000px) rotateY(200deg);
	    transform: perspective(2000px) rotateY(200deg);
	  }
	  100% {
	    -webkit-transform: perspective(2000px) rotateY(180deg);
	    -moz-transform: perspective(2000px) rotateY(180deg);
	    -ms-transform: perspective(2000px) rotateY(180deg);
	    -o-transform: perspective(2000px) rotateY(180deg);
	    transform: perspective(2000px) rotateY(180deg);
	  }
	}

	@-webkit-keyframes rotate-inverse {
	  0% {
	    -webkit-transform: perspective(2000px) rotateY(-180deg);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -webkit-transform: perspective(2000px) rotateY(20deg);
	  }
	  100% {
	    -webkit-transform: perspective(2000px) rotateY(0);
	  }
	}

	@-moz-keyframes rotate-inverse {
	  0% {
	    -moz-transform: perspective(2000px) rotateY(-180deg);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -moz-transform: perspective(2000px) rotateY(20deg);
	  }
	  100% {
	    -moz-transform: perspective(2000px) rotateY(0);
	  }
	}

	@keyframes rotate-inverse {
	  0% {
	    -webkit-transform: perspective(2000px) rotateY(-180deg);
	    -moz-transform: perspective(2000px) rotateY(-180deg);
	    -ms-transform: perspective(2000px) rotateY(-180deg);
	    -o-transform: perspective(2000px) rotateY(-180deg);
	    transform: perspective(2000px) rotateY(-180deg);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -webkit-transform: perspective(2000px) rotateY(20deg);
	    -moz-transform: perspective(2000px) rotateY(20deg);
	    -ms-transform: perspective(2000px) rotateY(20deg);
	    -o-transform: perspective(2000px) rotateY(20deg);
	    transform: perspective(2000px) rotateY(20deg);
	  }
	  100% {
	    -webkit-transform: perspective(2000px) rotateY(0);
	    -moz-transform: perspective(2000px) rotateY(0);
	    -ms-transform: perspective(2000px) rotateY(0);
	    -o-transform: perspective(2000px) rotateY(0);
	    transform: perspective(2000px) rotateY(0);
	  }
	}

	@-webkit-keyframes rotate-back {
	  0% {
	    -webkit-transform: perspective(2000px) rotateY(0);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -webkit-transform: perspective(2000px) rotateY(-200deg);
	  }
	  100% {
	    -webkit-transform: perspective(2000px) rotateY(-180deg);
	  }
	}

	@-moz-keyframes rotate-back {
	  0% {
	    -moz-transform: perspective(2000px) rotateY(0);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -moz-transform: perspective(2000px) rotateY(-200deg);
	  }
	  100% {
	    -moz-transform: perspective(2000px) rotateY(-180deg);
	  }
	}

	@keyframes rotate-back {
	  0% {
	    -webkit-transform: perspective(2000px) rotateY(0);
	    -moz-transform: perspective(2000px) rotateY(0);
	    -ms-transform: perspective(2000px) rotateY(0);
	    -o-transform: perspective(2000px) rotateY(0);
	    transform: perspective(2000px) rotateY(0);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -webkit-transform: perspective(2000px) rotateY(-200deg);
	    -moz-transform: perspective(2000px) rotateY(-200deg);
	    -ms-transform: perspective(2000px) rotateY(-200deg);
	    -o-transform: perspective(2000px) rotateY(-200deg);
	    transform: perspective(2000px) rotateY(-200deg);
	  }
	  100% {
	    -webkit-transform: perspective(2000px) rotateY(-180deg);
	    -moz-transform: perspective(2000px) rotateY(-180deg);
	    -ms-transform: perspective(2000px) rotateY(-180deg);
	    -o-transform: perspective(2000px) rotateY(-180deg);
	    transform: perspective(2000px) rotateY(-180deg);
	  }
	}

	@-webkit-keyframes rotate-inverse-back {
	  0% {
	    -webkit-transform: perspective(2000px) rotateY(180deg);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -webkit-transform: perspective(2000px) rotateY(-20deg);
	  }
	  100% {
	    -webkit-transform: perspective(2000px) rotateY(0);
	  }
	}

	@-moz-keyframes rotate-inverse-back {
	  0% {
	    -moz-transform: perspective(2000px) rotateY(180deg);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -moz-transform: perspective(2000px) rotateY(-20deg);
	  }
	  100% {
	    -moz-transform: perspective(2000px) rotateY(0);
	  }
	}

	@keyframes rotate-inverse-back {
	  0% {
	    -webkit-transform: perspective(2000px) rotateY(180deg);
	    -moz-transform: perspective(2000px) rotateY(180deg);
	    -ms-transform: perspective(2000px) rotateY(180deg);
	    -o-transform: perspective(2000px) rotateY(180deg);
	    transform: perspective(2000px) rotateY(180deg);
	  }
	  70% {
	    /* this creates the bounce effect */
	    -webkit-transform: perspective(2000px) rotateY(-20deg);
	    -moz-transform: perspective(2000px) rotateY(-20deg);
	    -ms-transform: perspective(2000px) rotateY(-20deg);
	    -o-transform: perspective(2000px) rotateY(-20deg);
	    transform: perspective(2000px) rotateY(-20deg);
	  }
	  100% {
	    -webkit-transform: perspective(2000px) rotateY(0);
	    -moz-transform: perspective(2000px) rotateY(0);
	    -ms-transform: perspective(2000px) rotateY(0);
	    -o-transform: perspective(2000px) rotateY(0);
	    transform: perspective(2000px) rotateY(0);
	  }
	}


	/* Video Promotion
	---------------------------------------- */

	.videopromotion {
	  color: #fff;
	  background: url("../../../_assets/media/images/new-landing/sections/video-bg.jpg");
	  background-attachment: fixed;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: auto 110%;
	}

	.videopromotion p {
	  color: #fff;
	}

	.btn-wrapper {
	  margin-top: 70px
	}

	.btn-wrapper a {
	  border-radius: 50%;
	  -moz-box-sizing: border-box;
	  -webkit-box-sizing: border-box;
	  box-sizing: border-box;
	  display: inline-block;
	  height: 80px;
	  position: relative;
	  text-align: center;
	  -moz-transition: all 0.3s ease;
	  -o-transition: all 0.3s ease;
	  -webkit-transition: all 0.3s ease;
	  transition: all 0.3s ease;
	  width: 80px;
	  outline: none;
	}

	.btn-wrapper a:before {
	  content: "\f04b";
	  font-family: FontAwesome;
	  font-size: 26px;
	  left: 50%;
	  line-height: 41px;
	  margin-left: -7px;
	  margin-top: -20px;
	  position: absolute;
	  top: 50%;
	}

	.btn-wrapper a:hover {
	  border-width: 22px;
	}

	.btn-wrapper a:hover:before {
	  color: #fff;
	}


	/* Contact
	---------------------------------------- */

	.contact {
	  background-color: #efefef;
	}

	.contact .container:first-child {
	  padding: 70px 15px 30px !important;
	}


	/* Google Map */

	.gmap_container {
	  overflow: hidden;
	  height: 710px;
	  width: 100%;
	}

	.contact #gmap_canvas {
	  height: 710px;
	  width: 100%;
	}

	.contact #gmap_canvas img {
	  max-width: none!important;
	  background: none!important;
	}

	.contact #gmap_canvas strong {
	  font-size: 17px;
	  font-weight: 600;
	  display: block;
	  margin-bottom: 8px;
	}

	.info-map {
	  position: relative;
	  clear: both;
	  border-bottom: 1px solid #ccc;
	}

	.map-info-window {
	  padding: 6px;
	}

	.info-map-gmap {
	  height: 450px;
	}

	.info-map-boxes {
	  zoom: 1;
	  position: relative;
	  margin-top: -255px;
	  -webkit-transition: opacity 0.2s linear;
	  transition: opacity 0.2s linear;
	}

	.info-map-boxes:before {
	  content: "";
	  display: table;
	}

	.info-map-boxes:after {
	  content: "";
	  display: table;
	  clear: both;
	}

	.info-map-boxes-item {
	  padding: 18px 20px 15px;
	  width: 100%;
	  border-radius: 7px;
	  font-size: 18px;
	  color: inherit;
	  background: #fff;
	  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 0 7px rgba(0, 0, 0, 0.15);
	}

	.info-map-boxes-item:before {
	  float: right;
	  margin: 0;
	  padding: 10px 0 0 30px;
	  font-size: 70px;
	  background: #fff;
	}

	.info-map-boxes-item:hover,
	.info-map-boxes-item:focus {
	  text-decoration: none;
	}

	.info-map-boxes-item h1 {
	  margin: 0;
	  padding: 0 0 15px;
	  border-bottom: 1px solid #eee;
	  font-size: 17px;
	  font-weight: bold;
	  text-transform: uppercase;
	  line-height: 20px;
	}

	.info-map-boxes-item p {
	  margin: 11px 0 0;
	  line-height: 25px;
	  font-size: 18px;
	}


	/* Contact Form */

	.contactform {
	  color: #fff;
	  background: url("../../../_assets/media/images/new-landing/sections/contactform-bg.jpg");
	  background-attachment: fixed;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: auto 110%;
	}

	.contactform .form-container {
	  max-width: 750px;
	  margin: 0 auto;
	  padding: 60px 0 30px;
	}

	.custom-form-group {
	  margin-bottom: 20px;
	}

	.custom-form-group .input-group .input-group-addon {
	  padding: 0px 13px;
	  font-size: 18px;
	  border: none;
	  color: white;
	}

	.form-inputs .form-control {
	  height: 40px;
	}

	.form-inputs .form-control {
	  background: #fff;
	  border-radius: 30px;
	  box-shadow: none;
	  -webkit-transition: 0.3s;
	  transition: 0.3s;
	}

	.custom-form-group textarea {
	  width: 100%;
	  padding: 20px 0 0 25px;
	}

	.form-inputs .form-group textarea:focus,
	.form-inputs .form-group .form-control:focus {
	  outline: 0px !important;
	  -webkit-appearance: none;
	}

	.submit-form button {
	  padding: 14px 26px;
	  border-radius: 40px;
	  display: block;
	  font-weight: 600;
	  margin: 0 auto;
	  width: 100%;
	  outline: none;
	  font-size: 19px;
	}

	.submit-form button:before {
	  right: 102px;
	  font-size: 18px;
	  margin-top: -9px;
	}

	.contactform .output_message {
	  padding: 8px;
	  margin-bottom: 17px;
	  border-radius: 2px;
	  color: #fff;
	  border-radius: 40px;
	  font-weight: 600;
	  text-transform: uppercase;
	  font-size: 19px;
	}

	.contactform .output_message.success {
	  background: #28a745;
	  padding: 15px;
	}

	.contactform .output_message.success:before {
	  font-family: FontAwesome;
	  content: "\f14a";
	  padding-right: 10px;
	}

	.contactform .output_message.error {
	  background: #dc3545;
	  padding: 15px;
	}

	.contactform .output_message.error:before {
	  font-family: FontAwesome;
	  content: "\f071";
	  padding-right: 10px;
	}

	.contactform .output_message_holder {
	  display: none;
	}


	/* Logos Slider */

	.logos {
	  background-color: #fff;
	}

	.bx-wrapper {
	  margin: 80px 0;
	  box-shadow: none;
	}

	ul.bxslider img {
	  opacity: 0.7;
	}


	/* Banner */

	.banner {
	  background-image: url("../../../_assets/media/images/new-landing/banner.jpg");
	  background-repeat: no-repeat;
	  background-position: center top;
	  background-attachment: fixed;
	}

	.banner.banner-blog-post {
	  background-image: url("../../../_assets/media/images/new-landing/blog/banner-blog.jpg");
	  background-size: initial;
	}

	.blog-page .banner-blog-post .meta span {
	  color: #ccc
	}

	.blog-page .banner-blog-post .meta,
	&.dark.blog-page .banner-blog-post .meta {
	  border-top: 0;
	  border-bottom: 0;
	}

	.banner .content {
	  background-color: rgba(0, 0, 0, .8);
	  padding: 200px 0;
	  width: 100%;
	}

	.banner .content h1,
	.banner .breadcrumb>li {
	  color: #fff !important;
	}

	.banner hr {
	  width: 80px;
	  margin-top: 32px;
	  margin-bottom: 22px;
	}


	/* Breadcrumb */

	.banner .breadcrumb {
	  font-size: 18px;
	  text-transform: uppercase;
	  margin: 15px 0 0;
	  background: transparent;
	}

	.banner .breadcrumb>li {
	  font-weight: 400;
	}

	.banner .breadcrumb>li a {
	  font-weight: 700;
	  color: #fff;
	}

	.banner .breadcrumb>li a:hover {
	  opacity: .9;
	}

	.banner .breadcrumb>li+li:before {
	  color: #fff;
	}


	/* Auth Pages */

	.auth-page {
	  background: #fff;
	}

	.user-auth {
	  padding: 0;
	}

	.user-auth .logo {
	  position: absolute;
	  left: 0;
	  top: 0;
	  z-index: 1;
	  margin-left: 30px;
	  margin-top: 30px;
	}

	.user-auth .logo img {
	  width: 110px;
	}

	.user-auth>div {
	  padding: 0;
	}

	.user-auth>div:nth-child(2) {
	  height: 100vh;
	  background: #fff;
	}

	.user-auth>div:nth-child(2) .copyright-text {
	  font-size: 12px;
	  position: absolute;
	  bottom: 0;
	  width: 100%;
	  margin-bottom: 3px;
	  opacity: .8;
	}

	.user-auth>div:nth-child(2) .form-container {
	  display: table;
	  position: absolute;
	  height: 100vh;
	  width: 100%;
	  left: 0;
	}

	.user-auth>div:nth-child(2) .form-container>div {
	  display: table-cell;
	  vertical-align: middle;
	}

	.user-auth>div:nth-child(2) .form-container form {
	  max-width: 450px;
	  margin: 0 auto;
	}

	.user-auth>div:nth-child(2) .form-container form .form-group {
	  margin: 0 auto;
	  margin-bottom: 30px;
	}

	.user-auth>div:nth-child(2) .form-container .custom-button {
	  border-radius: 40px;
	  font-size: 18px;
	  font-weight: 600;
	  margin: 0 auto;
	  display: block;
	  margin-bottom: 10px;
	}

	.user-auth>div:nth-child(2) .form-container .custom-button:before {
	  content: "\f234";
	  margin-top: -10px;
	}

	.user-auth>div:nth-child(2) .form-container .custom-button.login:before {
	  content: "\f090";
	  margin-top: -9px;
	}

	.user-auth>div:nth-child(2) .form-container p {
	  text-transform: uppercase;
	  margin-bottom: 40px;
	}

	.user-auth>div:nth-child(2) .form-container p.info-form {
	  margin-bottom: 30px;
	  font-size: 18px;
	}

	.user-auth>div:nth-child(2) .form-container .form-group p {
	  font-size: 12px;
	}


	/* Carousel Testimonials */

	#carousel-testimonials .item {
	  height: 100vh;
	  color: #fff;
	  -webkit-background-size: cover;
	  background-position: center center;
	  background-size: cover;
	  -webkit-backface-visibility: hidden;
	  background-attachment: initial;
	}

	#carousel-testimonials .item.item-1 {
	  background-image: url("../../../_assets/media/images/new-landing/testimonial/auth-page/bg-user.jpg");
	}

	#carousel-testimonials .item.item-2 {
	  background-image: url("../../../_assets/media/images/new-landing/testimonial/auth-page/bg-user-2.jpg");
	}

	#carousel-testimonials .item.item-3 {
	  background-image: url("../../../_assets/media/images/new-landing/testimonial/auth-page/bg-user-3.jpg");
	}

	#carousel-testimonials .carousel-control {
	  display: none;
	}

	#carousel-testimonials .item>div {
	  position: absolute;
	  bottom: 0;
	}

	#carousel-testimonials .item>div:after {
	  content: "";
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  width: 100%;
	  z-index: -1;
	  height: 480px;
	  background-image: -webkit-linear-gradient(91deg, #141516 7%, rgba(43, 51, 65, 0) 100%);
	  background-image: linear-gradient(-1deg, #141516 7%, rgba(43, 51, 65, 0) 100%);
	}

	#carousel-testimonials blockquote {
	  border-left: 0;
	  padding: 10px 30px;
	}

	#carousel-testimonials blockquote p {
	  position: relative;
	  margin-bottom: 40px;
	  font-style: italic;
	  color: #fff;
	  line-height: 31px;
	}

	#carousel-testimonials blockquote p:before,
	#carousel-testimonials blockquote p:after {
	  font-family: "FontAwesome";
	}

	#carousel-testimonials blockquote p:before {
	  margin-right: 20px;
	  content: "\f10d";
	}

	#carousel-testimonials blockquote p:after {
	  margin-left: 20px;
	  content: "\f10e";
	}

	#carousel-testimonials blockquote footer {
	  font-size: 12px;
	  text-transform: uppercase;
	}

	#carousel-testimonials blockquote footer span {
	  color: #eee;
	}

	#carousel-testimonials .carousel-indicators {
	  text-align: right;
	  width: 100%;
	  left: 0;
	  margin-left: 0;
	  padding-right: 30px;
	  margin-bottom: 5px;
	}

	#carousel-testimonials .carousel-indicators li {
	  display: inline-block;
	  background-color: #fff;
	  border: 0;
	  margin-right: 5px;
	}

	#carousel-testimonials .carousel-indicators li:last-child {
	  margin-right: 0;
	}

	.img-ceos {
		border-radius: 75px;
	}
  	//     -webkit-transition-property: opacity;
  	//     transition-property: opacity;
  	//   }
	/* Fix Bootstrap Carousel Bug in Firefox (Replace Slide by Fade) */

	// @-moz-document url-prefix() {
	// 	.carousel-fade .carousel-inner .item {
  	//     -webkit-transition-property: opacity;
  	//     transition-property: opacity;
  	//   }
  	//   .carousel-fade .carousel-inner .item,
  	//   .carousel-fade .carousel-inner .active.left,
  	//   .carousel-fade .carousel-inner .active.right {
  	//     opacity: 0;
  	//   }
  	//   .carousel-fade .carousel-inner .active,
  	//   .carousel-fade .carousel-inner .next.left,
  	//   .carousel-fade .carousel-inner .prev.right {
  	//     opacity: 1;
  	//   }
  	//   .carousel-fade .carousel-inner .next,
  	//   .carousel-fade .carousel-inner .prev,
  	//   .carousel-fade .carousel-inner .active.left,
  	//   .carousel-fade .carousel-inner .active.right {
  	//     left: 0;
  	//     -webkit-transform: translate3d(0, 0, 0);
  	//     transform: translate3d(0, 0, 0);
  	//   }
  	//   .carousel-fade .carousel-control {
  	//     z-index: 2;
  	//   }
  	//   .carousel,
  	//   .carousel-inner,
  	//   .carousel-inner .item {
  	//     height: 100%;
  	//   }
	// }
	//
	// .carousel-inner {
	//   position: relative;
	// }
	// .carousel-inner .item {
	//   -webkit-transition-property: opacity;
	//   transition-property: opacity;
	//
	// }
	// .carousel-inner .item,
	// .carousel-inner .active.left,
	// .carousel-inner .active.right {
	//   opacity: 0;
	// }
	// .carousel-inner .active,
	// .carousel-inner .next.left,
	// .carousel-inner .prev.right {
	//   opacity: 1;
	// }
	// .carousel-inner .next,
	// .carousel-inner .prev,
	// .carousel-inner .active.left,
	// .carousel-inner .active.right {
	//   left: 0;
	//   -webkit-transform: translate3d(0, 0, 0);
	//   transform: translate3d(0, 0, 0);
	// }
	// .carousel-control {
	//   z-index: 2;
	// }
	// .carousel,
	// .carousel-inner,
	// .carousel-inner .item {
	//   height: 100%;
	// }


	/*** Error Page ***/

	&.error-page .error {
	  height: 100vh;
	  background-image: url("../../../_assets/media/images/new-landing/backgrounds/404.jpg");
	  background-size: cover;
	  background-position: center center;
	  padding: 0;
	}

	&.error-page.server-error-page .error {
	  background-image: url("../../../_assets/media/images/new-landing/backgrounds/503.jpg");
	}

	&.error-page .error .logo {
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	}

	&.error-page .logo img {
	  margin: 0 auto;
	  padding-top: 30px;
	}

	&.error-page .error>div {
	  display: table;
	  position: absolute;
	  height: 100vh;
	  width: 100%;
	  left: 0;
	  background: rgba(17, 17, 17, .9);
	}

	&.error-page .error>div>div {
	  display: table-cell;
	  vertical-align: middle;
	}

	&.error-page .error h3 {
	  font-size: 36px;
	  line-height: 36px;
	  color: #fff;
	  text-transform: uppercase;
	  letter-spacing: 1px;
	}

	&.error-page .error .big-404 {
	  font-size: 220px;
	  line-height: 220px;
	  color: #999;
	  font-weight: 300;
	  margin-top: 70px;
	}

	&.error-page .error p {
	  color: #999;
	  font-size: 19px;
	  padding: 20px 0;
	  text-transform: uppercase;
	  margin: 0 auto;
	  max-width: 530px;
	}

	&.error-page .error .custom-button:before {
	  content: "\f015";
	}

	&.error-page .error .custom-button.refresh:before {
	  content: "\f021";
	}


	/*** Coming Soon Page ***/

	&.error-page.coming-soon .error {
	  background-image: url("../../../_assets/media/images/new-landing/backgrounds/coming-soon.jpg");
	}

	&.error-page.coming-soon .coming-soon-title {
	  margin-top: 70px;
	}

	.countdown {
	  margin: 10px 0 40px;
	}

	.countdown-section {
	  display: inline-block;
	  font-size: 17px;
	  line-height: 1;
	  text-align: center;
	  color: #888;
	  text-transform: capitalize;
	  padding: 0 20px;
	}

	.countdown-amount {
	  display: block;
	  font-size: 45px;
	  margin-bottom: 15px;
	}


	/*** FAQ Panels ***/

	.faq {
	  background: #fff;
	}

	.faq .container {
	  padding: 100px 15px;
	}

	.panel-group {
	  margin-bottom: 80px;
	}

	.panel-default>.panel-heading {
	  background: none;
	  border-radius: 0;
	  position: relative;
	  padding: 15px;
	}

	.panel-group .panel {
	  border-radius: 0;
	  margin-bottom: -6px;
	}

	.panel-group .panel:first-child {
	  border-top-left-radius: 7px;
	  border-top-right-radius: 7px;
	}

	.panel-group .panel:last-child {
	  border-bottom-left-radius: 7px;
	  border-bottom-right-radius: 7px;
	}

	.panel.panel-default {
	  background: transparent;
	  border-color: #ddd;
	  box-shadow: none;
	}

	.panel-default>.panel-heading+.panel-collapse>.panel-body {
	  border-top-color: #ddd;
	  line-height: 30px;
	}

	h4.panel-title {
	  font-size: 17px;
	  background: none;
	  border: 0;
	  padding: 8px 0 8px 30px;
	  line-height: normal;
	  border-radius: 0;
	  text-transform: uppercase;
	}

	h4.panel-title a.collapsed {
	  color: #555;
	}

	h4.panel-title span {
	  float: right;
	}

	h4.panel-title a:before,
	h4.panel-title a.collapsed:before {
	  font-family: "FontAwesome";
	  position: absolute;
	  z-index: 0;
	  font-size: 12px;
	  left: 15px;
	  padding: 0 5px;
	  text-align: center;
	  top: 50%;
	  margin-top: -7px;
	}

	h4.panel-title a:before {
	  content: "\f106";
	}

	h4.panel-title a.collapsed:before {
	  content: "\f107";
	}

	.panel-body ul {
	  padding-left: 25px;
	}


	/*** Terms of Services ***/

	.terms {
	  background: #fff;
	}

	.terms p {
	  margin-bottom: 45px;
	}


	/*** Shopping Cart ***/

	.shop-cart {
	  background: #fff;
	}

	.shop-cart .table-responsive .table.order {
	  margin-bottom: 35px;
	}

	.shop-cart table.order thead tr {
	  background: #efefef;
	  color: #555;
	  text-transform: uppercase;
	}

	.shop-cart .table thead>tr>th {
	  padding: 20px 0;
	  font-size: 19px;
	}

	.shop-cart .table-responsive .table.order tr td {
	  vertical-align: middle;
	  padding-top: 29px;
	  padding-bottom: 29px;
	  padding-left: 0;
	}

	.shop-cart .table h6 {
	  font-size: 18px;
	  color: #555;
	}

	.shop-cart .table .icon-delete-product {
	  color: #555;
	}

	.shop-cart .table td span.price {
	  font-weight: 700;
	  color: #555;
	}

	.shop-cart .row-buttons {
	  margin-left: 15px;
	  margin-right: 15px;
	}

	.shop-cart .form-control {
	  max-width: 135px;
	  display: inline-block;
	  text-align: center;
	}

	.shop-cart .btn.btn-coupon,
	.shop-cart .btn.btn-update-cart {
	  background: transparent;
	  color: #999;
	  border: 1px solid #999;
	}

	.shop-cart .btn.btn-primary.btn-coupon:hover,
	.shop-cart .btn.btn-primary.btn-coupon:focus,
	.shop-cart .btn.btn-primary.btn-coupon:active,
	.shop-cart .btn.btn-update-cart:hover,
	.shop-cart .btn.btn-update-cart:focus,
	.shop-cart .btn.btn-update-cart:active {
	  background: transparent !important;
	}

	.shop-cart .form-control,
	.shop-cart button {
	  font-size: 18px;
	  float: left;
	  margin-top: 0;
	}

	.shop-cart .form-control {
	  border-radius: 30px;
	  height: 46px !important;
	  margin-right: 15px;
	}

	.shop-cart .secondary-button {
	  background: transparent;
	  border-radius: 30px;
	  height: 46px;
	  padding: 0 30px;
	  transition: .2s;
	  text-transform: uppercase;
	  font-weight: 600;
	  outline: none !important;
	}

	.shop-cart .secondary-button:hover,
	.shop-cart .secondary-button:focus,
	.shop-cart .secondary-button:active {
	  color: #fff;
	}

	.shop-cart .secondary-button.update-cart {
	  float: right;
	}

	.shop-cart .checkout-button,
	.shop-checkout .checkout-button {
	  float: right;
	  margin-top: 0;
	}

	.shop-cart .checkout-button.custom-button:before,
	.shop-checkout .checkout-button.custom-button:before {
	  content: "\f07a";
	}

	.shop-cart h4.title-totals {
	  color: #555;
	  text-transform: uppercase;
	  margin: 70px 0 20px;
	  font-weight: 600;
	}

	.shop-cart .table.cart-total {
	  margin-top: 20px;
	}

	.shop-cart .table.cart-total .section-border {
	  border-bottom: 1px solid #eee;
	}

	.shop-cart .table.cart-total th {
	  font-weight: 400;
	  text-transform: capitalize;
	  border: none;
	}

	.shop-cart .table.cart-total th.total .price {
	  font-weight: 800;
	  font-size: 20px;
	  color: #333;
	}

	.shop-cart .price {
	  color: #333;
	}

	.shop-cart .table,
	.shop-checkout .table {
	  border: 0;
	}

	.shop-cart .table thead>tr>th,
	.shop-checkout .table thead>tr>th {
	  border-bottom: none;
	}

	.shop-cart .table thead>tr>th,
	.shop-checkout .table thead>tr>th,
	.shop-cart .table tbody>tr>th,
	.shop-cart .table tfoot>tr>th,
	.shop-cart .table thead>tr>td,
	.shop-cart .table tbody>tr>td,
	.shop-cart .table tfoot>tr>td,
	.shop-checkout .table tbody>tr>th,
	.shop-checkout .table tfoot>tr>th,
	.shop-checkout .table thead>tr>td,
	.shop-checkout .table tbody>tr>td,
	.shop-checkout .table tfoot>tr>td {
	  border: 0;
	}

	.shop-cart .table tbody>tr,
	.shop-checkout .table tbody>tr {
	  border-bottom: 1px solid #eee;
	}

	.shop-cart .quantity {
	  text-align: center;
	  font-size: 12px;
	  background: #efefef;
	  padding-top: 11px;
	  padding-bottom: 11px;
	  width: 82px;
	  height: auto;
	  display: inline-block;
	}

	.shop-cart .qty {
	  text-align: center;
	  width: 26px;
	}

	.shop-cart .qty,
	.shop-cart input.qtyplus,
	.shop-cart input.qtyminus {
	  background: #efefef;
	  border: 0;
	  outline: none;
	}

	.shop-cart input.qtyplus,
	.shop-cart input.qtyminus {
	  font-size: 19px;
	}


	/* Checkout Page */

	.shop-checkout {
	  background: #fff;
	}

	.shop-checkout h3 {
	  color: #555;
	  text-transform: uppercase;
	  margin: 45px 0 25px;
	}

	.shop-checkout .form-group {
	  margin-bottom: 30px;
	}

	.shop-checkout textarea {
	  height: 150px;
	  padding: 15px 20px;
	}

	.shop-checkout select {
	  height: 42px;
	  box-shadow: none;
	  outline: none;
	  border: 1px solid #ddd;
	  background: #f2f2f2;
	  text-transform: uppercase;
	  font-size: 18px;
	  color: #999;
	}

	.shop-checkout .form-group select.form-control:focus {
	  outline: none !important;
	  box-shadow: none;
	  border: 1px solid #ddd;
	}

	.shop-checkout .checkout table.products thead tr {
	  background: #efefef;
	}

	.shop-checkout .checkout table.products th.with-bg {
	  background: #efefef;
	}

	.shop-checkout .checkout table.products td.text-price {
	  color: #555;
	}

	.shop-checkout .checkout table.products td.text-price.big-price {
	  font-size: 20px;
	  font-weight: 800;
	}

	.products>thead>tr>th,
	.products>thead>tr>td,
	.products>tbody>tr>th,
	.products>tbody>tr>td,
	.products>tfoot>tr>th,
	.products>tfoot>tr>td {
	  padding-top: 20px;
	  padding-bottom: 20px;
	  padding-left: 15px;
	  padding-right: 15px;
	  line-height: 1.66667;
	  vertical-align: middle;
	  border-top: 1px solid;
	}

	.shop-checkout .checkout .payment .cheque,
	.shop-checkout .checkout .payment .paypal {
	  background: #efefef;
	  padding: 30px;
	  margin-bottom: 30px;
	}

	.shop-checkout .checkout .payment .cheque {
	  margin-bottom: 1px;
	}

	.shop-checkout .checkout .payment .radio {
	  display: inline-block;
	  margin-right: 15px;
	}

	.shop-checkout .checkout .payment .payment-cards {
	  margin-top: 10px;
	  padding-bottom: 15px;
	}

	.shop-checkout .checkout .payment .payment-cards img {
	  margin: 0 5px 5px 0;
	}

	.tooltip.top .tooltip-inner {
	  padding: 20px;
	  width: 290px;
	  min-width: 290px;
	  line-height: 19px;
	  text-align: left;
	}

	.checkbox input[type=checkbox],
	.shop-checkout .checkout .payment input[type=radio] {
	  margin-top: 4px;
	}


	/* Social media */

	.social-icons ul {
	  margin: 0;
	  padding: 0;
	  line-height: 0;
	  font-size: 0;
	}

	.social-icons ul li {
	  display: inline-block;
	  margin: 0 15px 15px 0;
	  padding: 0;
	  vertical-align: top;
	  list-style: none;
	}

	.social-icons ul li a {
	  background: transparent;
	  display: block;
	  width: 63px;
	  height: 63px;
	  line-height: 59px;
	  border-radius: 50%;
	  -webkit-transition: 0.8s;
	  transition: 0.8s;
	  text-align: center;
	  text-decoration: none;
	  color: #fff !important;
	}

	.social-icons ul li a:hover {
	  -webkit-transition: 0.4s;
	  transition: 0.4s;
	  color: #fff !important;
	}

	.social-icons ul li a:hover:before {
	  transform: rotateY(360deg);
	  -webkit-transform: rotateY(360deg);
	  -webkit-transition: 0.4s;
	  transition: 0.4s;
	  color: #fff !important;
	}

	.social-icons ul li a:before {
	  display: block;
	  -webkit-transition: 0.8s;
	  transition: 0.8s;
	  font-family: FontAwesome;
	  font-size: 30px;
	  text-align: center;
	}

	.social-icons ul li a[title="facebook"]:before {
	  content: "\f09a";
	}

	.social-icons ul li a[title="twitter"]:before {
	  content: "\f099";
	}

	.social-icons ul li a[title="google"]:before {
	  content: "\f0d5";
	}

	.social-icons ul li a[title="skype"]:before {
	  content: "\f17e";
	}

	.social-icons ul li a[title="instagram"]:before {
	  content: "\f16d";
	}

	.social-icons ul li a[title="linkedin"]:before {
	  content: "\f0e1";
	}

	.social-icons ul li a[title="instagram"]:before {
	  content: "\f16d";
	}

	.social-icons ul li a[title="youtube"]:before {
	  content: "\f167";
	}


	/* Back To Top
	---------------------------------------- */

	#back-top {
	  display: none;
	  margin: 0;
	}

	#back-top-wrapper {
	  position: fixed;
	  right: 26px;
	  bottom: 26px;
	  overflow: hidden;
	  z-index: 1111;
	}

	#back-top a {
	  display: flex;
	  background-color: transparent;
	  text-align: center;
	  text-decoration: none;
	  -webkit-transition: 1s;
	  transition: 1s;
	  width: 45px;
	  height: 45px;
	  margin: 0 auto;
	  border-radius: 50%;
	  justify-content: center;
	  align-items: center;
	}

	#back-top a:hover span:before {
	  top: -50px;
	}

	#back-top a:hover span:after {
	  top: 0;
	}

	#back-top a:hover span {
	  background: none;
	}

	#back-top span {
	  display: block;
	  -webkit-transition: 1s;
	  transition: 1s;
	  z-index: 1;
	  margin-bottom: 0;
	  width: 41px;
	  height: 41px;
	  background: none;
	  position: relative;
	  overflow: hidden;
	}

	#back-top span:before,
	#back-top span:after {
	  content: "\f062";
	  font-size: 19px;
	  font-family: FontAwesome;
	  line-height: 41px;
	  position: absolute;
	  left: 0;
	  width: 41px;
	  text-align: center;
	  -webkit-transition: 0.5s;
	  transition: 0.5s;
	}

	#back-top span:before {
	  top: 0;
	}

	#back-top span:after {
	  top: 50px;
	  color: #fff;
	}


	/* Inputs */

	.input {
	  position: relative;
	  z-index: 1;
	  display: inline-block;
	}

	.input-field {
	  position: relative;
	  display: block;
	  float: right;
	  padding: 0.8em;
	  width: 60%;
	  border: none;
	  border-radius: 0;
	  background: #f0f0f0;
	  color: #aaa;
	  -webkit-appearance: none;
	}

	.input-field:focus {
	  outline: none;
	}

	.input-label {
	  display: inline-block;
	  float: right;
	  padding: 0 1em;
	  width: 40%;
	  color: #6a7989;
	  font-weight: bold;
	  font-size: 70.25%;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  -webkit-touch-callout: none;
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	}

	.custom-input {
	  overflow: hidden;
	  border-radius: 30px;
	  background: #fff;
	  width: 100%;
	}

	.custom-input-field {
	  padding-left: 51px;
	  width: 100%;
	  background: transparent;
	  -webkit-transform: translate3d(1em, 0, 0);
	  transform: translate3d(1em, 0, 0);
	  -webkit-transition: -webkit-transform 0.3s;
	  transition: -webkit-transform 0.3s;
	  transition: transform 0.3s;
	  transition: transform 0.3s, -webkit-transform 0.3s;
	  height: 50px;
	  border-radius: 4px;
	}

	.custom-input-field:focus {
	  outline: 0px !important;
	  -webkit-appearance: none;
	}

	.custom-input-field:focus .custom-input {
	  background: blue;
	}

	.custom-input-label {
	  position: absolute;
	  padding: 14px;
	  width: 52px;
	  height: 50px;
	}

	.contactform textarea {
	  color: #aaa;
	  border-radius: 30px;
	  box-shadow: none;
	  -webkit-transition: 0.3s;
	  transition: 0.3s;
	}

	.custom-input-label::before {
	  content: "";
	  position: absolute;
	  top: 0;
	  left: 0;
	  z-index: -1;
	  width: 50px;
	  height: 50px;
	  -webkit-transform-origin: 0% 50%;
	  transform-origin: 0% 50%;
	  -webkit-transition: -webkit-transform 0.3s;
	  transition: -webkit-transform 0.3s;
	  transition: transform 0.3s;
	  transition: transform 0.3s, -webkit-transform 0.3s;
	}

	.icon-field {
	  color: #fff;
	  -webkit-transform: scale3d(1, 1, 1);
	  transform: scale3d(1, 1, 1);
	  -webkit-transform-origin: 0% 50%;
	  transform-origin: 0% 50%;
	  -webkit-transition: -webkit-transform 0.3s;
	  transition: -webkit-transform 0.3s;
	  transition: transform 0.3s;
	  transition: transform 0.3s, -webkit-transform 0.3s;
	  margin: 0 auto;
	  display: block;
	  text-align: center;
	  font-size: 20px;
	  margin: 0;
	}

	.custom-input-field:focus {
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}

	.custom-input-field:focus+.custom-input-label::before {
	  -webkit-transform: scale3d(0.8, 1, 1);
	  transform: scale3d(0.8, 1, 1);
	}

	.custom-input-field:focus+.custom-input-label .icon-field {
	  -webkit-transform: scale3d(0.6, 0.6, 1);
	  transform: scale3d(0.6, 0.6, 1);
	}


	/* [ FOOTER ] */


	/*================================================== */

	.footer {
	  background: #111;
	}

	.footer .container {
	  padding: 80px 15px 0;
	}

	.footer .top-footer>.row {
	  padding-bottom: 30px;
	}

	.footer .top-footer h4 {
	  text-transform: uppercase;
	  margin-top: 0;
	  font-size: 18px;
	  margin-bottom: 30px;
	  font-weight: 700;
	}


	/*** Menu ***/

	.footer .top-footer ul {
	  padding: 0;
	}

	.footer .top-footer .menu ul {
	  list-style-type: none;
	}

	.footer .top-footer .menu ul li {
	  margin-bottom: 17px;
	}

	.footer .top-footer .menu ul li a,
	.footer .top-footer .menu ul li span {
	  text-transform: uppercase;
	  color: #fff;
	  font-size: 18px;
	  font-weight: 400;
	  transition: .1s;
	}

	.footer .top-footer .menu ul li span {
	  color: #ddd;
	}

	.footer .top-footer .menu ul li a:hover {
	  color: #fff;
	}

	.footer .top-footer .menu ul li span i {
	  padding-right: 3px;
	}

	.top-footer .contacts>div {
	  padding-bottom: 12px;
	  font-size: 18px;
	  font-weight: 600;
	  color: #fff;
	  text-transform: uppercase;
	}


	/*** Social Icons ***/

	.footer .social-icons {
	  padding-top: 10px;
	}

	.footer .social-icons ul li {
	  margin: 0 10px 15px 0;
	}

	.footer .social-icons ul li a {
	  width: 40px;
	  height: 40px;
	  line-height: 38px;
	}

	.footer .social-icons ul li a:before {
	  font-size: 19px;
	}

	.facts-footer>div {
	  width: 49%;
	  display: inline-block;
	}

	.facts-footer>div:first-child h5,
	.facts-footer>div:nth-child(2) h5 {
	  margin-top: 0;
	}

	.facts-footer>div {
	  margin-bottom: 18px;
	}

	.facts-footer>div h5 {
	  font-size: 30px;
	  font-weight: 500;
	  margin: 15px 0 12px;
	}

	.facts-footer>div span {
	  text-transform: uppercase;
	  font-size: 12px;
	  color: #ddd;
	}


	/*** Copyright Bar ***/

	.footer .bottom-footer p {
	  margin: 0;
	  font-size: 18px;
	  border-top: 1px solid #222;
	  padding: 17px 0;
	  color: #ddd;
	}

	.footer .bottom-footer p a:hover {
	  color: #fff;
	}

	.footer hr {
	  background-color: #1d1d1d;
	  border: 0;
	  height: 1px;
	  margin: 6px 0 17px;
	}


	/* Responsive Design Styles
	---------------------------------------- */

	@media (min-width: 1200px) {
	  ul.navbar-nav>li.visible-md.visible-lg {
	    display: inline-block !important;
	  }
	}

	.homeBox {
		position: relative;
		background-position: center;
		background-size: cover;
		padding-top: 20px;
		padding-bottom: 40px;

		.logoBox {
			padding-top: 120px;
			text-align: center;

			img {
				max-width: 240px;
			}
		}

		.textBox {
			font-size: 10px;
			text-align: center;
			color: #fff;
			padding: 25px 0;
		}

		.subText {
			display: none;
		}

		.btnBox {
			text-align: center;
		}

		.imgsBox {
			padding-top: 20px;
			padding-bottom: 15px;
			display: flex;
			justify-content: center;
			padding-left: 0;
			padding-right: 0;

			img {
				width: 100%;
				object-fit: contain;
			}
		}
	}

	@media screen and (min-width: 992px) {
		.navbar-toggler {
		  display: none;
		}

		ul.navbar-nav li a.lastLink {
   		 padding-right: 40px;
   	 }

		.homeBox {
			position: relative;
			background-position: center;
			background-size: cover;
			padding-top: 120px ;

			padding-bottom: 40px;

			.logoBox {
				padding-top: 120px;
				text-align: center;

				img {
					max-width: 300px;
				}
			}

			.textBox {
				z-index: 12;
				white-space: nowrap;
				letter-spacing: 2px;
				font-weight: 500;
				visibility: inherit;
				transition: none 0s ease 0s;
				text-align: inherit;
				line-height: 20px;
				border-width: 0px;
				margin: 0px;
				padding: 0px;
				font-size: 19px;
				min-height: 0px;
				min-width: 0px;
				max-height: none;
				max-width: none;
				opacity: 1;
				transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
				transform-origin: 50% 50% 0px;
				text-align: center;
				padding-top: 50px;
				padding-bottom: 20px;

				div {
					display: inline-block;
					text-align: left;

					min-width: 518px;
					max-width: 518px;
				}
			}

			.subText {
				z-index: 13;
				white-space: normal;
				visibility: inherit;
				transition: none 0s ease 0s;
				text-align: inherit;
				line-height: 24px;
				border-width: 0px;
				margin: 0px;
				padding: 0px;
				letter-spacing: 0px;
				font-weight: 600;
				font-size: 18px;
				min-height: 0px;
				max-height: none;
				opacity: 1;
				transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
				transform-origin: 50% 50% 0px;
				display: block;
				text-align: center;
				padding-bottom: 20px;

				div {
					min-width: 518px;
					max-width: 518px;
					display: inline-block;
					text-align: left;
				}
			}

			.btnBox {
				text-align: center;
			}

			.imgsBox {
				padding-top: 20px;
				padding-bottom: 15px;
				display: flex;
				justify-content: center;
				padding-left: 0;
				padding-right: 0;

				img {
					width: 100%;
					object-fit: contain;
				}
			}
		}
	}

	@media screen and (max-width: 991px) {
		.header {
			background: #161A2C;
		}

		.LandingIndex-container .header .logo a {
			margin-top: 0;
		}
	}

	@media (max-width: 1200px) and (min-width: 992px) {
		&.double-diagonal .about .container {
	    padding: 50px 15px 90px !important;
	}
	&.double-diagonal .projectmanager .section-overlay {
	    padding: 110px 0 140px;
	}
	&.double-diagonal .team .container {
	    padding: 50px 15px 130px !important;
	}
	&.double-diagonal .services .container {
	    padding: 50px 15px 90px !important;
	}
	&.double-diagonal .newsletter .section-overlay, &.double-diagonal .call-to-action .section-overlay {
	    padding: 110px 0 160px;
	}
	}

	@media (min-width:991px) {
	  ul.nav li.dropdown:hover ul.dropdown-menu {
	    display: block;
	  }
	}

	@media (min-width: 768px) and (max-width: 991px) {
	  .navbar-collapse.collapse {
	    display: none !important;
	  }
	  .navbar-collapse.collapse.in {
	    display: block !important;
	  }
	  .navbar-header .collapse,
	  .navbar-toggle {
	    display: block !important;
	  }
	  .navbar-header {
	    float: none;
	  }
	  .top-footer>.row>div:nth-child(4) {
	    margin-top: 25px;
	  }
	  .shop-checkout .table {
	    margin-bottom: 30px;
	  }
	}


	/* Large Devices */

	@media (max-width: 1199px) {
	  .newsletter .custom-form-group {
	    padding: 0;
	  }
	  .projectmanager {
	    text-align: center;
	  }
	  .projectmanager .img-responsive {
	    float: none;
	    margin: 0 auto 50px;
	  }
	}

	@media (max-width: 1199px) and (min-width:992px) {
	  ul.nav.nav-tabs li a,
	  ul.nav.nav-tabs li.active a {
	    padding: 0 15px;
	  }
	  .sidebar ul.nav.nav-tabs li a {
	    padding-left: 0;
	  }
	  .about ul.nav.nav-tabs li:first-child>a {
	    padding-left: 0;
	  }
	  .pricing-footer .custom-button:before {
	    right: 56px;
	  }
	}


	/* Small Devices */

	@media (max-width: 991px) {
	  .navbar-nav .open .dropdown-menu {
	    position: static;
	    float: none;
	    width: auto;
	    margin-top: 0;
	    background-color: transparent;
	    border: 0;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	  }
	  ul.navbar-nav {
	    padding-bottom: 62px;
	  }
	  ul.navbar-nav li.dropdown ul.dropdown-menu li a,
	  .header-fixed ul.navbar-nav li.dropdown ul.dropdown-menu li a {
	    padding: 10px 20px 10px 40px;
	  }
	  &.dark .navbar-collapse,
	  &.dark .navbar-collapse.in {
	    background: #222;
	    border-bottom: 1px solid #343434;
	  }
	  &.dark ul.navbar-nav>li>a,
	  &.dark .header-fixed ul.navbar-nav>li>a {
	    border-top: 1px solid #343434;
	  }
	  .navbar-collapse.in {
	    overflow-x: hidden;
	    overflow-y: auto;
	    padding-right: 17px;
	  }
	  ul.navbar-nav>li.open>a,
	  ul.navbar-nav>li.open>a:hover,
	  ul.navbar-nav>li.open>a:focus,
	  ul.navbar-nav>li.open>a:active {
	    border: 1px solid #f6f6f6;
	    border-left: 0;
	    border-right: 0;
	  }
	  &.dark ul.navbar-nav>li.open>a,
	  &.dark ul.navbar-nav>li.open>a:hover,
	  &.dark ul.navbar-nav>li.open>a:focus,
	  &.dark ul.navbar-nav>li.open>a:active {
	    border-top: 1px solid #343434;
	    border-bottom: 1px solid #343434;
	    border-left: 0;
	    border-right: 0;
	  }
	  .pagination {
	    margin-bottom: 80px;
	  }
	  .blog-left-sidebar .pagination,
	  .blog-grid-no-sidebar .pagination {
	    margin-bottom: 20px;
	  }
	  .project .project-details h3 {
	    margin-top: 50px;
	  }
	  .services .services-box-item-cover {
	    display: none;
	  }
	  .services .services-box {
	    margin: 30px -9px;
	  }
	  .services .services-box-item-cover,
	  .services .services-box-item-content {
	    transform: none;
	    transition: none;
	  }
	  .services .services-box-item {
	    margin: 28px 0;
	  }
	  .projectmanager {
	    text-align: center;
	  }
	  .projectmanager .img-responsive {
	    float: none;
	    margin: 0 auto 50px;
	  }
	  .about .about-content .title-about {
	    margin-top: 0;
	  }
	  .about-right-side {
	    padding-top: 50px;
	  }
	  .team-member {
	    margin-bottom: 30px;
	  }
	  .newsletter form .custom-button {
	    position: relative;
	    margin-top: 20px;
	    width: 100%;
	  }
	  .newsletter form .form-group input {
	    padding-right: 0;
	  }
	  .submit-form button:before {
	    right: 260px;
	  }
	  .custom-button:before {
	    display: none !important;
	  }
	  .custom-button span {
	    transform: none !important;
	  }
	  .header-inner {
	    padding-left: 15px;
	    padding-right: 15px;
	  }


	  .header {
	    height: 65px;
	  }
	  .header .logo a {
	    margin: 23px 0 0 0;
	    width: 100px;
	    transition: all 0.3s ease 0s;
	    -o-transition: all 0.3s ease 0s;
	    -moz-transition: all 0.3s ease 0s;
	    -webkit-transition: all 0.3s ease 0s;
	  }
	  ul.nav.nav-tabs li a,
	  ul.nav.nav-tabs li.active a {
	    padding: 0 20px;
	  }
	  .sidebar ul.nav.nav-tabs li a {
	    padding: 0;
	  }
	  ul.nav.nav-tabs li:first-child a {
	    padding-left: 0;
	  }
	  ul.navbar-nav {
	    float: none;
	    margin: 0;
	  }
	  ul.navbar-nav>li {
	    display: block;
	  }
	  ul.navbar-nav>li>a,
	  .header-fixed ul.navbar-nav>li>a {
	    padding: 20px 18px;
	    border-top: 1px solid #f6f6f6;
	    text-align: left;
	    color: #555;
	  }
	  .navbar-nav .open .dropdown-menu>li>a {
	    line-height: 30px;
	    border-bottom: 0;
	  }
	  .navbar-collapse,
	  .navbar-collapse.in {
	    position: fixed;
	    top: 64px;
	    left: 0;
	    float: none;
	    margin: 0;
	    padding: 0;
	    width: 100%;
	    background: #fff;
	    overflow-y: auto;
	    z-index: 999;
	    border-bottom: 1px solid #f6f6f6;
	    border-top: 0;
	  }
	  #back-top-wrapper {
	    right: 15px;
	    bottom: 15px;
	  }
	  .footer .top-footer>.row {
	    padding-bottom: 15px;
	  }
	}


	/* Extra Small Devices */

	@media (max-width: 767px) {
	  .container {
	    width: 570px;
	  }
	  .banner .content {
	    padding: 150px 0;
	  }
	  .services .services-box-item-content {
	    margin-bottom: 15px;
	  }
	  .about .container,
	  .services .container,
	  .team .container,
	  &.big-triangle .team .container,
	  .blog .container,
	  .contact .container {
	    padding-left: 15px !important;
	    padding-right: 15px !important;
	  }
	  &.double-diagonal .about .container {
		  padding: 60px 15px 100px !important;
	  }
	  &.double-diagonal.about-page .about .container {
		  padding: 35px 15px 100px !important;
	  }
	  &.double-diagonal .services .container {
		  padding: 60px 15px 45px !important;
	  }
	  &.double-diagonal .portfolio .container {
		 padding: 60px 15px 85px !important;
	  }
	  &.double-diagonal .section-overlay {
		  padding: 75px 15px 150px !important;
	  }
	  &.double-diagonal .facts .section-overlay {
		  padding: 75px 15px 100px !important;
	  }
	  &.double-diagonal .projectmanager .section-overlay {
		  padding: 90px 15px 100px !important;
	  }
	  &.double-diagonal .contactform .section-overlay {
		  padding: 75px 0 100px !important;
	  }
	  &.double-diagonal .team .container {
	    padding: 60px 15px 85px !important;
	}
	&.double-diagonal.portfolio-page .portfolio .container {
		    padding: 40px 15px !important;
	}
	  &.double-diagonal .pricing .container {
		 padding: 60px 15px 100px !important;
	  }
	  &.double-diagonal.pricing-page .pricing .container {
	    padding: 70px 15px 20px !important;
	}
	  &.double-diagonal .blog .container {
		      padding: 60px 15px 15px !important;
	  }
	  &.double-diagonal.blog-page .blog .container {
		  padding: 75px 15px 50px !important;
	  }
	  &.double-diagonal .footer .container {
		      padding: 100px 15px 0;
	  }
	  &.double-diagonal .shop-cart .container, &.double-diagonal .shop-checkout .container {
		  padding: 85px 15px 60px !important;
	  }
	  &.double-diagonal .faq .container, &.double-diagonal .terms .container {
	    padding: 85px 15px 50px !important;
	}

	  .header-inner,
	  .videopromotion p,
	  .newsletter p,
	  .logos .container {
	    padding-left: 15px;
	    padding-right: 15px;
	  }
	  h1 {
	    font-size: 40px;
	    line-height: 40px;
	  }
	  h2 {
	    font-size: 32px;
	  }
	  h4 {
	    font-size: 18px;
	  }
	  .pricing-list>li {
	    margin-bottom: 30px;
	  }
	  .top-text h1,
	  .top-text h4,
	  .footer p {
	    margin-left: 15px;
	    margin-right: 15px;
	  }
	  .top-text h4 {
	    line-height: 28px;
	  }
	  &.double-diagonal .about::before,
	  &.double-diagonal .services::before,
	  &.double-diagonal .portfolio::before,
	  &.double-diagonal .team::before,
	  &.double-diagonal .blog::before,
	  &.double-diagonal .contact::before,
	  &.double-diagonal .logos::before,
	  &.double-diagonal .pricing::before,
	  &.double-diagonal .project::before,
	  &.double-diagonal .shop-cart::before,
	  &.double-diagonal .shop-checkout::before,
	  &.double-diagonal .faq::before,
	  &.double-diagonal .terms::before  {
	    -webkit-transform: rotate(-5deg);
	    transform: rotate(-5deg);
	  }
	  .about .about-content .about-left-side {
	    margin-top: 0;
	  }
	  .about-right-side .hovered .full-image-overlay {
	    margin-top: 60px;
	    position: relative;
	  }
	  .list-why-choose-us li {
	    margin-bottom: 17px;
	  }
	  .full-image-container {
	    background-image: url("../../../_assets/media/images/new-landing/about.jpg");
	    background-size: cover;
	  }
	  .comments-list .comment-body {
	    margin-left: 0;
	  }
	  .comments-list .comment-content {
	    margin: 70px 0 15px;
	  }
	  .comments-list .comment-date {
	    float: left !important;
	  }
	  .comments-reply {
	    margin: 0;
	  }
	  .comments-list .comment {
	    margin-bottom: 15px;
	    padding: 15px;
	  }
	  .projectmanager .img-responsive {
	    float: none;
	    margin: 0 auto 50px;
	  }
	  .projectmanager ul,
	  .projectmanager li {
	    margin-top: 15px;
	  }
	  .portfolio .nav-pills li {
	    margin: 0 10px 15px 0;
	  }
	  .mobile-logo {
	    margin: 0 auto;
	    margin-top: 70px;
	  }
	  .user-auth>div:nth-child(2) {
	    height: auto;
	  }
	  .user-auth>div:nth-child(2) .form-container {
	    display: block;
	    padding: 15px;
	    position: relative;
	    height: auto;
	  }
	  .user-auth>div:nth-child(2) .form-container>div {
	    display: block;
	  }
	  .user-auth>div:nth-child(2) .form-container p.info-form {
	    margin: 8px 15px 30px 15px;
	  }
	  .user-auth>div:nth-child(2) .form-container form .form-group {
	    margin-bottom: 15px;
	  }
	  .user-auth>div:nth-child(2) .form-container .form-group p {
	    margin-bottom: 40px;
	  }
	  &.error-page .error {
	    height: auto;
	  }
	  &.error-page .error>div {
	    display: block;
	    position: relative;
	    height: auto;
	  }
	  &.error-page .error>div>div {
	    height: 100vh;
	    margin: 0 auto;
	    display: block;
	    overflow-y: auto;
	    max-width: 490px;
	  }
	  &.error-page .error .big-404 {
	    font-size: 150px;
	    line-height: 150px;
	    margin-top: 0;
	  }
	  &.error-page .error .custom-button {
		  margin-bottom:30px;
	  }
	  &.error-page .error .logo {
	    position: relative;
	    float: none;
	  }
	  &.error-page .logo img {
	    padding-top: 70px;
	    padding-bottom: 35px;
	  }
	  &.error-page .error p,
	  &.error-page h3 {
	    margin-left: 15px;
	    margin-right: 15px;
	  }
	  .latest-posts-content>div {
	    margin-bottom: 30px;
	  }
	  .testimonials #quote-carousel blockquote {
	    padding: 0 15px;
	  }
	  .services .services-box-item {
	    float: none;
	    margin: 0 auto;
	    display: block;
	    margin-top: 25px;
	  }
	  .top-footer>.row>div:first-child,
	  .top-footer>.row>div:nth-child(2) {
	    margin-bottom: 40px;
	  }
	  .services .services-box-item-cover {
	    margin: 0;
	  }
	  .team-member {
	    max-width: 480px;
	    margin: 0 auto;
	    display: block;
	    margin-bottom: 25px;
	  }
	  .newsletter .custom-form-group {
	    padding: 0;
	  }
	  .info-map-gmap {
	    height: 300px;
	  }
	  .info-map-boxes-item {
	    width: 100%;
	    margin-top: 30px;
	  }
	  .info-map-boxes {
	    margin-top: 0px;
	    margin-bottom: 30px;
	  }
	  &.skew .info-map-boxes,
	  &.reversed-skew .info-map-boxes,
	  &.double-diagonal .info-map-boxes {
	    margin-top: 0px;
	    margin-bottom: 100px;
	  }
	  .footer li a {
	    font-size: 23px;
	  }
	  .social-icons ul li a {
	    width: 43px;
	    height: 43px;
	    line-height: 40px;
	  }
	  .social-icons ul li a:before {
	    font-size: 21px;
	  }
	  .submit-form button {
	    width: 100%;
	  }
	  .submit-form button:before {
	    right: 42px;
	  }
	  .output_message_holder {
	    margin-top: 30px;
	  }
	  .footer .top-footer>.row {
	    padding-bottom: 12px;
	  }
	  &.normal .blog .container {
	    padding: 70px 15px !important;
	  }
	  .fact-badges>.row>div {
	    margin-bottom: 80px;
	  }
	  .fact-badges>.row>div:last-child {
	    margin-bottom: 0;
	  }
	  &.skew .blog .container {
	    padding: 90px 15px 100px !important;
	  }
	  &.double-diagonal .section-overlay {
	    padding-bottom: 120px !important;
	  }
	  .shop-cart .table-responsive {
	    border: 0;
	  }
	  .shop-cart .table-responsive {
	    border: 0;
	  }
	  .shop-cart .form-control {
	    max-width: initial;
	    width: 39%;
	  }
	  .shop-cart button {
	    width: calc(61% - 15px);
	    margin: 0;
	  }
	  .shop-cart .update-cart {
	    width: 100%;
	    margin-top: 15px;
	  }
	  .shop-cart .checkout-button {
	    width: 100%;
	    text-align: center;
	  }
	  .shop-cart .table-responsive .table.order colgroup {
	    width: auto;
	  }
	  .shop-cart .table-responsive .table.order colgroup col {
	    padding-left: 5px;
	    padding-right: 5px;
	  }
	  .shop-cart .table-responsive .table.order tr td,
	  .shop-cart .table-responsive .table.order tr th {
	    padding-left: 5px;
	    padding-right: 5px;
	    white-space: normal;
	  }
	  .shop-cart .table-responsive .table.order tr td h6,
	  .shop-cart .table-responsive .table.order tr th h6 {
	    font-size: 12px;
	  }
	  .shop-cart .table-responsive .table.order tr td .price,
	  .shop-cart .table-responsive .table.order tr th .price {
	    font-size: 11px;
	  }
	  .shop-cart .table-responsive .table.order tr td .quantity,
	  .shop-cart .table-responsive .table.order tr th .quantity {
	    padding-top: 4px;
	    padding-bottom: 4px;
	  }
	  .shop-cart .table-responsive .table.order tr th {
	    font-size: 12px;
	  }
	  .shop-checkout .form-group,
	  .shop-checkout .checkout .payment .paypal,
	  .shop-checkout .table,
	  .contact-form .form-group {
	    margin-bottom: 15px;
	  }
	}

	@media (min-width: 576px) and (max-width:767px) {
	  &.skew .portfolio .container,
	  &.double-diagonal .portfolio .container,
	  &.big-triangle .portfolio .container {
	    padding-left: 0 !important;
	    padding-right: 0 !important;
	  }
	}

	@media (max-width: 576px) {
	  footer {
	    padding-left: 0;
	    padding-right: 0;
	  }
	  .portfolio .container,
		&.double-diagonal .portfolio .container,
	&.skew .portfolio .container,
	&.reversed-skew .portfolio .container,
	&.big-triangle .portfolio .container,
	&.normal .portfolio .container 	{
	    padding-left: 15px !important;
	    padding-right: 15px !important;
	  }
	  .portfolio .filtr-item {
	    padding-left: 0;
	    padding-right: 0;
	  }
	  .container {
	    width: 100%;
	  }
	  .about-content ul.nav.nav-tabs {
	    border: 1px solid #ddd;
	    border-radius: 7px;
	  }
	  .about-content ul.nav.nav-tabs li a {
	    margin: 0;
	    padding: 14px 0;
	    border-left: 0;
	  }
	  &.dark .about ul.nav.nav-tabs li>a {
		  border-left: 0;
	  }
	  .about-content ul.nav.nav-tabs li.active a {
	    color: #fff;
	  }
	  .about-content ul.nav.nav-tabs li:first-child a {
	    border-top-left-radius: 7px;
	    border-top-right-radius: 7px;
	  }
	  .about-content ul.nav.nav-tabs li:last-child a {
	    border-bottom-left-radius: 7px;
	    border-bottom-right-radius: 7px;
	  }
	  .nav-tabs>li {
	    float: none;
	    text-align: center;
	    border-bottom: 1px solid #ddd;
	  }
	  &.dark .nav-tabs>li {
	    border-bottom: 1px solid #343434;
	  }
	  &.dark .about-content ul.nav.nav-tabs {
	    border: 1px solid #343434;
	  }
	  .nav-tabs>li:last-child,
	  &.dark .nav-tabs>li:last-child,
	  &.dark .sidebar ul.nav-tabs>li:last-child {
	    border-bottom: 0;
	  }
	  .nav-tabs>li.active {
	    border-bottom: 0;
	  }
	  .about .tab-content p {
	    margin-top: 20px;
	  }
	  .services .services-box-item {
	    margin-top: 15px;
	    padding: 0 15px;
	  }
	  .services .services-box-item-content {
	    margin-bottom: 11px;
	    padding: 15px;
	  }
	  .call-to-action .call-to-action-content ul {
	    margin: 0;
	  }
	  .pricing-list>li {
	    margin-bottom: 15px;
	  }
	  .newsletter .custom-form-group {
	    margin-bottom: 10px;
	  }
	  .newsletter form .custom-button {
	    margin-top: 0;
	  }
	  .call-to-action .call-to-action-content .custom-button {
	    width: 100%;
	  }
	  .top-footer>.row>div {
	    width: 100%;
	  }
	  .top-footer>.row>div:nth-child(3) {
	    margin-bottom: 40px;
	  }
	  &.normal .portfolio .container,
	  &.reversed-skew .portfolio .container {
	    padding-left: 15px !important;
	    padding-right: 15px !important;
	  }
	  .services .services-box {
	    margin: 40px -9px;
	  }
	  .preloader.loading .logopreloader img {
	    width: 120px;
	  }
	  .preloader .loader {
	    width: 50px;
	    height: 50px;
	    background-size: cover;
	    margin: 0px 0px 0 -21px;
	  }
	  .team-members>div {
	    width: 100%;
	  }
	  .team-member,
	  .widget {
	    margin-bottom: 15px;
	  }
	  .widget {
	    padding: 30px 15px 15px;
	  }
	  .blog-left-sidebar .widget-tags {
	    margin-bottom: 30px;
	  }
	  .info-map-boxes-item {
	    padding: 13px 15px;
	    margin-top: 0;
	    margin-bottom: 15px;
	  }
	  .info-map-boxes-item.fa-phone {
		   margin-bottom: 45px;
	  }
	  .info-map-boxes {
	    margin-top: 15px;
	    margin-bottom: 0;
	  }
	  &.skew .info-map-boxes,
	  &.reversed-skew .info-map-boxes,
	  &.double-diagonal .info-map-boxes {
	       margin: 85px 0 130px;
	  }
	}

	.white-l {
	  display: none;
	}

	.dark-l {
	  display: block;
	}

	.dark .dark-l {
	  display: none;
	}

	.dark .white-l {
	  display: block;
	}


	/* =================================================================== */
	/* Green #0FB855
	====================================================================== */
	a,
	h1 > span,
	.projectmanager h3,
	.projectmanager li a,
	.testimonials #quote-carousel h5,
	.services-box-item-content:before,
	.services-box-item-content h2,
	.social-icons ul li a:before,
	.info-map-boxes-item:before,
	.info-map-boxes-item h1,
	.info-map-boxes-item a,
	.services-box-item-cover:before,
	.services-box-item-cover:after,
	#back-top span:before,
	.videopromotion a i,
	.fact-badges h2 span,
	span.heart,
	a.link:hover,
	a.link:focus,
	a.link:active,
	.services .services-box-item-content h2,
	.btn-wrapper a,
	ul.navbar-nav > li.active > a,
	ul.navbar-nav > li > a:hover,
	ul.navbar-nav > li > a:focus,
	ul.navbar-nav > li > a:active,
	.navbar-nav .fa-search:hover,
	ul.navbar-nav > li.open > a,
	ul.navbar-nav > li.open > a:hover,
	ul.navbar-nav > li.open > a:focus,
	ul.navbar-nav > li.open > a:active,
	&.dark ul.navbar-nav > li.open > a,
	&.dark ul.navbar-nav > li.open > a:hover,
	&.dark ul.navbar-nav > li.open > a:focus,
	&.dark ul.navbar-nav > li.open > a:active,
	&.dark .latest-post .post-title a:hover,
	&.dark ul.navbar-nav > li.active > a,
	&.dark ul.navbar-nav > li > a:hover,
	&.dark .navbar-nav .fa-search:hover,
	.portfolio .caption a.title-link:hover h3,
	.latest-post .post-title a:hover,
	.footer .top-footer h4,
	.facts-footer > div h5,
	.footer .bottom-footer p a,
	.footer .top-footer .menu ul li a:hover,
	.about ul.nav.nav-tabs li.active a,
	.banner .breadcrumb>li a:hover,
	&.blog-page .meta span a,
	&.blog-page .meta span i,
	&.blog-page article h4:hover,
	.sidebar ul.nav.nav-tabs li a:hover,
	&.dark .sidebar ul.nav.nav-tabs li a:hover,
	.widget.recent-posts .entry-title a:hover,
	&.dark .widget.recent-posts .entry-title a:hover,
	.comments-list .comment-reply,
	.user-auth > div:nth-child(2) .form-container .form-group a,
	.countdown-amount,
	h4.panel-title a,
	.project .project-navigation nav ul li a,
	.shop-cart .secondary-button,
	.payment .tooltip-text{
	    color: #0FB855;
	}
	.custom-colorRotatingWords,
	.team .social-icons ul.social li a:hover,
	.team .social-icons ul.social li a:hover:before {
		color: #0FB855 !important;
	}
	.latest-post .post-date,
	.shop-cart .secondary-button:hover,
	.shop-cart .secondary-button:focus,
	.shop-cart .secondary-button:active,
	.bx-wrapper .bx-controls-direction a,
	#carousel-testimonials .carousel-indicators li.active,
	&.dark.blog-page .pagination li.active a:hover,
	&.blog-page .pagination li.active a,
	ul.nav li.dropdown ul.dropdown-menu li a:hover,
	ul.nav li.dropdown ul.dropdown-menu li a:focus,
	ul.nav li.dropdown ul.dropdown-menu li a:active,
	ul.nav li.dropdown ul.dropdown-menu li.active a,
	.pricing-list .popular,
	.pricing-switcher .switch,
	.custom-button,
	.portfolio .nav-pills li:hover,
	.portfolio .nav-pills li.active,
	#back-top a:hover,
	.social-icons ul li a:hover,
	.custom-input-label::before,
	.testimonials #quote-carousel .carousel-indicators .active,
	.custom-form-group  .input-group .input-group-addon,
	.submit-form button,
	&.double-diagonal .about::before,
	&.double-diagonal .faq::before,
	&.double-diagonal .terms::before,
	&.double-diagonal .shop-cart::before,
	&.double-diagonal .shop-checkout::before,
	&.double-diagonal .project::before,
	&.double-diagonal .services::before,
	&.double-diagonal .pricing::before,
	&.double-diagonal .portfolio::before,
	&.double-diagonal .team::before,
	&.double-diagonal .blog::before,
	&.double-diagonal .contact::before,
	&.double-diagonal .logos::before,
	.team-member:hover .team-member-caption,
	.btn-wrapper a:hover,
	.widget-tags ul > li a:hover   {
	    background-color: #0FB855;
	}
	&.blog-page .pagination li.active a,
	.widget-tags ul > li a:hover,
	.shop-cart .secondary-button {
		border:1px solid #0FB855;
	}
	.about-right-side .full-image-container.hovered:before  {
		border-left: 5px solid #0FB855;
	}
	.projectmanager blockquote,
	#quote-carousel .carousel-indicators .active {
	    border-color: #0FB855;
	}
	.projectmanagerpicture {
	    border: 12px solid #0FB855;
	}
	.portfolio .nav-pills li {
	    border: 2px solid #0FB855;
	}
	#back-top a {
	    border: 2px solid #0FB855;
	}

	#back-top a:hover svg {
	    color: #fff;
	}

	.hrVerde {
		width: 80px;
		text-align: center;
		border-top: 5px solid #0FB855;
		margin: 30px auto;
		opacity: 1;
	}
	#back-top a svg {
	    margin: 0;
	}
	.team-member-caption {
		border-top: 3px solid #0FB855;
	}
	.social-icons ul li a{
	    border: 3px solid #0FB855;
	}
	.footer .social-icons ul li a {
	    border: 2px solid #0FB855;
	}
	.banner hr, &.dark .banner hr {
		border-top: 5px solid #0FB855;
	}
	.preloader .loader {
	  background-image: url("../../../_assets/media/images/new-landing/preloaders/preloader-green-light.svg");
	  background-repeat:no-repeat;
	  background-position: 50% 50%;
	}
	&.dark .preloader .loader {
	  background-image: url("../../../_assets/media/images/new-landing/preloaders/preloader-green-dark.svg");
	  background-repeat:no-repeat;
	  background-position: 50% 50%;
	}
	.btn-wrapper a {
		border: 4px solid #0FB855;
	}
	@media (max-width: 991px) {
		ul.nav li.dropdown ul.dropdown-menu li.active a,
		&.dark ul.nav li.dropdown ul.dropdown-menu li.active a	{
			background:transparent !important;
			color:#0FB855 !important;
		}

		ul.navbar-nav > li {
			padding: 20px !important;
		}
	}
	@media (max-width:767px){
		.social-icons ul li a {
			border: 2px solid #0FB855;
		}
		.about-right-side .full-image-container.hovered:before  {
			border-top: 5px solid #0FB855;
			border-left:none;
		}
		.rtl-version .about-right-side .full-image-container.hovered:before {
			border-right:none;
		}
	}
	@media (max-width:576px){
		.about-content ul.nav.nav-tabs li.active a {
			background :#0FB855;
			color:#fff;
		}
	}



	/*-----------------------------------------------------------------------------

	-	Revolution Slider 5.0 Default Style Settings -

	Screen Stylesheet

	version:   	5.4.5
	date:      	15/05/17
	author:		themepunch
	email:     	info@themepunch.com
	website:   	http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	#debungcontrolls,
.debugtimeline {
    width: 100%;
    box-sizing: border-box;
}
.rev_column,
.rev_column .tp-parallax-wrap,
.tp-svg-layer svg {
    vertical-align: top;
}
#debungcontrolls {
    z-index: 100000;
    position: fixed;
    bottom: 0;
    height: auto;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
}
.debugtimeline {
    height: 10px;
    position: relative;
    margin-bottom: 3px;
    display: none;
    white-space: nowrap;
}
.debugtimeline:hover {
    height: 15px;
}
.the_timeline_tester {
    background: #e74c3c;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
}
.rs-go-fullscreen {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 9999999 !important;
    background: #fff !important;
}
.debugtimeline.tl_slide .the_timeline_tester {
    background: #f39c12;
}
.debugtimeline.tl_frame .the_timeline_tester {
    background: #3498db;
}
.debugtimline_txt {
    color: #fff;
    font-weight: 400;
    font-size: 7px;
    position: absolute;
    left: 10px;
    top: 0;
    white-space: nowrap;
    line-height: 10px;
}
.rtl {
    direction: rtl;
}
@font-face {
    font-family: revicons;
    src: url(./fonts/revicons/revicons90c6.eot?5510888);
    src: url(./fonts/revicons/revicons90c6.eot?5510888#iefix) format("embedded-opentype"), url(./fonts/revicons/revicons90c6.woff?5510888) format("woff"), url(./fonts/revicons/revicons90c6.ttf?5510888) format("truetype"),
        url(./fonts/revicons/revicons90c6.svg?5510888#revicons) format("svg");
    font-weight: 400;
    font-style: normal;
}
[class*=" revicon-"]:before,
[class^="revicon-"]:before {
    font-family: revicons;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
}
.revicon-search-1:before {
    content: "\e802";
}
.revicon-pencil-1:before {
    content: "\e831";
}
.revicon-picture-1:before {
    content: "\e803";
}
.revicon-cancel:before {
    content: "\e80a";
}
.revicon-info-circled:before {
    content: "\e80f";
}
.revicon-trash:before {
    content: "\e801";
}
.revicon-left-dir:before {
    content: "\e817";
}
.revicon-right-dir:before {
    content: "\e818";
}
.revicon-down-open:before {
    content: "\e83b";
}
.revicon-left-open:before {
    content: "\e819";
}
.revicon-right-open:before {
    content: "\e81a";
}
.revicon-angle-left:before {
    content: "\e820";
}
.revicon-angle-right:before {
    content: "\e81d";
}
.revicon-left-big:before {
    content: "\e81f";
}
.revicon-right-big:before {
    content: "\e81e";
}
.revicon-magic:before {
    content: "\e807";
}
.revicon-picture:before {
    content: "\e800";
}
.revicon-export:before {
    content: "\e80b";
}
.revicon-cog:before {
    content: "\e832";
}
.revicon-login:before {
    content: "\e833";
}
.revicon-logout:before {
    content: "\e834";
}
.revicon-video:before {
    content: "\e805";
}
.revicon-arrow-combo:before {
    content: "\e827";
}
.revicon-left-open-1:before {
    content: "\e82a";
}
.revicon-right-open-1:before {
    content: "\e82b";
}
.revicon-left-open-mini:before {
    content: "\e822";
}
.revicon-right-open-mini:before {
    content: "\e823";
}
.revicon-left-open-big:before {
    content: "\e824";
}
.revicon-right-open-big:before {
    content: "\e825";
}
.revicon-left:before {
    content: "\e836";
}
.revicon-right:before {
    content: "\e826";
}
.revicon-ccw:before {
    content: "\e808";
}
.revicon-arrows-ccw:before {
    content: "\e806";
}
.revicon-palette:before {
    content: "\e829";
}
.revicon-list-add:before {
    content: "\e80c";
}
.revicon-doc:before {
    content: "\e809";
}
.revicon-left-open-outline:before {
    content: "\e82e";
}
.revicon-left-open-2:before {
    content: "\e82c";
}
.revicon-right-open-outline:before {
    content: "\e82f";
}
.revicon-right-open-2:before {
    content: "\e82d";
}
.revicon-equalizer:before {
    content: "\e83a";
}
.revicon-layers-alt:before {
    content: "\e804";
}
.revicon-popup:before {
    content: "\e828";
}
.rev_slider_wrapper {
    position: relative;
    z-index: 0;
    width: 100%;
}
.rev_slider {
    position: relative;
    overflow: visible;
}
.entry-content .rev_slider a,
.rev_slider a {
    box-shadow: none;
}
.tp-overflow-hidden {
    overflow: hidden !important;
}
.group_ov_hidden {
    overflow: hidden;
}
.rev_slider img,
.tp-simpleresponsive img {
    max-width: none !important;
    transition: none;
    margin: 0;
    padding: 0;
    border: none;
}
.rev_slider .no-slides-text {
    font-weight: 700;
    text-align: center;
    padding-top: 80px;
}
.rev_slider > ul,
.rev_slider > ul > li,
.rev_slider > ul > li:before,
.rev_slider_wrapper > ul,
.tp-revslider-mainul > li,
.tp-revslider-mainul > li:before,
.tp-simpleresponsive > ul,
.tp-simpleresponsive > ul > li,
.tp-simpleresponsive > ul > li:before {
    list-style: none !important;
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: visible;
    overflow-y: visible;
    background-image: none;
    background-position: 0 0;
    text-indent: 0;
    top: 0;
    left: 0;
}
.rev_slider > ul > li,
.rev_slider > ul > li:before,
.tp-revslider-mainul > li,
.tp-revslider-mainul > li:before,
.tp-simpleresponsive > ul > li,
.tp-simpleresponsive > ul > li:before {
    visibility: hidden;
}
.tp-revslider-mainul,
.tp-revslider-slidesli {
    padding: 0 !important;
    margin: 0 !important;
    list-style: none !important;
}
.fullscreen-container,
.fullwidthbanner-container {
    padding: 0;
    position: relative;
}
.rev_slider li.tp-revslider-slidesli {
    position: absolute !important;
}
.tp-caption .rs-untoggled-content {
    display: block;
}
.tp-caption .rs-toggled-content {
    display: none;
}
.rs-toggle-content-active.tp-caption .rs-toggled-content {
    display: block;
}
.rs-toggle-content-active.tp-caption .rs-untoggled-content {
    display: none;
}
.rev_slider .caption,
.rev_slider .tp-caption {
    position: relative;
    visibility: hidden;
    white-space: nowrap;
    display: block;
    -webkit-font-smoothing: antialiased !important;
    z-index: 1;
}
.rev_slider .caption,
.rev_slider .tp-caption,
.tp-simpleresponsive img {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
.rev_slider .tp-mask-wrap .tp-caption,
.rev_slider .tp-mask-wrap :last-child,
.wpb_text_column .rev_slider .tp-mask-wrap .tp-caption,
.wpb_text_column .rev_slider .tp-mask-wrap :last-child {
    margin-bottom: 0;
}
.tp-svg-layer svg {
    width: 100%;
    height: 100%;
    position: relative;
}
.tp-carousel-wrapper {
    cursor: grab, move;

}
.tp-carousel-wrapper.dragged {
    cursor: grabbing, move;
}
.tp_inner_padding {
    box-sizing: border-box;
    max-height: none !important;
}
.tp-caption.tp-layer-selectable {
    -moz-user-select: all;
    -khtml-user-select: all;
    -webkit-user-select: all;
    -o-user-select: all;
}
.tp-caption.tp-hidden-caption,
.tp-forcenotvisible,
.tp-hide-revslider,
.tp-parallax-wrap.tp-hidden-caption {
    visibility: hidden !important;
    display: none !important;
}
.rev_slider audio,
.rev_slider embed,
.rev_slider iframe,
.rev_slider object,
.rev_slider video {
    max-width: none !important;
}
.tp-element-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.tp-blockmask,
.tp-blockmask_in,
.tp-blockmask_out {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 1000;
    transform: scaleX(0) scaleY(0);
}
.tp-parallax-wrap {
    transform-style: preserve-3d;
}
.rev_row_zone {
    position: absolute;
    width: 100%;
    left: 0;
    box-sizing: border-box;
    min-height: 50px;
    font-size: 0;
}
.rev_column_inner,
.rev_slider .tp-caption.rev_row {
    position: relative;
    width: 100% !important;
    box-sizing: border-box;
}
.rev_row_zone_top {
    top: 0;
}
.rev_row_zone_middle {
    top: 50%;
    transform: translateY(-50%);
}
.rev_row_zone_bottom {
    bottom: 0;
}
.rev_slider .tp-caption.rev_row {
    display: table;
    table-layout: fixed;
    vertical-align: top;
    height: auto !important;
    font-size: 0;
}
.rev_column {
    display: table-cell;
    position: relative;
    height: auto;
    box-sizing: border-box;
    font-size: 0;
}
.rev_column_inner {
    display: block;
    height: auto !important;
    white-space: normal !important;
}
.rev_column_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    box-sizing: border-box;
    background-clip: content-box;
    border: 0 solid transparent;
}
.tp-caption .backcorner,
.tp-caption .backcornertop,
.tp-caption .frontcorner,
.tp-caption .frontcornertop {
    height: 0;
    top: 0;
    width: 0;
    position: absolute;
}
.rev_column_inner .tp-loop-wrap,
.rev_column_inner .tp-mask-wrap,
.rev_column_inner .tp-parallax-wrap {
    text-align: inherit;
}
.rev_column_inner .tp-mask-wrap {
    display: inline-block;
}
.rev_column_inner .tp-parallax-wrap,
.rev_column_inner .tp-parallax-wrap .tp-loop-wrap,
.rev_column_inner .tp-parallax-wrap .tp-mask-wrap {
    position: relative !important;
    left: auto !important;
    top: auto !important;
    line-height: 0;
}
.tp-video-play-button,
.tp-video-play-button i {
    line-height: 50px !important;
    vertical-align: top;
    text-align: center;
}
.rev_column_inner .rev_layer_in_column,
.rev_column_inner .tp-parallax-wrap,
.rev_column_inner .tp-parallax-wrap .tp-loop-wrap,
.rev_column_inner .tp-parallax-wrap .tp-mask-wrap {
    vertical-align: top;
}
.rev_break_columns {
    display: block !important;
}
.rev_break_columns .tp-parallax-wrap.rev_column {
    display: block !important;
    width: 100% !important;
}
.fullwidthbanner-container {
    overflow: hidden;
}
.fullwidthbanner-container .fullwidthabanner {
    width: 100%;
    position: relative;
}
.tp-static-layers {
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
}
.tp-caption .frontcorner {
    border-left: 40px solid transparent;
    border-right: 0 solid transparent;
    border-top: 40px solid #00a8ff;
    left: -40px;
}
.tp-caption .backcorner {
    border-left: 0 solid transparent;
    border-right: 40px solid transparent;
    border-bottom: 40px solid #00a8ff;
    right: 0;
}
.tp-caption .frontcornertop {
    border-left: 40px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 40px solid #00a8ff;
    left: -40px;
}
.tp-caption .backcornertop {
    border-left: 0 solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid #00a8ff;
    right: 0;
}
.tp-layer-inner-rotation {
    position: relative !important;
}
img.tp-slider-alternative-image {
    width: 100%;
    height: auto;
}
.caption.fullscreenvideo,
.rs-background-video-layer,
.tp-caption.coverscreenvideo,
.tp-caption.fullscreenvideo {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}
.noFilterClass {
    filter: none !important;
}
.rs-background-video-layer {
    visibility: hidden;
    z-index: 0;
}
.caption.fullscreenvideo audio,
.caption.fullscreenvideo iframe,
.caption.fullscreenvideo video,
.tp-caption.fullscreenvideo iframe,
.tp-caption.fullscreenvideo iframe audio,
.tp-caption.fullscreenvideo iframe video {
    width: 100% !important;
    height: 100% !important;
    display: none;
}
.fullcoveredvideo audio,
.fullscreenvideo audio .fullcoveredvideo video,
.fullscreenvideo video {
    background: #000;
}
.fullcoveredvideo .tp-poster {
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.videoisplaying .html5vid .tp-poster {
    display: none;
}
.tp-video-play-button {
    background: #000;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    margin-top: -25px;
    margin-left: -25px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    display: inline-block;
    z-index: 4;
    opacity: 0;
    transition: opacity 0.3s ease-out !important;
}
.tp-audio-html5 .tp-video-play-button,
.tp-hiddenaudio {
    display: none !important;
}
.tp-caption .html5vid {
    width: 100% !important;
    height: 100% !important;
}
.tp-video-play-button i {
    width: 50px;
    height: 50px;
    display: inline-block;
    font-size: 40px !important;
}
.rs-fullvideo-cover,
.tp-dottedoverlay,
.tp-shadowcover {
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}
.tp-caption:hover .tp-video-play-button {
    opacity: 1;
    display: block;
}
.tp-caption .tp-revstop {
    display: none;
    border-left: 5px solid #fff !important;
    border-right: 5px solid #fff !important;
    margin-top: 15px !important;
    line-height: 20px !important;
    vertical-align: top;
    font-size: 25px !important;
}
.tp-seek-bar,
.tp-video-button,
.tp-volume-bar {
    outline: 0;
    line-height: 12px;
    margin: 0;
    cursor: pointer;
}
.videoisplaying .revicon-right-dir {
    display: none;
}
.videoisplaying .tp-revstop {
    display: inline-block;
}
.videoisplaying .tp-video-play-button {
    display: none;
}
.fullcoveredvideo .tp-video-play-button {
    display: none !important;
}
.fullscreenvideo .fullscreenvideo audio,
.fullscreenvideo .fullscreenvideo video {
    object-fit: contain !important;
}
.fullscreenvideo .fullcoveredvideo audio,
.fullscreenvideo .fullcoveredvideo video {
    object-fit: cover !important;
}
.tp-video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    opacity: 0;
    transition: opacity 0.3s;
    background-image: linear-gradient(to bottom, #000 13%, #323232 100%);
    display: table;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}
.rev-btn.rev-hiddenicon i,
.rev-btn.rev-withicon i {
    transition: all 0.2s ease-out !important;
    font-size: 15px;
}
.tp-caption:hover .tp-video-controls {
    opacity: 0.9;
}
.tp-video-button {
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 3px;
    font-size: 12px;
    color: #fff;
    padding: 0;
}
.tp-video-button:hover {
    cursor: pointer;
}
.tp-video-button-wrap,
.tp-video-seek-bar-wrap,
.tp-video-vol-bar-wrap {
    padding: 0 5px;
    display: table-cell;
    vertical-align: middle;
}
.tp-video-seek-bar-wrap {
    width: 80%;
}
.tp-video-vol-bar-wrap {
    width: 20%;
}
.tp-seek-bar,
.tp-volume-bar {
    width: 100%;
    padding: 0;
}
.rs-fullvideo-cover {
    width: 100%;
    background: 0 0;
    z-index: 5;
}
.disabled_lc .tp-video-play-button,
.rs-background-video-layer audio::-webkit-media-controls,
.rs-background-video-layer video::-webkit-media-controls,
.rs-background-video-layer video::-webkit-media-controls-start-playback-button {
    display: none !important;
}
.tp-audio-html5 .tp-video-controls {
    opacity: 1 !important;
    visibility: visible !important;
}
.tp-dottedoverlay {
    background-repeat: repeat;
    width: 100%;
    z-index: 3;
}
.tp-dottedoverlay.twoxtwo {
    // background: url(../assets/gridtile.html);
}
.tp-dottedoverlay.twoxtwowhite {
    // background: url(../assets/gridtile_white.html);
}
.tp-dottedoverlay.threexthree {
    // background: url(../assets/gridtile_3x3.html);
}
.tp-dottedoverlay.threexthreewhite {
    // background: url(../assets/gridtile_3x3_white.html);
}
.tp-shadowcover {
    width: 100%;
    background: #fff;
    z-index: -1;
}
.tp-shadow1 {
    box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.8);
}
.tp-shadow2:after,
.tp-shadow2:before,
.tp-shadow3:before,
.tp-shadow4:after {
    z-index: -2;
    position: absolute;
    content: "";
    bottom: 10px;
    left: 10px;
    width: 50%;
    top: 85%;
    max-width: 300px;
    background: 0 0;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.8);
    transform: rotate(-3deg);
}
.tp-shadow2:after,
.tp-shadow4:after {
    transform: rotate(3deg);
    right: 10px;
    left: auto;
}
.tp-shadow5 {
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.tp-shadow5:after,
.tp-shadow5:before {
    content: "";
    position: absolute;
    z-index: -2;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.6);
    top: 30%;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-radius: 100px/20px;
}
.tp-button {
    padding: 6px 13px 5px;
    border-radius: 3px;
    height: 30px;
    cursor: pointer;
    color: #fff !important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6) !important;
    font-size: 15px;
    line-height: 45px !important;
    font-family: arial, sans-serif;
    font-weight: 700;
    letter-spacing: -1px;
    text-decoration: none;
}
.tp-button.big {
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
    font-weight: 700;
    padding: 9px 20px;
    font-size: 19px;
    line-height: 57px !important;
}
.purchase:hover,
.tp-button.big:hover,
.tp-button:hover {
    background-position: bottom, 15px 11px;
}
.purchase.green,
.purchase:hover.green,
.tp-button.green,
.tp-button:hover.green {
    background-color: #21a117;
    box-shadow: 0 3px 0 0 #104d0b;
}
.purchase.blue,
.purchase:hover.blue,
.tp-button.blue,
.tp-button:hover.blue {
    background-color: #1d78cb;
    box-shadow: 0 3px 0 0 #0f3e68;
}
.purchase.red,
.purchase:hover.red,
.tp-button.red,
.tp-button:hover.red {
    background-color: #cb1d1d;
    box-shadow: 0 3px 0 0 #7c1212;
}
.purchase.orange,
.purchase:hover.orange,
.tp-button.orange,
.tp-button:hover.orange {
    background-color: #f70;
    box-shadow: 0 3px 0 0 #a34c00;
}
.purchase.darkgrey,
.purchase:hover.darkgrey,
.tp-button.darkgrey,
.tp-button.grey,
.tp-button:hover.darkgrey,
.tp-button:hover.grey {
    background-color: #555;
    box-shadow: 0 3px 0 0 #222;
}
.purchase.lightgrey,
.purchase:hover.lightgrey,
.tp-button.lightgrey,
.tp-button:hover.lightgrey {
    background-color: #888;
    box-shadow: 0 3px 0 0 #555;
}
.rev-btn,
.rev-btn:visited {
    outline: 0 !important;
    box-shadow: none !important;
    text-decoration: none !important;
    line-height: 44px;
    font-size: 17px;
    font-weight: 500;
    padding: 12px 35px;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    cursor: pointer;
}
.rev-btn.rev-uppercase,
.rev-btn.rev-uppercase:visited {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 900;
}
.rev-btn.rev-withicon i {
    font-weight: 400;
    position: relative;
    top: 0;
    margin-left: 10px !important;
}
.rev-btn.rev-hiddenicon i {
    font-weight: 400;
    position: relative;
    top: 0;
    opacity: 0;
    margin-left: 0 !important;
    width: 0 !important;
}
.rev-btn.rev-hiddenicon:hover i {
    opacity: 1 !important;
    margin-left: 10px !important;
    width: auto !important;
}
.rev-btn.rev-medium,
.rev-btn.rev-medium:visited {
    line-height: 36px;
    font-size: 14px;
    padding: 10px 30px;
}
.rev-btn.rev-medium.rev-hiddenicon i,
.rev-btn.rev-medium.rev-withicon i {
    font-size: 14px;
    top: 0;
}
.rev-btn.rev-small,
.rev-btn.rev-small:visited {
    line-height: 28px;
    font-size: 12px;
    padding: 7px 20px;
}
.rev-btn.rev-small.rev-hiddenicon i,
.rev-btn.rev-small.rev-withicon i {
    font-size: 12px;
    top: 0;
}
.rev-maxround {
    border-radius: 30px;
}
.rev-minround {
    border-radius: 3px;
}
.rev-burger {
    position: relative;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    padding: 22px 0 0 14px;
    border-radius: 50%;
    border: 1px solid rgba(51, 51, 51, 0.25);
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}
.rev-burger span {
    display: block;
    width: 30px;
    height: 3px;
    background: #333;
    transition: 0.7s;
    pointer-events: none;
    transform-style: flat !important;
}
.rev-burger.revb-white span,
.rev-burger.revb-whitenoborder span {
    background: #fff;
}
.rev-burger span:nth-child(2) {
    margin: 3px 0;
}
#dialog_addbutton .rev-burger:hover :first-child,
.open .rev-burger :first-child,
.open.rev-burger :first-child {
    transform: translateY(6px) rotate(-45deg);
}
#dialog_addbutton .rev-burger:hover :nth-child(2),
.open .rev-burger :nth-child(2),
.open.rev-burger :nth-child(2) {
    transform: rotate(-45deg);
    opacity: 0;
}
#dialog_addbutton .rev-burger:hover :last-child,
.open .rev-burger :last-child,
.open.rev-burger :last-child {
    transform: translateY(-6px) rotate(-135deg);
}
.rev-burger.revb-white {
    border: 2px solid rgba(255, 255, 255, 0.2);
}
.rev-burger.revb-darknoborder,
.rev-burger.revb-whitenoborder {
    border: 0;
}
.rev-burger.revb-darknoborder span {
    background: #333;
}
.rev-burger.revb-whitefull {
    background: #fff;
    border: none;
}
.rev-burger.revb-whitefull span {
    background: #333;
}
.rev-burger.revb-darkfull {
    background: #333;
    border: none;
}
.rev-burger.revb-darkfull span,
.rev-scroll-btn.revs-fullwhite {
    background: #fff;
}
@keyframes rev-ani-mouse {
    0% {
        opacity: 1;
        top: 29%;
    }
    15% {
        opacity: 1;
        top: 50%;
    }
    50% {
        opacity: 0;
        top: 50%;
    }
    100% {
        opacity: 0;
        top: 29%;
    }
}
.rev-scroll-btn {
    display: inline-block;
    position: relative;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
    width: 35px;
    height: 55px;
    box-sizing: border-box;
    border: 3px solid #fff;
    border-radius: 23px;
}
.rev-scroll-btn > * {
    display: inline-block;
    line-height: 18px;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 2px;
}
.rev-scroll-btn > .active,
.rev-scroll-btn > :focus,
.rev-scroll-btn > :hover {
    color: #fff;
}
.rev-scroll-btn > .active,
.rev-scroll-btn > :active,
.rev-scroll-btn > :focus,
.rev-scroll-btn > :hover {
    opacity: 0.8;
}
.rev-scroll-btn.revs-fullwhite span {
    background: #333;
}
.rev-scroll-btn.revs-fulldark {
    background: #333;
    border: none;
}
.rev-scroll-btn.revs-fulldark span,
.tp-bullet {
    background: #fff;
}
.rev-scroll-btn span {
    position: absolute;
    display: block;
    top: 29%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin: -4px 0 0 -4px;
    background: #fff;
    border-radius: 50%;
    animation: rev-ani-mouse 2.5s linear infinite;
}
.rev-scroll-btn.revs-dark {
    border-color: #333;
}
.rev-scroll-btn.revs-dark span {
    background: #333;
}
.rev-control-btn {
    position: relative;
    display: inline-block;
    z-index: 5;
    color: #fff;
    font-size: 20px;
    line-height: 60px;
    font-weight: 400;
    font-style: normal;
    font-family: Raleway;
    text-decoration: none;
    text-align: center;
    background-color: #000;
    border-radius: 50px;
    text-shadow: none;
    background-color: rgba(0, 0, 0, 0.5);
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    cursor: pointer;
}
.rev-cbutton-dark-sr,
.rev-cbutton-light-sr {
    border-radius: 3px;
}
.rev-cbutton-light,
.rev-cbutton-light-sr {
    color: #333;
    background-color: rgba(255, 255, 255, 0.75);
}
.rev-sbutton {
    line-height: 37px;
    width: 37px;
    height: 37px;
}
.rev-sbutton-blue {
    background-color: #3b5998;
}
.rev-sbutton-lightblue {
    background-color: #00a0d1;
}
.rev-sbutton-red {
    background-color: #dd4b39;
}
.tp-bannertimer {
    visibility: hidden;
    width: 100%;
    height: 5px;
    background: #000;
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 200;
    top: 0;
}
.tp-bannertimer.tp-bottom {
    top: auto;
    bottom: 0 !important;
    height: 5px;
}
.tp-caption img {
    background: 0 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
    zoom: 1;
}
.caption.slidelink a div,
.tp-caption.slidelink a div {
    width: 3000px;
    height: 1500px;
    // background: url(../assets/coloredbg.html);
}
.tp-caption.slidelink a span {
    // background: url(../assets/coloredbg.html);
}
.tp-loader.spinner0,
.tp-loader.spinner5 {
    // background-image: url(../assets/loader.html);
    background-repeat: no-repeat;
}
.tp-shape {
    width: 100%;
    height: 100%;
}
.tp-caption .rs-starring {
    display: inline-block;
}
.tp-caption .rs-starring .star-rating {
    float: none;
    display: inline-block;
    vertical-align: top;
    color: #ffc321 !important;
}
.tp-caption .rs-starring .star-rating,
.tp-caption .rs-starring-page .star-rating {
    position: relative;
    height: 1em;
    width: 5.4em;
    font-family: star;
    font-size: 1em !important;
}
.tp-loader.spinner0,
.tp-loader.spinner1 {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    animation: tp-rotateplane 1.2s infinite ease-in-out;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}
.tp-caption .rs-starring .star-rating:before,
.tp-caption .rs-starring-page .star-rating:before {
    content: "\73\73\73\73\73";
    color: #e0dadf;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
}
.tp-caption .rs-starring .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
    font-size: 1em !important;
}
.tp-caption .rs-starring .star-rating span:before {
    content: "\53\53\53\53\53";
    top: 0;
    position: absolute;
    left: 0;
}
.tp-loader {
    top: 50%;
    left: 50%;
    z-index: 10000;
    position: absolute;
}
.tp-loader.spinner0 {
    background-position: center center;
}
.tp-loader.spinner5 {
    background-position: 10px 10px;
    background-color: #fff;
    margin: -22px;
    width: 44px;
    height: 44px;
    border-radius: 3px;
}
@keyframes tp-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0) rotateY(0);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
.tp-loader.spinner2 {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    background-color: red;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    animation: tp-scaleout 1s infinite ease-in-out;
}
@keyframes tp-scaleout {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
.tp-loader.spinner3 {
    margin: -9px 0 0 -35px;
    width: 70px;
    text-align: center;
}
.tp-loader.spinner3 .bounce1,
.tp-loader.spinner3 .bounce2,
.tp-loader.spinner3 .bounce3 {
    width: 18px;
    height: 18px;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    display: inline-block;
    animation: tp-bouncedelay 1.4s infinite ease-in-out;
    animation-fill-mode: both;
}
.tp-loader.spinner3 .bounce1 {
    animation-delay: -0.32s;
}
.tp-loader.spinner3 .bounce2 {
    animation-delay: -0.16s;
}
@keyframes tp-bouncedelay {
    0%,
    100%,
    80% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
.tp-loader.spinner4 {
    margin: -20px 0 0 -20px;
    width: 40px;
    height: 40px;
    text-align: center;
    animation: tp-rotate 2s infinite linear;
}
.tp-loader.spinner4 .dot1,
.tp-loader.spinner4 .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #fff;
    border-radius: 100%;
    animation: tp-bounce 2s infinite ease-in-out;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}
.tp-loader.spinner4 .dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
}
@keyframes tp-rotate {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes tp-bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
.tp-bullets.navbar,
.tp-tabs.navbar,
.tp-thumbs.navbar {
    border: none;
    min-height: 0;
    margin: 0;
    border-radius: 0;
}
.tp-bullets,
.tp-tabs,
.tp-thumbs {
    position: absolute;
    display: block;
    z-index: 1000;
    top: 0;
    left: 0;
}
.tp-tab,
.tp-thumb {
    cursor: pointer;
    position: absolute;
    opacity: 0.5;
    box-sizing: border-box;
}
.tp-arr-imgholder,
.tp-tab-image,
.tp-thumb-image,
.tp-videoposter {
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}
.tp-tab.selected,
.tp-tab:hover,
.tp-thumb.selected,
.tp-thumb:hover {
    opacity: 1;
}
.tp-tab-mask,
.tp-thumb-mask {
    box-sizing: border-box !important;
}
.tp-tabs,
.tp-thumbs {
    box-sizing: content-box !important;
}
.tp-bullet {
    width: 15px;
    height: 15px;
    position: absolute;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}
.tp-bullet.selected,
.tp-bullet:hover {
    background: #fff;
}
.tparrows {
    cursor: pointer;
    background: #000;
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000;
}
.tparrows:hover {
    background: #000;
}
.tparrows:before {
    font-family: revicons;
    font-size: 15px;
    color: #fff;
    display: block;
    line-height: 40px;
    text-align: center;
}
.hginfo,
.hglayerinfo {
    font-size: 12px;
    font-weight: 600;
}
.tparrows.tp-leftarrow:before {
    content: "\e824";
}
.tparrows.tp-rightarrow:before {
    content: "\e825";
}
&.rtl .tp-kbimg {
    left: 0 !important;
}
.dddwrappershadow {
    box-shadow: 0 45px 100px rgba(0, 0, 0, 0.4);
}
.hglayerinfo {
    position: fixed;
    bottom: 0;
    left: 0;
    color: #fff;
    line-height: 20px;
    background: rgba(0, 0, 0, 0.75);
    padding: 5px 10px;
    z-index: 2000;
    white-space: normal;
}
.helpgrid,
.hginfo {
    position: absolute;
}
.hginfo {
    top: -2px;
    left: -2px;
    color: #e74c3c;
    background: #000;
    padding: 2px 5px;
}
.indebugmode .tp-caption:hover {
    border: 1px dashed #c0392b !important;
}
.helpgrid {
    border: 2px dashed #c0392b;
    top: 0;
    left: 0;
    z-index: 0;
}
#revsliderlogloglog {
    padding: 15px;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 150px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100000;
    font-size: 10px;
    overflow: scroll;
}
.aden {
    filter: hue-rotate(-20deg) contrast(0.9) saturate(0.85) brightness(1.2);
}
.aden::after {
    background: linear-gradient(to right, rgba(66, 10, 14, 0.2), transparent);
    mix-blend-mode: darken;
}
.perpetua::after,
.reyes::after {
    mix-blend-mode: soft-light;
    opacity: 0.5;
}
.inkwell {
    filter: sepia(0.3) contrast(1.1) brightness(1.1) grayscale(1);
}
.perpetua::after {
    background: linear-gradient(to bottom, #005b9a, #e6c13d);
}
.reyes {
    filter: sepia(0.22) brightness(1.1) contrast(0.85) saturate(0.75);
}
.reyes::after {
    background: #efcdad;
}
.gingham {
    filter: brightness(1.05) hue-rotate(-10deg);
}
.gingham::after {
    background: linear-gradient(to right, rgba(66, 10, 14, 0.2), transparent);
    mix-blend-mode: darken;
}
.toaster {
    filter: contrast(1.5) brightness(0.9);
}
.toaster::after {
    background: radial-gradient(circle, #804e0f, #3b003b);
    mix-blend-mode: screen;
}
.walden {
    filter: brightness(1.1) hue-rotate(-10deg) sepia(0.3) saturate(1.6);
}
.walden::after {
    background: #04c;
    mix-blend-mode: screen;
    opacity: 0.3;
}
.hudson {
    filter: brightness(1.2) contrast(0.9) saturate(1.1);
}
.hudson::after {
    background: radial-gradient(circle, #a6b1ff 50%, #342134);
    mix-blend-mode: multiply;
    opacity: 0.5;
}
.earlybird {
    filter: contrast(0.9) sepia(0.2);
}
.earlybird::after {
    background: radial-gradient(circle, #d0ba8e 20%, #360309 85%, #1d0210 100%);
    mix-blend-mode: overlay;
}
.mayfair {
    filter: contrast(1.1) saturate(1.1);
}
.mayfair::after {
    background: radial-gradient(circle at 40% 40%, rgba(255, 255, 255, 0.8), rgba(255, 200, 200, 0.6), #111 60%);
    mix-blend-mode: overlay;
    opacity: 0.4;
}
.lofi {
    filter: saturate(1.1) contrast(1.5);
}
.lofi::after {
    background: radial-gradient(circle, transparent 70%, #222 150%);
    mix-blend-mode: multiply;
}
._1977 {
    filter: contrast(1.1) brightness(1.1) saturate(1.3);
}
._1977:after {
    background: rgba(243, 106, 188, 0.3);
    mix-blend-mode: screen;
}
.brooklyn {
    filter: contrast(0.9) brightness(1.1);
}
.brooklyn::after {
    background: radial-gradient(circle, rgba(168, 223, 193, 0.4) 70%, #c4b7c8);
    mix-blend-mode: overlay;
}
.xpro2 {
    filter: sepia(0.3);
}
.xpro2::after {
    background: radial-gradient(circle, #e6e7e0 40%, rgba(43, 42, 161, 0.6) 110%);
    mix-blend-mode: color-burn;
}
.nashville {
    filter: sepia(0.2) contrast(1.2) brightness(1.05) saturate(1.2);
}
.nashville::after {
    background: rgba(0, 70, 150, 0.4);
    mix-blend-mode: lighten;
}
.nashville::before {
    background: rgba(247, 176, 153, 0.56);
    mix-blend-mode: darken;
}
.lark {
    filter: contrast(0.9);
}
.lark::after {
    background: rgba(242, 242, 242, 0.8);
    mix-blend-mode: darken;
}
.lark::before {
    background: #22253f;
    mix-blend-mode: color-dodge;
}
.moon {
    filter: grayscale(1) contrast(1.1) brightness(1.1);
}
.moon::before {
    background: #a0a0a0;
    mix-blend-mode: soft-light;
}
.moon::after {
    background: #383838;
    mix-blend-mode: lighten;
}
.clarendon {
    filter: contrast(1.2) saturate(1.35);
}
.clarendon:before {
    background: rgba(127, 187, 227, 0.2);
    mix-blend-mode: overlay;
}
.willow {
    filter: grayscale(0.5) contrast(0.95) brightness(0.9);
}
.willow::before {
    background-color: radial-gradient(40%, circle, #d4a9af 55%, #000 150%);
    mix-blend-mode: overlay;
}
.willow::after {
    background-color: #d8cdcb;
    mix-blend-mode: color;
}
.rise {
    filter: brightness(1.05) sepia(0.2) contrast(0.9) saturate(0.9);
}
.rise::after {
    background: radial-gradient(circle, rgba(232, 197, 152, 0.8), transparent 90%);
    mix-blend-mode: overlay;
    opacity: 0.6;
}
.rise::before {
    background: radial-gradient(circle, rgba(236, 205, 169, 0.15) 55%, rgba(50, 30, 7, 0.4));
    mix-blend-mode: multiply;
}


._1977:after,
._1977:before,
.aden:after,
.aden:before,
.brooklyn:after,
.brooklyn:before,
.clarendon:after,
.clarendon:before,
.earlybird:after,
.earlybird:before,
.gingham:after,
.gingham:before,
.hudson:after,
.hudson:before,
.inkwell:after,
.inkwell:before,
.lark:after,
.lark:before,
.lofi:after,
.lofi:before,
.mayfair:after,
.mayfair:before,
.moon:after,
.moon:before,
.nashville:after,
.nashville:before,
.perpetua:after,
.perpetua:before,
.reyes:after,
.reyes:before,
.rise:after,
.rise:before,
.slumber:after,
.slumber:before,
.toaster:after,
.toaster:before,
.walden:after,
.walden:before,
.willow:after,
.willow:before,
.xpro2:after,
.xpro2:before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
}
._1977,
.aden,
.brooklyn,
.clarendon,
.earlybird,
.gingham,
.hudson,
.inkwell,
.lark,
.lofi,
.mayfair,
.moon,
.nashville,
.perpetua,
.reyes,
.rise,
.slumber,
.toaster,
.walden,
.willow,
.xpro2 {
    position: relative;
}
._1977 img,
.aden img,
.brooklyn img,
.clarendon img,
.earlybird img,
.gingham img,
.hudson img,
.inkwell img,
.lark img,
.lofi img,
.mayfair img,
.moon img,
.nashville img,
.perpetua img,
.reyes img,
.rise img,
.slumber img,
.toaster img,
.walden img,
.willow img,
.xpro2 img {
    width: 100%;
    z-index: 1;
}
._1977:before,
.aden:before,
.brooklyn:before,
.clarendon:before,
.earlybird:before,
.gingham:before,
.hudson:before,
.inkwell:before,
.lark:before,
.lofi:before,
.mayfair:before,
.moon:before,
.nashville:before,
.perpetua:before,
.reyes:before,
.rise:before,
.slumber:before,
.toaster:before,
.walden:before,
.willow:before,
.xpro2:before {
    z-index: 2;
}
._1977:after,
.aden:after,
.brooklyn:after,
.clarendon:after,
.earlybird:after,
.gingham:after,
.hudson:after,
.inkwell:after,
.lark:after,
.lofi:after,
.mayfair:after,
.moon:after,
.nashville:after,
.perpetua:after,
.reyes:after,
.rise:after,
.slumber:after,
.toaster:after,
.walden:after,
.willow:after,
.xpro2:after {
    z-index: 3;
}
.slumber {
    filter: saturate(0.66) brightness(1.05);
}
.slumber::after {
    background: rgba(125, 105, 24, 0.5);
    mix-blend-mode: soft-light;
}
.slumber::before {
    background: rgba(69, 41, 12, 0.4);
    mix-blend-mode: lighten;
}
.tp-kbimg-wrap:after,
.tp-kbimg-wrap:before {
    height: 500%;
    width: 500%;
}


	/*-----------------------------------------------------------------------------

	-	Revolution Slider 5.0 Layer Style Settings -

	Screen Stylesheet

	version:   	5.0.0
	date:      	18/03/15
	author:		themepunch
	email:     	info@themepunch.com
	website:   	http://www.themepunch.com
	-----------------------------------------------------------------------------*/

	* {
	    -webkit-box-sizing: border-box;
	    -moz-box-sizing: border-box;
	    box-sizing: border-box;
	}

	.tp-caption.Twitter-Content a,.tp-caption.Twitter-Content a:visited
	{
		color:#0084B4!important;
	}

	.tp-caption.Twitter-Content a:hover
	{
		color:#0084B4!important;
		text-decoration:underline!important;
	}

	.tp-caption.medium_grey,.medium_grey
	{
		background-color:#888;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:Arial;
		font-size:20px;
		font-weight:700;
		line-height:20px;
		margin:0;
		padding:2px 4px;
		position:absolute;
		text-shadow:0 2px 5px rgba(0,0,0,0.5);
		white-space:nowrap;
	}

	.tp-caption.small_text,.small_text
	{
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:Arial;
		font-size:14px;
		font-weight:700;
		line-height:20px;
		margin:0;
		position:absolute;
		text-shadow:0 2px 5px rgba(0,0,0,0.5);
		white-space:nowrap;
	}

	.tp-caption.medium_text,.medium_text
	{
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:Arial;
		font-size:20px;
		font-weight:700;
		line-height:20px;
		margin:0;
		position:absolute;
		text-shadow:0 2px 5px rgba(0,0,0,0.5);
		white-space:nowrap;
	}

	.tp-caption.large_text,.large_text
	{
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:Arial;
		font-size:40px;
		font-weight:700;
		line-height:40px;
		margin:0;
		position:absolute;
		text-shadow:0 2px 5px rgba(0,0,0,0.5);
		white-space:nowrap;
	}

	.tp-caption.very_large_text,.very_large_text
	{
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:Arial;
		font-size:60px;
		font-weight:700;
		letter-spacing:-2px;
		line-height:60px;
		margin:0;
		position:absolute;
		text-shadow:0 2px 5px rgba(0,0,0,0.5);
		white-space:nowrap;
	}

	.tp-caption.very_big_white,.very_big_white
	{
		background-color:#000;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:Arial;
		font-size:60px;
		font-weight:800;
		line-height:60px;
		margin:0;
		padding:1px 4px 0;
		position:absolute;
		text-shadow:none;
		white-space:nowrap;
	}

	.tp-caption.very_big_black,.very_big_black
	{
		background-color:#fff;
		border-style:none;
		border-width:0;
		color:#000;
		font-family:Arial;
		font-size:60px;
		font-weight:700;
		line-height:60px;
		margin:0;
		padding:1px 4px 0;
		position:absolute;
		text-shadow:none;
		white-space:nowrap;
	}

	.tp-caption.modern_medium_fat,.modern_medium_fat
	{
		border-style:none;
		border-width:0;
		color:#000;
		font-family:"Open Sans", sans-serif;
		font-size:24px;
		font-weight:800;
		line-height:20px;
		margin:0;
		position:absolute;
		text-shadow:none;
		white-space:nowrap;
	}

	.tp-caption.modern_medium_fat_white,.modern_medium_fat_white
	{
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans", sans-serif;
		font-size:24px;
		font-weight:800;
		line-height:20px;
		margin:0;
		position:absolute;
		text-shadow:none;
		white-space:nowrap;
	}

	.tp-caption.modern_medium_light,.modern_medium_light
	{
		border-style:none;
		border-width:0;
		color:#000;
		font-family:"Open Sans", sans-serif;
		font-size:24px;
		font-weight:300;
		line-height:20px;
		margin:0;
		position:absolute;
		text-shadow:none;
		white-space:nowrap;
	}

	.tp-caption.modern_big_bluebg,.modern_big_bluebg
	{
		background-color:#4e5b6c;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans", sans-serif;
		font-size:30px;
		font-weight:800;
		letter-spacing:0;
		line-height:36px;
		margin:0;
		padding:3px 10px;
		position:absolute;
		text-shadow:none;
	}

	.tp-caption.modern_big_redbg,.modern_big_redbg
	{
		background-color:#de543e;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans", sans-serif;
		font-size:30px;
		font-weight:300;
		letter-spacing:0;
		line-height:36px;
		margin:0;
		padding:1px 10px 3px;
		position:absolute;
		text-shadow:none;
	}

	.tp-caption.modern_small_text_dark,.modern_small_text_dark
	{
		border-style:none;
		border-width:0;
		color:#555;
		font-family:Arial;
		font-size:14px;
		line-height:22px;
		margin:0;
		position:absolute;
		text-shadow:none;
		white-space:nowrap;
	}

	.tp-caption.boxshadow,.boxshadow
	{
		box-shadow:0 0 20px rgba(0,0,0,0.5);
	}

	.tp-caption.black,.black
	{
		color:#000;
		text-shadow:none;
	}

	.tp-caption.noshadow,.noshadow
	{
		text-shadow:none;
	}

	.tp-caption.thinheadline_dark,.thinheadline_dark
	{
		background-color:transparent;
		color:rgba(0,0,0,0.85);
		font-family:"Open Sans";
		font-size:30px;
		font-weight:300;
		line-height:30px;
		position:absolute;
		text-shadow:none;
	}

	.tp-caption.thintext_dark,.thintext_dark
	{
		background-color:transparent;
		color:rgba(0,0,0,0.85);
		font-family:"Open Sans";
		font-size:16px;
		font-weight:300;
		line-height:26px;
		position:absolute;
		text-shadow:none;
	}

	.tp-caption.largeblackbg,.largeblackbg
	{


		background-color:#000;
		border-radius:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:50px;
		font-weight:300;
		line-height:70px;
		padding:0 20px;
		position:absolute;
		text-shadow:none;
	}

	.tp-caption.largepinkbg,.largepinkbg
	{


		background-color:#db4360;
		border-radius:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:50px;
		font-weight:300;
		line-height:70px;
		padding:0 20px;
		position:absolute;
		text-shadow:none;
	}

	.tp-caption.largewhitebg,.largewhitebg
	{


		background-color:#fff;
		border-radius:0;
		color:#000;
		font-family:"Open Sans";
		font-size:50px;
		font-weight:300;
		line-height:70px;
		padding:0 20px;
		position:absolute;
		text-shadow:none;
	}

	.tp-caption.largegreenbg,.largegreenbg
	{


		background-color:#67ae73;
		border-radius:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:50px;
		font-weight:300;
		line-height:70px;
		padding:0 20px;
		position:absolute;
		text-shadow:none;
	}

	.tp-caption.excerpt,.excerpt
	{
		background-color:rgba(0,0,0,1);
		border-color:#fff;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:Arial;
		font-size:36px;
		font-weight:700;
		height:auto;
		letter-spacing:-1.5px;
		line-height:36px;
		margin:0;
		padding:1px 4px 0;
		text-decoration:none;
		text-shadow:none;
		white-space:normal!important;
		width:150px;
	}

	.tp-caption.large_bold_grey,.large_bold_grey
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#666;
		font-family:"Open Sans";
		font-size:60px;
		font-weight:800;
		line-height:60px;
		margin:0;
		padding:1px 4px 0;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.medium_thin_grey,.medium_thin_grey
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#666;
		font-family:"Open Sans";
		font-size:34px;
		font-weight:300;
		line-height:30px;
		margin:0;
		padding:1px 4px 0;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.small_thin_grey,.small_thin_grey
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#757575;
		font-family:"Open Sans";
		font-size:18px;
		font-weight:300;
		line-height:26px;
		margin:0;
		padding:1px 4px 0;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.lightgrey_divider,.lightgrey_divider
	{
		background-color:rgba(235,235,235,1);
		background-position:initial;
		background-repeat:initial;
		border-color:#222;
		border-style:none;
		border-width:0;
		height:3px;
		text-decoration:none;
		width:370px;
	}

	.tp-caption.large_bold_darkblue,.large_bold_darkblue
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#34495e;
		font-family:"Open Sans";
		font-size:58px;
		font-weight:800;
		line-height:60px;
		text-decoration:none;
	}

	.tp-caption.medium_bg_darkblue,.medium_bg_darkblue
	{
		background-color:#34495e;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:20px;
		font-weight:800;
		line-height:20px;
		padding:10px;
		text-decoration:none;
	}

	.tp-caption.medium_bold_red,.medium_bold_red
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#e33a0c;
		font-family:"Open Sans";
		font-size:24px;
		font-weight:800;
		line-height:30px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.medium_light_red,.medium_light_red
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#e33a0c;
		font-family:"Open Sans";
		font-size:21px;
		font-weight:300;
		line-height:26px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.medium_bg_red,.medium_bg_red
	{
		background-color:#e33a0c;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:20px;
		font-weight:800;
		line-height:20px;
		padding:10px;
		text-decoration:none;
	}

	.tp-caption.medium_bold_orange,.medium_bold_orange
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#f39c12;
		font-family:"Open Sans";
		font-size:24px;
		font-weight:800;
		line-height:30px;
		text-decoration:none;
	}

	.tp-caption.medium_bg_orange,.medium_bg_orange
	{
		background-color:#f39c12;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:20px;
		font-weight:800;
		line-height:20px;
		padding:10px;
		text-decoration:none;
	}

	.tp-caption.grassfloor,.grassfloor
	{
		background-color:rgba(160,179,151,1);
		border-color:#222;
		border-style:none;
		border-width:0;
		height:150px;
		text-decoration:none;
		width:4000px;
	}

	.tp-caption.large_bold_white,.large_bold_white
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:58px;
		font-weight:800;
		line-height:60px;
		text-decoration:none;
	}

	.tp-caption.medium_light_white,.medium_light_white
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:30px;
		font-weight:300;
		line-height:36px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.mediumlarge_light_white,.mediumlarge_light_white
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:34px;
		font-weight:300;
		line-height:40px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.mediumlarge_light_white_center,.mediumlarge_light_white_center
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:34px;
		font-weight:300;
		line-height:40px;
		padding:0;
		text-align:center;
		text-decoration:none;
	}

	.tp-caption.medium_bg_asbestos,.medium_bg_asbestos
	{
		background-color:#7f8c8d;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:20px;
		font-weight:800;
		line-height:20px;
		padding:10px;
		text-decoration:none;
	}

	.tp-caption.medium_light_black,.medium_light_black
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#000;
		font-family:"Open Sans";
		font-size:30px;
		font-weight:300;
		line-height:36px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.large_bold_black,.large_bold_black
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#000;
		font-family:"Open Sans";
		font-size:58px;
		font-weight:800;
		line-height:60px;
		text-decoration:none;
	}

	.tp-caption.mediumlarge_light_darkblue,.mediumlarge_light_darkblue
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#34495e;
		font-family:"Open Sans";
		font-size:34px;
		font-weight:300;
		line-height:40px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.small_light_white,.small_light_white
	{
		background-color:transparent;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:17px;
		font-weight:300;
		line-height:28px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.roundedimage,.roundedimage
	{
		border-color:#222;
		border-style:none;
		border-width:0;
	}

	.tp-caption.large_bg_black,.large_bg_black
	{
		background-color:#000;
		border-color:#ffd658;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Open Sans";
		font-size:40px;
		font-weight:800;
		line-height:40px;
		padding:10px 20px 15px;
		text-decoration:none;
	}

	.tp-caption.mediumwhitebg,.mediumwhitebg
	{
		background-color:#fff;
		border-color:#000;
		border-style:none;
		border-width:0;
		color:#000;
		font-family:"Open Sans";
		font-size:30px;
		font-weight:300;
		line-height:30px;
		padding:5px 15px 10px;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.maincaption,.maincaption
	{
		background-color:transparent;
		border-color:#000;
		border-style:none;
		border-width:0;
		color:#212a40;
		font-family:roboto;
		font-size:33px;
		font-weight:500;
		line-height:43px;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.miami_title_60px,.miami_title_60px
	{
		background-color:transparent;
		border-color:#000;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Source Sans Pro";
		font-size:60px;
		font-weight:700;
		letter-spacing:1px;
		line-height:60px;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.miami_subtitle,.miami_subtitle
	{
		background-color:transparent;
		border-color:#000;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,0.65);
		font-family:"Source Sans Pro";
		font-size:17px;
		font-weight:400;
		letter-spacing:2px;
		line-height:24px;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.divideline30px,.divideline30px
	{
		background:#fff;
		background-color:#fff;
		border-color:#222;
		border-style:none;
		border-width:0;
		height:2px;
		min-width:30px;
		text-decoration:none;
	}

	.tp-caption.Miami_nostyle,.Miami_nostyle
	{
		border-color:#222;
		border-style:none;
		border-width:0;
	}

	.tp-caption.miami_content_light,.miami_content_light
	{
		background-color:transparent;
		border-color:#000;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Source Sans Pro";
		font-size:22px;
		font-weight:400;
		letter-spacing:0;
		line-height:28px;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.miami_title_60px_dark,.miami_title_60px_dark
	{
		background-color:transparent;
		border-color:#000;
		border-style:none;
		border-width:0;
		color:#333;
		font-family:"Source Sans Pro";
		font-size:60px;
		font-weight:700;
		letter-spacing:1px;
		line-height:60px;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.miami_content_dark,.miami_content_dark
	{
		background-color:transparent;
		border-color:#000;
		border-style:none;
		border-width:0;
		color:#666;
		font-family:"Source Sans Pro";
		font-size:22px;
		font-weight:400;
		letter-spacing:0;
		line-height:28px;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.divideline30px_dark,.divideline30px_dark
	{
		background-color:#333;
		border-color:#222;
		border-style:none;
		border-width:0;
		height:2px;
		min-width:30px;
		text-decoration:none;
	}

	.tp-caption.ellipse70px,.ellipse70px
	{
		background-color:rgba(0,0,0,0.14902);
		border-color:#222;
		border-radius:50px 50px 50px 50px;
		border-style:none;
		border-width:0;
		cursor:pointer;
		line-height:1px;
		min-height:70px;
		min-width:70px;
		text-decoration:none;
	}

	.tp-caption.arrowicon,.arrowicon
	{
		border-color:#222;
		border-style:none;
		border-width:0;
		line-height:1px;
	}

	.tp-caption.MarkerDisplay,.MarkerDisplay
	{
		background-color:transparent;
		border-color:#000;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		font-family:"Permanent Marker";
		font-style:normal;
		padding:0;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.Restaurant-Display,.Restaurant-Display
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:Roboto;
		font-size:120px;
		font-style:normal;
		font-weight:700;
		line-height:120px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Restaurant-Cursive,.Restaurant-Cursive
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:"Nothing you could do";
		font-size:30px;
		font-style:normal;
		font-weight:400;
		letter-spacing:2px;
		line-height:30px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Restaurant-ScrollDownText,.Restaurant-ScrollDownText
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:Roboto;
		font-size:17px;
		font-style:normal;
		font-weight:400;
		letter-spacing:2px;
		line-height:17px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Restaurant-Description,.Restaurant-Description
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:Roboto;
		font-size:20px;
		font-style:normal;
		font-weight:300;
		letter-spacing:3px;
		line-height:30px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Restaurant-Price,.Restaurant-Price
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:#fff;
		font-family:Roboto;
		font-size:30px;
		font-style:normal;
		font-weight:300;
		letter-spacing:3px;
		line-height:30px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Restaurant-Menuitem,.Restaurant-Menuitem
	{
		background-color:rgba(0,0,0,1.00);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Roboto;
		font-size:17px;
		font-style:normal;
		font-weight:400;
		letter-spacing:2px;
		line-height:17px;
		padding:10px 30px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Furniture-LogoText,.Furniture-LogoText
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(230,207,163,1.00);
		font-family:Raleway;
		font-size:160px;
		font-style:normal;
		font-weight:300;
		line-height:150px;
		padding:0;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.Furniture-Plus,.Furniture-Plus
	{
		background-color:rgba(255,255,255,1.00);
		border-color:transparent;
		border-radius:30px 30px 30px 30px;
		border-style:none;
		border-width:0;
		box-shadow:rgba(0,0,0,0.1) 0 1px 3px;
		color:rgba(230,207,163,1.00);
		font-family:Raleway;
		font-size:20px;
		font-style:normal;
		font-weight:400;
		line-height:20px;
		padding:6px 7px 4px;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.Furniture-Title,.Furniture-Title
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(0,0,0,1.00);
		font-family:Raleway;
		font-size:20px;
		font-style:normal;
		font-weight:700;
		letter-spacing:3px;
		line-height:20px;
		padding:0;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.Furniture-Subtitle,.Furniture-Subtitle
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(0,0,0,1.00);
		font-family:Raleway;
		font-size:17px;
		font-style:normal;
		font-weight:300;
		line-height:20px;
		padding:0;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.Gym-Display,.Gym-Display
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:80px;
		font-style:normal;
		font-weight:900;
		line-height:70px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Gym-Subline,.Gym-Subline
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:30px;
		font-style:normal;
		font-weight:100;
		letter-spacing:5px;
		line-height:30px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Gym-SmallText,.Gym-SmallText
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:17px;
		font-style:normal;
		font-weight:300;
		line-height:22;
		padding:0;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.Fashion-SmallText,.Fashion-SmallText
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:12px;
		font-style:normal;
		font-weight:600;
		letter-spacing:2px;
		line-height:20px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Fashion-BigDisplay,.Fashion-BigDisplay
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(0,0,0,1.00);
		font-family:Raleway;
		font-size:60px;
		font-style:normal;
		font-weight:900;
		letter-spacing:2px;
		line-height:60px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Fashion-TextBlock,.Fashion-TextBlock
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(0,0,0,1.00);
		font-family:Raleway;
		font-size:20px;
		font-style:normal;
		font-weight:400;
		letter-spacing:2px;
		line-height:40px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Sports-Display,.Sports-Display
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:130px;
		font-style:normal;
		font-weight:100;
		letter-spacing:13px;
		line-height:130px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Sports-DisplayFat,.Sports-DisplayFat
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:130px;
		font-style:normal;
		font-weight:900;
		line-height:130px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Sports-Subline,.Sports-Subline
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(0,0,0,1.00);
		font-family:Raleway;
		font-size:32px;
		font-style:normal;
		font-weight:400;
		letter-spacing:4px;
		line-height:32px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Instagram-Caption,.Instagram-Caption
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Roboto;
		font-size:20px;
		font-style:normal;
		font-weight:900;
		line-height:20px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.News-Title,.News-Title
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:"Roboto Slab";
		font-size:70px;
		font-style:normal;
		font-weight:400;
		line-height:60px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.News-Subtitle,.News-Subtitle
	{
		background-color:rgba(255,255,255,0);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:"Roboto Slab";
		font-size:15px;
		font-style:normal;
		font-weight:300;
		line-height:24px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.News-Subtitle:hover,.News-Subtitle:hover
	{
		background-color:rgba(255,255,255,0);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:0;
		color:rgba(255,255,255,0.65);
		text-decoration:none;
	}

	.tp-caption.Photography-Display,.Photography-Display
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:80px;
		font-style:normal;
		font-weight:100;
		letter-spacing:5px;
		line-height:70px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Photography-Subline,.Photography-Subline
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(119,119,119,1.00);
		font-family:Raleway;
		font-size:20px;
		font-style:normal;
		font-weight:300;
		letter-spacing:3px;
		line-height:30px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Photography-ImageHover,.Photography-ImageHover
	{
		background-color:transparent;
		border-color:rgba(255,255,255,0);
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-size:20px;
		font-style:normal;
		font-weight:400;
		line-height:22;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Photography-ImageHover:hover,.Photography-ImageHover:hover
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Photography-Menuitem,.Photography-Menuitem
	{
		background-color:rgba(0,0,0,0.65);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:20px;
		font-style:normal;
		font-weight:300;
		letter-spacing:2px;
		line-height:20px;
		padding:3px 5px 3px 8px;
		text-decoration:none;
	}

	.tp-caption.Photography-Menuitem:hover,.Photography-Menuitem:hover
	{
		background-color:rgba(0,255,222,0.65);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Photography-Textblock,.Photography-Textblock
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:17px;
		font-style:normal;
		font-weight:300;
		letter-spacing:2px;
		line-height:30px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Photography-Subline-2,.Photography-Subline-2
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,0.35);
		font-family:Raleway;
		font-size:20px;
		font-style:normal;
		font-weight:300;
		letter-spacing:3px;
		line-height:30px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Photography-ImageHover2,.Photography-ImageHover2
	{
		background-color:transparent;
		border-color:rgba(255,255,255,0);
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Arial;
		font-size:20px;
		font-style:normal;
		font-weight:400;
		line-height:22;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.Photography-ImageHover2:hover,.Photography-ImageHover2:hover
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.WebProduct-Title,.WebProduct-Title
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(51,51,51,1.00);
		font-family:Raleway;
		font-size:90px;
		font-style:normal;
		font-weight:100;
		line-height:90px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.WebProduct-SubTitle,.WebProduct-SubTitle
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(153,153,153,1.00);
		font-family:Raleway;
		font-size:15px;
		font-style:normal;
		font-weight:400;
		line-height:20px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.WebProduct-Content,.WebProduct-Content
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(153,153,153,1.00);
		font-family:Raleway;
		font-size:16px;
		font-style:normal;
		font-weight:600;
		line-height:24px;
		padding:0;
		text-decoration:none;
	}

	.tp-caption.WebProduct-Menuitem,.WebProduct-Menuitem
	{
		background-color:rgba(51,51,51,1.00);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:15px;
		font-style:normal;
		font-weight:500;
		letter-spacing:2px;
		line-height:20px;
		padding:3px 5px 3px 8px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.WebProduct-Menuitem:hover,.WebProduct-Menuitem:hover
	{
		background-color:rgba(255,255,255,1.00);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(153,153,153,1.00);
		text-decoration:none;
	}

	.tp-caption.WebProduct-Title-Light,.WebProduct-Title-Light
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:90px;
		font-style:normal;
		font-weight:100;
		line-height:90px;
		padding:0;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.WebProduct-SubTitle-Light,.WebProduct-SubTitle-Light
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,0.35);
		font-family:Raleway;
		font-size:15px;
		font-style:normal;
		font-weight:400;
		line-height:20px;
		padding:0;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.WebProduct-Content-Light,.WebProduct-Content-Light
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,0.65);
		font-family:Raleway;
		font-size:16px;
		font-style:normal;
		font-weight:600;
		line-height:24px;
		padding:0;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.FatRounded,.FatRounded
	{
		background-color:rgba(0,0,0,0.50);
		border-color:rgba(211,211,211,1.00);
		border-radius:50px 50px 50px 50px;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:30px;
		font-style:normal;
		font-weight:900;
		line-height:30px;
		padding:20px 22px 20px 25px;
		text-align:left;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.FatRounded:hover,.FatRounded:hover
	{
		background-color:rgba(0,0,0,1.00);
		border-color:rgba(211,211,211,1.00);
		border-radius:50px 50px 50px 50px;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.NotGeneric-Title,.NotGeneric-Title
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:70px;
		font-style:normal;
		font-weight:800;
		line-height:70px;
		padding:10px 0;
		text-decoration:none;
	}

	.tp-caption.NotGeneric-SubTitle,.NotGeneric-SubTitle
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:13px;
		font-style:normal;
		font-weight:500;
		letter-spacing:4px;
		line-height:20px;
		padding:0;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.NotGeneric-CallToAction,.NotGeneric-CallToAction
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,0.50);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:14px;
		font-style:normal;
		font-weight:500;
		letter-spacing:3px;
		line-height:14px;
		padding:10px 30px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.NotGeneric-CallToAction:hover,.NotGeneric-CallToAction:hover
	{
		background-color:transparent;
		border-color:rgba(255,255,255,1.00);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.NotGeneric-Icon,.NotGeneric-Icon
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,0);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:30px;
		font-style:normal;
		font-weight:400;
		letter-spacing:3px;
		line-height:30px;
		padding:0;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.NotGeneric-Menuitem,.NotGeneric-Menuitem
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,0.15);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:14px;
		font-style:normal;
		font-weight:500;
		letter-spacing:3px;
		line-height:14px;
		padding:27px 30px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.NotGeneric-Menuitem:hover,.NotGeneric-Menuitem:hover
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,1.00);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.MarkerStyle,.MarkerStyle
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:"Permanent Marker";
		font-size:17px;
		font-style:normal;
		font-weight:100;
		line-height:30px;
		padding:0;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Gym-Menuitem,.Gym-Menuitem
	{
		background-color:rgba(0,0,0,1.00);
		border-color:rgba(255,255,255,0);
		border-radius:3px 3px 3px 3px;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:20px;
		font-style:normal;
		font-weight:300;
		letter-spacing:2px;
		line-height:20px;
		padding:3px 5px 3px 8px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Gym-Menuitem:hover,.Gym-Menuitem:hover
	{
		background-color:rgba(0,0,0,1.00);
		border-color:rgba(255,255,255,0.25);
		border-radius:3px 3px 3px 3px;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Newspaper-Button,.Newspaper-Button
	{
		background-color:rgba(255,255,255,0);
		border-color:rgba(255,255,255,0.25);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		font-family:Roboto;
		font-size:13px;
		font-style:normal;
		font-weight:700;
		letter-spacing:2px;
		line-height:17px;
		padding:12px 35px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Newspaper-Button:hover,.Newspaper-Button:hover
	{
		background-color:rgba(255,255,255,1.00);
		border-color:rgba(255,255,255,1.00);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(0,0,0,1.00);
		text-decoration:none;
	}

	.tp-caption.Newspaper-Subtitle,.Newspaper-Subtitle
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(168,216,238,1.00);
		font-family:Roboto;
		font-size:15px;
		font-style:normal;
		font-weight:900;
		line-height:20px;
		padding:0;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Newspaper-Title,.Newspaper-Title
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:"Roboto Slab";
		font-size:50px;
		font-style:normal;
		font-weight:400;
		line-height:55px;
		padding:0 0 10px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Newspaper-Title-Centered,.Newspaper-Title-Centered
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:"Roboto Slab";
		font-size:50px;
		font-style:normal;
		font-weight:400;
		line-height:55px;
		padding:0 0 10px;
		text-align:center;
		text-decoration:none;
	}

	.tp-caption.Hero-Button,.Hero-Button
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,0.50);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:14px;
		font-style:normal;
		font-weight:500;
		letter-spacing:3px;
		line-height:14px;
		padding:10px 30px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Hero-Button:hover,.Hero-Button:hover
	{
		background-color:rgba(255,255,255,1.00);
		border-color:rgba(255,255,255,1.00);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(0,0,0,1.00);
		text-decoration:none;
	}

	.tp-caption.Video-Title,.Video-Title
	{
		background-color:rgba(0,0,0,1.00);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:30px;
		font-style:normal;
		font-weight:900;
		line-height:30px;
		padding:5px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Video-SubTitle,.Video-SubTitle
	{
		background-color:rgba(0,0,0,0.35);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:12px;
		font-style:normal;
		font-weight:600;
		letter-spacing:2px;
		line-height:12px;
		padding:5px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.NotGeneric-Button,.NotGeneric-Button
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,0.50);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:14px;
		font-style:normal;
		font-weight:500;
		letter-spacing:3px;
		line-height:14px;
		padding:10px 30px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.NotGeneric-Button:hover,.NotGeneric-Button:hover
	{
		background-color:transparent;
		border-color:rgba(255,255,255,1.00);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.NotGeneric-BigButton,.NotGeneric-BigButton
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,0.15);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:14px;
		font-style:normal;
		font-weight:500;
		letter-spacing:3px;
		line-height:14px;
		padding:27px 30px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.NotGeneric-BigButton:hover,.NotGeneric-BigButton:hover
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,1.00);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.WebProduct-Button,.WebProduct-Button
	{
		background-color:rgba(51,51,51,1.00);
		border-color:rgba(0,0,0,1.00);
		border-radius:0 0 0 0;
		border-style:none;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:16px;
		font-style:normal;
		font-weight:600;
		letter-spacing:1px;
		line-height:48px;
		padding:0 40px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.WebProduct-Button:hover,.WebProduct-Button:hover
	{
		background-color:rgba(255,255,255,1.00);
		border-color:rgba(0,0,0,1.00);
		border-radius:0 0 0 0;
		border-style:none;
		border-width:2px;
		color:rgba(51,51,51,1.00);
		text-decoration:none;
	}

	.tp-caption.Restaurant-Button,.Restaurant-Button
	{
		background-color:rgba(10,10,10,0);
		border-color:rgba(255,255,255,0.50);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		font-family:Roboto;
		font-size:17px;
		font-style:normal;
		font-weight:500;
		letter-spacing:3px;
		line-height:17px;
		padding:12px 35px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Restaurant-Button:hover,.Restaurant-Button:hover
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,224,129,1.00);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Gym-Button,.Gym-Button
	{
		background-color:rgba(139,192,39,1.00);
		border-color:rgba(0,0,0,0);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:15px;
		font-style:normal;
		font-weight:600;
		letter-spacing:1px;
		line-height:15px;
		padding:13px 35px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Gym-Button:hover,.Gym-Button:hover
	{
		background-color:rgba(114,168,0,1.00);
		border-color:rgba(0,0,0,0);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:0;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Gym-Button-Light,.Gym-Button-Light
	{
		background-color:transparent;
		border-color:rgba(255,255,255,0.25);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:15px;
		font-style:normal;
		font-weight:600;
		line-height:15px;
		padding:12px 35px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Gym-Button-Light:hover,.Gym-Button-Light:hover
	{
		background-color:rgba(114,168,0,0);
		border-color:rgba(139,192,39,1.00);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Sports-Button-Light,.Sports-Button-Light
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,0.50);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:17px;
		font-style:normal;
		font-weight:600;
		letter-spacing:2px;
		line-height:17px;
		padding:12px 35px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Sports-Button-Light:hover,.Sports-Button-Light:hover
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,1.00);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Sports-Button-Red,.Sports-Button-Red
	{
		background-color:rgba(219,28,34,1.00);
		border-color:rgba(219,28,34,0);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:17px;
		font-style:normal;
		font-weight:600;
		letter-spacing:2px;
		line-height:17px;
		padding:12px 35px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Sports-Button-Red:hover,.Sports-Button-Red:hover
	{
		background-color:rgba(0,0,0,1.00);
		border-color:rgba(0,0,0,1.00);
		border-radius:0 0 0 0;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Photography-Button,.Photography-Button
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,0.25);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		font-family:Raleway;
		font-size:15px;
		font-style:normal;
		font-weight:600;
		letter-spacing:1px;
		line-height:15px;
		padding:13px 35px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Photography-Button:hover,.Photography-Button:hover
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,1.00);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:1px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Newspaper-Button-2,.Newspaper-Button-2
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,0.50);
		border-radius:3px 3px 3px 3px;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		font-family:Roboto;
		font-size:15px;
		font-style:normal;
		font-weight:900;
		line-height:15px;
		padding:10px 30px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Newspaper-Button-2:hover,.Newspaper-Button-2:hover
	{
		background-color:rgba(0,0,0,0);
		border-color:rgba(255,255,255,1.00);
		border-radius:3px 3px 3px 3px;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Feature-Tour,.Feature-Tour
	{
		background-color:rgba(139,192,39,1.00);
		border-color:rgba(0,0,0,0);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Roboto;
		font-size:17px;
		font-style:normal;
		font-weight:700;
		line-height:17px;
		padding:17px 35px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Feature-Tour:hover,.Feature-Tour:hover
	{
		background-color:rgba(114,168,0,1.00);
		border-color:rgba(0,0,0,0);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:0;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Feature-Examples,.Feature-Examples
	{
		background-color:transparent;
		border-color:rgba(33,42,64,0.15);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:2px;
		color:rgba(33,42,64,0.50);
		font-family:Roboto;
		font-size:17px;
		font-style:normal;
		font-weight:700;
		line-height:17px;
		padding:15px 35px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Feature-Examples:hover,.Feature-Examples:hover
	{
		background-color:transparent;
		border-color:rgba(139,192,39,1.00);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:2px;
		color:rgba(139,192,39,1.00);
		text-decoration:none;
	}

	.tp-caption.subcaption,.subcaption
	{
		background-color:transparent;
		border-color:rgba(0,0,0,1.00);
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(111,124,130,1.00);
		font-family:roboto;
		font-size:19px;
		font-style:normal;
		font-weight:400;
		line-height:24px;
		padding:0;
		text-align:left;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.menutab,.menutab
	{
		background-color:transparent;
		border-color:rgba(0,0,0,1.00);
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(41,46,49,1.00);
		font-family:roboto;
		font-size:25px;
		font-style:normal;
		font-weight:300;
		line-height:30px;
		padding:0;
		text-align:left;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.menutab:hover,.menutab:hover
	{
		background-color:transparent;
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(213,0,0,1.00);
		text-decoration:none;
	}

	.tp-caption.maincontent,.maincontent
	{
		background-color:transparent;
		border-color:rgba(0,0,0,1.00);
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(41,46,49,1.00);
		font-family:roboto;
		font-size:21px;
		font-style:normal;
		font-weight:300;
		line-height:26px;
		padding:0;
		text-align:left;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.minitext,.minitext
	{
		background-color:transparent;
		border-color:rgba(0,0,0,1.00);
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(185,186,187,1.00);
		font-family:roboto;
		font-size:15px;
		font-style:normal;
		font-weight:400;
		line-height:20px;
		padding:0;
		text-align:left;
		text-decoration:none;
		text-shadow:none;
	}

	.tp-caption.Feature-Buy,.Feature-Buy
	{
		background-color:rgba(0,154,238,1.00);
		border-color:rgba(0,0,0,0);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Roboto;
		font-size:17px;
		font-style:normal;
		font-weight:700;
		line-height:17px;
		padding:17px 35px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Feature-Buy:hover,.Feature-Buy:hover
	{
		background-color:rgba(0,133,214,1.00);
		border-color:rgba(0,0,0,0);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:0;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Feature-Examples-Light,.Feature-Examples-Light
	{
		background-color:transparent;
		border-color:rgba(255,255,255,0.15);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		font-family:Roboto;
		font-size:17px;
		font-style:normal;
		font-weight:700;
		line-height:17px;
		padding:15px 35px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Feature-Examples-Light:hover,.Feature-Examples-Light:hover
	{
		background-color:transparent;
		border-color:rgba(255,255,255,1.00);
		border-radius:30px 30px 30px 30px;
		border-style:solid;
		border-width:2px;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Facebook-Likes,.Facebook-Likes
	{
		background-color:rgba(59,89,153,1.00);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		font-family:Roboto;
		font-size:15px;
		font-style:normal;
		font-weight:500;
		line-height:22px;
		padding:5px 15px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Twitter-Favorites,.Twitter-Favorites
	{
		background-color:rgba(255,255,255,0);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(136,153,166,1.00);
		font-family:Roboto;
		font-size:15px;
		font-style:normal;
		font-weight:500;
		line-height:22px;
		padding:0;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Twitter-Link,.Twitter-Link
	{
		background-color:rgba(255,255,255,1.00);
		border-color:transparent;
		border-radius:30px 30px 30px 30px;
		border-style:none;
		border-width:0;
		color:rgba(135,153,165,1.00);
		font-family:Roboto;
		font-size:15px;
		font-style:normal;
		font-weight:500;
		line-height:15px;
		padding:11px 11px 9px;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Twitter-Link:hover,.Twitter-Link:hover
	{
		background-color:rgba(0,132,180,1.00);
		border-color:transparent;
		border-radius:30px 30px 30px 30px;
		border-style:none;
		border-width:0;
		color:rgba(255,255,255,1.00);
		text-decoration:none;
	}

	.tp-caption.Twitter-Retweet,.Twitter-Retweet
	{
		background-color:rgba(255,255,255,0);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(136,153,166,1.00);
		font-family:Roboto;
		font-size:15px;
		font-style:normal;
		font-weight:500;
		line-height:22px;
		padding:0;
		text-align:left;
		text-decoration:none;
	}

	.tp-caption.Twitter-Content,.Twitter-Content
	{
		background-color:rgba(255,255,255,1.00);
		border-color:transparent;
		border-radius:0 0 0 0;
		border-style:none;
		border-width:0;
		color:rgba(41,47,51,1.00);
		font-family:Roboto;
		font-size:20px;
		font-style:normal;
		font-weight:500;
		line-height:28px;
		padding:30px 30px 70px;
		text-align:left;
		text-decoration:none;
	}

	.revtp-searchform input[type="text"],
	.revtp-searchform input[type="email"],
	.revtp-form input[type="text"],
	.revtp-form input[type="email"]{
		font-family: "Arial", sans-serif;
	    font-size: 15px;
	    color: #000;
	    background-color: #fff;
	    line-height: 46px;
	    padding: 0 20px;
	    cursor: text;
	    border: 0;
	    width: 400px;
	    margin-bottom: 0px;
	    -webkit-transition: background-color 0.5s;
	    -moz-transition: background-color 0.5s;
	    -o-transition: background-color 0.5s;
	    -ms-transition: background-color 0.5s;
	    transition: background-color 0.5s;


	    border-radius: 0px;
	}


	.tp-caption.BigBold-Title,
	.BigBold-Title {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 110px;
	    line-height: 100px;
	    font-weight: 800;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 10px 0px 10px 0;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.BigBold-SubTitle,
	.BigBold-SubTitle {
	    color: rgba(255, 255, 255, 0.50);
	    font-size: 15px;
	    line-height: 24px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left;
	    letter-spacing: 1px
	}
	.tp-caption.BigBold-Button,
	.BigBold-Button {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 13px;
	    line-height: 13px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 15px 50px 15px 50px;
	    text-decoration: none;
	    background-color: rgba(0, 0, 0, 0);
	    border-color: rgba(255, 255, 255, 0.50);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px;
	    text-align: left;
	    letter-spacing: 1px
	}
	.tp-caption.BigBold-Button:hover,
	.BigBold-Button:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: rgba(255, 255, 255, 1.00);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px
	}
	.tp-caption.FoodCarousel-Content,
	.FoodCarousel-Content {
	    color: rgba(41, 46, 49, 1.00);
	    font-size: 17px;
	    line-height: 28px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 30px 30px 30px 30px;
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(41, 46, 49, 1.00);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.FoodCarousel-Button,
	.FoodCarousel-Button {
	    color: rgba(41, 46, 49, 1.00);
	    font-size: 13px;
	    line-height: 13px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 15px 70px 15px 50px;
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(41, 46, 49, 1.00);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px;
	    text-align: left;
	    letter-spacing: 1px
	}
	.tp-caption.FoodCarousel-Button:hover,
	.FoodCarousel-Button:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: rgba(41, 46, 49, 1.00);
	    border-color: rgba(41, 46, 49, 1.00);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px
	}
	.tp-caption.FoodCarousel-CloseButton,
	.FoodCarousel-CloseButton {
	    color: rgba(41, 46, 49, 1.00);
	    font-size: 20px;
	    line-height: 20px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 14px 14px 14px 16px;
	    text-decoration: none;
	    background-color: rgba(0, 0, 0, 0);
	    border-color: rgba(41, 46, 49, 0);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 30px 30px 30px 30px;
	    text-align: left;
	    letter-spacing: 1px
	}
	.tp-caption.FoodCarousel-CloseButton:hover,
	.FoodCarousel-CloseButton:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: rgba(41, 46, 49, 1.00);
	    border-color: rgba(41, 46, 49, 0);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 30px 30px 30px 30px
	}
	.tp-caption.Video-SubTitle,
	.Video-SubTitle {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 12px;
	    line-height: 12px;
	    font-weight: 600;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 5px 5px 5px 5px;
	    text-decoration: none;
	    background-color: rgba(0, 0, 0, 0.35);
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    letter-spacing: 2px;
	    text-align: left
	}
	.tp-caption.Video-Title,
	.Video-Title {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 30px;
	    line-height: 30px;
	    font-weight: 900;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 5px 5px 5px 5px;
	    text-decoration: none;
	    background-color: rgba(0, 0, 0, 1.00);
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Travel-BigCaption,
	.Travel-BigCaption {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 50px;
	    line-height: 50px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Travel-SmallCaption,
	.Travel-SmallCaption {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 25px;
	    line-height: 30px;
	    font-weight: 300;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Travel-CallToAction,
	.Travel-CallToAction {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 25px;
	    line-height: 25px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 12px 20px 12px 20px;
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 0.05);
	    border-color: rgba(255, 255, 255, 1.00);
	    border-style: solid;
	    border-width: 2px;
	    border-radius: 5px 5px 5px 5px;
	    text-align: left;
	    letter-spacing: 1px
	}
	.tp-caption.Travel-CallToAction:hover,
	.Travel-CallToAction:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 0.15);
	    border-color: rgba(255, 255, 255, 1.00);
	    border-style: solid;
	    border-width: 2px;
	    border-radius: 5px 5px 5px 5px
	}


	.tp-caption.RotatingWords-TitleWhite,
	.RotatingWords-TitleWhite {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 70px;
	    line-height: 70px;
	    font-weight: 800;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 0px 0px 0px 0;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.RotatingWords-Button,
	.RotatingWords-Button {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 20px;
	    line-height: 20px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 20px 50px 20px 50px;
	    text-decoration: none;
	    background-color: rgba(0, 0, 0, 0);
	    border-color: rgba(255, 255, 255, 0.15);
	    border-style: solid;
	    border-width: 2px;
	    border-radius: 0px 0px 0px 0px;
	    text-align: left;
	    letter-spacing: 3px
	}
	.tp-caption.RotatingWords-Button:hover,
	.RotatingWords-Button:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: rgba(255, 255, 255, 1.00);
	    border-style: solid;
	    border-width: 2px;
	    border-radius: 0px 0px 0px 0px
	}
	.tp-caption.RotatingWords-SmallText,
	.RotatingWords-SmallText {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 14px;
	    line-height: 20px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left;
	    text-shadow: none
	}




	.tp-caption.ContentZoom-SmallTitle,
	.ContentZoom-SmallTitle {
	    color: rgba(41, 46, 49, 1.00);
	    font-size: 33px;
	    line-height: 45px;
	    font-weight: 600;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.ContentZoom-SmallSubtitle,
	.ContentZoom-SmallSubtitle {
	    color: rgba(111, 124, 130, 1.00);
	    font-size: 16px;
	    line-height: 24px;
	    font-weight: 600;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.ContentZoom-SmallIcon,
	.ContentZoom-SmallIcon {
	    color: rgba(41, 46, 49, 1.00);
	    font-size: 20px;
	    line-height: 20px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 10px 10px 10px 10px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.ContentZoom-SmallIcon:hover,
	.ContentZoom-SmallIcon:hover {
	    color: rgba(111, 124, 130, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px
	}
	.tp-caption.ContentZoom-DetailTitle,
	.ContentZoom-DetailTitle {
	    color: rgba(41, 46, 49, 1.00);
	    font-size: 70px;
	    line-height: 70px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.ContentZoom-DetailSubTitle,
	.ContentZoom-DetailSubTitle {
	    color: rgba(111, 124, 130, 1.00);
	    font-size: 25px;
	    line-height: 25px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.ContentZoom-DetailContent,
	.ContentZoom-DetailContent {
	    color: rgba(111, 124, 130, 1.00);
	    font-size: 17px;
	    line-height: 28px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.ContentZoom-Button,
	.ContentZoom-Button {
	    color: rgba(41, 46, 49, 1.00);
	    font-size: 13px;
	    line-height: 13px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 15px 50px 15px 50px;
	    text-decoration: none;
	    background-color: rgba(0, 0, 0, 0);
	    border-color: rgba(41, 46, 49, 0.50);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px;
	    text-align: left;
	    letter-spacing: 1px
	}
	.tp-caption.ContentZoom-Button:hover,
	.ContentZoom-Button:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: rgba(41, 46, 49, 1.00);
	    border-color: rgba(41, 46, 49, 1.00);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px
	}
	.tp-caption.ContentZoom-ButtonClose,
	.ContentZoom-ButtonClose {
	    color: rgba(41, 46, 49, 1.00);
	    font-size: 13px;
	    line-height: 13px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 14px 14px 14px 16px;
	    text-decoration: none;
	    background-color: rgba(0, 0, 0, 0);
	    border-color: rgba(41, 46, 49, 0.50);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 30px 30px 30px 30px;
	    text-align: left;
	    letter-spacing: 1px
	}
	.tp-caption.ContentZoom-ButtonClose:hover,
	.ContentZoom-ButtonClose:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: rgba(41, 46, 49, 1.00);
	    border-color: rgba(41, 46, 49, 1.00);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 30px 30px 30px 30px
	}
	.tp-caption.Newspaper-Title,
	.Newspaper-Title {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 50px;
	    line-height: 55px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: "Roboto Slab";
	    padding: 0 0 10px 0;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Newspaper-Subtitle,
	.Newspaper-Subtitle {
	    color: rgba(168, 216, 238, 1.00);
	    font-size: 15px;
	    line-height: 20px;
	    font-weight: 900;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Newspaper-Button,
	.Newspaper-Button {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 13px;
	    line-height: 17px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 12px 35px 12px 35px;
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 0);
	    border-color: rgba(255, 255, 255, 0.25);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px;
	    letter-spacing: 2px;
	    text-align: left
	}
	.tp-caption.Newspaper-Button:hover,
	.Newspaper-Button:hover {
	    color: rgba(0, 0, 0, 1.00);
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(255, 255, 255, 1.00);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px
	}
	.tp-caption.rtwhitemedium,
	.rtwhitemedium {
	    font-size: 22px;
	    line-height: 26px;
	    color: rgb(255, 255, 255);
	    text-decoration: none;
	    background-color: transparent;
	    border-width: 0px;
	    border-color: rgb(0, 0, 0);
	    border-style: none;
	    text-shadow: none
	}

	@media only screen and (max-width: 767px) {
		.revtp-searchform input[type="text"],
		.revtp-searchform input[type="email"],
		.revtp-form input[type="text"],
		.revtp-form input[type="email"] { width: 200px !important; }
	}

	.revtp-searchform input[type="submit"],
	.revtp-form input[type="submit"] {
		font-family: "Arial", sans-serif;
	    line-height: 46px;
	    letter-spacing: 1px;
	    text-transform: uppercase;
	    font-size: 15px;
	    font-weight: 700;
	    padding: 0 20px;
	    border: 0;
	    background: #009aee;
	    color: #fff;


	    border-radius: 0px;
	}

	.tp-caption.Twitter-Content a,
	    .tp-caption.Twitter-Content a:visited {
	        color: #0084B4 !important
	    }
	    .tp-caption.Twitter-Content a:hover {
	        color: #0084B4 !important;
	        text-decoration: underline !important
	    }
	    .tp-caption.Concept-Title,
	    .Concept-Title {
	        color: rgba(255, 255, 255, 1.00);
	        font-size: 70px;
	        line-height: 70px;
	        font-weight: 700;
	        font-style: normal;
	        font-family: "Roboto Condensed";
	        padding: 0px 0px 10px 0px;
	        text-decoration: none;
	        text-align: left;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0px 0px 0px 0px;
	        letter-spacing: 5px
	    }
	    .tp-caption.Concept-SubTitle,
	    .Concept-SubTitle {
	        color: rgba(255, 255, 255, 0.65);
	        font-size: 25px;
	        line-height: 25px;
	        font-weight: 700;
	        font-style: italic;
	        font-family: ""Playfair Display"";
	        padding: 0px 0px 10px 0px;
	        text-decoration: none;
	        text-align: left;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0px 0px 0px 0px
	    }
	    .tp-caption.Concept-Content,
	    .Concept-Content {
	        color: rgba(255, 255, 255, 1.00);
	        font-size: 20px;
	        line-height: 30px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: "Roboto Condensed";
	        padding: 0px 0px 0px 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: rgba(0, 0, 0, 0);
	        border-color: rgba(255, 255, 255, 1.00);
	        border-style: none;
	        border-width: 2px;
	        border-radius: 0px 0px 0px 0px
	    }
	    .tp-caption.Concept-MoreBtn,
	    .Concept-MoreBtn {
	        color: rgba(255, 255, 255, 1.00);
	        font-size: 30px;
	        line-height: 30px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 10px 8px 7px 10px;
	        text-decoration: none;
	        text-align: left;
	        background-color: transparent;
	        border-color: rgba(255, 255, 255, 0);
	        border-style: solid;
	        border-width: 0px;
	        border-radius: 50px 50px 50px 50px;
	        letter-spacing: 1px;
	        text-align: left
	    }
	    .tp-caption.Concept-MoreBtn:hover,
	    .Concept-MoreBtn:hover {
	        color: rgba(255, 255, 255, 1.00);
	        text-decoration: none;
	        background-color: rgba(255, 255, 255, 0.15);
	        border-color: rgba(255, 255, 255, 0);
	        border-style: solid;
	        border-width: 0px;
	        border-radius: 50px 50px 50px 50px
	    }
	    .tp-caption.Concept-LessBtn,
	    .Concept-LessBtn {
	        color: rgba(255, 255, 255, 1.00);
	        font-size: 30px;
	        line-height: 30px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 10px 8px 7px 10px;
	        text-decoration: none;
	        text-align: left;
	        background-color: rgba(0, 0, 0, 1.00);
	        border-color: rgba(255, 255, 255, 0);
	        border-style: solid;
	        border-width: 0px;
	        border-radius: 50px 50px 50px 50px;
	        letter-spacing: 1px;
	        text-align: left
	    }
	    .tp-caption.Concept-LessBtn:hover,
	    .Concept-LessBtn:hover {
	        color: rgba(0, 0, 0, 1.00);
	        text-decoration: none;
	        background-color: rgba(255, 255, 255, 1.00);
	        border-color: rgba(255, 255, 255, 0);
	        border-style: solid;
	        border-width: 0px;
	        border-radius: 50px 50px 50px 50px
	    }
	    .tp-caption.Concept-SubTitle-Dark,
	    .Concept-SubTitle-Dark {
	        color: rgba(0, 0, 0, 0.65);
	        font-size: 25px;
	        line-height: 25px;
	        font-weight: 700;
	        font-style: italic;
	        font-family: "Playfair Display";
	        padding: 0px 0px 10px 0px;
	        text-decoration: none;
	        text-align: left;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0px 0px 0px 0px
	    }
	    .tp-caption.Concept-Title-Dark,
	    .Concept-Title-Dark {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 70px;
	        line-height: 70px;
	        font-weight: 700;
	        font-style: normal;
	        font-family: "Roboto Condensed";
	        padding: 0px 0px 10px 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0px 0px 0px 0px;
	        letter-spacing: 5px
	    }
	    .tp-caption.Concept-MoreBtn-Dark,
	    .Concept-MoreBtn-Dark {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 30px;
	        line-height: 30px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 10px 8px 7px 10px;
	        text-decoration: none;
	        text-align: left;
	        background-color: transparent;
	        border-color: rgba(255, 255, 255, 0);
	        border-style: solid;
	        border-width: 0px;
	        border-radius: 50px 50px 50px 50px;
	        letter-spacing: 1px;
	        text-align: left
	    }
	    .tp-caption.Concept-MoreBtn-Dark:hover,
	    .Concept-MoreBtn-Dark:hover {
	        color: rgba(255, 255, 255, 1.00);
	        text-decoration: none;
	        background-color: rgba(0, 0, 0, 1.00);
	        border-color: rgba(255, 255, 255, 0);
	        border-style: solid;
	        border-width: 0px;
	        border-radius: 50px 50px 50px 50px
	    }
	    .tp-caption.Concept-Content-Dark,
	    .Concept-Content-Dark {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 20px;
	        line-height: 30px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: "Roboto Condensed";
	        padding: 0px 0px 0px 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: rgba(0, 0, 0, 0);
	        border-color: rgba(255, 255, 255, 1.00);
	        border-style: none;
	        border-width: 2px;
	        border-radius: 0px 0px 0px 0px
	    }
	    .tp-caption.Concept-Notice,
	    .Concept-Notice {
	        color: rgba(255, 255, 255, 1.00);
	        font-size: 15px;
	        line-height: 15px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: "Roboto Condensed";
	        padding: 0px 0px 0px 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: rgba(0, 0, 0, 0);
	        border-color: rgba(255, 255, 255, 1.00);
	        border-style: none;
	        border-width: 2px;
	        border-radius: 0px 0px 0px 0px;
	        letter-spacing: 2px
	    }
	    .tp-caption.Concept-Content a,
	    .tp-caption.Concept-Content a:visited {
	        color: #fff !important;
	        border-bottom: 1px solid #fff !important;
	        font-weight: 700 !important;
	    }
	    .tp-caption.Concept-Content a:hover {
	        border-bottom: 1px solid transparent !important;
	    }
	    .tp-caption.Concept-Content-Dark a,
	    .tp-caption.Concept-Content-Dark a:visited {
	        color: #000 !important;
	        border-bottom: 1px solid #000 !important;
	        font-weight: 700 !important;
	    }
	    .tp-caption.Concept-Content-Dark a:hover {
	        border-bottom: 1px solid transparent !important;
	    }

	    .tp-caption.Twitter-Content a,
	    .tp-caption.Twitter-Content a:visited {
	        color: #0084B4 !important
	    }
	    .tp-caption.Twitter-Content a:hover {
	        color: #0084B4 !important;
	        text-decoration: underline !important
	    }
	    .tp-caption.Creative-Title,
	    .Creative-Title {
	        color: rgba(255, 255, 255, 1.00);
	        font-size: 70px;
	        line-height: 70px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: "Playfair Display";
	        padding: 0px 0px 0px 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0px 0px 0px 0px
	    }
	    .tp-caption.Creative-SubTitle,
	    .Creative-SubTitle {
	        color: rgba(205, 176, 131, 1.00);
	        font-size: 14px;
	        line-height: 14px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0px 0px 0px 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0px 0px 0px 0px;
	        letter-spacing: 2px
	    }
	    .tp-caption.Creative-Button,
	    .Creative-Button {
	        color: rgba(205, 176, 131, 1.00);
	        font-size: 13px;
	        line-height: 13px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: Lato;
	        padding: 15px 50px 15px 50px;
	        text-decoration: none;
	        text-align: left;
	        background-color: rgba(0, 0, 0, 0);
	        border-color: rgba(205, 176, 131, 0.25);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 0px 0px 0px 0px;
	        letter-spacing: 2px
	    }
	    .tp-caption.Creative-Button:hover,
	    .Creative-Button:hover {
	        color: rgba(205, 176, 131, 1.00);
	        text-decoration: none;
	        background-color: rgba(0, 0, 0, 0);
	        border-color: rgba(205, 176, 131, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 0px 0px 0px 0px
	    }

	.tp-caption.subcaption,
	    .subcaption {
	        color: rgba(111, 124, 130, 1.00);
	        font-size: 19px;
	        line-height: 24px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: roboto;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        background-color: transparent;
	        border-color: rgba(0, 0, 0, 1.00);
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-shadow: none;
	        text-align: left
	    }
	    .tp-caption.RedDot,
	    .RedDot {
	        color: rgba(0, 0, 0, 1.00);
	        font-weight: 400;
	        font-style: normal;
	        padding: 0px 0px 0px 0px;
	        text-decoration: none;
	        text-align: left;
	        background-color: rgba(213, 0, 0, 1.00);
	        border-color: rgba(255, 255, 255, 1.00);
	        border-style: solid;
	        border-width: 5px;
	        border-radius: 50px 50px 50px 50px
	    }
	    .tp-caption.RedDot:hover,
	    .RedDot:hover {
	        color: rgba(0, 0, 0, 1.00);
	        text-decoration: none;
	        background-color: rgba(255, 255, 255, 0.75);
	        border-color: rgba(213, 0, 0, 1.00);
	        border-style: solid;
	        border-width: 5px;
	        border-radius: 50px 50px 50px 50px
	    }

	    .tp-caption.SlidingOverlays-Title,
	    .SlidingOverlays-Title {
	        color: rgba(255, 255, 255, 1.00);
	        font-size: 50px;
	        line-height: 50px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: "Playfair Display";
	        padding: 0px 0px 0px 0px;
	        text-decoration: none;
	        text-align: left;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0px 0px 0px 0px
	    }
	    .tp-caption.SlidingOverlays-Title,
	    .SlidingOverlays-Title {
	        color: rgba(255, 255, 255, 1.00);
	        font-size: 50px;
	        line-height: 50px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: "Playfair Display";
	        padding: 0px 0px 0px 0px;
	        text-decoration: none;
	        text-align: left;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0px 0px 0px 0px
	    }

	     .tp-caption.Woo-TitleLarge,
	    .Woo-TitleLarge {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 40px;
	        line-height: 40px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: "Playfair Display";
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center;

	    }
	    .tp-caption.Woo-Rating,
	    .Woo-Rating {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 14px;
	        line-height: 30px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: left;

	    }
	    .tp-caption.Woo-SubTitle,
	    .Woo-SubTitle {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 18px;
	        line-height: 18px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center;
	        letter-spacing: 2px;

	    }
	    .tp-caption.Woo-PriceLarge,
	    .Woo-PriceLarge {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 60px;
	        line-height: 60px;
	        font-weight: 700;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center;

	    }
	    .tp-caption.Woo-ProductInfo,
	    .Woo-ProductInfo {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 15px;
	        line-height: 15px;
	        font-weight: 500;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 12px 75px 12px 50px;
	        text-decoration: none;
	        background-color: rgba(254, 207, 114, 1.00);
	        border-color: rgba(0, 0, 0, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 4px 4px 4px 4px;
	        text-align: left;

	    }
	    .tp-caption.Woo-ProductInfo:hover,
	    .Woo-ProductInfo:hover {
	        color: rgba(0, 0, 0, 1.00);
	        text-decoration: none;
	        background-color: rgba(243, 168, 71, 1.00);
	        border-color: rgba(0, 0, 0, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 4px 4px 4px 4px
	    }
	    .tp-caption.Woo-AddToCart,
	    .Woo-AddToCart {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 15px;
	        line-height: 15px;
	        font-weight: 500;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 12px 35px 12px 35px;
	        text-decoration: none;
	        background-color: rgba(254, 207, 114, 1.00);
	        border-color: rgba(0, 0, 0, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 4px 4px 4px 4px;
	        text-align: left;

	    }
	    .tp-caption.Woo-AddToCart:hover,
	    .Woo-AddToCart:hover {
	        color: rgba(0, 0, 0, 1.00);
	        text-decoration: none;
	        background-color: rgba(243, 168, 71, 1.00);
	        border-color: rgba(0, 0, 0, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 4px 4px 4px 4px
	    }
	    .tp-caption.Woo-TitleLarge,
	    .Woo-TitleLarge {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 40px;
	        line-height: 40px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: "Playfair Display";
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center;

	    }
	    .tp-caption.Woo-SubTitle,
	    .Woo-SubTitle {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 18px;
	        line-height: 18px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center;
	        letter-spacing: 2px;

	    }
	    .tp-caption.Woo-PriceLarge,
	    .Woo-PriceLarge {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 60px;
	        line-height: 60px;
	        font-weight: 700;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center;

	    }
	    .tp-caption.Woo-ProductInfo,
	    .Woo-ProductInfo {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 15px;
	        line-height: 15px;
	        font-weight: 500;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 12px 75px 12px 50px;
	        text-decoration: none;
	        background-color: rgba(254, 207, 114, 1.00);
	        border-color: rgba(0, 0, 0, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 4px 4px 4px 4px;
	        text-align: left;

	    }
	    .tp-caption.Woo-ProductInfo:hover,
	    .Woo-ProductInfo:hover {
	        color: rgba(0, 0, 0, 1.00);
	        text-decoration: none;
	        background-color: rgba(243, 168, 71, 1.00);
	        border-color: rgba(0, 0, 0, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 4px 4px 4px 4px
	    }
	    .tp-caption.Woo-AddToCart,
	    .Woo-AddToCart {
	        color: rgba(0, 0, 0, 1.00);
	        font-size: 15px;
	        line-height: 15px;
	        font-weight: 500;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 12px 35px 12px 35px;
	        text-decoration: none;
	        background-color: rgba(254, 207, 114, 1.00);
	        border-color: rgba(0, 0, 0, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 4px 4px 4px 4px;
	        text-align: left;

	    }
	    .tp-caption.Woo-AddToCart:hover,
	    .Woo-AddToCart:hover {
	        color: rgba(0, 0, 0, 1.00);
	        text-decoration: none;
	        background-color: rgba(243, 168, 71, 1.00);
	        border-color: rgba(0, 0, 0, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 4px 4px 4px 4px
	    }

	    .tp-caption.FullScreen-Toggle,
	    .FullScreen-Toggle {
	        color: rgba(255, 255, 255, 1.00);
	        font-size: 20px;
	        line-height: 20px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: Raleway;
	        padding: 11px 8px 11px 12px;
	        text-decoration: none;
	        text-align: left;
	        background-color: rgba(0, 0, 0, 0.50);
	        border-color: rgba(255, 255, 255, 0);
	        border-style: solid;
	        border-width: 0px;
	        border-radius: 0px 0px 0px 0px;
	        letter-spacing: 3px;
	        text-align: left
	    }
	    .tp-caption.FullScreen-Toggle:hover,
	    .FullScreen-Toggle:hover {
	        color: rgba(255, 255, 255, 1.00);
	        text-decoration: none;
	        background-color: rgba(0, 0, 0, 1.00);
	        border-color: rgba(255, 255, 255, 0);
	        border-style: solid;
	        border-width: 0px;
	        border-radius: 0px 0px 0px 0px
	    }

	    .tp-caption.Agency-Title,
	.Agency-Title {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 70px;
	    line-height: 70px;
	    font-weight: 900;
	    font-style: normal;
	    font-family: lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left;
	    letter-spacing: 10px
	}
	.tp-caption.Agency-SubTitle,
	.Agency-SubTitle {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 20px;
	    line-height: 20px;
	    font-weight: 400;
	    font-style: italic;
	    font-family: Georgia, serif;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center
	}
	.tp-caption.Agency-PlayBtn,
	.Agency-PlayBtn {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 30px;
	    line-height: 71px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0px 0px 0px 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: rgba(255, 255, 255, 1.00);
	    border-style: solid;
	    border-width: 2px;
	    border-radius: 100px 100px 100px 100px;
	    text-align: center
	}
	.tp-caption.Agency-PlayBtn:hover,
	.Agency-PlayBtn:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: rgba(255, 255, 255, 1.00);
	    border-style: solid;
	    border-width: 2px;
	    border-radius: 100px 100px 100px 100px;
	    cursor: pointer
	}
	.tp-caption.Agency-SmallText,
	.Agency-SmallText {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 12px;
	    line-height: 12px;
	    font-weight: 900;
	    font-style: normal;
	    font-family: lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left;
	    letter-spacing: 5px
	}
	.tp-caption.Agency-Social,
	.Agency-Social {
	    color: rgba(51, 51, 51, 1.00);
	    font-size: 25px;
	    line-height: 50px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Georgia, serif;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: rgba(51, 51, 51, 1.00);
	    border-style: solid;
	    border-width: 2px;
	    border-radius: 30px 30px 30px 30px;
	    text-align: center
	}
	.tp-caption.Agency-Social:hover,
	.Agency-Social:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: rgba(51, 51, 51, 1.00);
	    border-color: rgba(51, 51, 51, 1.00);
	    border-style: solid;
	    border-width: 2px;
	    border-radius: 30px 30px 30px 30px;
	    cursor: pointer
	}
	.tp-caption.Agency-CloseBtn,
	.Agency-CloseBtn {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 50px;
	    line-height: 50px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0px 0px 0px 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: rgba(255, 255, 255, 0);
	    border-style: none;
	    border-width: 0px;
	    border-radius: 100px 100px 100px 100px;
	    text-align: center
	}
	.tp-caption.Agency-CloseBtn:hover,
	.Agency-CloseBtn:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: rgba(255, 255, 255, 0);
	    border-style: none;
	    border-width: 0px;
	    border-radius: 100px 100px 100px 100px;
	    cursor: pointer
	}

	.tp-caption.Dining-Title,
	.Dining-Title {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 70px;
	    line-height: 70px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Georgia, serif;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left;
	    letter-spacing: 10px
	}
	.tp-caption.Dining-SubTitle,
	.Dining-SubTitle {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 20px;
	    line-height: 20px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Georgia, serif;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Dining-BtnLight,
	.Dining-BtnLight {
	    color: rgba(255, 255, 255, 0.50);
	    font-size: 15px;
	    line-height: 15px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: Lato;
	    padding: 17px 73px 17px 50px;
	    text-decoration: none;
	    background-color: rgba(0, 0, 0, 0);
	    border-color: rgba(255, 255, 255, 0.25);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px;
	    text-align: left;
	    letter-spacing: 2px
	}
	.tp-caption.Dining-BtnLight:hover,
	.Dining-BtnLight:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: rgba(0, 0, 0, 0);
	    border-color: rgba(255, 255, 255, 1.00);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px
	}
	.tp-caption.Dining-Social,
	.Dining-Social {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 25px;
	    line-height: 50px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Georgia, serif;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: rgba(255, 255, 255, 0.25);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 30px 30px 30px 30px;
	    text-align: center
	}
	.tp-caption.Dining-Social:hover,
	.Dining-Social:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: rgba(255, 255, 255, 1.00);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 30px 30px 30px 30px;
	    cursor: pointer
	}
	tp-caption.Team-Thumb,
	.Team-Thumb {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 20px;
	    line-height: 22px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Arial;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Team-Thumb:hover,
	.Team-Thumb:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    cursor: pointer
	}
	.tp-caption.Team-Name,
	.Team-Name {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 70px;
	    line-height: 70px;
	    font-weight: 900;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Team-Position,
	.Team-Position {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 30px;
	    line-height: 30px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Georgia, serif;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Team-Description,
	.Team-Description {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 18px;
	    line-height: 28px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Team-Social,
	.Team-Social {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 50px;
	    line-height: 50px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Arial;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center
	}
	.tp-caption.Team-Social:hover,
	.Team-Social:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0px 0px 0px 0px;
	    cursor: pointer
	}

	.tp-caption.VideoControls-Play,
	.VideoControls-Play {
	    color: rgba(0, 0, 0, 1.00);
	    font-size: 50px;
	    line-height: 120px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0px 0px 0px 7px;
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 100px 100px 100px 100px;
	    text-align: center
	}
	.tp-caption.VideoControls-Play:hover,
	.VideoControls-Play:hover {
	    color: rgba(0, 0, 0, 1.00);
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 100px 100px 100px 100px;
	    cursor: pointer
	}
	.tp-caption.VideoPlayer-Title,
	.VideoPlayer-Title {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 40px;
	    line-height: 40px;
	    font-weight: 900;
	    font-style: normal;
	    font-family: Lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left;
	    letter-spacing: 10px
	}
	.tp-caption.VideoPlayer-SubTitle,
	.VideoPlayer-SubTitle {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 20px;
	    line-height: 20px;
	    font-weight: 400;
	    font-style: italic;
	    font-family: Georgia, serif;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center
	}
	.tp-caption.VideoPlayer-Social,
	.VideoPlayer-Social {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 50px;
	    line-height: 50px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Arial;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center
	}
	.tp-caption.VideoPlayer-Social:hover,
	.VideoPlayer-Social:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0px 0px 0px 0px;
	    cursor: pointer
	}
	.tp-caption.VideoControls-Mute,
	.VideoControls-Mute {
	    color: rgba(0, 0, 0, 1.00);
	    font-size: 20px;
	    line-height: 50px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0px 0px 0px 0px;
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 100px 100px 100px 100px;
	    text-align: center
	}
	.tp-caption.VideoControls-Mute:hover,
	.VideoControls-Mute:hover {
	    color: rgba(0, 0, 0, 1.00);
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 100px 100px 100px 100px;
	    cursor: pointer
	}
	.tp-caption.VideoControls-Pause,
	.VideoControls-Pause {
	    color: rgba(0, 0, 0, 1.00);
	    font-size: 20px;
	    line-height: 50px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0px 0px 0px 0px;
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 100px 100px 100px 100px;
	    text-align: center
	}
	.tp-caption.VideoControls-Pause:hover,
	.VideoControls-Pause:hover {
	    color: rgba(0, 0, 0, 1.00);
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 100px 100px 100px 100px;
	    cursor: pointer
	 }

	.soundcloudwrapper iframe {
	    width: 100% !important
	}
	.tp-caption.SleekLanding-Title,
	.SleekLanding-Title {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 35px;
	    line-height: 40px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: left;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left;
	    letter-spacing: 5px
	}
	.tp-caption.SleekLanding-ButtonBG,
	.SleekLanding-ButtonBG {
	    color: rgba(0, 0, 0, 1.00);

	    font-weight: 700;
	    font-style: normal;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: left;
	    background-color: rgba(255, 255, 255, 0.10);
	    border-color: rgba(0, 0, 0, 0);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 5px 5px 5px 5px;
	    text-align: left;
	    box-shadow: inset 0px 2px 0px 0px rgba(0, 0, 0, 0.15)
	}
	.tp-caption.SleekLanding-SmallTitle,
	.SleekLanding-SmallTitle {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 13px;
	    line-height: 50px;
	    font-weight: 900;
	    font-style: normal;
	    font-family: Lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: left;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left;
	    letter-spacing: 2px
	}
	.tp-caption.SleekLanding-BottomText,
	.SleekLanding-BottomText {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 15px;
	    line-height: 24px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: left;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.SleekLanding-Social,
	.SleekLanding-Social {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 22px;
	    line-height: 30px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Arial;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center
	}
	.tp-caption.SleekLanding-Social:hover,
	.SleekLanding-Social:hover {
	    color: rgba(0, 0, 0, 0.25);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    cursor: pointer
	}
	#rev_slider_429_1_wrapper .tp-loader.spinner2 {
	    background-color: #555555 !important;
	}
	.tp-fat {
	    font-weight: 900 !important;
	}

	.tp-caption.PostSlider-Category,
	.PostSlider-Category {
	    color: rgba(0, 0, 0, 1.00);
	    font-size: 15px;
	    line-height: 15px;
	    font-weight: 300;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    letter-spacing: 3px;
	    text-align: left
	}
	.tp-caption.PostSlider-Title,
	.PostSlider-Title {
	    color: rgba(0, 0, 0, 1.00);
	    font-size: 40px;
	    line-height: 40px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: "Playfair Display";
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.PostSlider-Content,
	.PostSlider-Content {
	    color: rgba(119, 119, 119, 1.00);
	    font-size: 15px;
	    line-height: 23px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.PostSlider-Button,
	.PostSlider-Button {
	    color: rgba(0, 0, 0, 1.00);
	    font-size: 15px;
	    line-height: 40px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 1px 56px 1px 32px;
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px;
	    text-align: left
	}
	.tp-caption.PostSlider-Button:hover,
	.PostSlider-Button:hover {
	    color: rgba(0, 0, 0, 1.00);
	    text-decoration: none;
	    background-color: rgba(238, 238, 238, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 1px;
	    border-radius: 0px 0px 0px 0px;
	    cursor: pointer
	}

	/* media queries */

	@media only screen and (max-width: 960px) {} @media only screen and (max-width: 768px) {} .tp-caption.LandingPage-Title,
	.LandingPage-Title {
	    color:rgba(255,
	    255,
	    255,
	    1.00);
	    font-size:70px;
	    line-height:80px;
	    font-weight:900;
	    font-style:normal;
	    font-family:Lato;
	    padding:0 0 0 0px;
	    text-decoration:none;
	    background-color:transparent;
	    border-color:transparent;
	    border-style:none;
	    border-width:0px;
	    border-radius:0 0 0 0px;
	    text-align:left;
	    letter-spacing:10px
	}
	.tp-caption.LandingPage-SubTitle,
	.LandingPage-SubTitle {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 20px;
	    line-height: 30px;
	    font-weight: 400;
	    font-style: italic;
	    font-family: Georgia, serif;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.LandingPage-Button,
	.LandingPage-Button {
	    color: rgba(0, 0, 0, 1.00);
	    font-size: 15px;
	    line-height: 54px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0px 35px 0px 35px;
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 0px 0px 0px 0px;
	    text-align: left;
	    letter-spacing: 3px
	}
	.tp-caption.LandingPage-Button:hover,
	.LandingPage-Button:hover {
	    color: rgba(0, 0, 0, 1.00);
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 0px 0px 0px 0px;
	    cursor: pointer
	}
	.tp-caption.App-Content a,
	.tp-caption.App-Content a:visited {
	    color: #89124e !important;
	    border-bottom: 1px solid transparent !important;
	    font-weight: bold !important;
	}
	.tp-caption.App-Content a:hover {
	    border-bottom: 1px solid #89124e !important;
	}
	.tp-caption.RockBand-LogoText,
	.RockBand-LogoText {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 60px;
	    line-height: 60px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: Oswald;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: left;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Twitter-Content a,
	.tp-caption.Twitter-Content a:visited {
	    color: #fff !important;
	    text-decoration: underline !important;
	}
	.tp-caption.Twitter-Content a:hover {
	    color: #fff !important;
	    text-decoration: none !important;
	}
	.soundcloudwrapper iframe {
	    width: 100% !important
	}

	.tp-caption.Agency-LogoText,
	.Agency-LogoText {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 12px;
	    line-height: 20px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center;
	    letter-spacing: 1px
	}
	.tp-caption.ComingSoon-Highlight,
	.ComingSoon-Highlight {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 20px;
	    line-height: 37px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Lato;
	    padding: 0 20px 3px 20px;
	    text-decoration: none;
	    text-align: left;
	    background-color: rgba(0, 154, 238, 1.00);
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.ComingSoon-Count,
	.ComingSoon-Count {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 50px;
	    line-height: 50px;
	    font-weight: 900;
	    font-style: normal;
	    font-family: Lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: left;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.ComingSoon-CountUnit,
	.ComingSoon-CountUnit {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 20px;
	    line-height: 20px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center
	}
	.tp-caption.ComingSoon-NotifyMe,
	.ComingSoon-NotifyMe {
	    color: rgba(164, 157, 143, 1.00);
	    font-size: 27px;
	    line-height: 35px;
	    font-weight: 600;
	    font-style: normal;
	    font-family: Lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center
	}

	#mc_embed_signup input#mce-EMAIL {
	    font-family: "Lato", sans-serif;
	    font-size: 15px;
	    color: #000;
	    background-color: #fff;
	    line-height: 46px;
	    padding: 0 20px;
	    cursor: text;
	    border: 1px solid #fff;
	    width: 400px;
	    margin-bottom: 0px;
	    -webkit-transition: background-color 0.5s;
	    -moz-transition: background-color 0.5s;
	    -o-transition: background-color 0.5s;
	    -ms-transition: background-color 0.5s;
	    transition: background-color 0.5s;


	    border-radius: 0px;
	}
	#mc_embed_signup input#mce-EMAIL[type="email"]:focus {
	    background-color: #fff;
	    border: 1px solid #666;
	    border-right: 0;
	}
	#mc_embed_signup input#mc-embedded-subscribe,
	#mc_embed_signup input#mc-embedded-subscribe:focus {
	    font-family: "Lato", sans-serif;
	    line-height: 46px;
	    letter-spacing: 1px;
	    text-transform: uppercase;
	    font-size: 13px;
	    font-weight: 900;
	    padding: 0 20px;
	    border: 1px solid #009aee;
	    background: #009aee;
	    color: #fff;


	    border-radius: 0px;
	}
	#mc_embed_signup input#mc-embedded-subscribe:hover {
	    background: #0083d4;
	}
	@media only screen and (max-width: 767px) {
	    #mc_embed_signup input#mce-EMAIL {
	        width: 200px;
	    }
	}
	.tp-caption.Agency-SmallTitle,
	.Agency-SmallTitle {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 15px;
	    line-height: 22px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center;
	    letter-spacing: 6px
	}
	.tp-caption.Agency-SmallContent,
	.Agency-SmallContent {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 15px;
	    line-height: 24px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center
	}
	.tp-caption.Agency-SmallLink,
	.Agency-SmallLink {
	    color: rgba(248, 124, 9, 1.00);
	    font-size: 12px;
	    line-height: 22px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: lato;
	    padding: 0 0 0px 0;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center;
	    letter-spacing: 2px;
	    border-bottom: 1px solid #f87c09 !important
	}
	.tp-caption.Agency-SmallLink:hover,
	.Agency-SmallLink:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    cursor: pointer
	}
	.tp-caption.Agency-NavButton,
	.Agency-NavButton {
	    color: rgba(51, 51, 51, 1.00);
	    font-size: 17px;
	    line-height: 50px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0px 0px 0px 0px;
	    text-decoration: none;
	    text-align: center;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 0px 0px 0px 0px;
	    text-align: center
	}
	.tp-caption.Agency-NavButton:hover,
	.Agency-NavButton:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: rgba(51, 51, 51, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 0px 0px 0px 0px;
	    cursor: pointer
	}
	.tp-caption.Agency-SmallLinkGreen,
	.Agency-SmallLinkGreen {
	    color: rgba(109, 177, 155, 1.00);
	    font-size: 12px;
	    line-height: 22px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: lato;
	    padding: 0 0 0px 0;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center;
	    letter-spacing: 2px;
	    border-bottom: 1px solid #6db19b !important
	}
	.tp-caption.Agency-SmallLinkGreen:hover,
	.Agency-SmallLinkGreen:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    cursor: pointer
	}
	.tp-caption.Agency-SmallLinkBlue,
	.Agency-SmallLinkBlue {
	    color: rgba(153, 153, 153, 1.00);
	    font-size: 12px;
	    line-height: 22px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: lato;
	    padding: 0 0 0px 0;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center;
	    letter-spacing: 2px;
	    border-bottom: 1px solid #999 !important
	}
	.tp-caption.Agency-SmallLinkBlue:hover,
	.Agency-SmallLinkBlue:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    cursor: pointer
	}
	.tp-caption.Agency-LogoText,
	.Agency-LogoText {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 12px;
	    line-height: 20px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center;
	    letter-spacing: 1px
	}
	.tp-caption.Agency-ArrowTooltip,
	.Agency-ArrowTooltip {
	    color: rgba(51, 51, 51, 1.00);
	    font-size: 15px;
	    line-height: 20px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: "Permanent Marker";
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: left;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: left
	}
	.tp-caption.Agency-SmallSocial,
	.Agency-SmallSocial {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 30px;
	    line-height: 30px;
	    font-weight: 400;
	    font-style: normal;
	    font-family: Arial;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center
	}
	.tp-caption.Agency-SmallSocial:hover,
	.Agency-SmallSocial:hover {
	    color: rgba(51, 51, 51, 1.00);
	    text-decoration: none;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0px 0px 0px 0px;
	    cursor: pointer
	}
	.tp-caption.Twitter-Content a,
	.tp-caption.Twitter-Content a:visited {
	    color: #0084B4 !important
	}
	.tp-caption.Twitter-Content a:hover {
	    color: #0084B4 !important;
	    text-decoration: underline !important
	}
	.tp-caption.CreativeFrontPage-Btn,
	.CreativeFrontPage-Btn {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 14px;
	    line-height: 60px;
	    font-weight: 900;
	    font-style: normal;
	    font-family: Roboto;
	    padding: 0px 50px 0px 50px;
	    text-decoration: none;
	    text-align: left;
	    background-color: rgba(0, 104, 92, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 4px 4px 4px 4px;
	    letter-spacing: 2px
	}
	.tp-caption.CreativeFrontPage-Btn:hover,
	.CreativeFrontPage-Btn:hover {
	    color: rgba(255, 255, 255, 1.00);
	    text-decoration: none;
	    background-color: rgba(0, 0, 0, 0.25);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 4px 4px 4px 4px;
	    cursor: pointer
	}
	.tp-caption.CreativeFrontPage-Menu,
	.CreativeFrontPage-Menu {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 14px;
	    line-height: 14px;
	    font-weight: 500;
	    font-style: normal;
	    font-family: roboto;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: left;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    letter-spacing: 2px
	}
	.tp-flip-index {
	    z-index: 1000 !important;
	}
	.tp-caption.Twitter-Content a,
	.tp-caption.Twitter-Content a:visited {
	    color: #0084B4 !important
	}
	.tp-caption.Twitter-Content a:hover {
	    color: #0084B4 !important;
	    text-decoration: underline !important
	}
	.tp-caption.FullScreenMenu-Category,
	.FullScreenMenu-Category {
	    color: rgba(17, 17, 17, 1.00);
	    font-size: 20px;
	    line-height: 20px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: BenchNine;
	    padding: 21px 30px 16px 30px;
	    text-decoration: none;
	    text-align: left;
	    background-color: rgba(255, 255, 255, 0.90);
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    letter-spacing: 3px
	}
	.tp-caption.FullScreenMenu-Title,
	.FullScreenMenu-Title {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 65px;
	    line-height: 70px;
	    font-weight: 700;
	    font-style: normal;
	    font-family: BenchNine;
	    padding: 21px 30px 16px 30px;
	    text-decoration: none;
	    text-align: left;
	    background-color: rgba(17, 17, 17, 0.90);
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px
	}
	.tp-caption.Twitter-Content a,
	.tp-caption.Twitter-Content a:visited {
	    color: #0084B4 !important
	}
	.tp-caption.Twitter-Content a:hover {
	    color: #0084B4 !important;
	    text-decoration: underline !important
	}
	.tp-caption.TechJournal-Button,
	.TechJournal-Button {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 13px;
	    line-height: 40px;
	    font-weight: 900;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 1px 30px 1px 30px;
	    text-decoration: none;
	    text-align: left;
	    background-color: rgba(138, 0, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 0px 0px 0px 0px;
	    letter-spacing: 3px
	}
	.tp-caption.TechJournal-Button:hover,
	.TechJournal-Button:hover {
	    color: rgba(0, 0, 0, 1.00);
	    text-decoration: none;
	    background-color: rgba(255, 255, 255, 1.00);
	    border-color: rgba(0, 0, 0, 1.00);
	    border-style: solid;
	    border-width: 0px;
	    border-radius: 0px 0px 0px 0px;
	    cursor: pointer
	}
	.tp-caption.TechJournal-Big,
	.TechJournal-Big {
	    color: rgba(255, 255, 255, 1.00);
	    font-size: 120px;
	    line-height: 120px;
	    font-weight: 900;
	    font-style: normal;
	    font-family: Raleway;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: left;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    letter-spacing: 0px
	}
	.rev_slider {
	    overflow: hidden;
	}
	.effect_layer {
	    position: absolute;
	    top: 0px;
	    left: 0px;
	    width: 100%;
	    height: 100%;
	}

	.tp-caption.Twitter-Content a,
	    .tp-caption.Twitter-Content a:visited {
	        color: #0084B4 !important
	    }
	    .tp-caption.Twitter-Content a:hover {
	        color: #0084B4 !important;
	        text-decoration: underline !important
	    }
	    #menu_forcefullwidth {
	        z-index: 5000;
	        position: fixed !important;
	        top: 0px;
	        left: 0px;
	        width: 100%
	    }
	    .tp-caption.FullSiteBlock-Title,
	    .FullSiteBlock-Title {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 55px;
	        line-height: 65px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-SubTitle,
	    .FullSiteBlock-SubTitle {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 34px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-Link,
	    .FullSiteBlock-Link {
	        color: rgba(0, 150, 255, 1.00);
	        font-size: 25px;
	        line-height: 24px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-Link:hover,
	    .FullSiteBlock-Link:hover {
	        color: rgba(51, 51, 51, 1.00);
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        cursor: pointer
	    }
	    .tp-caption.FullSiteBlock-DownButton,
	    .FullSiteBlock-DownButton {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 32px;
	        font-weight: 500;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 1px 1px 1px 1px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: rgba(51, 51, 51, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 30px 30px 30px 30px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-DownButton:hover,
	    .FullSiteBlock-DownButton:hover {
	        color: rgba(0, 150, 255, 1.00);
	        text-decoration: none;
	        background-color: transparent;
	        border-color: rgba(0, 150, 255, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 30px 30px 30px 30px;
	        cursor: pointer
	    }
	    .tp-caption.FullSiteBlock-Title,
	    .FullSiteBlock-Title {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 55px;
	        line-height: 65px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-SubTitle,
	    .FullSiteBlock-SubTitle {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 34px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-Link,
	    .FullSiteBlock-Link {
	        color: rgba(0, 150, 255, 1.00);
	        font-size: 25px;
	        line-height: 24px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-Link:hover,
	    .FullSiteBlock-Link:hover {
	        color: rgba(51, 51, 51, 1.00);
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        cursor: pointer
	    }
	    .tp-caption.FullSiteBlock-DownButton,
	    .FullSiteBlock-DownButton {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 32px;
	        font-weight: 500;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 1px 1px 1px 1px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: rgba(51, 51, 51, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 30px 30px 30px 30px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-DownButton:hover,
	    .FullSiteBlock-DownButton:hover {
	        color: rgba(0, 150, 255, 1.00);
	        text-decoration: none;
	        background-color: transparent;
	        border-color: rgba(0, 150, 255, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 30px 30px 30px 30px;
	        cursor: pointer
	    }
	    .tp-caption.FullSiteBlock-Title,
	    .FullSiteBlock-Title {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 55px;
	        line-height: 65px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-SubTitle,
	    .FullSiteBlock-SubTitle {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 34px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-DownButton,
	    .FullSiteBlock-DownButton {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 32px;
	        font-weight: 500;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 1px 1px 1px 1px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: rgba(51, 51, 51, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 30px 30px 30px 30px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-DownButton:hover,
	    .FullSiteBlock-DownButton:hover {
	        color: rgba(0, 150, 255, 1.00);
	        text-decoration: none;
	        background-color: transparent;
	        border-color: rgba(0, 150, 255, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 30px 30px 30px 30px;
	        cursor: pointer
	    }
	    .tp-caption.FullSiteBlock-Title,
	    .FullSiteBlock-Title {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 55px;
	        line-height: 65px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-SubTitle,
	    .FullSiteBlock-SubTitle {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 34px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-Link,
	    .FullSiteBlock-Link {
	        color: rgba(0, 150, 255, 1.00);
	        font-size: 25px;
	        line-height: 24px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-Link:hover,
	    .FullSiteBlock-Link:hover {
	        color: rgba(51, 51, 51, 1.00);
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        cursor: pointer
	    }
	    .tp-caption.FullSiteBlock-DownButton,
	    .FullSiteBlock-DownButton {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 32px;
	        font-weight: 500;
	        font-style: normal;
	        font-family: Roboto;
	        padding: 1px 1px 1px 1px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: rgba(51, 51, 51, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 30px 30px 30px 30px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-DownButton:hover,
	    .FullSiteBlock-DownButton:hover {
	        color: rgba(0, 150, 255, 1.00);
	        text-decoration: none;
	        background-color: transparent;
	        border-color: rgba(0, 150, 255, 1.00);
	        border-style: solid;
	        border-width: 1px;
	        border-radius: 30px 30px 30px 30px;
	        cursor: pointer
	    }
	    .rev_slider {
	        overflow: hidden;
	    }
	    .effect_layer {
	        position: absolute;
	        top: 0px;
	        left: 0px;
	        width: 100%;
	        height: 100%;
	    }
	    .gyges .tp-thumb {
	        opacity: 1
	    }
	    .gyges .tp-thumb-img-wrap {
	        padding: 3px;
	        background-color: rgba(0, 0, 0, 0.25);
	        display: inline-block;
	        width: 100%;
	        height: 100%;
	        position: relative;
	        margin: 0px;
	        box-sizing: border-box;
	        transition: all 0.3s;
	        -webkit-transition: all 0.3s;
	    }
	    .gyges .tp-thumb-image {
	        padding: 3px;
	        display: block;
	        box-sizing: border-box;
	        position: relative;
	        -webkit-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
	        -moz-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
	        box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
	    }
	    .gyges .tp-thumb:hover .tp-thumb-img-wrap,
	    .gyges .tp-thumb.selected .tp-thumb-img-wrap {
	        background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
	        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
	        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
	        background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
	        background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
	        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
	    }
	    .tp-caption.FullSiteBlock-Title,
	    .FullSiteBlock-Title {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 55px;
	        line-height: 65px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-SubTitle,
	    .FullSiteBlock-SubTitle {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 34px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-Link,
	    .FullSiteBlock-Link {
	        color: rgba(0, 150, 255, 1.00);
	        font-size: 25px;
	        line-height: 24px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-Link:hover,
	    .FullSiteBlock-Link:hover {
	        color: rgba(51, 51, 51, 1.00);
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        cursor: pointer
	    }
	    .tp-caption.FullSiteBlock-SubTitle,
	    .FullSiteBlock-SubTitle {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 34px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-SubTitle,
	    .FullSiteBlock-SubTitle {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 34px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.FullSiteBlock-FooterLink,
	    .FullSiteBlock-FooterLink {
	        color: rgba(85, 85, 85, 1.00);
	        font-size: 15px;
	        line-height: 20px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: left;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: left
	    }
	    .tp-caption.FullSiteBlock-FooterLink:hover,
	    .FullSiteBlock-FooterLink:hover {
	        color: rgba(0, 150, 255, 1.00);
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        cursor: pointer
	    }
	    .fb-share-button.fb_iframe_widget iframe {
	        width: 115px!important;
	    }
	    #tp-socialwrapper {
	        opacity: 0;
	    }

	        .tp-caption.Twitter-Content a,
	    .tp-caption.Twitter-Content a:visited {
	        color: #0084B4 !important
	    }
	    .tp-caption.Twitter-Content a:hover {
	        color: #0084B4 !important;
	        text-decoration: underline !important
	    }
	    #menu_forcefullwidth {
	        z-index: 5000;
	        position: fixed !important;
	        top: 0px;
	        left: 0px;
	        width: 100%
	    }
	    #tp-menubg {
	        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
	        /* FF3.6-15 */

	        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
	        /* Chrome10-25,Safari5.1-6 */

	        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
	        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

	        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9000000', endColorstr='#00000000', GradientType=0);
	        /* IE6-9 */
	    }
	    #mc_embed_signup input[type="email"] {
	        font-family: "Lato", sans-serif;
	        font-size: 16px;
	        font-weight: 400;
	        background-color: #fff;
	        color: #888 !important;
	        line-height: 46px;
	        padding: 0 20px;
	        cursor: text;
	        border: 0;
	        width: 400px;
	        margin-bottom: 0px;
	        -webkit-transition: background-color 0.5s;
	        -moz-transition: background-color 0.5s;
	        -o-transition: background-color 0.5s;
	        -ms-transition: background-color 0.5s;
	        transition: background-color 0.5s;
	        -webkit-border-radius: 3px;
	        -moz-border-radius: 3px;
	        border-radius: 3px;
	    }
	    #mc_embed_signup input[type="email"]::-webkit-input-placeholder {
	        color: #888 !important;
	    }
	    #mc_embed_signup input[type="email"]::-moz-placeholder {
	        color: #888 !important;
	    }
	    #mc_embed_signup input[type="email"]:-ms-input-placeholder {
	        color: #888 !important;
	    }
	    #mc_embed_signup input[type="email"]:focus {
	        background-color: #f5f5f5;
	        color: #454545;
	    }
	    #mc_embed_signup input#mc-embedded-subscribe,
	    #mc_embed_signup input#mc-embedded-subscribe:focus {
	        font-family: "Lato", sans-serif;
	        line-height: 46px;
	        font-size: 16px;
	        font-weight: 700;
	        padding: 0 30px;
	        border: 0;
	        background: #f04531;
	        text-transform: none;
	        color: #fff;
	        -webkit-border-radius: 3px;
	        -moz-border-radius: 3px;
	        border-radius: 3px;
	    }
	    #mc_embed_signup input#mc-embedded-subscribe:hover {
	        background: #e03727;
	    }
	    @media only screen and (max-width: 767px) {
	        #mc_embed_signup input[type="email"] {
	            width: 260px;
	        }
	    }
	    @media only screen and (max-width: 480px) {
	        #mc_embed_signup input[type="email"] {
	            width: 160px;
	        }
	    }
	    #rev_slider_167_6 .uranus.tparrows {
	        width: 50px;
	        height: 50px;
	        background: rgba(255, 255, 255, 0);
	    }
	    #rev_slider_167_6 .uranus.tparrows:before {
	        width: 50px;
	        height: 50px;
	        line-height: 50px;
	        font-size: 40px;
	        transition: all 0.3s;
	        -webkit-transition: all 0.3s;
	    }
	    #rev_slider_167_6 .uranus.tparrows:hover:before {
	        opacity: 0.75;
	    }
	    .tp-caption.FullSiteBlock-SubTitle,
	    .FullSiteBlock-SubTitle {
	        color: rgba(51, 51, 51, 1.00);
	        font-size: 25px;
	        line-height: 34px;
	        font-weight: 300;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: center;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        text-align: center
	    }
	    .tp-caption.ParallaxWebsite-FooterItem,
	    .ParallaxWebsite-FooterItem {
	        color: rgba(255, 255, 255, 0.50);
	        font-size: 16px;
	        line-height: 24px;
	        font-weight: 400;
	        font-style: normal;
	        font-family: Lato;
	        padding: 0 0 0 0px;
	        text-decoration: none;
	        text-align: left;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px
	    }
	    .tp-caption.ParallaxWebsite-FooterItem:hover,
	    .ParallaxWebsite-FooterItem:hover {
	        color: rgba(255, 255, 255, 1.00);
	        text-decoration: none;
	        background-color: transparent;
	        border-color: transparent;
	        border-style: none;
	        border-width: 0px;
	        border-radius: 0 0 0 0px;
	        cursor: pointer
	    }
	    .fb-share-button.fb_iframe_widget iframe {
	        width: 115px!important;
	    }
	    iframe.twitter-share-button {
	        display: none;
	    }
	    .fb-share-button.fb_iframe_widget iframe {
	        display: none;
	    }

	    .tp-caption.FullSiteBlock-Link,
	    .FullSiteBlock-Link {
	    color: rgba(0,150,255,1.00);
	    font-size: 25px;
	    line-height: 24px;
	    font-weight: 300;
	    font-style: normal;
	    font-family: Lato;
	    padding: 0 0 0 0px;
	    text-decoration: none;
	    text-align: center;
	    background-color: transparent;
	    border-color: transparent;
	    border-style: none;
	    border-width: 0px;
	    border-radius: 0 0 0 0px;
	    text-align: center;
	}

	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 ARES SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	.ares.tparrows {
	  cursor:pointer;
	  background:#fff;
	  min-width:60px;
	    min-height:60px;
	  position:absolute;
	  display:block;
	  z-index:100;
	    border-radius:50%;
	}
	.ares.tparrows:hover {
	}
	.ares.tparrows:before {
	  font-family: "revicons";
	  font-size:25px;
	  color:#aaa;
	  display:block;
	  line-height: 60px;
	  text-align: center;
	    -webkit-transition: color 0.3s;
	    -moz-transition: color 0.3s;
	    transition: color 0.3s;
	    z-index:2;
	    position:relative;
	}
	.ares.tparrows.tp-leftarrow:before {
	  content: "\e81f";
	}
	.ares.tparrows.tp-rightarrow:before {
	  content: "\e81e";
	}
	.ares.tparrows:hover:before {
	 color:#000;
	      }
	.ares .tp-title-wrap {
	  position:absolute;
	  z-index:1;
	  display:inline-block;
	  background:#fff;
	  min-height:60px;
	  line-height:60px;
	  top:0px;
	  margin-left:30px;
	  border-radius:0px 30px 30px 0px;
	  overflow:hidden;
	  -webkit-transition: -webkit-transform 0.3s;
	  transition: transform 0.3s;
	  transform:scaleX(0);
	  -webkit-transform:scaleX(0);
	  transform-origin:0% 50%;
	   -webkit-transform-origin:0% 50%;
	}
	 .ares.tp-rightarrow .tp-title-wrap {
	   right:0px;
	   margin-right:30px;margin-left:0px;
	   -webkit-transform-origin:100% 50%;
	border-radius:30px 0px 0px 30px;
	 }
	.ares.tparrows:hover .tp-title-wrap {
	  transform:scaleX(1) scaleY(1);
	    -webkit-transform:scaleX(1) scaleY(1);
	}
	.ares .tp-arr-titleholder {
	  position:relative;
	  -webkit-transition: -webkit-transform 0.3s;
	  transition: transform 0.3s;
	  transform:translateX(200px);
	  text-transform:uppercase;
	  color:#000;
	  font-weight:400;
	  font-size:14px;
	  line-height:60px;
	  white-space:nowrap;
	  padding:0px 20px;
	  margin-left:10px;
	  opacity:0;
	}

	.ares.tp-rightarrow .tp-arr-titleholder {
	   transform:translateX(-200px);
	   margin-left:0px; margin-right:10px;
	      }

	.ares.tparrows:hover .tp-arr-titleholder {
	   transform:translateX(0px);
	   -webkit-transform:translateX(0px);
	  transition-delay: 0.1s;
	  opacity:1;
	}

	/* BULLETS */
	.ares.tp-bullets {
	}
	.ares.tp-bullets:before {
		content:" ";
		position:absolute;
		width:100%;
		height:100%;
		background:transparent;
		padding:10px;
		margin-left:-10px;margin-top:-10px;
		box-sizing:content-box;
	}
	.ares .tp-bullet {
		width:13px;
		height:13px;
		position:absolute;
		background:#e5e5e5;
		border-radius:50%;
		cursor: pointer;
		box-sizing:content-box;
	}
	.ares .tp-bullet:hover,
	.ares .tp-bullet.selected {
		background:#fff;
	}
	.ares .tp-bullet-title {
	  position:absolute;
	  color:#888;
	  font-size:12px;
	  padding:0px 10px;
	  font-weight:600;
	  right:27px;
	  top:-4px;
	  background:#fff;
	  background:rgba(255,255,255,0.75);
	  visibility:hidden;
	  transform:translateX(-20px);
	  -webkit-transform:translateX(-20px);
	  transition:transform 0.3s;
	  -webkit-transition:transform 0.3s;
	  line-height:20px;
	  white-space:nowrap;
	}

	.ares .tp-bullet-title:after {
	    width: 0px;
		height: 0px;
		border-style: solid;
		border-width: 10px 0 10px 10px;
		border-color: transparent transparent transparent rgba(255,255,255,0.75);
		content:" ";
	    position:absolute;
	    right:-10px;
		top:0px;
	}

	.ares .tp-bullet:hover .tp-bullet-title{
	  visibility:visible;
	   transform:translateX(0px);
	  -webkit-transform:translateX(0px);
	}

	.ares .tp-bullet.selected:hover .tp-bullet-title {
	    background:#fff;
	        }
	.ares .tp-bullet.selected:hover .tp-bullet-title:after {
	  border-color:transparent transparent transparent #fff;
	}
	.ares.tp-bullets:hover .tp-bullet-title {
	        visibility:hidden;
	}
	.ares.tp-bullets:hover .tp-bullet:hover .tp-bullet-title {
	    visibility:visible;
	      }

	/* TABS */
	.ares .tp-tab {
	  opacity:1;
	  padding:10px;
	  box-sizing:border-box;
	  font-family: "Roboto", sans-serif;
	  border-bottom: 1px solid #e5e5e5;
	 }
	.ares .tp-tab-image
	{
	  width:60px;
	  height:60px; max-height:100%; max-width:100%;
	  position:relative;
	  display:inline-block;
	  float:left;

	}
	.ares .tp-tab-content
	{
	    background:rgba(0,0,0,0);
	    position:relative;
	    padding:15px 15px 15px 85px;
	 left:0px;
	 overflow:hidden;
	 margin-top:-15px;
	    box-sizing:border-box;
	    color:#333;
	    display: inline-block;
	    width:100%;
	    height:100%;
	 position:absolute; }
	.ares .tp-tab-date
	  {
	  display:block;
	  color: #aaa;
	  font-weight:500;
	  font-size:12px;
	  margin-bottom:0px;
	  }
	.ares .tp-tab-title
	{
	    display:block;
	    text-align:left;
	    color:#333;
	    font-size:14px;
	    font-weight:500;
	    text-transform:none;
	    line-height:17px;
	}
	.ares .tp-tab:hover,
	.ares .tp-tab.selected {
		background:#eee;
	}

	.ares .tp-tab-mask {
	}

	/* MEDIA QUERIES */
	@media only screen and (max-width: 960px) {

	}
	@media only screen and (max-width: 768px) {

	}

	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 CUSTOM SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.custom.tparrows {
		cursor:pointer;
		background:#000;
		background:rgba(0,0,0,0.5);
		width:40px;
		height:40px;
		position:absolute;
		display:block;
		z-index:10000;
	}
	.custom.tparrows:hover {
		background:#000;
	}
	.custom.tparrows:before {
		font-family: "revicons";
		font-size:15px;
		color:#fff;
		display:block;
		line-height: 40px;
		text-align: center;
	}
	.custom.tparrows.tp-leftarrow:before {
		content: "\e824";
	}
	.custom.tparrows.tp-rightarrow:before {
		content: "\e825";
	}



	/* BULLETS */
	.custom.tp-bullets {
	}
	.custom.tp-bullets:before {
		content:" ";
		position:absolute;
		width:100%;
		height:100%;
		background:transparent;
		padding:10px;
		margin-left:-10px;margin-top:-10px;
		box-sizing:content-box;
	}
	.custom .tp-bullet {
		width:12px;
		height:12px;
		position:absolute;
		background:#aaa;
	    background:rgba(125,125,125,0.5);
		cursor: pointer;
		box-sizing:content-box;
	}
	.custom .tp-bullet:hover,
	.custom .tp-bullet.selected {
		background:rgb(125,125,125);
	}
	.custom .tp-bullet-image {
	}
	.custom .tp-bullet-title {
	}


	/* THUMBS */


	/* TABS */


	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 DIONE SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.dione.tparrows {
	  height:100%;
	  width:100px;
	  background:transparent;
	  background:rgba(0,0,0,0);
	  line-height:100%;
	  transition:all 0.3s;
	-webkit-transition:all 0.3s;
	}

	.dione.tparrows:hover {
	 background:rgba(0,0,0,0.45);
	 }
	.dione .tp-arr-imgwrapper {
	 width:100px;
	 left:0px;
	 position:absolute;
	 height:100%;
	 top:0px;
	 overflow:hidden;
	 }
	.dione.tp-rightarrow .tp-arr-imgwrapper {
	left:auto;
	right:0px;
	}

	.dione .tp-arr-imgholder {
	background-position:center center;
	background-size:cover;
	width:100px;
	height:100%;
	top:0px;
	visibility:hidden;
	transform:translateX(-50px);
	-webkit-transform:translateX(-50px);
	transition:all 0.3s;
	-webkit-transition:all 0.3s;
	opacity:0;
	left:0px;
	}

	.dione.tparrows.tp-rightarrow .tp-arr-imgholder {
	  right:0px;
	  left:auto;
	  transform:translateX(50px);
	 -webkit-transform:translateX(50px);
	}

	.dione.tparrows:before {
	position:absolute;
	line-height:30px;
	margin-left:-22px;
	top:50%;
	left:50%;
	font-size:30px;
	margin-top:-15px;
	transition:all 0.3s;
	-webkit-transition:all 0.3s;
	}

	.dione.tparrows.tp-rightarrow:before {
	margin-left:6px;
	}

	.dione.tparrows:hover:before {
	  transform:translateX(-20px);
	-webkit-transform:translateX(-20px);
	opacity:0;
	}

	.dione.tparrows.tp-rightarrow:hover:before {
	  transform:translateX(20px);
	-webkit-transform:translateX(20px);
	}

	.dione.tparrows:hover .tp-arr-imgholder {
	 transform:translateX(0px);
	-webkit-transform:translateX(0px);
	opacity:1;
	visibility:visible;
	}



	/* BULLETS */
	.dione .tp-bullet {
	    opacity:1;
	    width:50px;
	    height:50px;
	    padding:3px;
	    background:#000;
	    background-color:rgba(0,0,0,0.25);
	    margin:0px;
	    box-sizing:border-box;
	    transition:all 0.3s;
	    -webkit-transition:all 0.3s;

	  }

	.dione .tp-bullet-image {
	   display:block;
	   box-sizing:border-box;
	   position:relative;
	    -webkit-box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	  -moz-box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	  box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	  width:44px;
	  height:44px;
	  background-size:cover;
	  background-position:center center;
	 }
	.dione .tp-bullet-title {
	     position:absolute;
	   bottom:65px;
	     display:inline-block;
	     left:50%;
	     background:#000;
	     background:rgba(0,0,0,0.75);
	     color:#fff;
	     padding:10px 30px;
	     border-radius:4px;
	   -webkit-border-radius:4px;
	     opacity:0;
	      transition:all 0.3s;
	    -webkit-transition:all 0.3s;
	    transform: translateZ(0.001px) translateX(-50%) translateY(14px);
	    transform-origin:50% 100%;
	    -webkit-transform: translateZ(0.001px) translateX(-50%) translateY(14px);
	    -webkit-transform-origin:50% 100%;
	    opacity:0;
	    white-space:nowrap;
	 }

	.dione .tp-bullet:hover .tp-bullet-title {
	     transform:rotateX(0deg) translateX(-50%);
	    -webkit-transform:rotateX(0deg) translateX(-50%);
	    opacity:1;
	}

	.dione .tp-bullet.selected,
	.dione .tp-bullet:hover  {

	   background: rgba(255,255,255,1);
	  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(119,119,119,1)));
	  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#777777", GradientType=0 );

	      }
	.dione .tp-bullet-title:after {
	        content:" ";
	        position:absolute;
	        left:50%;
	        margin-left:-8px;
	        width: 0;
	    height: 0;
	    border-style: solid;
	    border-width: 8px 8px 0 8px;
	    border-color: rgba(0,0,0,0.75) transparent transparent transparent;
	        bottom:-8px;
	   }


	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 ERINYEN SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.erinyen.tparrows {
	  cursor:pointer;
	  background:#000;
	  background:rgba(0,0,0,0.5);
	  min-width:70px;
	  min-height:70px;
	  position:absolute;
	  display:block;
	  z-index:1000;
	  border-radius:35px;
	}

	.erinyen.tparrows:before {
	  font-family: "revicons";
	  font-size:20px;
	  color:#fff;
	  display:block;
	  line-height: 70px;
	  text-align: center;
	  z-index:2;
	  position:relative;
	}
	.erinyen.tparrows.tp-leftarrow:before {
	  content: "\e824";
	}
	.erinyen.tparrows.tp-rightarrow:before {
	  content: "\e825";
	}

	.erinyen .tp-title-wrap {
	  position:absolute;
	  z-index:1;
	  display:inline-block;
	  background:#000;
	  background:rgba(0,0,0,0.5);
	  min-height:70px;
	  line-height:70px;
	  top:0px;
	  margin-left:0px;
	  border-radius:35px;
	  overflow:hidden;
	  transition: opacity 0.3s;
	  -webkit-transition:opacity 0.3s;
	  -moz-transition:opacity 0.3s;
	  -webkit-transform: scale(0);
	  -moz-transform: scale(0);
	  transform: scale(0);
	  visibility:hidden;
	  opacity:0;
	}

	.erinyen.tparrows:hover .tp-title-wrap{
	  -webkit-transform: scale(1);
	  -moz-transform: scale(1);
	  transform: scale(1);
	  opacity:1;
	  visibility:visible;
	}

	 .erinyen.tp-rightarrow .tp-title-wrap {
	   right:0px;
	   margin-right:0px;margin-left:0px;
	   -webkit-transform-origin:100% 50%;
	  border-radius:35px;
	  padding-right:20px;
	  padding-left:10px;
	 }


	.erinyen.tp-leftarrow .tp-title-wrap {
	   padding-left:20px;
	  padding-right:10px;
	}

	.erinyen .tp-arr-titleholder {
	  letter-spacing: 3px;
	   position:relative;
	  -webkit-transition: -webkit-transform 0.3s;
	  transition: transform 0.3s;
	  transform:translateX(200px);
	  text-transform:uppercase;
	  color:#fff;
	  font-weight:600;
	  font-size:13px;
	  line-height:70px;
	  white-space:nowrap;
	  padding:0px 20px;
	  margin-left:11px;
	  opacity:0;
	}

	.erinyen .tp-arr-imgholder {
	  width:100%;
	  height:100%;
	  position:absolute;
	  top:0px;
	  left:0px;
	  background-position:center center;
	  background-size:cover;
	    }
	 .erinyen .tp-arr-img-over {
	   width:100%;
	  height:100%;
	  position:absolute;
	  top:0px;
	  left:0px;
	   background:#000;
	   background:rgba(0,0,0,0.5);
	        }
	.erinyen.tp-rightarrow .tp-arr-titleholder {
	   transform:translateX(-200px);
	   margin-left:0px; margin-right:11px;
	      }

	.erinyen.tparrows:hover .tp-arr-titleholder {
	   transform:translateX(0px);
	   -webkit-transform:translateX(0px);
	  transition-delay: 0.1s;
	  opacity:1;
	}

	/* BULLETS */
	.erinyen.tp-bullets {
	}
	.erinyen.tp-bullets:before {
		content:" ";
		position:absolute;
		width:100%;
		height:100%;
		background: #555555; /* old browsers */
	    background: -moz-linear-gradient(top,  #555555 0%, #222222 100%); /* ff3.6+ */
	    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#555555), color-stop(100%,#222222)); /* chrome,safari4+ */
	    background: -webkit-linear-gradient(top,  #555555 0%,#222222 100%); /* chrome10+,safari5.1+ */
	    background: -o-linear-gradient(top,  #555555 0%,#222222 100%); /* opera 11.10+ */
	    background: -ms-linear-gradient(top,  #555555 0%,#222222 100%); /* ie10+ */
	    background: linear-gradient(to bottom,  #555555 0%,#222222 100%); /* w3c */
	    filter: progid:dximagetransform.microsoft.gradient( startcolorstr="#555555", endcolorstr="#222222",gradienttype=0 ); /* ie6-9 */
		padding:10px 15px;
		margin-left:-15px;margin-top:-10px;
		box-sizing:content-box;
	   border-radius:10px;
	   box-shadow:0px 0px 2px 1px rgba(33,33,33,0.3);
	}
	.erinyen .tp-bullet {
		width:13px;
		height:13px;
		position:absolute;
		background:#111;
		border-radius:50%;
		cursor: pointer;
		box-sizing:content-box;
	}
	.erinyen .tp-bullet:hover,
	.erinyen .tp-bullet.selected {
		background: #e5e5e5; /* old browsers */
	background: -moz-linear-gradient(top,  #e5e5e5 0%, #999999 100%); /* ff3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e5e5e5), color-stop(100%,#999999)); /* chrome,safari4+ */
	background: -webkit-linear-gradient(top,  #e5e5e5 0%,#999999 100%); /* chrome10+,safari5.1+ */
	background: -o-linear-gradient(top,  #e5e5e5 0%,#999999 100%); /* opera 11.10+ */
	background: -ms-linear-gradient(top,  #e5e5e5 0%,#999999 100%); /* ie10+ */
	background: linear-gradient(to bottom,  #e5e5e5 0%,#999999 100%); /* w3c */
	filter: progid:dximagetransform.microsoft.gradient( startcolorstr="#e5e5e5", endcolorstr="#999999",gradienttype=0 ); /* ie6-9 */
	  border:1px solid #555;
	  width:12px;height:12px;
	}
	.erinyen .tp-bullet-image {
	}
	.erinyen .tp-bullet-title {
	}


	/* THUMBS */
	.erinyen .tp-thumb {
	opacity:1
	}

	.erinyen .tp-thumb-over {
	  background:#000;
	  background:rgba(0,0,0,0.25);
	  width:100%;
	  height:100%;
	  position:absolute;
	  top:0px;
	  left:0px;
	  z-index:1;
	  -webkit-transition:all 0.3s;
	  transition:all 0.3s;
	}

	.erinyen .tp-thumb-more:before {
	  font-family: "revicons";
	  font-size:12px;
	  color:#aaa;
	  color:rgba(255,255,255,0.75);
	  display:block;
	  line-height: 12px;
	  text-align: left;
	  z-index:2;
	  position:absolute;
	  top:20px;
	  right:20px;
	  z-index:2;
	}
	.erinyen .tp-thumb-more:before {
	  content: "\e825";
	}

	.erinyen .tp-thumb-title {
	  font-family:"Raleway";
	  letter-spacing:1px;
	  font-size:12px;
	  color:#fff;
	  display:block;
	  line-height: 15px;
	  text-align: left;
	  z-index:2;
	  position:absolute;
	  top:0px;
	  left:0px;
	  z-index:2;
	  padding:20px 35px 20px 20px;
	  width:100%;
	  height:100%;
	  box-sizing:border-box;
	  transition:all 0.3s;
	  -webkit-transition:all 0.3s;
	  font-weight:500;
	}

	.erinyen .tp-thumb.selected .tp-thumb-more:before,
	.erinyen .tp-thumb:hover .tp-thumb-more:before {
	 color:#aaa;

	}

	.erinyen .tp-thumb.selected .tp-thumb-over,
	.erinyen .tp-thumb:hover .tp-thumb-over {
	 background:#fff;
	}
	.erinyen .tp-thumb.selected .tp-thumb-title,
	.erinyen .tp-thumb:hover .tp-thumb-title {
	  color:#000;

	}


	/* TABS */
	.erinyen .tp-tab-title {
	    color:#a8d8ee;
	    font-size:13px;
	    font-weight:700;
	    text-transform:uppercase;
	    font-family:"Roboto Slab";
	    margin-bottom:5px;
	}

	.erinyen .tp-tab-desc {
		font-size:18px;
	    font-weight:400;
	    color:#fff;
	    line-height:25px;
		font-family:"Roboto Slab";
	}


	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 GYGES SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */


	/* BULLETS */
	.gyges.tp-bullets {
	}
	.gyges.tp-bullets:before {
		content:" ";
		position:absolute;
		width:100%;
		height:100%;
		background: #777777; /* Old browsers */
	    background: -moz-linear-gradient(top,  #777777 0%, #666666 100%);
	    background: -webkit-gradient(linear, left top, left bottom,
	    color-stop(0%,#777777), color-stop(100%,#666666));
	    background: -webkit-linear-gradient(top,  #777777 0%,#666666 100%);
	    background: -o-linear-gradient(top,  #777777 0%,#666666 100%);
	    background: -ms-linear-gradient(top,  #777777 0%,#666666 100%);
	    background: linear-gradient(to bottom,  #777777 0%,#666666 100%);
	    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#777777",
	    endColorstr="#666666",GradientType=0 );
		padding:10px;
		margin-left:-10px;margin-top:-10px;
		box-sizing:content-box;
	  border-radius:10px;
	}
	.gyges .tp-bullet {
		width:12px;
		height:12px;
		position:absolute;
		background:#333;
		border:3px solid #444;
		border-radius:50%;
		cursor: pointer;
		box-sizing:content-box;
	}
	.gyges .tp-bullet:hover,
	.gyges .tp-bullet.selected {
		background: #ffffff; /* Old browsers */
	    background: -moz-linear-gradient(top,  #ffffff 0%, #e1e1e1 100%); /* FF3.6+ */
	    background: -webkit-gradient(linear, left top, left bottom,
	    color-stop(0%,#ffffff), color-stop(100%,#e1e1e1)); /* Chrome,Safari4+ */
	    background: -webkit-linear-gradient(top,  #ffffff 0%,#e1e1e1 100%); /* Chrome10+,Safari5.1+ */
	    background: -o-linear-gradient(top,  #ffffff 0%,#e1e1e1 100%); /* Opera 11.10+ */
	    background: -ms-linear-gradient(top,  #ffffff 0%,#e1e1e1 100%); /* IE10+ */
	    background: linear-gradient(to bottom,  #ffffff 0%,#e1e1e1 100%); /* W3C */
	    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff",
	    endColorstr="#e1e1e1",GradientType=0 ); /* IE6-9 */

	}
	.gyges .tp-bullet-image {
	}
	.gyges .tp-bullet-title {
	}


	/* THUMBS */
	.gyges .tp-thumb {
	      opacity:1
	  }
	.gyges .tp-thumb-img-wrap {
	  padding:3px;
	    background:#000;
	  background-color:rgba(0,0,0,0.25);
	  display:inline-block;

	  width:100%;
	  height:100%;
	  position:relative;
	  margin:0px;
	  box-sizing:border-box;
	    transition:all 0.3s;
	    -webkit-transition:all 0.3s;
	}
	.gyges .tp-thumb-image {
	   padding:3px;
	   display:block;
	   box-sizing:border-box;
	   position:relative;
	    -webkit-box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	  -moz-box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	  box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	 }
	.gyges .tp-thumb-title {
	     position:absolute;
	     bottom:100%;
	     display:inline-block;
	     left:50%;
	     background:rgba(255,255,255,0.8);
	     padding:10px 30px;
	     border-radius:4px;
		 -webkit-border-radius:4px;
	     margin-bottom:20px;
	     opacity:0;
	      transition:all 0.3s;
	    -webkit-transition:all 0.3s;
	    transform: translateZ(0.001px) translateX(-50%) translateY(14px);
	    transform-origin:50% 100%;
	    -webkit-transform: translateZ(0.001px) translateX(-50%) translateY(14px);
	    -webkit-transform-origin:50% 100%;
	    white-space:nowrap;
	 }
	.gyges .tp-thumb:hover .tp-thumb-title {
	  	 transform:rotateX(0deg) translateX(-50%);
	    -webkit-transform:rotateX(0deg) translateX(-50%);
	    opacity:1;
	}

	.gyges .tp-thumb:hover .tp-thumb-img-wrap,
	 .gyges .tp-thumb.selected .tp-thumb-img-wrap {

	  background: rgba(255,255,255,1);
	  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(119,119,119,1)));
	  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#777777", GradientType=0 );
	 }
	.gyges .tp-thumb-title:after {
	        content:" ";
	        position:absolute;
	        left:50%;
	        margin-left:-8px;
	        width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 8px 0 8px;
			border-color: rgba(255,255,255,0.8) transparent transparent transparent;
	        bottom:-8px;
	   }


	/* TABS */
	.gyges .tp-tab {
	  opacity:1;
	  padding:10px;
	  box-sizing:border-box;
	  font-family: "Roboto", sans-serif;
	  border-bottom: 1px solid rgba(255,255,255,0.15);
	 }
	.gyges .tp-tab-image
	{
	  width:60px;
	  height:60px; max-height:100%; max-width:100%;
	  position:relative;
	  display:inline-block;
	  float:left;

	}
	.gyges .tp-tab-content
	{
	    background:rgba(0,0,0,0);
	    position:relative;
	    padding:15px 15px 15px 85px;
	 left:0px;
	  overflow:hidden;
	 margin-top:-15px;
	    box-sizing:border-box;
	    color:#333;
	    display: inline-block;
	    width:100%;
	    height:100%;
	 position:absolute; }
	.gyges .tp-tab-date
	  {
	  display:block;
	  color: rgba(255,255,255,0.25);
	  font-weight:500;
	  font-size:12px;
	  margin-bottom:0px;
	  }
	.gyges .tp-tab-title
	{
	    display:block;
	    text-align:left;
	    color:#fff;
	    font-size:14px;
	    font-weight:500;
	    text-transform:none;
	    line-height:17px;
	}
	.gyges .tp-tab:hover,
	.gyges .tp-tab.selected {
	  background:rgba(0,0,0,0.5);
	}

	.gyges .tp-tab-mask {
	}

	/* MEDIA QUERIES */
	@media only screen and (max-width: 960px) {

	}
	@media only screen and (max-width: 768px) {

	}

	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 HADES SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.hades.tparrows {
		cursor:pointer;
		background:#000;
		background:rgba(0,0,0,0.15);
		width:100px;
		height:100px;
		position:absolute;
		display:block;
		z-index:1000;
	}

	.hades.tparrows:before {
		font-family: "revicons";
		font-size:30px;
		color:#fff;
		display:block;
		line-height: 100px;
		text-align: center;
	  transition: background 0.3s, color 0.3s;
	}
	.hades.tparrows.tp-leftarrow:before {
		content: "\e824";
	}
	.hades.tparrows.tp-rightarrow:before {
		content: "\e825";
	}

	.hades.tparrows:hover:before {
	   color:#aaa;
	   background:#fff;
	   background:rgba(255,255,255,1);
	 }
	.hades .tp-arr-allwrapper {
	  position:absolute;
	  left:100%;
	  top:0px;
	  background:#888;
	  width:100px;height:100px;
	  -webkit-transition: all 0.3s;
	  transition: all 0.3s;
	  -ms-filter: "progid:dximagetransform.microsoft.alpha(opacity=0)";
	  filter: alpha(opacity=0);
	  -moz-opacity: 0.0;
	  -khtml-opacity: 0.0;
	  opacity: 0.0;
	  -webkit-transform: rotatey(-90deg);
	  transform: rotatey(-90deg);
	  -webkit-transform-origin: 0% 50%;
	  transform-origin: 0% 50%;
	}
	.hades.tp-rightarrow .tp-arr-allwrapper {
	   left:auto;
	   right:100%;
	   -webkit-transform-origin: 100% 50%;
	  transform-origin: 100% 50%;
	   -webkit-transform: rotatey(90deg);
	  transform: rotatey(90deg);
	}

	.hades:hover .tp-arr-allwrapper {
	   -ms-filter: "progid:dximagetransform.microsoft.alpha(opacity=100)";
	  filter: alpha(opacity=100);
	  -moz-opacity: 1;
	  -khtml-opacity: 1;
	  opacity: 1;
	    -webkit-transform: rotatey(0deg);
	  transform: rotatey(0deg);

	 }

	.hades .tp-arr-iwrapper {
	}
	.hades .tp-arr-imgholder {
	  background-size:cover;
	  position:absolute;
	  top:0px;left:0px;
	  width:100%;height:100%;
	}
	.hades .tp-arr-titleholder {
	}
	.hades .tp-arr-subtitleholder {
	}


	/* BULLETS */
	.hades.tp-bullets {
	}
	.hades.tp-bullets:before {
		content:" ";
		position:absolute;
		width:100%;
		height:100%;
		background:transparent;
		padding:10px;
		margin-left:-10px;margin-top:-10px;
		box-sizing:content-box;
	}
	.hades .tp-bullet {
		width:3px;
		height:3px;
		position:absolute;
		background:#888;
		cursor: pointer;
	    border:5px solid #fff;
		box-sizing:content-box;
	    box-shadow:0px 0px 3px 1px rgba(0,0,0,0.2);
	    -webkit-perspective:400;
	    perspective:400;
	    -webkit-transform:translatez(0.01px);
	    transform:translatez(0.01px);
	}
	.hades .tp-bullet:hover,
	.hades .tp-bullet.selected {
		background:#555;

	}

	.hades .tp-bullet-image {
	  position:absolute;top:-80px; left:-60px;width:120px;height:60px;
	  background-position:center center;
	  background-size:cover;
	  visibility:hidden;
	  opacity:0;
	  transition:all 0.3s;
	  -webkit-transform-style:flat;
	  transform-style:flat;
	  perspective:600;
	  -webkit-perspective:600;
	  transform: rotatex(-90deg);
	  -webkit-transform: rotatex(-90deg);
	  box-shadow:0px 0px 3px 1px rgba(0,0,0,0.2);
	  transform-origin:50% 100%;
	  -webkit-transform-origin:50% 100%;


	}
	.hades .tp-bullet:hover .tp-bullet-image {
	  display:block;
	  opacity:1;
	  transform: rotatex(0deg);
	  -webkit-transform: rotatex(0deg);
	  visibility:visible;
	    }
	.hades .tp-bullet-title {
	}


	/* THUMBS */
	.hades .tp-thumb {
	      opacity:1
	  }
	.hades .tp-thumb-img-wrap {
	  border-radius:50%;
	  padding:3px;
	  display:inline-block;
	background:#000;
	  background-color:rgba(0,0,0,0.25);
	  width:100%;
	  height:100%;
	  position:relative;
	  margin:0px;
	  box-sizing:border-box;
	    transition:all 0.3s;
	    -webkit-transition:all 0.3s;
	}
	.hades .tp-thumb-image {
	   padding:3px;
	   border-radius:50%;
	   display:block;
	   box-sizing:border-box;
	   position:relative;
	    -webkit-box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	  -moz-box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	  box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	 }


	.hades .tp-thumb:hover .tp-thumb-img-wrap,
	.hades .tp-thumb.selected .tp-thumb-img-wrap {

	   background: rgba(255,255,255,1);
	  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(119,119,119,1)));
	  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#777777", GradientType=0 );

	      }
	.hades .tp-thumb-title:after {
	        content:" ";
	        position:absolute;
	        left:50%;
	        margin-left:-8px;
	        width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 8px 0 8px;
			border-color: rgba(0,0,0,0.75) transparent transparent transparent;
	        bottom:-8px;
	   }


	/* TABS */
	.hades .tp-tab {
	  opacity:1;
	 }

	.hades .tp-tab-title
	 {
	      display:block;
	      color:#333;
	      font-weight:600;
	      font-size:18px;
	      text-align:center;
	      line-height:25px;
	    }
	.hades .tp-tab-price
	 {
		display:block;
	    text-align:center;
	    color:#999;
	    font-size:16px;
	    margin-top:10px;
	   line-height:20px
	}

	.hades .tp-tab-button {
	    display:inline-block;
	    margin-top:15px;
	    text-align:center;
		padding:5px 15px;
	  	color:#fff;
	  	font-size:14px;
	  	background:#219bd7;
	   	border-radius:4px;
	   font-weight:400;
	}
	.hades .tp-tab-inner {
		text-align:center;
	}



	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 HEBE SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.hebe.tparrows {
	  cursor:pointer;
	  background:#fff;
	  min-width:70px;
	    min-height:70px;
	  position:absolute;
	  display:block;
	  z-index:1000;
	}
	.hebe.tparrows:hover {
	}
	.hebe.tparrows:before {
	  font-family: "revicons";
	  font-size:30px;
	  color:#aaa;
	  display:block;
	  line-height: 70px;
	  text-align: center;
	  -webkit-transition: color 0.3s;
	  -moz-transition: color 0.3s;
	  transition: color 0.3s;
	  z-index:2;
	  position:relative;
	   background:#fff;
	  min-width:70px;
	    min-height:70px;
	}
	.hebe.tparrows.tp-leftarrow:before {
	  content: "\e824";
	}
	.hebe.tparrows.tp-rightarrow:before {
	  content: "\e825";
	}
	.hebe.tparrows:hover:before {
	 color:#000;
	      }
	.hebe .tp-title-wrap {
	  position:absolute;
	  z-index:0;
	  display:inline-block;
	  background:#000;
	  background:rgba(0,0,0,0.75);
	  min-height:60px;
	  line-height:60px;
	  top:-10px;
	  margin-left:0px;
	  -webkit-transition: -webkit-transform 0.3s;
	  transition: transform 0.3s;
	  transform:scaleX(0);
	  -webkit-transform:scaleX(0);
	  transform-origin:0% 50%;
	   -webkit-transform-origin:0% 50%;
	}
	 .hebe.tp-rightarrow .tp-title-wrap {
	   right:0px;
	   -webkit-transform-origin:100% 50%;
	 }
	.hebe.tparrows:hover .tp-title-wrap {
	  transform:scaleX(1);
	  -webkit-transform:scaleX(1);
	}
	.hebe .tp-arr-titleholder {
	  position:relative;
	  text-transform:uppercase;
	  color:#fff;
	  font-weight:600;
	  font-size:12px;
	  line-height:90px;
	  white-space:nowrap;
	  padding:0px 20px 0px 90px;
	}

	.hebe.tp-rightarrow .tp-arr-titleholder {
	   margin-left:0px;
	   padding:0px 90px 0px 20px;
	 }

	.hebe.tparrows:hover .tp-arr-titleholder {
	   transform:translateX(0px);
	   -webkit-transform:translateX(0px);
	  transition-delay: 0.1s;
	  opacity:1;
	}

	.hebe .tp-arr-imgholder{
	      width:90px;
	      height:90px;
	      position:absolute;
	      left:100%;
	      display:block;
	      background-size:cover;
	      background-position:center center;
	  	 top:0px; right:-90px;
	    }
	.hebe.tp-rightarrow .tp-arr-imgholder{
	        right:auto;left:-90px;
	      }

	/* BULLETS */
	.hebe.tp-bullets {
	}
	.hebe.tp-bullets:before {
	  content:" ";
	  position:absolute;
	  width:100%;
	  height:100%;
	  background:transparent;
	  padding:10px;
	  margin-left:-10px;margin-top:-10px;
	  box-sizing:content-box;
	}

	.hebe .tp-bullet {
	  width:3px;
	  height:3px;
	  position:absolute;
	  background:#fff;
	  cursor: pointer;
	  border:5px solid #222;
	  border-radius:50%;
	  box-sizing:content-box;
	  -webkit-perspective:400;
	  perspective:400;
	  -webkit-transform:translateZ(0.01px);
	  transform:translateZ(0.01px);
	   transition:all 0.3s;
	}
	.hebe .tp-bullet:hover,
	.hebe .tp-bullet.selected {
	  background:#222;
	  border-color:#fff;
	}

	.hebe .tp-bullet-image {
	  position:absolute;
	  top:-90px; left:-40px;
	  width:70px;
	  height:70px;
	  background-position:center center;
	  background-size:cover;
	  visibility:hidden;
	  opacity:0;
	  transition:all 0.3s;
	  -webkit-transform-style:flat;
	  transform-style:flat;
	  perspective:600;
	  -webkit-perspective:600;
	  transform: scale(0);
	  -webkit-transform: scale(0);
	  transform-origin:50% 100%;
	  -webkit-transform-origin:50% 100%;
	border-radius:6px;


	}
	.hebe .tp-bullet:hover .tp-bullet-image {
	  display:block;
	  opacity:1;
	  transform: scale(1);
	  -webkit-transform: scale(1);
	  visibility:visible;
	    }
	.hebe .tp-bullet-title {
	}


	/* TABS */
	.hebe .tp-tab-title {
	    color:#a8d8ee;
	    font-size:13px;
	    font-weight:700;
	    text-transform:uppercase;
	    font-family:"Roboto Slab";
	    margin-bottom:5px;
	}

	.hebe .tp-tab-desc {
		font-size:18px;
	    font-weight:400;
	    color:#fff;
	    line-height:25px;
		font-family:"Roboto Slab";
	}


	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 HEPHAISTOS SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.hephaistos.tparrows {
		cursor:pointer;
		background:#000;
		background:rgba(0,0,0,0.5);
		width:40px;
		height:40px;
		position:absolute;
		display:block;
		z-index:1000;
	    border-radius:50%;
	}
	.hephaistos.tparrows:hover {
		background:#000;
	}
	.hephaistos.tparrows:before {
		font-family: "revicons";
		font-size:18px;
		color:#fff;
		display:block;
		line-height: 40px;
		text-align: center;
	}
	.hephaistos.tparrows.tp-leftarrow:before {
		content: "\e82c";
	  margin-left:-2px;

	}
	.hephaistos.tparrows.tp-rightarrow:before {
		content: "\e82d";
	   margin-right:-2px;
	}



	/* BULLETS */
	.hephaistos.tp-bullets {
	}
	.hephaistos.tp-bullets:before {
		content:" ";
		position:absolute;
		width:100%;
		height:100%;
		background:transparent;
		padding:10px;
		margin-left:-10px;margin-top:-10px;
		box-sizing:content-box;
	}
	.hephaistos .tp-bullet {
		width:12px;
		height:12px;
		position:absolute;
		background:#999;
		border:3px solid #f5f5f5;
		border-radius:50%;
		cursor: pointer;
		box-sizing:content-box;
	  box-shadow: 0px 0px 2px 1px rgba(130,130,130, 0.3);

	}
	.hephaistos .tp-bullet:hover,
	.hephaistos .tp-bullet.selected {
		background:#fff;
	    border-color:#000;
	}
	.hephaistos .tp-bullet-image {
	}
	.hephaistos .tp-bullet-title {
	}


	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 HERMES SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.hermes.tparrows {
		cursor:pointer;
		background:#000;
		background:rgba(0,0,0,0.5);
		width:30px;
		height:110px;
		position:absolute;
		display:block;
		z-index:1000;
	}

	.hermes.tparrows:before {
		font-family: "revicons";
		font-size:15px;
		color:#fff;
		display:block;
		line-height: 110px;
		text-align: center;
	    transform:translateX(0px);
	    -webkit-transform:translateX(0px);
	    transition:all 0.3s;
	    -webkit-transition:all 0.3s;
	}
	.hermes.tparrows.tp-leftarrow:before {
		content: "\e824";
	}
	.hermes.tparrows.tp-rightarrow:before {
		content: "\e825";
	}
	.hermes.tparrows.tp-leftarrow:hover:before {
	    transform:translateX(-20px);
	    -webkit-transform:translateX(-20px);
	     opacity:0;
	}
	.hermes.tparrows.tp-rightarrow:hover:before {
	    transform:translateX(20px);
	    -webkit-transform:translateX(20px);
	     opacity:0;
	}

	.hermes .tp-arr-allwrapper {
	    overflow:hidden;
	    position:absolute;
		width:180px;
	    height:140px;
	    top:0px;
	    left:0px;
	    visibility:hidden;
	      -webkit-transition: -webkit-transform 0.3s 0.3s;
	  transition: transform 0.3s 0.3s;
	  -webkit-perspective: 1000px;
	  perspective: 1000px;
	    }
	.hermes.tp-rightarrow .tp-arr-allwrapper {
	   right:0px;left:auto;
	      }
	.hermes.tparrows:hover .tp-arr-allwrapper {
	   visibility:visible;
	          }
	.hermes .tp-arr-imgholder {
	  width:180px;position:absolute;
	  left:0px;top:0px;height:110px;
	  transform:translateX(-180px);
	  -webkit-transform:translateX(-180px);
	  transition:all 0.3s;
	  transition-delay:0.3s;
	}
	.hermes.tp-rightarrow .tp-arr-imgholder{
	    transform:translateX(180px);
	  -webkit-transform:translateX(180px);
	      }

	.hermes.tparrows:hover .tp-arr-imgholder {
	   transform:translateX(0px);
	   -webkit-transform:translateX(0px);
	}
	.hermes .tp-arr-titleholder {
	  top:110px;
	  width:180px;
	  text-align:left;
	  display:block;
	  padding:0px 10px;
	  line-height:30px; background:#000;
	  background:rgba(0,0,0,0.75);color:#fff;
	  font-weight:600; position:absolute;
	  font-size:12px;
	  white-space:nowrap;
	  letter-spacing:1px;
	  -webkit-transition: all 0.3s;
	  transition: all 0.3s;
	  -webkit-transform: rotateX(-90deg);
	  transform: rotateX(-90deg);
	  -webkit-transform-origin: 50% 0;
	  transform-origin: 50% 0;
	  box-sizing:border-box;

	}
	.hermes.tparrows:hover .tp-arr-titleholder {
	    -webkit-transition-delay: 0.6s;
	  transition-delay: 0.6s;
	  -webkit-transform: rotateX(0deg);
	  transform: rotateX(0deg);
	}


	/* BULLETS */
	.hermes.tp-bullets {
	}

	.hermes .tp-bullet {
	    overflow:hidden;
	    border-radius:50%;
	    width:16px;
	    height:16px;
	    background-color: rgba(0, 0, 0, 0);
	    box-shadow: inset 0 0 0 2px #FFF;
	    -webkit-transition: background 0.3s ease;
	    transition: background 0.3s ease;
	    position:absolute;
	}

	.hermes .tp-bullet:hover {
		  background-color: rgba(0, 0, 0, 0.2);
	}
	.hermes .tp-bullet:after {
	  content: ' ';
	  position: absolute;
	  bottom: 0;
	  height: 0;
	  left: 0;
	  width: 100%;
	  background-color: #FFF;
	  box-shadow: 0 0 1px #FFF;
	  -webkit-transition: height 0.3s ease;
	  transition: height 0.3s ease;
	}
	.hermes .tp-bullet.selected:after {
	  height:100%;
	}


	/* TABS */
	.hermes .tp-tab {
	  opacity:1;
	  padding-right:10px;
	  box-sizing:border-box;
	 }
	.hermes .tp-tab-image
	{
	  width:100%;
	  height:60%;
	  position:relative;
	}
	.hermes .tp-tab-content
	{
	    background:rgb(54,54,54);
	    position:absolute;
	    padding:20px 20px 20px 30px;
	    box-sizing:border-box;
	    color:#fff;
	  display:block;
	  width:100%;
	  min-height:40%;
	  bottom:0px;
	  left:-10px;
	  }
	.hermes .tp-tab-date
	  {
	  display:block;
	  color:#888;
	  font-weight:600;
	  font-size:12px;
	  margin-bottom:10px;
	  }
	.hermes .tp-tab-title
	{
	    display:block;
	    color:#fff;
	    font-size:16px;
	    font-weight:800;
	    text-transform:uppercase;
	   line-height:19px;
	}

	.hermes .tp-tab.selected .tp-tab-title:after {
	    width: 0px;
		height: 0px;
		border-style: solid;
		border-width: 30px 0 30px 10px;
		border-color: transparent transparent transparent rgb(54,54,54);
		content:" ";
	    position:absolute;
	    right:-9px;
	    bottom:50%;
	    margin-bottom:-30px;
	}
	.hermes .tp-tab-mask {
	     padding-right:10px !important;
	          }

	/* MEDIA QUERIES */
	@media only screen and (max-width: 960px) {
	  .hermes .tp-tab .tp-tab-title {font-size:14px;line-height:16px;}
	  .hermes .tp-tab-date { font-size:11px; line-height:13px;margin-bottom:10px;}
	  .hermes .tp-tab-content { padding:15px 15px 15px 25px;}
	}
	@media only screen and (max-width: 768px) {
	  .hermes .tp-tab .tp-tab-title {font-size:12px;line-height:14px;}
	  .hermes .tp-tab-date {font-size:10px; line-height:12px;margin-bottom:5px;}
	  .hermes .tp-tab-content {padding:10px 10px 10px 20px;}
	}

	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 HESPERIDEN SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.hesperiden.tparrows {
		cursor:pointer;
		background:#000;
		background:rgba(0,0,0,0.5);
		width:40px;
		height:40px;
		position:absolute;
		display:block;
		z-index:1000;
	    border-radius: 50%;
	}
	.hesperiden.tparrows:hover {
		background:#000;
	}
	.hesperiden.tparrows:before {
		font-family: "revicons";
		font-size:20px;
		color:#fff;
		display:block;
		line-height: 40px;
		text-align: center;
	}
	.hesperiden.tparrows.tp-leftarrow:before {
		content: "\e82c";
	    margin-left:-3px;
	}
	.hesperiden.tparrows.tp-rightarrow:before {
		content: "\e82d";
	    margin-right:-3px;
	}

	/* BULLETS */
	.hesperiden.tp-bullets {
	}
	.hesperiden.tp-bullets:before {
		content:" ";
		position:absolute;
		width:100%;
		height:100%;
		background:transparent;
		padding:10px;
		margin-left:-10px;margin-top:-10px;
		box-sizing:content-box;
	   border-radius:8px;

	}
	.hesperiden .tp-bullet {
		width:12px;
		height:12px;
		position:absolute;
		background: #999999; /* old browsers */
	    background: -moz-linear-gradient(top,  #999999 0%, #e1e1e1 100%); /* ff3.6+ */
	    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#999999),
	    color-stop(100%,#e1e1e1)); /* chrome,safari4+ */
	    background: -webkit-linear-gradient(top,  #999999 0%,#e1e1e1 100%); /* chrome10+,safari5.1+ */
	    background: -o-linear-gradient(top,  #999999 0%,#e1e1e1 100%); /* opera 11.10+ */
	    background: -ms-linear-gradient(top,  #999999 0%,#e1e1e1 100%); /* ie10+ */
	    background: linear-gradient(to bottom,  #999999 0%,#e1e1e1 100%); /* w3c */
	    filter: progid:dximagetransform.microsoft.gradient(
	    startcolorstr="#999999", endcolorstr="#e1e1e1",gradienttype=0 ); /* ie6-9 */
		border:3px solid #e5e5e5;
		border-radius:50%;
		cursor: pointer;
		box-sizing:content-box;
	}
	.hesperiden .tp-bullet:hover,
	.hesperiden .tp-bullet.selected {
		background:#666;
	}
	.hesperiden .tp-bullet-image {
	}
	.hesperiden .tp-bullet-title {
	}


	/* THUMBS */
	.hesperiden .tp-thumb {
	  opacity:1;
	  -webkit-perspective: 600px;
	  perspective: 600px;
	}
	.hesperiden .tp-thumb .tp-thumb-title {
	    font-size:12px;
	    position:absolute;
	    margin-top:-10px;
	    color:#fff;
	    display:block;
	    z-index:10000;
	    background-color:#000;
	    padding:5px 10px;
	    bottom:0px;
	    left:0px;
	    width:100%;
	  box-sizing:border-box;
	    text-align:center;
	    overflow:hidden;
	    white-space:nowrap;
	    transition:all 0.3s;
	    -webkit-transition:all 0.3s;
	    transform:rotatex(90deg) translatez(0.001px);
	    transform-origin:50% 100%;
	    -webkit-transform:rotatex(90deg) translatez(0.001px);
	    -webkit-transform-origin:50% 100%;
	    opacity:0;
	 }
	.hesperiden .tp-thumb:hover .tp-thumb-title {
	  	 transform:rotatex(0deg);
	    -webkit-transform:rotatex(0deg);
	    opacity:1;
	}

	/* TABS */
	.hesperiden .tp-tab {
	  opacity:1;
	  padding:10px;
	  box-sizing:border-box;
	  font-family: "Roboto", sans-serif;
	  border-bottom: 1px solid #e5e5e5;
	 }
	.hesperiden .tp-tab-image
	{
	  width:60px;
	  height:60px; max-height:100%; max-width:100%;
	  position:relative;
	  display:inline-block;
	  float:left;

	}
	.hesperiden .tp-tab-content
	{
	    background:rgba(0,0,0,0);
	    position:relative;
	    padding:15px 15px 15px 85px;
	 left:0px;
	 overflow:hidden;
	 margin-top:-15px;
	    box-sizing:border-box;
	    color:#333;
	    display: inline-block;
	    width:100%;
	    height:100%;
	 position:absolute; }
	.hesperiden .tp-tab-date
	  {
	  display:block;
	  color: #aaa;
	  font-weight:500;
	  font-size:12px;
	  margin-bottom:0px;
	  }
	.hesperiden .tp-tab-title
	{
	    display:block;
	    text-align:left;
	    color:#333;
	    font-size:14px;
	    font-weight:500;
	    text-transform:none;
	    line-height:17px;
	}
	.hesperiden .tp-tab:hover,
	.hesperiden .tp-tab.selected {
		background:#eee;
	}

	.hesperiden .tp-tab-mask {
	}

	/* MEDIA QUERIES */
	@media only screen and (max-width: 960px) {

	}
	@media only screen and (max-width: 768px) {

	}

	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 METIS SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.metis.tparrows {
	  background:#fff;
	  padding:10px;
	  transition:all 0.3s;
	  -webkit-transition:all 0.3s;
	  width:60px;
	  height:60px;
	  box-sizing:border-box;
	 }

	 .metis.tparrows:hover {
	   background:#fff;
	   background:rgba(255,255,255,0.75);
	 }

	 .metis.tparrows:before {
	  color:#000;
	   transition:all 0.3s;
	  -webkit-transition:all 0.3s;
	 }

	 .metis.tparrows:hover:before {
	   transform:scale(1.5);
	  }


	/* BULLETS */
	.metis .tp-bullet {
	    opacity:1;
	    width:50px;
	    height:50px;
	    padding:3px;
	    background:#000;
	    background-color:rgba(0,0,0,0.25);
	    margin:0px;
	    box-sizing:border-box;
	    transition:all 0.3s;
	    -webkit-transition:all 0.3s;
	    border-radius:50%;
	  }

	.metis .tp-bullet-image {

	   border-radius:50%;
	   display:block;
	   box-sizing:border-box;
	   position:relative;
	    -webkit-box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	  -moz-box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	  box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.25);
	  width:44px;
	  height:44px;
	  background-size:cover;
	  background-position:center center;
	 }
	.metis .tp-bullet-title {
	     position:absolute;
		 bottom:65px;
	     display:inline-block;
	     left:50%;
	     background:#000;
	     background:rgba(0,0,0,0.75);
	     color:#fff;
	     padding:10px 30px;
	     border-radius:4px;
		 -webkit-border-radius:4px;
	     opacity:0;
	      transition:all 0.3s;
	    -webkit-transition:all 0.3s;
	    transform: translateZ(0.001px) translateX(-50%) translateY(14px);
	    transform-origin:50% 100%;
	    -webkit-transform: translateZ(0.001px) translateX(-50%) translateY(14px);
	    -webkit-transform-origin:50% 100%;
	    opacity:0;
	    white-space:nowrap;
	 }

	.metis .tp-bullet:hover .tp-bullet-title {
	  	 transform:rotateX(0deg) translateX(-50%);
	    -webkit-transform:rotateX(0deg) translateX(-50%);
	    opacity:1;
	}

	.metis .tp-bullet.selected,
	.metis .tp-bullet:hover  {

	   background: rgba(255,255,255,1);
	  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(119,119,119,1)));
	  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(119,119,119,1) 100%);
	  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#777777", GradientType=0 );

	      }
	.metis .tp-bullet-title:after {
	        content:" ";
	        position:absolute;
	        left:50%;
	        margin-left:-8px;
	        width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 8px 0 8px;
			border-color: rgba(0,0,0,0.75) transparent transparent transparent;
	        bottom:-8px;
	   }

	.metis .tp-tab-number {
	        color: #fff;
	        font-size: 40px;
	        line-height: 30px;
	        font-weight: 400;
	        font-family: "Playfair Display";
	        width: 50px;
	        margin-right: 17px;
	        display: inline-block;
	        float: left;
	    }
	    .metis .tp-tab-mask {
	        padding-left: 20px;
	        left: 0px;
	        max-width: 90px !important;
	        transition: 0.4s padding-left, 0.4s left, 0.4s max-width;
	    }
	    .metis:hover .tp-tab-mask {
	        padding-left: 0px;
	        left: 50px;
	        max-width: 500px !important;
	    }
	    .metis .tp-tab-divider {
	        border-right: 1px solid transparent;
	        height: 30px;
	        width: 1px;
	        margin-top: 5px;
	        display: inline-block;
	        float: left;
	    }
	    .metis .tp-tab-title {
	        color: #fff;
	        font-size: 20px;
	        line-height: 20px;
	        font-weight: 400;
	        font-family: "Playfair Display";
	        position: relative;
	        padding-top: 10px;
	        padding-left: 30px;
	        display: inline-block;
	        transform: translateX(-100%);
	        transition: 0.4s all;
	    }
	    .metis .tp-tab-title-mask {
	        position: absolute;
	        overflow: hidden;
	        left: 67px;
	    }
	    .metis:hover .tp-tab-title {
	        transform: translateX(0);
	    }
	    .metis .tp-tab {
	        opacity: 0.15;
	        transition: 0.4s all;
	    }
	    .metis .tp-tab:hover,
	    .metis .tp-tab.selected {
	        opacity: 1;
	    }
	    .metis .tp-tab.selected .tp-tab-divider {
	        border-right: 1px solid #cdb083;
	    }
	    .metis.tp-tabs {
	        max-width: 118px !important;
	        padding-left: 50px;
	    }
	    .metis.tp-tabs:before {
	        content: " ";
	        height: 100%;
	        width: 88px;
	        background: rgba(0, 0, 0, 0.15);
	        border-right: 1px solid rgba(255, 255, 255, 0.10);
	        left: 0px;
	        top: 0px;
	        position: absolute;
	        transition: 0.4s all;
	    }
	    .metis.tp-tabs:hover:before {
	        width: 118px;
	    }
	    @media (max-width: 499px) {
	        .metis.tp-tabs:before {
	            background: rgba(0, 0, 0, 0.75);
	        }
	    }

	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 PERSEPHONE SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.persephone.tparrows {
		cursor:pointer;
		background:#aaa;
		background:rgba(200,200,200,0.5);
		width:40px;
		height:40px;
		position:absolute;
		display:block;
		z-index:100;
	  border:1px solid #f5f5f5;
	}
	.persephone.tparrows:hover {
		background:#333;
	}
	.persephone.tparrows:before {
		font-family: "revicons";
		font-size:15px;
		color:#fff;
		display:block;
		line-height: 40px;
		text-align: center;
	}
	.persephone.tparrows.tp-leftarrow:before {
		content: "\e824";
	}
	.persephone.tparrows.tp-rightarrow:before {
		content: "\e825";
	}



	/* BULLETS */
	.persephone.tp-bullets {
	}
	.persephone.tp-bullets:before {
		content:" ";
		position:absolute;
		width:100%;
		height:100%;
		background:#transparent;
		padding:10px;
		margin-left:-10px;margin-top:-10px;
		box-sizing:content-box;
	}
	.persephone .tp-bullet {
		width:12px;
		height:12px;
		position:absolute;
		background:#aaa;
		border:1px solid #e5e5e5;
		cursor: pointer;
		box-sizing:content-box;
	}
	.persephone .tp-bullet:hover,
	.persephone .tp-bullet.selected {
		background:#222;
	}
	.persephone .tp-bullet-image {
	}
	.persephone .tp-bullet-title {
	}


	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 URANUS SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.uranus.tparrows {
	  width:50px;
	  height:50px;
	  background:transparent;
	 }
	 .uranus.tparrows:before {
	 width:50px;
	 height:50px;
	 line-height:50px;
	 font-size:40px;
	 transition:all 0.3s;
	-webkit-transition:all 0.3s;
	 }

	  .uranus.tparrows:hover:before {
	    opacity:0.75;
	  }

	/* BULLETS */
	.uranus .tp-bullet{
		border-radius: 50%;
	  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
	  -webkit-transition: box-shadow 0.3s ease;
	  transition: box-shadow 0.3s ease;
	  background:transparent;
	}
	.uranus .tp-bullet.selected,
	.uranus .tp-bullet:hover {
	  box-shadow: 0 0 0 2px #FFF;
	  border:none;
	  border-radius: 50%;

	   background:transparent;
	}



	.uranus .tp-bullet-inner {
	  background-color: rgba(255, 255, 255, 0.7);
	  -webkit-transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
	  transition: background-color 0.3s ease, transform 0.3s ease;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  outline: none;
	  border-radius: 50%;
	  background-color: #FFF;
	  background-color: rgba(255, 255, 255, 0.3);
	  text-indent: -999em;
	  cursor: pointer;
	  position: absolute;
	}

	.uranus .tp-bullet.selected .tp-bullet-inner,
	.uranus .tp-bullet:hover .tp-bullet-inner{
	 transform: scale(0.4);
	 -webkit-transform: scale(0.4);
	 background-color:#fff;
	}

	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 ZEUS SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/
	/* ARROWS */
	.zeus.tparrows {
	  cursor:pointer;
	  min-width:70px;
	  min-height:70px;
	  position:absolute;
	  display:block;
	  z-index:100;
	  border-radius:35px;
	  overflow:hidden;
	  background:rgba(0,0,0,0.10);
	}

	.zeus.tparrows:before {
	  font-family: "revicons";
	  font-size:20px;
	  color:#fff;
	  display:block;
	  line-height: 70px;
	  text-align: center;
	  z-index:2;
	  position:relative;
	}
	.zeus.tparrows.tp-leftarrow:before {
	  content: "\e824";
	}
	.zeus.tparrows.tp-rightarrow:before {
	  content: "\e825";
	}

	.zeus .tp-title-wrap {
	  background:#000;
	  background:rgba(0,0,0,0.5);
	  width:100%;
	  height:100%;
	  top:0px;
	  left:0px;
	  position:absolute;
	  opacity:0;
	  transform:scale(0);
	  -webkit-transform:scale(0);
	   transition: all 0.3s;
	  -webkit-transition:all 0.3s;
	  -moz-transition:all 0.3s;
	   border-radius:50%;
	 }
	.zeus .tp-arr-imgholder {
	  width:100%;
	  height:100%;
	  position:absolute;
	  top:0px;
	  left:0px;
	  background-position:center center;
	  background-size:cover;
	  border-radius:50%;
	  transform:translateX(-100%);
	  -webkit-transform:translateX(-100%);
	   transition: all 0.3s;
	  -webkit-transition:all 0.3s;
	  -moz-transition:all 0.3s;

	 }
	.zeus.tp-rightarrow .tp-arr-imgholder {
	    transform:translateX(100%);
	  -webkit-transform:translateX(100%);
	      }
	.zeus.tparrows:hover .tp-arr-imgholder {
	  transform:translateX(0);
	  -webkit-transform:translateX(0);
	  opacity:1;
	}

	.zeus.tparrows:hover .tp-title-wrap {
	  transform:scale(1);
	  -webkit-transform:scale(1);
	  opacity:1;
	}


	/* BULLETS */
	.zeus .tp-bullet {
	     box-sizing:content-box; -webkit-box-sizing:content-box; border-radius:50%;
	      background-color: rgba(0, 0, 0, 0);
	      -webkit-transition: opacity 0.3s ease;
	      transition: opacity 0.3s ease;
		  width:13px;height:13px;
		  border:2px solid #fff;
	 }
	.zeus .tp-bullet:after {
	  content: "";
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  left: 0;
	  border-radius: 50%;
	  background-color: #FFF;
	  -webkit-transform: scale(0);
	  transform: scale(0);
	  -webkit-transform-origin: 50% 50%;
	  transform-origin: 50% 50%;
	  -webkit-transition: -webkit-transform 0.3s ease;
	  transition: transform 0.3s ease;
	}
	.zeus .tp-bullet:hover:after,
	.zeus .tp-bullet.selected:after{
	    -webkit-transform: scale(1.2);
	  transform: scale(1.2);
	}

	 .zeus .tp-bullet-image,
	 .zeus .tp-bullet-imageoverlay{
	        width:135px;
	        height:60px;
	        position:absolute;
	        background:#000;
	        background:rgba(0,0,0,0.5);
	        bottom:25px;
	        left:50%;
	        margin-left:-65px;
	        box-sizing:border-box;
	        background-size:cover;
	        background-position:center center;
	        visibility:hidden;
	        opacity:0;
	         -webkit-backface-visibility: hidden;
	      	backface-visibility: hidden;
	        -webkit-transform-origin: 50% 50%;
			transform-origin: 50% 50%;
	  		-webkit-transition: all 0.3s ease;
	  		transition: all 0.3s ease;
	        border-radius:4px;

	}


	.zeus .tp-bullet-title,
	.zeus .tp-bullet-imageoverlay {
	        z-index:2;
	        -webkit-transition: all 0.5s ease;
		  	transition: all 0.5s ease;
	}
	.zeus .tp-bullet-title {
	        color:#fff;
	        text-align:center;
	        line-height:15px;
	        font-size:13px;
	        font-weight:600;
	        z-index:3;
	         visibility:hidden;
	        opacity:0;
	         -webkit-backface-visibility: hidden;
	      	backface-visibility: hidden;
	        -webkit-transform-origin: 50% 50%;
			transform-origin: 50% 50%;
	  		-webkit-transition: all 0.3s ease;
	  		transition: all 0.3s ease;
	        position:absolute;
	        bottom:45px;
	        width:135px;
	    	vertical-align:middle;
	        left:-57px;
	}

	.zeus .tp-bullet:hover .tp-bullet-title,
	.zeus .tp-bullet:hover .tp-bullet-image,
	.zeus .tp-bullet:hover .tp-bullet-imageoverlay{
	      opacity:1;
	      visibility:visible;
		  -webkit-transform:translateY(0px);
	      transform:translateY(0px);
	    }

	/* THUMBS */
	.zeus .tp-thumb {
	opacity:1
	}

	.zeus .tp-thumb-over {
	  background:#000;
	  background:rgba(0,0,0,0.25);
	  width:100%;
	  height:100%;
	  position:absolute;
	  top:0px;
	  left:0px;
	  z-index:1;
	  -webkit-transition:all 0.3s;
	  transition:all 0.3s;
	}

	.zeus .tp-thumb-more:before {
	  font-family: "revicons";
	  font-size:12px;
	  color:#aaa;
	  color:rgba(255,255,255,0.75);
	  display:block;
	  line-height: 12px;
	  text-align: left;
	  z-index:2;
	  position:absolute;
	  top:20px;
	  right:20px;
	  z-index:2;
	}
	.zeus .tp-thumb-more:before {
	  content: "\e825";
	}

	.zeus .tp-thumb-title {
	  font-family:"Raleway";
	  letter-spacing:1px;
	  font-size:12px;
	  color:#fff;
	  display:block;
	  line-height: 15px;
	  text-align: left;
	  z-index:2;
	  position:absolute;
	  top:0px;
	  left:0px;
	  z-index:2;
	  padding:20px 35px 20px 20px;
	  width:100%;
	  height:100%;
	  box-sizing:border-box;
	  transition:all 0.3s;
	  -webkit-transition:all 0.3s;
	  font-weight:500;
	}

	.zeus .tp-thumb.selected .tp-thumb-more:before,
	.zeus .tp-thumb:hover .tp-thumb-more:before {
	 color:#aaa;

	}

	.zeus .tp-thumb.selected .tp-thumb-over,
	.zeus .tp-thumb:hover .tp-thumb-over {
	 background:#000;
	}
	.zeus .tp-thumb.selected .tp-thumb-title,
	.zeus .tp-thumb:hover .tp-thumb-title {
	  color:#fff;

	}


	/* TABS */
	.zeus .tp-tab {
	  opacity:1;
	  box-sizing:border-box;
	}

	.zeus .tp-tab-title {
	display: block;
	text-align: center;
	background: rgba(0,0,0,0.25);
	font-family: "Roboto Slab", serif;
	font-weight: 700;
	font-size: 13px;
	line-height: 13px;
	color: #fff;
	padding: 9px 10px; }

	.zeus .tp-tab:hover .tp-tab-title,
	.zeus .tp-tab.selected .tp-tab-title {
	 color: #000;
	  background:rgba(255,255,255,1);
	}



	/*-----------------------------------------------------------------------------

	- Revolution Slider 5.0 Navigatin Skin Style  -

	 ZEUS SKIN

	author:  ThemePunch
	email:      info@themepunch.com
	website:    http://www.themepunch.com
	-----------------------------------------------------------------------------*/


	.post-tabs .tp-thumb {
	opacity:1
	}

	.post-tabs .tp-thumb-over {
	  background:#252525;
	  width:100%;
	  height:100%;
	  position:absolute;
	  top:0px;
	  left:0px;
	  z-index:1;
	  -webkit-transition:all 0.3s;
	  transition:all 0.3s;
	}

	.post-tabs .tp-thumb-more:before {
	  font-family: "revicons";
	  font-size:12px;
	  color:#aaa;
	  color:rgba(255,255,255,0.75);
	  display:block;
	  line-height: 12px;
	  text-align: left;
	  z-index:2;
	  position:absolute;
	  top:15px;
	  right:15px;
	  z-index:2;
	}
	.post-tabs .tp-thumb-more:before {
	  content: "\e825";
	}

	.post-tabs .tp-thumb-title {
	  font-family:"raleway";
	  letter-spacing:1px;
	  font-size:12px;
	  color:#fff;
	  display:block;
	  line-height: 15px;
	  text-align: left;
	  z-index:2;
	  position:absolute;
	  top:0px;
	  left:0px;
	  z-index:2;
	  padding:15px 30px 15px 15px;
	  width:100%;
	  height:100%;
	  box-sizing:border-box;
	  transition:all 0.3s;
	  -webkit-transition:all 0.3s;
	  font-weight:500;
	}

	.post-tabs .tp-thumb.selected .tp-thumb-more:before,
	.post-tabs .tp-thumb:hover .tp-thumb-more:before {
	 color:#aaa;

	}

	.post-tabs .tp-thumb.selected .tp-thumb-over,
	.post-tabs .tp-thumb:hover .tp-thumb-over {
	 background:#fff;
	}
	.post-tabs .tp-thumb.selected .tp-thumb-title,
	.post-tabs .tp-thumb:hover .tp-thumb-title {
	  color:#000;

	}

	.green-text{
		color: #0FB855;
	}

}
