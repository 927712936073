.container_edit_user {

    width: 100%;
    color: #9A9BA0;
    font-size: 16px;
    position: relative;
    padding-bottom: 40px;
    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);
    background: #fff;

    .container_header {
        background-color: #fff;
    }

    img {
        object-fit: contain;
    }

    &_photo {
        width: 100%;
        label {
            color: green;
            font-weight: bold;
            text-align: center;
            display: block;
            margin-top: -20px;
        }

        img {
            display: block;
            margin: 0 auto;
            border-radius: 50%;
            margin-bottom: 10px;
            margin-top: 20px;
        }

        .imageUser {
            width: 120px;
            height: 120px;
            object-fit: cover!important;
        }

        input {
            visibility: hidden;
        }
    }

    &_gender {
        padding: 15px 15px 0px 15px;

        p {
            margin: 10px 0px 10px 0px;
        }

        &_container {
            display: flex;
            justify-content: start;

            &.blue {
                padding-left: 15px;
                padding-right: 15px;
            }

            &_option {

                &_field {
                    button {
                        height: 50px;
                        text-align: center;
                        background-color: white;
                        border: none;
                        border: 1px solid #CDCED4;
                        color: #9A9BA0;
                        font-weight: bold;
                    }


                }

                .member {
                    button {
                        width: 110px!important;
                        margin-left: 5px;
                    }
                }

                .active {
                    button {
                        background-color: #DAF7E6;
                        border: none;
                        border: 1px solid #0FB855;
                        color: #0FB855;
                        font-weight: bold;

                        &.blue {


                            background-color: #DDEAFB;
                            border: 1px solid var(--wblue);
                            color: var(--wblue);
                        }
                    }
                }

                .h {
                    button {
                        width: 170px;
                        margin-right: 5px;
                    }
                }

                .m {
                    button {
                        width: 170px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .btn-save {
        width: 95%;
        display: block;
        margin: 40px auto 40px auto;
        background-color: var(--wblue);
        border: none;
        border-radius: 5px;
        height: 55px;
        color: white;
        font-weight: bold;
    }

    &_input{
        padding: 15px 15px 0px 15px;

        &.fullRow {
            padding-left: 0;
            padding-right: 0;
        }

        p {
            margin: 10px 0px 10px 0px;

            &.titleP {
                background: var(--wgray_softer);
                height: 55px;
                display: flex;
                align-items: center;
                padding-left: 15px;
                padding-right: 15px;
                font-size: 18px;
                font-weight: 600;
                color: var(--wblue);
            }

            &.subtitleP {
                padding: 15px;
            }
        }

        &_field {
            position: relative;

            &.fullCol {
                padding-left: 15px;
                padding-right: 15px;
            }

            .p-left {
                padding-left: 15px!important;
            }

            img {
                position: absolute;
                margin: 18px 5px 18px 15px ;
                width: 20px;
                height: 15px;

                &.datepicker-icn {
                    z-index: 1;
                }

                &.aficion {
                    margin: 7px;
                    width: 28px;
                    height: 22px;
                    left: 0;
                    bottom: 8px;
                }
            }

            input {
                width: 100%!important;
                height: 50px!important;
                padding-left: 40px;
                border: 1px solid var(--wgray_semisoft);
                border-radius: 3px;
                &.noIcon {
                    padding-left: 8px;
                }
            }

            // .react-datepicker-wrapper {
            //     position: relative!important;
            //     width: 100%!important;
            // }
            //
            // .react-datepicker__input-container {
            //     position: relative!important;
            //     width: 100%!important;
            // }
            //
            // .react-datepicker-ignore-onclickoutside {
            //     position: relative!important;
            //     width: 100%!important;
            // }

            .field-btn {
                height: 50px;
                background-color: white;
                border: none;
                width: 100%;
                padding-left: 10px;
                border: 1px solid #CDCED4;
                color: #9A9BA0;
                font-weight: bold;
                padding-left: 50px;
                justify-content: start!important;
            }

            // .react-datepicker__navigation {
            //     background-color: transparent;
            //     visibility: visible;
            //     height: 40px;
            //     z-index: 4;
            //     margin-top: 7px;
            //     border: none;
            //     padding-left: 0;
            //     width: 30px;
            // }
            //
            // .react-datepicker__month-container {
            //     background-color: white;
            //     z-index: 3;
            // }

            button.button {
                padding-left: 20px;
            }

            .p-0 {
                padding-left: 15px!important;
            }

            .black {
                color: black;
            }

            .right {
                right: 20px;
                width: 40px;
                height: 50px;
                margin: 0;
            }

            .up-social-span {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
            }
        }

        .redes input{
            padding-left: 15px;
        }

        &_switch {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #F8F8FB;
            padding: 8px 10px 8px 10px;

              .switch {
                position: relative;
                display: inline-block;
                width: 58px;
                height: 30px;
              }

              .switch input {
                opacity: 0;
                width: 0;
                height: 0;
              }

              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: .4s;
                transition: .4s;
              }

              .slider:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 2px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
              }

              input:checked + .slider {
                background-color: green;
              }

              input:focus + .slider {
                box-shadow: 0 0 1px green;
              }

              input:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
              }

              /* Rounded sliders */
              .slider.round {
                border-radius: 34px;
              }

              .slider.round:before {
                border-radius: 50%;
              }
        }
    }

    .container-lada {

        padding: 15px 15px 0 15px;

        p {
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
        }

        .Login-input-box-lada {
            height: 50px;
            width: 100%;
            border: 1px solid var(--wgray_semisoft);
            border-radius: 5px;
            padding: 5px;

            background: transparent;
            &.input-alert {
                border: 1px solid red;
                background: #ffefef;
            }

            .lada-btn-box {
                max-width: 100px;
                width: 100px;

                button {
                    width: 100%;
                    background: transparent;
                    outline: none !important;
                    border: none;
                    border-right: 1px solid var(--wgray_dark);
                    font-size: 17px;
                    font-weight: 600;
                    color: var(--wgreen);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .input-box {
                input {
                    width: 100%;
                    border: none;
                    color: var(--wblue_dark);
                    font-size: 17px;
                    font-weight: 600;
                    outline: none !important;
                    padding: 6px;
                    background: transparent;
                    &::placeholder {
                        color: var(--wgray_semisoft);
                    }
                }
            }
        }
    }

}


.EditUser-form-container {
    padding: 25px 15px 0;

    .EditUser-form-disclaimer {
        padding-bottom: 20px;

        .EditUser-form-disclaimer-title {
            font-size: 18px;
            font-weight: bold;
            color: var(--wblue);
            margin-bottom: 5px;
        }

        .EditUser-form-disclaimer-subtitle {
            line-height: 20px;

            color: #9A9BA0;
            font-size: 16px;
        }
    }

    .EditUser-form-option-container {
        padding-bottom: 14px;

        .mainBtn {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 14px;
            height: 55px;
            background: transparent;
            border-radius: 3px;
            border: 1px solid var(--wgray_dark);
            font-size: 15px;
            font-weight: 600;
            color: var(--wgray_darker);

            img {
                object-fit: cover;
                object-position: 0 calc(-30px * 8);

                &.isBullet {
                    object-position: 0 calc(-30px * 2);
                }
            }

            &.active {
                border: 1px solid var(--wblue);
                color: var(--wblue);
                background: #DDEAFB;

                img {
                    object-fit: cover;
                    object-position: 0 calc(-30px * 9);

                    &.isBullet {
                        object-position: 0 calc(-30px * 3);
                    }
                }
            }

            &:hover {
                border: 1px solid var(--wblue);
                color: var(--wblue);
                background: #DDEAFB;
            }
        }

        .EditUser-form-suboptions-container {
            padding: 24px 0;

            .subOptionsTitle {
                padding-bottom: 14px;
                font-size: 18px;
                color: var(--wgray_darker);
            }

            .btns-Options {

                button {
                    font-size: 17px;
                    font-weight: bold;
                    color: var(--wblue);
                    padding: 14px;
                    background: transparent;
                    border: 1px solid var(--wgray_dark);
                    border-radius: 3px;
                    max-width: 159px;

                    &.left {
                        margin-right: 4px;
                    }

                    &.right {
                        margin-left: 4px;
                    }

                    &:hover,
                    &.active {
                        background: #DDEAFB;
                        border: 1px solid var(--wblue);
                    }
                }
            }

            .linkBox {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                color: var(--wgray_darker);
                padding-top: 24px;

                a {
                    color: var(--wgreen);
                    font-weight: bold;
                }
            }

            .list-Options {

                .listOption {
                    height: 55px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--wgray_darker);
                    border-bottom: 1px solid #EBEBEB;
                    cursor: pointer;

                    &.isFirst {
                        border-top: 1px solid #EBEBEB;
                    }

                    img {
                        object-fit: cover;
                        object-position: 0 0;

                        &.isActive {
                            object-position: 0 -30px;
                        }
                    }

                    &.isActive {
                        cursor: default;
                        img {
                            object-position: 0 -30px;
                        }
                    }
                }
            }

            .selectOptions-container {
                height: 55px;
                display: flex;
                align-items: center;
                position: relative;
                margin-top: 24px;

                label {
                    position: absolute;
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--wgray_darker);
                    left: 14px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                select {
                    width: 100%;
                    height: 55px;
                    border: 1px solid var(--wgray_dark);
                    border-radius: 3px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    text-indent: 1px;
                    text-overflow: '';
                    text-align: end;
                    padding-right: 50px;
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--wblue);
                    outline: none !important;
                    z-index: 1;
                    background: transparent;
                }

                img {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    user-select: none;
                }
            }
        }
    }
}

/*FIX ONLY SAFARI**/
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
        .EditUser-form-container {
            .EditUser-form-option-container {
                .EditUser-form-suboptions-container {
                    .selectOptions-container {
                        select {
                            direction: rtl;
                        }
                    }
                }
            }
        }
    }
}


/* Safari 11+ */
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        .EditUser-form-container {
            .EditUser-form-option-container {
                .EditUser-form-suboptions-container {
                    .selectOptions-container {
                        select {
                            direction: rtl;
                        }
                    }
                }
            }
        }
    }
}

/* Safari 10.1 */
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
        .EditUser-form-container {
            .EditUser-form-option-container {
                .EditUser-form-suboptions-container {
                    .selectOptions-container {
                        select {
                            direction: rtl;
                        }
                    }
                }
            }
        }
    }
}

/* Safari 9+ */
@supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
    .EditUser-form-container {
        .EditUser-form-option-container {
            .EditUser-form-suboptions-container {
                .selectOptions-container {
                    select {
                        direction: rtl;
                    }
                }
            }
        }
    }
}

// /* Safari 6.1-7.0 */
// @media screen and (-webkit-min-device-pixel-ratio:0) and (min-color-index:0) {
//     .RegisterWizard-container {
//         .RegisterWizard-steps-container {
//             .RegisterWizard-steps-form-container {
//                 .RegisterWizard-form-option-container {
//                     .RegisterWizard-form-suboptions-container {
//                         .selectOptions-container {
//                             select {
//                                 direction: rtl;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
