.AppsDisclaimer-container {
    background: #fff;
    width: 100%;
    height: 100%;
    overflow: auto;
    top: 0;
    z-index: 3;
    padding: 24px;
    max-width: 580px;
    border-radius: 10px;

    .AppsDisclaimer-header-box {
        display: flex;
        align-items: center;
        justify-content: end;
        position: relative;

        padding: 0;
        background: #fff;
        border-bottom: 0px solid #fff;

        .AppsDisclaimer-btn-close {
            height: 30px;
            width: 30px;

            img {
                width: 100%;
                height: 30px;
            }
        }

        .AppsDisclaimer-btn-clear {
            height: 22px;
            color: var(--wred);
            font-size: 15px;
            right: 14px;
        }
    }

    .AppsDisclaimer-logo-box {

        img {
            filter: drop-shadow(0 13px 37px rgba(37,39,46,0.3));
        }
    }

    .AppsDisclaimer-text-main {
        padding: 27px 0;

        .firstLine {
            color: var(--wgray_darker);
            font-size: 26px;
            line-height: 27px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 5px;
        }

        .secondLine {
            color: var(--wgreen);
            font-size: 26px;
            font-weight: bold;
            text-align: center;
            margin: 0;
        }
    }

    .AppsDisclaimer-text-subtitle {
        font-size: 16px;
        color: var(--wgray_darker);
        text-align: center;
        padding-bottom: 25px;
    }
}

@media screen and (max-width: 500px) {
    .AppsDisclaimer-container {
        padding: 20px;

        .AppsDisclaimer-text-main {
            padding: 27px 0 10px;

            .firstLine {
                font-size: 22px;
                line-height: 22px;
                margin-bottom: 10px;
            }

            .secondLine {
                font-size: 20px;
            }
        }

        .AppsDisclaimer-text-subtitle {
            font-size: 15px;
        }
    }
}
// .BMAcademy-container {
//     background: #fff;
//     width: 100%;
//     height: calc(100vh - 73px);
//     overflow: auto;
//     top: 0;
//     z-index: 3;
//     padding: 0px 0px 20px 0px;
//     max-width: 700px;
//
//     .BMAcademy-header-box {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         position: relative;
//
//         padding: 30px 14px 10px 14px;
//         background: #fff;
//         border-bottom: 0px solid #fff;
//
//         p {
//             margin: 0;
//             font-size: 20px;
//             font-weight: 600;
//             color: var(--wblue_dark);
//
//             &.large-title{
//                 font-size: 17px;
//             }
//         }
//         .BMAcademy-btn-close,
//         .BMAcademy-btn-clear {
//             position: absolute;
//             bottom: 10px;
//         }
//
//         .BMAcademy-btn-close {
//             height: 30px;
//             width: 30px;
//             left: 14px;
//
//             img {
//                 width: 100%;
//                 height: 30px;
//             }
//         }
//
//         .BMAcademy-btn-clear {
//             height: 22px;
//             color: var(--wred);
//             font-size: 15px;
//             right: 14px;
//         }
//     }
//
//     .BMAcademy-content {
//
//         padding: 50px 35px;
//
//         .BMAcademy-content-label {
//             font-size: 20px;
//             font-weight: bold;
//             color: var(--wblue);
//             text-align: left;
//         }
//
//         .BMAcademy-content-code {
//             background: #E8FAF0;
//             border-radius: 5px;
//             border: 1px dashed #88DDAB;
//             height: 90px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             font-size: 23px;
//             font-weight: bold;
//             color: #585C6E;
//             margin: 20px 0 15px;
//         }
//
//         .BMAcademy-content-btn-copy {
//             padding-bottom: 20px;
//
//             button {
//                 height: 50px;
//                 background: #0FB855;
//                 width: 100%;
//                 font-size: 16px;
//                 font-weight: 500;
//                 color: #fff;
//
//                 span {
//                     padding-left: 5px;
//                 }
//             }
//         }
//
//         .BMAcademy-disclaimer-container {
//             padding-top: 5px;
//             font-size: 16px;
//             line-height: 20px;
//             color: var(--wblue_dark);
//         }
//
//     }
// }
//
//
// .BMAcademy-footer-container {
//     border-top: 1px solid var(--wgray_soft);
//     background: #fff;
//     width: 100%;
//     height: calc(45px + 14px + 14px);
//     padding: 14px;
//     z-index: 3;
//     position: absolute;
//     bottom: 0;
//
//     a {
//         background: var(--wblue);
//         width: 100%;
//         height: 45px;
//         border-radius: 5px;
//         font-size: 16px;
//         font-weight: bold;
//         color: #fff;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//     }
// }
