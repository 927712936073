

.container-settings {

    background-color: #F8F8FB;
    height: 100vh;
    padding: 0;
    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);

    &-header {
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        display: flex;
        padding: 30px 15px 10px 15px;
        align-items: center;
        background-color: #F8F8FB;


        h3 {
            color: black;
            width: 100vw;
            text-align: center;
            display: block;
            font-size: 17dp;
        }

        img {
            position: absolute;
            left: 15px;
            cursor: pointer;
        }
    }

    &_settings {
        background: #fff;

        .container-settings_option {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            padding: 10px 15px 10px 15px;
            align-items: center;
            background-color: white;
            margin-bottom: 5px;

            p {
                margin: 0;
            }

            .logout {
                width: 100vw;
                text-align: center;
            }

            a {
                text-decoration: none;
                color: black;
            }
        }

        .container-settings_logo {
            width: 100%;
            display: block;
            margin-bottom: 40px;

            a {
                text-decoration: none;
                color: black;
                display: block;
                margin: 0 auto;
                text-align: center;

                img {
                    margin: 0 auto;
                    margin-bottom: 40px;
                }
            }
        }

        .jump {
            margin-bottom: 60px;
        }
    }

    


}


@media screen and (min-width: 1450px) {
    .Settings-container {
        background: var(--wgray_softer);
        width: 100%;
        height: 100%;
        max-width: 100%;
        margin: 0 auto;

        .Settings-box-container {
            padding-top: 25px;
            height: calc(100vh - 100px);
            overflow: auto;

            .Settings-lateral-content {
                max-width: calc(350px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));

                .left {

                }

                .right {

                }
            }

            .Settings-post-box {
                max-width: 700px;
                min-width: 700px;
                border-left: 1px solid var(--wgray_semisoft);
                border-right: 1px solid var(--wgray_semisoft);

                .Settings-post-container {
                    width: 100%;
                    padding: 14px 0;
                    // height: calc(100% - 65px - 61px);
                    height: auto;
                    overflow: visible;
                    background: var(--wgray_softer);

                    .Settings-loaderBox {
                        background: #fff;
                        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
                        border-radius: 5px;
                        margin-bottom: 14px;
                        padding: 14px;
                    }
                }
            }


        }


    }
}
