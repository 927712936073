
.container_header {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 30px 15px 10px 15px;
    align-items: center;
    background-color: #F8F8FB;

    p {
        margin: 0;
        height: auto;
        text-align: center;
        margin: 0;
        text-align: center;
        color: green;
    }

    h3 {
        color: black;
    }
}
