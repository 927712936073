.container_preferences{
    width: 100%;
    height: 100vh;
    color: #9A9BA0;
    font-size: 16px;
    position: relative;
    padding-bottom: 40px;
    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);


    .container_header {
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        display: flex;
        padding: 30px 15px 10px 15px;
        align-items: center;
        background-color: #F8F8FB;


        h3 {
            color: black;
            width: 100vw;
            text-align: center;
            display: block;
            font-size: 17dp;
        }

        img {
            position: absolute;
            left: 15px;
            cursor: pointer;
        }
    }

    p {
        padding: 10px;
        background-color: white;
        font-size: 17px;
        color: #585C6E;
        padding: 10px 20px 10px 20px;
    }

    h5 {
        padding: 0 20px 0 20px;
        background-color: #F8F8FB!important;
        color: #272C40;
    }

    .Filters-container {
        padding-top: 0!important;
        height: auto!important;
    }

    .button-preferences {
        width: 90%;
        height: 55px;
        background-color: #0D458D;
        border: none;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        display: block;
        margin: 0 auto;
        margin-top: 40px;
    }
}


.container_preferences.isDesktop {
    width: 100%;
    height: calc(100% - 16px);
    color: #9A9BA0;
    font-size: 16px;
    position: relative;
    padding-bottom: 40px;
    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);


    .container_header {
        margin: 0;
        background-color: #fff!important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        h3 {
            font-size: 20px !important;
            color: var(--wblue_dark);
            text-align: left;
        }
    }

    p {
        padding: 10px;
        background-color: white;
        font-size: 17px;
        color: #585C6E;
        padding: 10px 20px 10px 20px;
    }

    h5 {
        padding: 0 20px 0 20px;
        background-color: #F8F8FB!important;
        color: #272C40;
        height: 55px;
        display: flex;
        align-items: center;
        margin: 0 20px;
    }

    .Filters-container {
        padding-top: 0!important;
        height: auto!important;
    }

    .button-preferences {
        width: 90%;
        height: 55px;
        background-color: #0D458D;
        border: none;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        display: block;
        margin: 0 auto;
        margin-top: 40px;
    }

    .text-muted-config{
        color: #585C6E;
    }
    
}
