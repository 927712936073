.BrochureSheet-container {
    margin: 24px 12px 20px 12px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
    padding: 24px;

    img {
        width: 100px;
        height: 80px;
        object-fit: cover;
    }

    span {
        font-size: 18px;
        color: var(--wgray_darker);
        padding-left: 24px;
    }
}
