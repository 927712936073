.RegisterCreate-container {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .RegisterCreate-close-btn-container {
        padding-left: 24px;
        padding-right: 24px;

        .RegisterCreate-close-btn {
            width: 30px;
            height: 30px;
            padding: 0;
            outline: none !important;

            background: transparent;
            border: none;

            img {
                width: 100%;
                height: 30px;
            }
        }
    }


    .RegisterCreate-titles-box {
        padding: 30px 24px 24px 24px;

        .title {
            color: var(--wblue_dark);
            font-size: 28px;
            font-weight: bold;
        }

        .subtitle {
            color: var(--wgray_darker);
            font-size: 17px;
        }
    }

    .RegisterCreate-terms-container {

        .list-group {

            .list-group-item {
                height: 55px;
                padding: 5px 24px;

                a {
                    text-decoration: none;
                    font-size: 17px;
                    color: var(--wgray_darker);
                    outline: none !important;

                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }

    .RegisterCreate-input-box {
        position: relative;
        label.icon {
            margin: 0;
            width: 55px;
            height: 55px;
            position: absolute;
            top: 0;
            left: 0;

            img {
                width: 100%;
                height: 55px;
            }
        }

        input {
            height: 55px;
            width: 100%;
            border: 1px solid var(--wgray_semisoft);
            padding: 2px 6px;
            color: var(--wblue_dark);
            border-radius: 5px;
            font-size: 17px;
            font-weight: 600;
            outline: none !important;

            &::placeholder {
                color: var(--wgray_semisoft);
            }
            &.iconned {
                padding-left: 55px;
            }
        }

        &.input-alert {
            input {
                border: 1px solid red;
                background: #ffefef;
            }
        }
    }

    .RegisterCreate-interior-container {
        padding-left: 24px;
        padding-right: 24px;

        .RegisterCreate-termscheck-container {
            background: var(--wgray_softer);
            border: 1px solid var(--wgray_semisoft);
            border-radius: 3px;
            padding: 16px;

            font-size: 17px;
            color: var(--wgray_darker);
            line-height: 21px;
            
            .check-col {
                max-width: 30px;
                max-height: 30px;

                button {
                    img {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .RegisterCreate-step-btn-box {
            padding-top: 16px;

            button {
                height: 55px;
                border-radius: 4px;
                background: var(--wblue);
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                border: none;
                outline: none !important;
                width: 100%;
                padding: 0;
            }
        }

    }


    .RegisterData-error-box {
        background: #ff6d6d;

        color: #fff;
        border-radius: 5px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
    }
}
