.GroupDetails-container {

    

    .GroupDetails-header-container {
        height: 60px;
        padding-left: 14px;
        padding-right: 14px;
        position: relative;
        display: flex;
        align-items: center;

        button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 14px;
            z-index: 2;

            img {
                width: 30px;
                height: 30px;
            }
        }

        p {
            margin: 0;
            font-size: 17px;
            font-weight: 600;
            color: var(--wblue_dark);
            text-align: center;
            width: 100%;
        }
    }

    .main-box {
        height: calc(100vh - 60px - 60px);
        overflow: auto;
        position: relative;

        .GroupDetails-image-container {
            padding: 0;
            width: 100%;
            max-height: 375px;
            position: relative;

            .group-img {
                width: 100%;
                height: 375px;
                max-height: 375px;
                object-fit: cover;
            }

            button {
                position: absolute;
                top: 14px;
                right: 14px;
                width: 60px;
                height: 60px;

                img {
                    width: 60px;
                    height: 60px;
                    background: rgba(255,255,255,0.9);
                    border-radius: 30px;
                }
            }
        }

        .GroupDetails-group-col-header {
            height: 44px;
            background: var(--wgray_softer);
            padding-left: 14px;
            padding-right: 14px;
            display: flex;
            align-items: center;
            font-size: 17px;
            font-weight: 600;
            color: var(--wgray_dark);
        }

        .GroupDetails-group-col {
            height: 60px;
            padding-left: 14px;
            padding-right: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 17px;
            font-weight: 600;
            color: var(--wblue_dark);
            border-bottom: 1px solid var(--wgray_soft);
        }

        .GroupDetails-group-members-container {
            padding: 14px;

            .GroupDetails-group-add-container {
                padding: 0 14px 14px 14px;
                border-bottom: 1px solid var(--wgray_soft);
                cursor: pointer;
                display: flex;
                align-items: center;

                img {
                    border-radius: 30px;
                    background: var(--wgray_softer);
                    padding: 8px;
                }

                p {
                    margin: 0;
                    padding-left: 14px;
                    font-size: 17px;
                    font-weight: 600;
                    color: var(--wblue);
                }
            }

            .GroupDetails-member-item {
                padding: 14px 0;
                border-bottom: 1px solid var(--wgray_soft);

                .avatar-image-box-60{
                    position: relative!important;
                    .avatar {
                        position: relative!important;
                    }
        
                    .miembro {
                        position: absolute!important;
                        width: 25px;
                        height: 25px;
                        bottom: 0;
                        right: -5px;
                    }
                }

                .admin-col {
                    max-width: 90px;
                    text-align: right;
                    font-size: 17px;
                    font-weight: 600;
                    color: var(--wgray_dark);

                    &.isDropdown {
                        max-width: 30px;
                        margin-top: -5px;
                    }


                    .dropdown {
                        .dropdown-toggle {
                            background: transparent;
                            border: none;
                            outline: none !important;
                            padding: 0;
                            box-shadow: none !important;
                            &::after {
                                display: none;
                            }
                        }
                    }
                }

            }
        }

        .footer-btn {
            position: fixed;
            bottom: 0;
            color: var(--wred_semisoft);
            font-weight: 600;
            font-size: 17px;
            padding: 14px;
            height: 60px;
            background: var(--wgray_softer);
            width: 100%;
            max-width: 700px;
        }
    }
}

.GroupDetails-edit-title-container {

    .GroupDetails-edit-header-container {
        height: 60px;
        background: var(--wgray_softer);
        border-bottom: 1px solid var(--wgray_soft);
        position: relative;
        display: flex;
        align-items: center;

        .back-btn, .submit-btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .back-btn {
            left: 14px;
        }

        .submit-btn {
            right: 14px;
            font-size: 17px;
            font-weight: 600;
        }

        p {
            margin: 0;
            font-weight: 600;
            font-size: 17px;
            color: var(--wblue_dark);
            width: 100%;
            text-align: center;
        }
    }

    .GroupDetails-edit-input-container {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-left: 14px;
        padding-right: 14px;

        label {
            font-weight: 600;
            color: var(--wgray_dark);
            font-size: 15px;
            margin-bottom: 12px;
        }

        input {
            height: 55px;
            padding: 14px;
            border-radius: 3px;
            border: 1px solid var(--wgray_semisoft);
            outline: none !important;
        }

        button {
            position: absolute;
            bottom: 7.5px;
            width: 40px;
            height: 40px;
            right: 14px;

            img {
                width: 100%;
                height: 40px;
            }
        }
    }
}

.GroupDetails-leave-container {
    padding: 14px;

    .GroupDetails-leave-item-container {
        padding: 14px 0;
        border-bottom: 1px solid var(--wgray_soft);

        .bullet-col {
            max-width: 30px;
            text-align: right;
            font-size: 17px;
            font-weight: 600;
            color: var(--wgray_dark);
            img {
                width: 100%;
                height: 30px;
            }
        }

        .avatar-image-box-60{
            position: relative!important;
            .avatar {
                position: relative!important;
            }

            .miembro {
                position: absolute!important;
                width: 25px;
                height: 25px;
                bottom: 0;
                right: -5px;
            }
        }
    }

    .GroupDetails-leave-disclaimer-container {
        height: 90px;
        font-size: 17px;
        font-weight: 600;
        color: var(--wgray_dark);
        border-bottom: 1px solid var(--wgray_soft);
        padding-bottom: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
    }
}

.user-col-noavatar-60 {
    max-width: calc(100% - 60px);
}


.gray-color{
    color: var(--wgray_dark);
    font-size: 17px;
    font-weight: 600;
    color: var(--wgray_dark);
}
