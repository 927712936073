@import "core/colors";
@import "core/dimensions";

body {
    background: var(--wgray_softer);
}
.tabbed {
    height: calc(100vh - 84px)!important;
}

@media screen and (min-width: 1450px) {
    .tabbed {
        height: calc(100vh)!important;
    }
}

.mouse-pointer {
    cursor: pointer;
}

.placeholderTxt {
    color: var(--wgray) !important;
}

.mouse-default {
    cursor: default;
}

.bg-white {
    background: #fff !important;
    background-color: #fff !important;
}

.overlay-image {
    .btn-close {
        background: #fff;
        border-radius: 15px;
    }
}

.z-index-1 {
    z-index: 1;
}

img {
    user-drag: none !important;
    -webkit-user-drag: none !important;
    user-select: none !important;
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
}

.pos-relative {
    position: relative;
}

.pos-sticky {
    position: sticky;
}

.btn-cleared {
    background: transparent;
    border: none;
    outline: none !important;
    padding: 0;
}

.text-block-truncate-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.text-block-truncate-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.isTop-10 {
    top: -10px;
}

.isTop-24 {
    top: -24px;
}

.isTop-525{
    top: 525px;
}

.text-block-truncate-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.text-block-truncate-4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.line-clamp-w {
	display            : -webkit-box;
	-webkit-box-orient : vertical;
	position           : relative;
	overflow           : hidden;
	text-overflow      : ellipsis;
	padding            : 0 !important;
    line-height: var(--line-height,1.2) !important;
}

.line-clamp-1-w {
	-webkit-line-clamp : 1;
	height             : calc(1em * var(--line-height, 1.2) * 1);
}

.line-clamp-2-w {
	-webkit-line-clamp : 2;
	height             : calc(1em * var(--line-height, 1.2) * 2);
}

.line-clamp-3-w {
	-webkit-line-clamp : 2;
	max-height             : calc(1em * var(--line-height, 1.2) * 3);
}
 
.line-clamp-4-w {
	-webkit-line-clamp : 4;
	height             : calc(1em * var(--line-height, 1.2) * 4);
}

.line-height-15px {
    line-height: 15px;
}

.line-height-16px {
    line-height: 16px;
}

.line-height-17px {
    line-height: 17px;
}

.line-height-18px {
    line-height: 18px;
}

.line-height-19px {
    line-height: 19px;
}

.line-height-20px {
    line-height: 20px;
}

.line-height-22px {
    line-height: 22px;
}

.line-height-45px {
    line-height: 45px;
}

.line-height-60px {
    line-height: 60px;
}

.mouse-pointer {
    cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.swal2-container.swal2-center.swal2-backdrop-show {
    z-index: 3000;
}
.swal2-popup.swal2-modal.swal2-loading.swal2-show {
    padding-top: 10px;
}

.ModalDefault-main-box {
    &.super {
        z-index: 4000;
    }
    .modal-dialog {
        max-width: 100%;
        margin: 0;
        height: 100%;

        .modal-content {
            height: 100%;

            border: none;
            border-radius: 0;

            .modal-body {
                padding: 0;
            }
        }
    }

    &.full-view {
        .modal-dialog {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            height: 100vh;
        }

        &.AppsDisclaimer {

            .modal-dialog {
                max-width: 580px;
                margin: 0 15px !important;

                .modal-content {
                    max-height: 580px;
                    background: transparent;
                }
            }

        }
    }

    &.overAll {
        z-index: 2000000000000;
        background: rgba(0,0,0,0.5);
    }
}

reach-portal {
    .BottomSheetMainBox > div {
        z-index: 2060;
    }

    div[role=dialog] {
        max-width: 700px;
        margin: 0 auto;
    }


}

.avatar-image-box-30 {
    padding: 0;
    max-width: 30px;
    max-height: 30px;

    img {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #EBEBEB;
        object-fit: cover !important;
    }
}

.avatar-image-box-45 {
    padding: 0;
    max-width: 45px;
    max-height: 45px;
    position: relative;

    img {
        width: 45px;
        height: 45px;
        border-radius: 22.5px;
        border: 1px solid #EBEBEB;
        object-fit: cover !important;
    }

    .bmIcon {
        border: 0 solid transparent !important;
    }

    &.isGold {
        img {
            border: 2px solid #F2AC1F;
        }
        .bmIcon {
            border: 0 solid transparent !important;
        }
    }
}


.avatar-image-box-50 {
    padding: 0;
    max-width: 50px;
    max-height: 50px;

    img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        border: 1px solid #EBEBEB;
        object-fit: cover !important;
    }
}

.avatar-image-box-60 {
    padding: 0;
    max-width: 60px;
    max-height: 60px;
    position: relative;

    img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        border: 1px solid #EBEBEB;
        object-fit: cover !important;
    }

    .bmIcon {
        border: 0 solid transparent !important;
    }

    &.isGold {
        img {
            border: 2px solid #F2AC1F;
        }
        .bmIcon {
            border: 0 solid transparent !important;
        }
    }
}

.user-details-title {
    font-size: 17px;
    color: var(--wblue_dark);
    font-weight: bold;
}

.user-details-subtitle {
    font-size: 15px;
    color: var(--wblue_dark);

    &.soft {
        color: var(--wgray_dark);
    }

    &.deleted {
        font-style: italic;
    }

    &.small {
        font-size: 13px;
    }
}



@media screen and (min-width: 575px) {
    .ModalDefault-main-box {
        .modal-dialog {
            max-width: 700px;
            margin: 0 auto;
            height: auto;

            .modal-content {
                // border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 0.3rem;
            }
        }

        &.full-view {
            .modal-dialog {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                height: 100vh;
            }

            &.AppsDisclaimer {

                .modal-dialog {
                    max-width: 580px;
                    margin: 0 auto !important;
                }

            }
        }
    }
}

.invisible {
    visibility: hidden;
}
.swal2-container.swal2-top-end.swal2-backdrop-show {
    z-index: 3000;
}

.swal2-cancel {
    margin: 0 5px !important;
}

.swal2-icon.swal2-warning.swal2-icon-show {
    .swal2-icon-content {
        font-size: 55px;
    }
}

.font-size-18px {
    font-size: 18px;
}

.font-weight-bold {
    font-weight: bold;
}

.font-color-blue {
    color: var(--wblue) !important;
}

.font-color-green {
    color: var(--wgreen) !important;
}

.font-color-gray {
    color: var(--wgray) !important;
}

.font-color-red {
    color: var(--wred) !important;
}

.text-17 {
    font-size: 17px!important;
}

.text-18 {
    font-size: 18px!important;
}

// .owl-carousel.owl-theme {
//     position: relative;
//
//     .owl-dots {
//         position: absolute;
//         width: 100%;
//         bottom: 0;
//         left: 0;
//     }
// }
//
// @media screen and (min-width: 1280px) {
//     .owl-carousel.owl-theme {
//         position: relative;
//
//         .owl-dots {
//             position: absolute;
//             width: 100%;
//             bottom: 26px;
//             left: 0;
//         }
//     }
// }

.overlay-image {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 5!important;
    margin-left: 0!important;
    min-width: 100%;

    .btn-close {
        width: 20px;
        height: 20px;
        position: absolute;
        color: white;
        left: 75%;
        top: 20vh;
        cursor: pointer;
        opacity: 1;
    }


    .image-full-screen {
        position: absolute;
        width: 50vw!important;
        height: 50vh!important;
        z-index: 5!important;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        // overflow: hidden;
        display: block;
        margin: auto auto;

        .owl-carousel {
            .item {
                img {
                    max-width: 50vw!important;
                    max-height: 50vh!important;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }


    }

}


@media screen and (max-width: 575px) {

    .overlay-image {

        .btn-close {
            left: 90vw;
            top: 25vh;
        }


        .image-full-screen {
            width: 100vw!important;
            height: 40vh!important;

        }

    }
}

.react-datepicker__month-read-view--down-arrow {
    display: none !important;
}

.mention {
  color: #4a85bb;
  text-decoration: none;
}

// .mentionSuggestions {
//   border-top: 1px solid #eee;
//   background: #fff;
//   border-radius: 2px;
//   cursor: pointer;
//   padding-top: 8px;
//   padding-bottom: 8px;
//   display: flex;
//   flex-direction: column;
//   box-sizing: border-box;
//   transform-origin: 50% 0%;
//   transform: scaleY(0);
//   margin: -16px;
// }
//
// .mentionSuggestionsEntryContainer {
//   display: table;
//   width: 100%;
// }
//
// .mentionSuggestionsEntryContainerLeft,
// .mentionSuggestionsEntryContainerRight {
//   display: table-cell;
//   vertical-align: middle;
// }
//
// .mentionSuggestionsEntryContainerRight {
//   width: 100%;
//   padding-left: 8px;
// }
//
// .mentionSuggestionsEntry {
//   padding: 7px 10px 3px 10px;
//   transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
// }
//
// .mentionSuggestionsEntry:active {
//   background-color: #cce7ff;
// }
//
// .mentionSuggestionsEntryFocused {
//   composes: mentionSuggestionsEntry;
//   background-color: #e6f3ff;
// }
//
// .mentionSuggestionsEntryText,
// .mentionSuggestionsEntryTitle {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
//
// .mentionSuggestionsEntryText {
// }
//
// .mentionSuggestionsEntryTitle {
//   font-size: 80%;
//   color: #a7a7a7;
// }
//
// .mentionSuggestionsEntryAvatar {
//   display: block;
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
// }
