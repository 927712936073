.DirectoryDesktopHeader-container {
    padding-left: calc(var(--bs-gutter-x) * .5);
    padding-right: calc(var(--bs-gutter-x) * .5);
    
    .DirectoryDesktopHeader-tab-container {
        display: flex;
        height: 60px;
        background: #fff;
        box-shadow: 0 1px 10px rgba(0,0,0,0.14);
        border-radius: 10px;

        button {
            width: 50%;
            background: transparent;
            border-radius: 10px 10px 4px 4px;
            border-bottom: 4px solid transparent;
            font-size: 20px;
            font-weight: bold;
            color: var(--wgray);

            &.active {
                background: var(--wgreen_softer);
                border-bottom: 4px solid var(--wgreen);
                color: var(--wgreen);
                cursor: default;
            }
        }
    }

    .DirectoryDesktopHeader-results-label {
        font-size: 20px;
        font-weight: 600;
        color: var(--wblue_dark);
    }

    .DirectoryDesktopHeader-filter-btn-container {
        button {
            height: 35px;
            border: 1px solid var(--wred);
            border-radius: 17.5px;
            padding: 5px 6px 5px 14px;
            font-size: 15px;
            font-weight: 600;
            color: var(--wred);
        }
    }
}
