.Notifications-container {
    background: #fff;
    width: 100%;
    height: calc(100vh - 73px);
    overflow: auto;
    top: 0;
    z-index: 3;
    padding: 0px 0px 20px 0px;
    max-width: 700px;

    &.hasSubtitle {
        padding-top: 0;

        .row.hasSubtitle {
            padding-top: 30px;
            background: var(--wgray_softer);
        }
    }

    .Notifications-subtitle-box {
        padding: 25px 14px;
        color: var(--wgray_darker);
        font-size: 17px;
    }

    .Notifications-header-box {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        padding: 30px 14px 10px 14px;
        background: #F8F8FB;
        border-bottom: 1px solid #EBEBEB;

        .readAllNot {
            position: absolute;
            right: 15px;
            font-size: 15px;
            font-weight: 400;
        }

        p {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: var(--wblue_dark);

            &.large-title {
                font-size: 17px;
            }
        }

        .Notifications-btn-close,
        .Notifications-btn-clear {
            position: absolute;
            bottom: 10px;
        }

        .Notifications-btn-close {
            height: 30px;
            width: 30px;
            left: 14px;

            img {
                width: 100%;
                height: 30px;
            }
        }

        .Notifications-btn-clear {
            height: 22px;
            color: var(--wred);
            font-size: 15px;
            right: 14px;
        }
    }

    .Notifications-content {


        .Notification-item-container {
            // height: 100px;
            border-bottom: 1px solid #979797;
            display: flex;
            align-items: center;
            background: #F1F7FF;

            &.readed {
                background: #fff;
            }

            .Notification-icon-container {
                padding: 0;
                max-width: 50px;
                max-height: 50px;
                width: 50px;
                height: 50px;
                background: #EDEDED;
                border-radius: 50px;

                img {
                    max-width: 50px;
                    max-height: 50px;
                    width: 50px;
                    height: 50px;
                }
            }

            .Notification-details-container {
                height: 100%;
                display: flex;
                align-items: center;
                padding-top: 30px;
                padding-bottom: 15px;

                .hour-badge {
                    position: absolute;
                    top: 10px;
                    right: 0;
                    font-size: 12px;
                    color: #9A9BA0;
                }

                .title {
                    font-size: 18px;
                    font-weight: bold;
                    color: #272C40;
                }

                .subtitle {
                    font-size: 18px;
                    color: #272C40;
                }
            }
        }
    }

    .container-tutorial {

        margin-top: 20px;
        margin-bottom: 20px;

        .title-tutorial {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            /* or 122% */
            letter-spacing: -0.432px;
            color: #272C40;
        }

        .container-body-tutorial {
            margin-top: 15px !important;
            background: #DDEAFB;
            border-radius: 10px;
            max-height: 100px;
        }

        .container-icn-tutorial {
            background-color: #3173C9;
            border-radius: 10px 0 0 10px;
            max-width: 100px;
            max-height: 100px;
        }

        .icn_tutorial {
            margin-left: calc(25px - 12px);
            margin-right: calc(25px - 12px);
            margin-top: 22px;
            margin-bottom: 22px;
            width: 55px;
        }

        .container-text-tutorial {
            margin-top: 14px;
            margin-left: calc(14px - 12px);
        }

        .main-text-tutorial {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.432px;
            color: #0D458D;
        }

        .link-tutorial {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.384px;
            color: #0D458D;
            text-decoration: #1C65C2;
        }

        .icn_arrow {
            width: 15px;
            height: 15px;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }



    }

    .sample-notification {
        border-bottom: lighten(#0FB855, 50%) 3px solid;
        cursor: pointer;
    }

    .amount-not-read {
        background-color: var(--wgreen);
        color: white;
        border-radius: 5px;
        padding-left: 4px;
        padding-right: 4px;
        margin-left: 7px;
    }


    .selected-notification {
        color: var(--wgreen);
        border-bottom: var(--wgreen) 3px solid;
    }

}

.modalRefereal-mobile {

    // width: 100vw!important;
    // height: 100vh!important;
    background-color: rgba(0, 0, 0, 0.3);

    .modal-dialog {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        width: 80vw;
    }

    .title-refeal {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #0D458D;
        margin-bottom: 30px;

    }

    .close-refereal {
        margin-top: 10px;
        margin-right: 15px;
        margin-bottom: 5px;
        cursor: pointer;
    }

    .icn-close-refereal {
        width: 20px;
    }

    .detail-refeal {
        background: #F4F4F6;
        border: 1px solid #CDCED4;
    }

    .icn_refeal {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 15px;
    }

    .container-user-info-refereal {
        margin-left: 15px;
        margin-top: 15px;
        margin-bottom: 35px;
    }

    .name-refereal {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.432px;
        color: #272C40;
    }

    .position-business-refereal {
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.36px;
        color: #585C6E;

    }

    .location-refereal {
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.36px;
        color: #585C6E;

    }

    .container-user-description-refereal {
        margin-left: 15px;
        margin-right: 15px;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.432px;
        color: #272C40;
    }

    .container-view-profile-refereal {
        margin-top: 5px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 25px;
        padding-right: 36px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .view-profile-refereal {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #0FB855;
    }

    .container-buttons-refeal {
        margin-top: 20px !important;
        margin-left: 15px !important;
        margin-right: 15px !important;
        margin-bottom: 20px !important;
    }

    .btn-cancel-refereal {
        border: 2px solid #0FB855;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #0FB855;
        width: 70%
    }

    .btn-cancel-refereal:hover {
        border: 2px solid #0FB855;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #0FB855;
    }

    .btn-ok-refereal {
        background: #0FB855;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
        width: 70%
    }

    .btn-ok-refereal:hover {
        background: #0FB855;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
    }

    .disabled-refereal {
        cursor: default !important;
    }
}


.Notifications-footer-container {
    border-top: 1px solid var(--wgray_soft);
    background: #fff;
    width: 100%;
    height: calc(45px + 14px + 14px);
    padding: 14px;
    z-index: 3;
    position: absolute;
    bottom: 0;

    button {
        background: var(--wgray_semisoft);
        width: 100%;
        height: 45px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;

        &.active {
            background: var(--wgreen);
        }
    }
}


.hr-tutorial {
    width: 95% !important;
}

@media (max-width: 575.98px) {
    .modalRefereal-mobile {
        width: 100vw !important;
        height: 100vh !important;
        background-color: rgba(0, 0, 0, 0.3);

        .modal-dialog {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            width: 100vw;
        }
    }

}

@media (min-width: 576px) and (max-width: 767.98px) {
    .modalRefereal-mobile {
        width: 100vw !important;
        height: 100vh !important;
        background-color: rgba(0, 0, 0, 0.3);

        .modal-dialog {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            width: 100vw;
        }
    }
}