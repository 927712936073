
.data_card {
    padding: 15px;
    .container_data_option_detail_member {
        margin: 0;
        width: 100%!important;
        &_description {
            &.no_margin {
                margin-left: 0;
            }
        }
    }
    .subtitulo {
        background: #fff;
        margin: 20px 0 10px 0;
        padding-left: 0px;
    }
}

.title_method {
    font-size: 17px;
    margin: 15px;
    margin-top: 40px;
}

.buttom_method_profile {
    color: var(--wblue);
    border-radius: 5px;
    width: calc(100% - 20px - 20px);
    height: 55px;
    display: block;
    margin: 140px 20px 140px 20px;
    border: 1px solid var(
    --wblue);
    background: transparent;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        color: #fff;
        background: var(--wblue);
    }
}
