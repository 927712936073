.ContentsModal-container {
    padding-top: 24px;
    padding-bottom: 24px;

    .ContentsModal-title-container {
        font-weight: bold;
        font-size: 22px;
        color: var(--wblue_dark);
        padding-bottom: 24px;
    }

    .ContentsModal-items-container {
        padding: 0 20px;

        .ContentsModal-item-box {
            background: var(--wgreen_softer);
            border: 1px solid var(--wgreen_soft);
            border-radius: 8px;
            min-height: 110px;

            .contentModal-icon {
                max-width: 32px;
                max-height: 32px;
                width: 32px;
                height: 32px;
                

                img {
                    width: 32px;
                    height: 32px;
                    object-fit: cover;
                    object-position: 0 0;
                }

                &.mp4 {
                    img {
                        object-position: 0 0;
                    }
                }

                &.mp3 {
                    img {
                        object-position: 0 -32px;
                    }
                }

                &.links {
                    img {
                        object-position: 0 -96px;
                    }
                }

                &.pdf,
                &.doc,
                &.docx,
                &.xlsx,
                &.pptx,
                &.ppt {
                    img {
                        object-position: 0 -64px;
                    }
                }
            }

            .contentModal-details {

                .contentModal-title {
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: var(--wblue);
                }

                .contentModal-type {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0px;
                    text-align: left;
                }

                .contentModal-description {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: left;

                }
            }

            .contentModal-arrow {
                max-width: 40px;
                max-height: 40px;

                img {
                    width: 40px;
                    height: 40px;
                }
            }
            // .bmIcon {
            //     width: 20px;
            //     height: 20px;
            //     position: absolute;
            //     right: 0;
            //     bottom: 0;
            // }

            // .textCol {
            //     padding-bottom: 10px;
            //     border-bottom: 1px solid #EBEBEB;
            //     .nameCol {
            //         font-size: 17px;
            //         font-weight: bold;
            //         color: var(--wblue_dark);
            //     }

            //     .detailsCol {
            //         font-size: 15px;
            //         color: var(--wblue_dark);

            //         &.soft {
            //             color: var(--wgray_dark);
            //         }
            //     }
            // }
        }
    }
}

.ContentsModal-container.isDesktop {
    padding-top: 0;
    // padding: 20px;

    .ContentsModal-title-container {
        font-weight: bold;
        font-size: 20px;
        color: var(--wblue_dark);
        position: relative;
        text-align: center;
        padding: 20px;
        border-bottom: 1px solid #EBEBEB;
        margin-bottom: 20px;

        button {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            img {
                width: 30px;
                height: 30px;
            }
        }

        .likesCounter {
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            font-size: 18px;
            font-weight: 500;
            color: var(--wgray_darker);

            img {
                width: 25px;
                height: 25px;
                object-fit: cover;
                object-position: 0 0;
                margin-right: 7px;
            }
        }
    }

    .ContentsModal-items-container {
        max-height: calc(100vh - 20px - 20px - 75px);
        overflow: auto;
        min-height: 25vh;
    }
}
