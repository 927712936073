.ContentPost-container {
    background: #fff;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    border-radius: 5px;
    margin-bottom: 14px;
    overflow: hidden;

    &.is-details {
        box-shadow: 0 7px 14px rgba(0,0,0,0.05);
        border-radius: 0;
        margin-bottom: 0;
    }

    .ContentPost-header {
        padding-top: 14px;

        .ContentPost-user-avatar-box {
            max-width: 60px;
            height: 60px;
            width: 60px;
            position: relative;

            img {
                width: 100%;
                height: 60px;
                border-radius: 30px;
                border: 1px solid #EBEBEB;
                object-fit: cover;
            }

            .avatar {
                position: relative;
            }

            .miembro {
                position: absolute;
                width: 25px;
                height: 25px;
                bottom: 0;
                right: -5px;
            }
        }

        .ContentPost-user-details {
            padding-right: 0;
            padding-left: calc(var(--bs-gutter-x) * .5);

            .ContentPost-user-title {
                font-size: 17px;
                font-weight: 600;
                color: var(--wblue_dark);
            }
            .ContentPost-user-options {
                max-width: 30px;
                max-height: 30px;

                &.isDetails {
                    position: absolute;
                    top: 20px;
                    right: calc(var(--bs-gutter-x) * .5);
                }

                .dropdown {
                    .dropdown-toggle {
                        background: transparent;
                        border: none;
                        outline: none !important;
                        padding: 0;
                        box-shadow: none !important;
                        &::after {
                            display: none;
                        }
                    }
                }
            }

            .ContentPost-user-name {
                font-size: 13px;
                color: var(--wgreen);
                font-weight: 500;
            }

            .ContentPost-user-info {
                font-size: 13px;
                color: var(--wgray_darker);

                span {
                    color: var(--wblue);
                }
            }
        }
    }

    .ContentPost-content {
        padding-bottom: 14px;
        padding-top: 14px;

        .ContentPost-content-text {
            font-size: 18px;
            color: var(--wblue_dark);
            .bubbleMention {
                font-weight: 600;
                color: var(--wgreen);
                cursor: pointer;
            }
        }
        
        .show-more-btn {
            text-align: right;
            button {
                color: var(--wgreen);
                font-size: 15px;
            }
        }

        .ContentPost-contents-box {
            background: var(--wgreen_softer);
            border: 1px solid var(--wgreen_soft);
            border-radius: 8px;
            height: 40px;
            margin-top: 14px;
            display: flex;
            align-items: center;
            padding: 0 9px;

            .contents-icon {
                padding: 0;
                width: 32px;
                height: 32px;
                max-width: 32px;
                max-height: 32px;

                img {
                    width: 32px;
                    height: 32px;
                }
            }

            .contents-title {
                font-size: 15px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0px;
                text-align: left;
                color: var(--wblue);
            }

            .contents-arrow {
                padding: 0;
                width: 30px;
                height: 30px;
                max-width: 30px;
                max-height: 30px;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .ContentPost-image-container {
        position: relative;

        .image-container {
            width: 100%;
            height: auto;
            // max-height: 250px;
            max-height: 674px;
            aspect-ratio: 1/1;
            object-fit: cover;
        }
        .image-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            height: 35px;
            width: 35px;
        }

        .itemSkeleton {
            padding: 40px 15px;
        }

        .owl-carousel {

            img {
                width: 100%;
                height: auto;
                // max-height: 250px;
                max-height: 674px;
                aspect-ratio: 1/1;
                object-fit: cover;

                &:before, &:after {
                    -moz-box-sizing: border-box;
                }
            }

            .itemSkeleton {
                padding: 40px 15px;
            }

            video {
                width: 100%;
                height: auto;
                // max-height: 250px;
                max-height: 674px;
                aspect-ratio: 1/1;

                &:before, &:after {
                    -moz-box-sizing: border-box;
                }
            }

            .isVideo {
                button {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 70px;
                        height: 70px;
                    }
                }
            }

            .image-icon {
                position: absolute;
                top: 10px;
                right: 10px;
                height: 35px;
                width: 35px;
            }
        }
    }

    .ContentPost-pdf-container {
        position: relative;
        border-radius: 5px;
        margin: 8px;
        width: calc(100% - 16px);
        box-shadow: 1px 0 10px rgba(0,0,0,0.14);
        .react-pdf__Document {
            .react-pdf__Page {
                .react-pdf__Page__canvas {
                    width: 100% !important;
                    height: 150px !important;
                    object-fit: cover;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
            }
        }

        img {
            width: 70%;
            height: 120px !important;
            object-fit: contain;
            padding: 15px;
            display: block;
            margin: 0 auto;
        }
        .black-view,
        a {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
        }

        .document-span {
            background: var(--wgray_softer);
            height: 50px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 0 14px;
            display: flex;
            align-items: center;
            color: var(--wgray_darker);
            font-size: 15px;
        }
    }

    .ContentPost-comments-count {
        font-size: 13px;
        color: var(--wgray_darker);
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: right;

        &.isLike {
            text-align: left;

            img {
                width: 25px;
                height: 25px;
                object-fit: cover;
                object-position: 0 0;
                margin-right: 5px;
            }
        }
    }

    .ContentPost-footer {

        display: flex;
        justify-content: space-between;
        padding-top: 8px;
        padding-bottom: 8px;
        border-top: 1px solid var(--wgray_soft);

        button {
            height: 30px;
            padding: 0 18px;

            img {
                width: 30px;
                height: 30px;
            }

            span {
                font-size: 15px;
                font-weight: 600;
                color: var(--wgray_darker);
                padding-left: 8px;
            }

            &.likeBtn {
                img {
                    object-fit: cover;
                }

                &.active,
                &:hover {
                    span {
                        color: var(--wgreen);
                    }

                    img {
                        object-position: 0 -60px;
                    }
                }
            }
        }
    }
}
