.RecomendationDetails-container {
    height: 100vh;
    overflow: auto;
    background: #fff;

    max-width: 700px;
    margin: 0 auto;

    .RecomendationDetails-back-container {
        padding: 20px calc(var(--bs-gutter-x) * .5) 10px calc(var(--bs-gutter-x) * .5);
        background: var(--wgray_softer);
        border-bottom: 1px solid #EBEBEB;

        .RecomendationDetails-back-col {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 17px;
            font-weight: bold;
            color: var(--wblue_dark);

            button {
                position: absolute;
                left: 0;
            }
        }
        .RecomendationDetails-user-options {
            max-width: 30px;
            max-height: 30px;

            .dropdown {
                .dropdown-toggle {
                    background: transparent;
                    border: none;
                    outline: none !important;
                    padding: 0;
                    box-shadow: none !important;
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .RecomendationDetails-post-box {
        // padding-top: 50px;
    }
}


@media screen and (min-width: 700px) {
    .RecomendationDetails-container {
        border-left: 1px solid var(--wgray_soft);
        border-right: 1px solid var(--wgray_soft);
    }
}
