.RegisterWizard-container {
    width: 100vw;
    height: 100vh;

    .RegisterWizard-preaccept {
        width: 100vw;
        height: 100vh;
        position: relative;
        max-width: 700px;
        background: #fff;
        margin: 0 auto;

        .RegisterWizard-preaccept-main-container {
            padding-top: 75px;
            padding-left: 24px;
            padding-right: 24px;

            .RegisterWizard-preaccept-logo-container {
                text-align: center;

                img {
                    width: 150px;
                    height: 35px;
                    object-fit: contain;
                }
            }

            .RegisterWizard-preaccept-title-container {
                padding-top: 24px;
                font-size: 18px;
                font-weight: 500;
                color: var(--wblue);
                text-align: center;
                line-height: 22px;
            }

            .RegisterWizard-preaccept-items-container {
                padding-top: 24px;

                .RegisterWizard-preaccept-item-row {
                    display: flex;
                    align-items: center;
                    height: 45px;
                    border-radius: 5px;
                    margin-bottom: 8px;
                    background: var(--wgray_softer);

                    .RegisterWizard-preaccept-item-icon {
                        padding: 0 0 0 7.5px;
                        max-width: calc(30px + 7.5px);

                        img {
                            width: 30px;
                            height: 30px;
                            object-fit: cover;
                            object-position: 0 -120px;
                        }
                    }
                }
            }
        }

        .RegisterWizard-accept-main-container {
            padding-top: 0;
            padding-left: 24px;
            padding-right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 100px;

            .RegisterWizard-accept-logo-container {
                text-align: center;

                img {
                    width: 150px;
                    height: 35px;
                    object-fit: contain;
                }
            }

            .RegisterWizard-accept-title-container {
                padding-top: 24px;
                font-size: 18px;
                font-weight: 500;
                color: var(--wblue);
                text-align: center;
                line-height: 22px;

                span {
                    color: var(--wgreen);
                }
            }
        }

        .RegisterWizard-preaccept-submit-container {
            max-width: 400px;
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0 24px;
            text-align: center;

            .isDisclaimer {
                font-size: 15px;
                font-weight: 500;
                color: var(--wgray_dark);
                padding-bottom: 20px;
            }

            button {
                width: 100%;
                height: 55px;
                border-radius: 5px;
                font-size: 16px;
                font-weight: 600;
                background: var(--wblue);
                color: #fff;

                &.isGreen {
                    background: var(--wgreen);
                }
            }


        }
    }

    .RegisterWizard-steps-container {
        width: 100vw;
        height: 100vh;
        position: relative;
        max-width: 700px;
        background: #fff;
        margin: 0 auto;
        overflow: auto;

        .RegisterWizard-steps-header-container {
            padding: 40px 24px 30px;
            display: flex;
            justify-content: space-between;

            img {
                width: 90px;
                height: 21px;
                object-fit: contain;
            }

            span {
                font-size: 14px;
                color: var(--wgray_semisoft);
            }
        }

        .RegisterWizard-steps-form-container {
            padding: 0 24px 24px;
            max-height: calc(100vh - 91px - 80px);
            overflow: auto;

            .RegisterWizard-form-group {
                position: relative;
                padding: 0;
                display: flex;
                flex-direction: column;
                margin-bottom: 25px;

                .textLbl {
                    color: var(--wgray_darker);
                    font-size: 18px;
                }

                .icnLabel {
                    height: 55px;
                    width: 55px;
                    position: absolute;
                    left: -15px;
                    bottom: 0;

                    img {
                        height: 55px;
                        width: 55px;
                        object-fit: cover;
                        object-position: 0 0;

                        &.formicon-1 {
                            object-position: 0 calc(-55px * 1);
                        }

                        &.formicon-2 {
                            object-position: 0 calc(-55px * 2);
                        }

                        &.formicon-3 {
                            object-position: 0 calc(-55px * 3);
                        }

                        &.formicon-4 {
                            object-position: 0 calc(-55px * 4);
                        }

                        &.formicon-5 {
                            object-position: 0 calc(-55px * 5);
                        }

                        &.formicon-6 {
                            object-position: 0 calc(-55px * 6);
                        }

                        &.formicon-7 {
                            object-position: 0 calc(-55px * 7);
                        }
                    }
                }

                .numberLabel {
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--wblue);
                    width: 23px;
                    position: absolute;
                    height: 55px;
                    display: flex;
                    align-items: center;
                    bottom: 0;
                }

                input {
                    height: 55px;
                    padding: 0;
                    outline: none !important;
                    border: none;
                    border-bottom: 1px solid var(--wgray_dark);
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--wblue);

                    &::placeholder {
                        color: var(--wgray_semisoft);
                    }
                }

                textarea {
                    border: 1px solid var( --wgray_dark);
                    border-radius: 3px;
                    padding: 10px;
                    resize: none;
                    height: 190px;
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--wblue);
                    outline: none !important;

                    &::placeholder {
                        color: var(--wgray_semisoft);
                    }
                }

                .RegisterWizard-textarea-counter {
                    font-size: 14px;
                    text-align: end;
                    color: var(--wgreen);
                }

                button {
                    height: 55px;
                    padding: 0;
                    outline: none !important;
                    border: none;
                    border-bottom: 1px solid var(--wgray_dark);
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--wgray_semisoft);
                    background: transparent;
                    border-radius: 0 !important;
                    display: flex;
                    justify-content: space-between;

                    &.isActive {
                        color: var(--wblue);
                    }
                }

                &.hasIcon {
                    input {
                        padding: 0 0 0 40px;
                    }

                    button {
                        padding-left: 40px !important;
                    }
                }

                &.hasNumber {
                    input {
                        padding-left: 23px;
                    }
                }
            }

            .RegisterWizard-form-disclaimer {
                padding-bottom: 20px;

                .RegisterWizard-form-disclaimer-title {
                    font-size: 18px;
                    font-weight: bold;
                    color: var(--wblue);
                    margin-bottom: 5px;
                }

                .RegisterWizard-form-disclaimer-subtitle {
                    font-size: 18px;
                    color: var(--wgray_darker);
                    line-height: 20px;

                    .pickerRow {
                        .col {
                            max-width: calc(159px + 9px);

                            button {
                                height: 55px;
                                border-radius: 3px;
                                max-width: 159px;
                                width: 100%;
                                font-size: 17px;
                                font-weight: 600;
                                color: var(--wgray_dark);
                                border: 1px solid var(--wgray_dark);

                                &.active {
                                    height: 55px;
                                    border-radius: 3px;
                                    max-width: 159px;
                                    width: 100%;
                                    font-size: 17px;
                                    font-weight: 600;
                                    color: var(--wblue);
                                    border: 1px solid var(--wblue);
                                    background: #DDEAFB;
                                }
                            }
                        }
                    }
                }

                .RegisterWizard-form-disclaimer-form-container {
                    padding-top: 24px;

                    .RegisterWizard-form-disclaimer-form-option-container {
                        padding-bottom: 14px;

                        .mainBtn {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            padding: 14px;
                            height: 55px;
                            background: transparent;
                            border-radius: 3px;
                            border: 1px solid var(--wgray_dark);
                            font-size: 18px;
                            font-weight: 600;
                            color: var(--wgray_darker);

                            img {
                                object-fit: cover;
                                object-position: 0 calc(-30px * 8);

                                &.isBullet {
                                    object-position: 0 calc(-30px * 2);
                                }
                            }

                            &.active {
                                border: 1px solid var(--wblue);
                                color: var(--wblue);
                                background: #DDEAFB;

                                img {
                                    object-fit: cover;
                                    object-position: 0 calc(-30px * 9);

                                    &.isBullet {
                                        object-position: 0 calc(-30px * 3);
                                    }
                                }
                            }

                            &:hover {
                                border: 1px solid var(--wblue);
                                color: var(--wblue);
                                background: #DDEAFB;
                            }
                        }

                        .RegisterWizard-form-disclaimer-form-suboptions-container {
                            padding: 24px 0;

                            .subOptionsTitle {
                                padding-bottom: 14px;
                                font-size: 18px;
                                color: var(--wgray_darker);
                            }

                            .btns-Options {

                                button {
                                    font-size: 17px;
                                    font-weight: bold;
                                    color: var(--wblue);
                                    padding: 14px;
                                    background: transparent;
                                    border: 1px solid var(--wgray_dark);
                                    border-radius: 3px;
                                    max-width: 159px;

                                    &.left {
                                        margin-right: 4px;
                                    }

                                    &.right {
                                        margin-left: 4px;
                                    }

                                    &:hover,
                                    &.active {
                                        background: #DDEAFB;
                                        border: 1px solid var(--wblue);
                                    }
                                }
                            }

                            .linkBox {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                font-size: 18px;
                                color: var(--wgray_darker);
                                padding-top: 24px;

                                a {
                                    color: var(--wgreen);
                                    font-weight: bold;
                                }
                            }

                            .list-Options {

                                .listOption {
                                    height: 55px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: var(--wgray_darker);
                                    border-bottom: 1px solid #EBEBEB;
                                    cursor: pointer;

                                    &.isFirst {
                                        border-top: 1px solid #EBEBEB;
                                    }

                                    img {
                                        object-fit: cover;
                                        object-position: 0 0;

                                        &.isActive {
                                            object-position: 0 -30px;
                                        }
                                    }

                                    &.isActive {
                                        cursor: default;
                                        img {
                                            object-position: 0 -30px;
                                        }
                                    }
                                }
                            }

                            .selectOptions-container {
                                height: 55px;
                                display: flex;
                                align-items: center;
                                position: relative;
                                margin-top: 24px;

                                label {
                                    position: absolute;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: var(--wgray_darker);
                                    left: 14px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }

                                select {
                                    width: 100%;
                                    height: 55px;
                                    border: 1px solid var(--wgray_dark);
                                    border-radius: 3px;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    text-indent: 1px;
                                    text-overflow: '';
                                    text-align: end;
                                    padding-right: 50px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: var(--wblue);
                                    outline: none !important;
                                    z-index: 1;
                                    background: transparent;
                                }

                                img {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    right: 10px;
                                    -webkit-user-select: none;
                                    -moz-user-select: none;
                                    user-select: none;
                                }
                            }
                        }
                    }

                }

                .RegisterWizard-form-disclaimer-other-row {
                    .subtitleP {
                        padding-top: 24px;
                    }
                }
            }

            .RegisterWizard-form-option-container {
                padding-bottom: 14px;

                .mainBtn {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 14px;
                    height: 55px;
                    background: transparent;
                    border-radius: 3px;
                    border: 1px solid var(--wgray_dark);
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--wgray_darker);

                    img {
                        object-fit: cover;
                        object-position: 0 calc(-30px * 8);
                    }

                    &.active {
                        border: 1px solid var(--wblue);
                        color: var(--wblue);
                        background: #DDEAFB;

                        img {
                            object-fit: cover;
                            object-position: 0 calc(-30px * 9);
                        }
                    }

                    &:hover {
                        border: 1px solid var(--wblue);
                        color: var(--wblue);
                        background: #DDEAFB;
                    }
                }

                .RegisterWizard-form-suboptions-container {
                    padding: 24px 0;

                    .subOptionsTitle {
                        padding-bottom: 14px;
                        font-size: 18px;
                        color: var(--wgray_darker);
                    }

                    .btns-Options {

                        button {
                            font-size: 17px;
                            font-weight: bold;
                            color: var(--wblue);
                            padding: 14px;
                            background: transparent;
                            border: 1px solid var(--wgray_dark);
                            border-radius: 3px;
                            max-width: 159px;

                            &.left {
                                margin-right: 4px;
                            }

                            &.right {
                                margin-left: 4px;
                            }

                            &:hover,
                            &.active {
                                background: #DDEAFB;
                                border: 1px solid var(--wblue);
                            }
                        }
                    }

                    .linkBox {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        color: var(--wgray_darker);
                        padding-top: 24px;

                        a {
                            color: var(--wgreen);
                            font-weight: bold;
                        }
                    }

                    .list-Options {

                        .listOption {
                            height: 55px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 18px;
                            font-weight: 600;
                            color: var(--wgray_darker);
                            border-bottom: 1px solid #EBEBEB;
                            cursor: pointer;

                            &.isFirst {
                                border-top: 1px solid #EBEBEB;
                            }

                            img {
                                object-fit: cover;
                                object-position: 0 0;

                                &.isActive {
                                    object-position: 0 -30px;
                                }
                            }

                            &.isActive {
                                cursor: default;
                                img {
                                    object-position: 0 -30px;
                                }
                            }
                        }
                    }

                    .selectOptions-container {
                        height: 55px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        margin-top: 24px;

                        label {
                            position: absolute;
                            font-size: 18px;
                            font-weight: 600;
                            color: var(--wgray_darker);
                            left: 14px;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        select {
                            width: 100%;
                            height: 55px;
                            border: 1px solid var(--wgray_dark);
                            border-radius: 3px;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            text-indent: 1px;
                            text-overflow: '';
                            text-align: end;
                            padding-right: 50px;
                            font-size: 18px;
                            font-weight: 600;
                            color: var(--wblue);
                            outline: none !important;
                            z-index: 1;
                            background: transparent;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 10px;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            user-select: none;
                        }
                    }
                }


            }

            .RegisterWizard-form-media {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                flex-direction: column;

                .imgbg {
                    width: 100%;
                    max-width: 327px;
                    max-height: 327px;
                    border-radius: 5px;
                }
                input {
                    display: none;
                }
                .uploaderBtn {
                    max-width: 327px;
                    max-height: 327px;
                    background: transparent;
                    border: none;
                    border-radius: 0;
                    display: flex;
                    flex-direction: column;
                    font-size: 18px;
                    color: var(--wgray_darker);
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;

                    span {
                        font-weight: bold;
                        color: var(--wgreen);
                        padding: 4px 0;
                    }
                }

                .eraserBtn {
                    margin-top: 10px;
                    height: 50px;
                    border-radius: 4px;
                    color: var(--wred);
                    font-weight: bold;
                    font-size: 18px;
                    background: #FFDBDB;
                    width: 100%;
                    max-width: 327px;
                }
            }
        }

        .RegisterWizard-steps-footer-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-height: calc(50px + 30px);
            position: fixed;
            bottom: 0;
            max-width: 700px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            padding-bottom: 30px;
            background: #fff;

            .RegisterWizard-back-btn {
                width: 50px;
                height: 50px;
                border: 2px solid var(--wgreen);
                border-radius: 6px;
            }

            .RegisterWizard-submit-btn {
                width: 164px;
                background: var(--wgreen);
                height: 50px;
                font-size: 18px;
                font-weight: bold;
                color: #fff;

                &.last-step {
                    background: var(--wblue);
                }
            }
        }

    }
}


/*FIX ONLY SAFARI**/
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
        .RegisterWizard-container {
            .RegisterWizard-steps-container {
                .RegisterWizard-steps-form-container {
                    .RegisterWizard-form-option-container {
                        .RegisterWizard-form-suboptions-container {
                            .selectOptions-container {
                                select {
                                    direction: rtl;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Safari 11+ */
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        .RegisterWizard-container {
            .RegisterWizard-steps-container {
                .RegisterWizard-steps-form-container {
                    .RegisterWizard-form-option-container {
                        .RegisterWizard-form-suboptions-container {
                            .selectOptions-container {
                                select {
                                    direction: rtl;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Safari 10.1 */
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
        .RegisterWizard-container {
            .RegisterWizard-steps-container {
                .RegisterWizard-steps-form-container {
                    .RegisterWizard-form-option-container {
                        .RegisterWizard-form-suboptions-container {
                            .selectOptions-container {
                                select {
                                    direction: rtl;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Safari 9+ */
@supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
    .RegisterWizard-container {
        .RegisterWizard-steps-container {
            .RegisterWizard-steps-form-container {
                .RegisterWizard-form-option-container {
                    .RegisterWizard-form-suboptions-container {
                        .selectOptions-container {
                            select {
                                direction: rtl;
                            }
                        }
                    }
                }
            }
        }
    }
}

// /* Safari 6.1-7.0 */
// @media screen and (-webkit-min-device-pixel-ratio:0) and (min-color-index:0) {
//     .RegisterWizard-container {
//         .RegisterWizard-steps-container {
//             .RegisterWizard-steps-form-container {
//                 .RegisterWizard-form-option-container {
//                     .RegisterWizard-form-suboptions-container {
//                         .selectOptions-container {
//                             select {
//                                 direction: rtl;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
