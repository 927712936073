.container-notifications {
    width: 100%;
    max-width: 700px !important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);
    background-color: #fff;
    height: 100vh;

    .container-header {
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        display: flex;

        align-items: center;
        background-color: #F8F8FB;
        border-bottom: 1px solid var(--wgray_soft);

        .back-img {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .container-subheader {
        padding: 5px 0;
        background-color: #F8F8FB;

        .sub-text {
            font-size: 15px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: -0.3px;
            text-align: left;
            color: var(--wgray_darker);
        }
    }

    .notifications-container {
        max-height: calc(100% - 110px);
        overflow: auto;

        .notification-item {
            padding-top: 14px;
            padding-bottom: 14px;
            border-bottom: 1px solid var(--wgray_semisoft);

            .item-title {
                font-size: 17px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: -0.34px;
                text-align: left;
                color: var(--wblue_dark);
                padding-bottom: 5px;
            }

            .item-description {
                font-size: 15px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: -0.3px;
                text-align: left;
                color: var(--wgray_darker);
            }

            .switch-box {
                max-width: calc(58px + calc(var(--bs-gutter-x) * .5));

                .switch {
                    position: relative;
                    display: inline-block;
                    width: 58px;
                    height: 30px;
    
                    input {
                        opacity: 0;
                        width: 0;
                        height: 0;
                    }
                }
    
                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    -webkit-transition: .4s;
                    transition: .4s;
    
                    &:before {
                        position: absolute;
                        content: "";
                        height: 26px;
                        width: 26px;
                        left: 4px;
                        bottom: 2px;
                        background-color: white;
                        -webkit-transition: .4s;
                        transition: .4s;
                    }
                }
    
                input {
                    &:checked+.slider {
                        background-color: green;
                    }
    
                    &:focus+.slider {
                        box-shadow: 0 0 1px green;
                    }
    
                    &:checked+.slider:before {
                        -webkit-transform: translateX(26px);
                        -ms-transform: translateX(26px);
                        transform: translateX(26px);
                    }
                }
    
                /* Rounded sliders */
                .slider.round {
                    border-radius: 34px;
    
                    &:before {
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.container-notifications.isDesktop {

    width: 100%;
    height: calc(100% - 16px);
    color: #9A9BA0;
    font-size: 16px;
    position: relative;
    padding-bottom: 40px;
    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);

    .container-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: #fff;
        
        color: var(--wblue_dark);
        text-align: left;

        .title-col {
            justify-content: start !important;
            padding-left: 15px !important;
            padding-right: 15px !important;
            
            h3 {
                font-size: 20px !important;
            }
        }
    }

    .notifications-container {
        max-height: 100%;
        overflow: auto;
    }
    // .container_header {
    //     margin: 0;
    //     background-color: #fff!important;
    //     border-top-left-radius: 10px;
    //     border-top-right-radius: 10px;
    //     padding: 30px 15px 10px 15px;
    //     height: 60px;

    //     h3 {
    //         font-size: 20px !important;
    //         color: var(--wblue_dark);
    //         text-align: left;
    //     }

    //     &_bloqued {
    //         width: 100%;
    //         text-align: center;
    //         color: gray;
    //         margin-top: 20px;
    //         padding: 0 14px;
    //     }

    // }

    // &_input {
    //     padding: 15px;

    // }

    // .jump_top {
    //     margin-top: 0px;
    // }

    // button {
    //     width: 92%;
    //     margin: 0 auto;
    //     display: block;
    //     border: none;
    //     background-color: #0D458D;
    //     height: 50px;
    //     color: white;
    //     font-weight: bold;
    //     margin-top: 50px;
    //     border-radius: 5px;
    // }

    // .button-recover {
    //     border: 1px solid #0FB855;
    //     color: #0FB855;
    //     background-color: #fff;
    // }
}