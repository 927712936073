.LogoutSheet-container {
    background: #fff;
    max-width: 350px;
    min-width: 350px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    border-radius: 10px;
    padding: 20px 20px 20px;

    .logOut{
        cursor: pointer;
    }
    
}
