.container-method {

    background-color: white;
    height: 100vh;
    padding: 0;

    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);

    .container_header {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        padding: 30px 15px 10px 15px;
        align-items: center;
        background-color: #fff;


        h3 {
            color: black;
            width: 100vw;
            text-align: center;
            display: block;
            font-size: 17dp;
        }
    }


    .container_mensaje {
        font-size: 17px;
        color: #585C6E;
        width: 90%;
        display: block;
        margin: 20px auto;
    }

    .padding {
        padding-left: 20px;
        padding-right: 20px;
    }

    .container_card {

        width: 100%;
        height: 158px;
        border-radius: 10px!important;

        &_cards {
            width: 100%;
            height: 108px;
            display: flex;
            background-color: #F8F8FB;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            cursor: pointer;

            img {
                margin-left: 7px;
            }
        }

        &_footer {
            width: 100%;
            height: 54px;
            text-align: center;
            padding: 14px;
            background-color: #EDEDED;
            color: #585C6E;
            font-size: 15px;
            margin: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }


}
