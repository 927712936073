.CompanyDetailsSheet-container {
    margin: 24px 12px 0px 12px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
    padding: 24px;

    .link-w-none{
        width: 92%;
    }

    .CompanyDetailsSheet-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .companyDetailsCol {
            .imgCol {
                max-width: 100px;
                max-height: 78px;
                width: 100px;
                height: 78px;

                img {
                    max-width: 100px;
                    max-height: 78px;
                    width: 100px;
                    height: 78px;
                    object-fit: cover;
                    box-shadow: 0 1px 10px rgba(0,0,0,0.1);
                }
            }

            .titleCol {
                font-weight: bold;
                font-size: 18px;
                color: var(--wblue_dark);
            }

            .subTitleRow {
                font-size: 15px;
                line-height: 20px;
                color: var(--wgray_darker);
            }
        }

        .btnCol {
            max-width: 95px;
            button {
                font-size: 18px;
                font-weight: 500;
                color: var(--wgreen);

                span {
                    padding-right: 5px;
                }
            }
        }



    }

    .CompanyDetailsSheet-main-box {
        padding-top: 24px;


        .leftBox {
            font-size: 18px;
            p {
                margin-bottom: 2px;
                font-weight: bold;
                color: var(--wblue_dark);
            }

            span {
                line-height: 20px;
                color: var(--wgray_darker);
            }
        }

        .rightBox {
            .CompanyDetailsSheet-details-container {
                .icnBox {
                    width: 30px;
                    height: 30px;
                    max-width: 30px;
                    max-height: 30px;

                    img {
                        width: 30px;
                        height: 30px;
                        object-position: 0px -0px;
                        object-fit: cover;

                        $positions: 8;

                        @for $i from 0 through $positions {
                            &.pos-#{$i + 1} {
                                object-position: 0px calc(-30px * #{$i});
                            }
                        }
                    }
                }

                .txtBox {
                    font-size: 18px;
                    color: var(--wgray_darker);

                    a {
                        color: var(--wgray_darker);
                    }
                }
            }
        }

        .CompanyDetailsSheet-discount-container {
            padding-top: 24px;

            .CompanyDetailsSheet-discount-col {
                height: 80px;
                background: #DDEAFB;
                border-radius: 5px;
                display: flex;
                align-items: center;

                .discount-number {
                    max-width: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 30px;
                    font-weight: bolder;
                    color: var(--wblue);
                }

                .discount-text {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: bold;
                    color: var(--wblue);
                }
            }
        }
    }
    .CompanyDetailsSheet-specialities-container {
        .CompanyDetailsSheet-specialities-title {
            padding-top: 24px;
            font-size: 17px;
            font-weight: bold;
            color: var(--wblue_dark);
            padding-bottom: 12px;
        }

        .CompanyDetailsSheet-specialities-options {
            button {
                height: 40px;
                background: var(--wgray_softer);
                border: 5px;
                font-size: 17px;
                color: var(--wgray_darker);
                padding: 17px;
                cursor: default;
            }
        }
    }
}
