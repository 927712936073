.FiltersSheet-container {
    background: #fff;
    max-width: 350px;
    min-width: 350px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);
    border-radius: 10px;

    .FiltersSheet-title-box {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        color: var(--wblue_dark);
        border-bottom: 1px solid var(--wgray_soft);
    }

    .FiltersSheet-filters-container {

        .FiltersSheet-filters-item {

            .FiltersSheet-filters-header {
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                border-bottom: 1px solid var(--wgray_soft);

                &.collapsed {
                    border-bottom: 0px solid transparent;
                }

                .titleSpan {
                    font-size: 17px;
                    font-weight: bold;
                    color: var(--wblue_dark);
                }

                .titleImg {
                    width: 30px;
                    height: 30px;
                    object-fit: cover;
                    object-position: 0 -60px;

                    &.active {
                        object-position: 0 -30px;
                    }
                }
            }

            .FiltersSheet-filters-options-container {
                border-bottom: 1px solid var(--wgray_soft);

                &.collapsed {
                    height: 0;
                    overflow: hidden;
                }

                .FiltersSheet-filters-options-item {
                    display: flex;
                    height: 55px;
                    align-items: center;

                    .FiltersSheet-filters-options-checkbox {
                        max-width: calc(30px + calc(var(--bs-gutter-x) * .5));
                        max-height: 30px;

                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    .FiltersSheet-filters-options-title {
                        font-size: 17px;
                        color: var(--wgray_darker);
                        height: 55px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid transparent;
                    }

                    &:not(.isLast) {
                        .FiltersSheet-filters-options-title {
                            border-bottom: 1px solid var(--wgray_soft);
                        }
                    }
                }
            }
        }
    }
}
