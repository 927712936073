.DirectoryListTable-container {
    padding: 20px 14px;
    height: calc(100vh - 84px - 107px - 50px);
    overflow: auto;

    .DirectoryListTable-noresults-container {
        padding-top: 70px;
    }

    .DirectoryListTable-item-col {

        .avatar-image-box-60{
            position: relative;
            .avatar {
                position: relative!important;
            }

            .miembro {
                position: absolute!important;
                width: 25px;
                height: 25px;
                bottom: 0;
                right: -5px;
            }
        }

        .DirectoryListTable-user-col {
            border-bottom: 1px solid var(--wgray_soft);
            padding-bottom: 8px;
            margin-bottom: 8px;
            margin: 0 0 10px 0;
        }
    }

}

@media screen and (min-width: 1450px) {
    .DirectoryListTable-container {
        height: calc(100vh - 107px - 50px);
    }
}
