.MessagesHeader-container {
    padding: 20px 14px 14px 14px;

    .MessagesHeader-newchat-btn-container {
        .col {
            max-width: 30px;
        }
        .new-chat {
            text-align: right;
        }
    }

    .MessagesHeader-title-container {
        padding: 0 0 14px 0;
        font-size: 28px;
        font-weight: bold;
        color: var(--wblue);
    }

    .MessagesHeader-searcher {
        position: relative;

        input {
            width: 100%;
            height: 40px;
            border: none;
            outline: none !important;
            background: var(--wgray_soft);
            border-radius: 5px;
            font-size: 17px;
            color: var(--wblue_darker);
            padding: 0 40px;
        }

        label,
        button {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 0;

            img {
                padding: 4px;
                width: 40px;
                height: 40px;
            }
        }

        label {
            left: 0;
        }

        button {
            right: 0;
        }
    }
}
