.LikesModal-container {
    padding-top: 24px;
    padding-bottom: 24px;

    .LikesModal-title-container {
        font-weight: bold;
        font-size: 22px;
        color: var(--wblue_dark);
        padding-bottom: 24px;
    }

    .LikesModal-items-container {
        padding: 0 20px;

        .LikesModal-item-box {
            padding-bottom: 10px;

            .bmIcon {
                width: 20px;
                height: 20px;
                position: absolute;
                right: 0;
                bottom: 0;
            }

            .textCol {
                padding-bottom: 10px;
                border-bottom: 1px solid #EBEBEB;
                .nameCol {
                    font-size: 17px;
                    font-weight: bold;
                    color: var(--wblue_dark);
                }

                .detailsCol {
                    font-size: 15px;
                    color: var(--wblue_dark);

                    &.soft {
                        color: var(--wgray_dark);
                    }
                }
            }
        }
    }
}

.LikesModal-container.isDesktop {
    padding-top: 0;
    // padding: 20px;

    .LikesModal-title-container {
        font-weight: bold;
        font-size: 20px;
        color: var(--wblue_dark);
        position: relative;
        text-align: center;
        padding: 20px;
        border-bottom: 1px solid #EBEBEB;
        margin-bottom: 20px;

        button {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            img {
                width: 30px;
                height: 30px;
            }
        }

        .likesCounter {
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            font-size: 18px;
            font-weight: 500;
            color: var(--wgray_darker);

            img {
                width: 25px;
                height: 25px;
                object-fit: cover;
                object-position: 0 0;
                margin-right: 7px;
            }
        }
    }

    .LikesModal-items-container {
        max-height: calc(100vh - 20px - 20px - 75px);
        overflow: auto;
        min-height: 25vh;
    }
}
