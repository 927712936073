.Tab-container {
    position: fixed;
    bottom: 0;
    z-index: 2;
    background: var(--wgray_softer);
    height: 84px;
    padding: 10px 14px;
    // border-top: 1px solid var(--wgray_soft);
    border: 1px solid var(--wgray_soft);
    width: 100%;
    left: 50%;
    transform: translateX(-50%);

    &.hideTab {
        display: none;
    }

    .logo-box {
        display: none;
    }

    ul {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-around;

        li {
            display: inline;

            .Tab-btn,
            a {
                text-decoration: none !important;
                font-size: 11px;
                color: var(--wgray_darker);
                display: block;

                .img-box {
                    position: relative;

                    .dotSpan {
                        background: red;
                        height: 8px;
                        width: 8px;
                        position: absolute;
                        border-radius: 4px;
                        top: 4px;
                        right: 0px;
                    }
                    img {
                        width: 34px;
                        height: 34px;
                    }
                }


                .text-center {
                    margin-top: 5px;
                }

                &.active {
                    color: var(--wgreen);

                    div {
                        img {
                            filter: invert(54%) sepia(69%) saturate(5300%) hue-rotate(122deg) brightness(107%) contrast(88%);
                        }
                    }
                }
            }
        }
    }

    .footer {
        position: fixed;
        bottom: 50px;

        button {
            font-size: 11pt;
            color: gray;
        }

    }


}

@media screen and (min-width: 1450px) {
    .Tab-container {
        display: none;
        position: absolute;
        top: 0;

        z-index: 2;
        background: var(--wgray_softer);

        padding: 50px;
        // border-top: 1px solid var(--wgray_soft);
        border: none;
        width: 100%;
        height: auto;
        left: 0;
        transform: translateX(0);
        &.hideTab {
            display: none;
        }

        .logo-box {
            display: block;
            padding-bottom: 70px;
            img {
                max-width: 180px;
                max-height: 60px;
                width: 180px;
                height: 60px;
                object-fit: contain;
                cursor: pointer;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            display: block;

            li {
                display: block;

                .Tab-btn,
                a {
                    display: flex;
                    text-decoration: none !important;
                    font-size: 11px;
                    color: var(--wgray_darker);
                    height: 84px;
                    margin-bottom: 16px;
                    border-radius: 10px;
                    padding: 22px 25px;

                    .img-box {
                        max-width: 40px;
                        .dotSpan {
                            background: red;
                            height: 8px;
                            width: 8px;
                            position: absolute;
                            border-radius: 4px;
                            top: 4px;
                            right: 0px;
                        }
                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    .title-box {
                        padding-left: 24px;
                        font-size: 24px;
                        font-weight: 600;
                        color: var(--wgray_darker);
                    }

                    &.active {
                        background: var(--wgray_soft);
                        color: var(--wblue_dark);

                        .title-box {
                            color:var(--wblue_dark);
                        }

                        div {
                            img {
                                filter: invert(54%) sepia(69%) saturate(5300%) hue-rotate(122deg) brightness(107%) contrast(88%);
                            }
                        }
                    }
                }
            }
        }
    }
}
