.ProfilePersonalInfo-container {
    margin: 0 12px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .nameCol {
        display: flex;
        max-width: calc(559px - 22px);
        align-items: center;

        .titleDiv {
            padding-right: 10px;
            font-weight: bold;
            font-size: 30px;
            color: var(--wblue_dark);
        }

        img {
            width: 20px;
            height: 20px;
        }
    }
    .invitation-label {
        display: flex;
        align-items: center;
        margin-bottom: 0px;

        span {
            display: flex;
            align-items: center;
            cursor: pointer;
            max-width: calc(100% - 30px - 50px);

            .invitation-miniature {
                width: 20px;
                height: 20px;
                border-radius: 10px;
                margin: 0 5px;
                object-fit: cover;
            }
        }
    }

    .btnCol {
        max-width: calc(calc(var(--bs-gutter-x) * .5) + 94px);

        button {
            span {
                font-size: 18px;
                font-weight: 500;
                color: var(--wgreen);
                padding-right: 5px;
            }
        }
    }

    .ProfilePersonalInfo-detail-container {
        padding-top: 30px;

        .imgCol {
            max-width: 264px;

            img {
                max-width: 264px;
                max-height: 264px;
                width: 264px;
                height: 264px;
                border-radius: 10px;
                object-fit: cover;
            }
        }

        .detailsCol {
            .anchorDetails {
                max-width: calc(100% - 30px);
                width: 100%;
                display: inline-block;
                padding-left: 20px;
            }
            span {
                font-size: 18px;
                color: var(--wgray_darker);
                padding-left: 20px;
            }

            img {
                width: 30px;
                height: 30px;
                object-fit: cover;
                object-position: 0 0;

                &.roleIcon {
                    object-position: 0 0;
                }

                &.phoneIcon {
                    object-position: 0 -30px;
                }

                &.emailIcon {
                    object-position: 0 -60px;
                }

                &.pinIcon {
                    object-position: 0 -90px;
                }

                &.hobbyIcon {
                    object-position: 0 -120px;
                }

                &.outIcon {
                    object-fit: contain;
                    object-position: center center;
                }
            }
        }
    }
}
