.HomeNewPost-container {
    background: #fff;
    padding: 20px 20px 14px;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.14);

    .inputBtn {
        height: 50px;
        border-radius: 25px;
        border: 1px solid var(--wgray_semisoft);
        width: 100%;
        justify-content: start;
        padding: 20px;
        font-size: 16px;
        font-weight: 600;
        color: var(--wgray_dark);

        &:hover {
            background: var(--wgray_chatbg);
        }
    }

    .HomeNewPost-options-box {
        padding-top: 8px;
        
        button {
            padding: 5px 10px;

            img {
                width: 30px;
                height: 30px;
            }

            span {
                padding-left: 10px;
                font-size: 18px;
                font-weight: 600;
                color: var(--wblue);
            }

            &:hover {
                background: var(--wgray_chatbg);
            }
        }
    }
}
