.NoResults-container {
    padding-bottom: 70px;

    .main-placeholder-container {
        text-align: center;

        p {
            font-size: 18px;
            font-weight: 600;
            color: var(--wblue_dark);
            margin: 0;
            text-align: center;
        }

        span {
            text-align: center;
            color: var(--wgray_dark);
        }
    }
}
