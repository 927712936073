.Messages-container {
    height: 100vh;

    .Messages-list-box {
        height: 100%;
        max-width: 100%;
        width: 100%;
        display: none;
        border-right: none;
        background: #fff;

        .Messages-header-box {
            border-bottom: 1px solid var(--wgray_soft);
            background: var(--wgray_softer);
        }
        &.active {
            width: 100%;
            display: block;
        }
    }

    .Messages-chat-box {
        max-width: 100%;
        width: 100%;
        display: none;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 12;
        &.active {
            width: 100%;
            display: block;
        }
    }
}

@media screen and (min-width: 768px) {

    .Messages-container {


        .Messages-list-box {
            max-width: 360px;
            width: 100%;
            display: block;
            border-right: 1px solid var(--wgray_soft);

            &.active {
                display: block;
            }
        }

        .Messages-chat-box {
            max-width: calc(100% - 360px);
            width: 100%;
            display: block;
            position: relative;
            top: unset;
            left: unset;
            height: 100%;
            z-index: 1;
            &.active {
                display: block;
            }
        }
    }
}

@media screen and (min-width: 1450px) {
    .Messages-container {
        background: var(--wgray_softer);
        width: 100%;
        height: 100%;
        max-width: 100%;
        margin: 0 auto;

        .Messages-box-container {
            padding-top: 25px;
            height: calc(100vh - 100px);
            overflow: auto;

            .Messages-lateral-content {
                max-width: calc(350px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));

                .left {

                }

                .right {

                }
            }

            .Messages-post-box {
                max-width: 1100px;

                .Messages-post-container {
                    width: 100%;
                    padding: 14px 0;
                    // height: calc(100% - 65px - 61px);
                    height: auto;
                    overflow: visible;
                    background: var(--wgray_softer);

                    .Messages-loaderBox {
                        background: #fff;
                        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
                        border-radius: 5px;
                        margin-bottom: 14px;
                        padding: 14px;
                    }
                }
            }


        }
    }
}
