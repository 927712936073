.RecomendationTalent-container {
    padding-top: 24px;
    .RecomendationTalent-user-container {

        // .recomendation-details-box {
        //     line-height: 20px;
        // }
        //
        // .recomendation-text-box {
        //     padding-top: 10px;
        //     font-size: 17px;
        //     color: var(--wgray_darker);
        //     line-height: 20px;
        // }

        .avatar-image-box-60 {

            .startIcn {
                position: absolute;
                border: none;
                width: 30px;
                height: 30px;
                right: -6px;
                bottom: 0;
            }
        }
    }

    .RecomendationTalent-text-container {
        padding-bottom: 10px;
        
        .title-box {
            font-size: 17px;
            font-weight: bold;
            color: var(--wblue_dark);
            padding: 24px 14px 14px;
        }

        .text-box {
            color: var(--wgray_darker);
            font-size: 17px;
            line-height: 22px;
        }
    }

    .RecomendationTalent-btn-container {
        padding-top: 20px;
        padding-bottom: 20px;

        button {
            height: 45px;
            border-radius: 4px;
            background: var(--wgreen_softer);
            border: 1px solid var(--wgreen);
            width: 100%;
            font-size: 15px;
            font-weight: 600;
            color: var(--wgreen);

            img {
                width: 35px;
                height: 35px;
            }
        }
    }
}
