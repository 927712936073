:root {
    --wblue: #0D458D;
    --wblue_dark: #272C40;
    --wgray: #ADB0BE;
    --wgray_dark: #9A9BA0;
    --wgray_darker: #585C6E;
    --wgray_semisoft: #CDCED4;
    --wgray_soft: #EDEDED;
    --wgray_softer: #F8F8FB;
    --wgray_chatbg: #E8E9EF;
    --wgreen: #0FB855;
    --wgreen_soft: #DAF7E6;
    --wgreen_softer: #E8FAF0;
    --wred: #FD3636;
    --wred_semisoft: #F06161;
    --wred_soft: #ffeaea;
}
