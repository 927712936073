.DiscountCode-bottomsheet-container {
    border-radius: 8px;
    border: 1px solid var(--wgreen);
    background: var(--wgreen_soft);
    padding: 24px;
    max-width: 327px;
    margin: 24px auto;

    .DiscountCode-bottomsheet-txt-box {
        text-align: center;
        font-size: 17px;
        font-weight: bold;
        color: var(--wblue);
        padding: 15px 0;
    }

    .DiscountCode-bottomsheet-input-box {
        padding: 0;

        input {
            height: 50px;
            width: 100%;
            border: 1px solid var(--wgreen);
            border-radius: 5px;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: var(--wgreen);
            outline: none !important;

            &::placeholder {
                color: var(--wgray_semisoft);
            }
        }

        &.hasError {

            input {
                border: 1px solid var(--wred);
                color: var(--wred);
            }
        }
    }
}

.DiscountCode-bottomsheet-alert-disclaimer {
    font-size: 16px;
    font-weight: 500;
    color: var(--wred);
    text-align: center;
}

@media screen and (max-width: 375px) {
    .DiscountCode-bottomsheet-container {
        margin: 24px;
    }
}
