.container_profile {
    width: 100%!important;
    height: 100%;
    overflow: auto;
    max-width: 700px!important;
    margin: 0 auto;
    padding-bottom: 60px;

    .image_user {
        background-color: green;
        margin: 0;
        position: relative;

        .image_profile {
            width: 100%;
            min-height: 375px;
            margin: 0;
        }

        .btn_configuration_profile {
            position: absolute;
            z-index: 1;
            margin: 10px;
            right: 0;

            img {
                max-height: 30px;
                max-width: 30px;
            }
        }
    }

    .data_user {
        margin-top: -5%;
        position: relative;
        border-radius: 15px 15px;
        height: 100%;
        background-color: #F8F8FB;

        .name-user {
            display: block;
            align-items: center;

            img {
                width: 30px;
                height: 30px;
                margin-left: 10px;
            }

            .data_account {
                margin: 0;
            }
        }

        h1 {
            border-radius: 15px 15px;
            background-color: white;
            width: 100%;
            padding: 15px;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        img {
            object-fit: contain;
        }

        .board-media {
            display: flex;
            align-items: center;
            color: #dadae4;
            margin-left: 20px;
            font-size: 17px;
            background-color: white;
            padding-left: 20px;
            margin: 0 0 20px 0px;
            padding-bottom: 10px;

            p {
                margin: 0;
                padding: 0;
                margin-left: 10px;
            }
        }

        .data_user_info {
            background-color: white;
            padding: 15px;
            &_fill {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                img {
                    width: 25px;
                    height: 20px;
                }

                p {
                    margin: 0;
                    margin-left: 10px;
                }
            }

            &_btn_edit {
                width: 100%;
                height: 50px;
                border-radius: 5px;
                background-color: #E8FAF0;
                border: none;
                color: #0FB855;
                font-size: 17px;
                margin-top: 20px;
            }
        }


        .data_company {
            background-color: white;
            margin-top: 15px;
            padding: 15px;

            &_header {
                display: flex;

                &_title {
                    margin-left: 20px;

                    h6 {
                        font-weight: bold;
                    }

                    p {
                        margin: 0;
                    }
                }
            }

            h5 {
                margin-top: 20px;
                font-weight: bold;
            }

            h3 {
                margin: 0;
                color: black;
                padding: 0;
                margin: 10px 0 10px 0 ;
                font-weight: bold;
            }

            p {
                font-size: 17px;
                color: #585C6E;
                line-height: 20px;
            }

            h4 {
                margin-top: 25px;
            }

            &_tags {
                display: flex;
                width: 100%;
                margin-top: 10px;
            }

            .container-tag {
                background-color: #F8F8FB;
                color: #585C6E;
                padding: 10px;
                text-align: center;
                border-radius: 5px;
                margin-left: 5px;

                p {
                    margin: 0;
                }
            }

            &_info_fill {
                display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    img {
                        width: 25px;
                        height: 20px;
                    }

                    p {
                        margin: 0;
                        margin-left: 10px;
                    }
            }

            &_btn_edit {
                width: 100%;
                height: 50px;
                border-radius: 5px;
                background-color: #E8FAF0;
                border: none;
                color: #0FB855;
                font-size: 17px;
                margin-top: 20px;
            }
        }

        .data_folleto {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            padding: 15px;
        }

        h3 {
            padding: 15px;
            font-size: 20px;
            color: #9A9BA0;
        }

        .data_account {

            background-color: white;
            padding: 5px 15px 0px 15px;
            margin-top: 5px;
            margin-bottom: 5px;
            align-items: center;

            &.noLink {
                padding: 5px 15px;
                display: flex;
                min-height: 45px;
            }

            &_option {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .green {
                    color: #0FB855;
                }
                p {
                    font-size: 17px;
                    color: #272C40;
                    text-align: center;
                    margin-top: 10px;

                    &.invitation-label {
                        display: flex;
                        align-items: center;

                        span {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            max-width: calc(100% - 30px - 50px);

                            .invitation-miniature {
                                width: 20px;
                                height: 20px;
                                border-radius: 10px;
                                margin: 0 5px;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
    }

    .data-network-container {
        background: #fff;
        margin: 10px 0 0px;
        padding: 20px 0;

        .data-network-header {
            font-size: 17px;
            font-weight: bold;
            color: var(--wblue_dark);
            padding-bottom: 14px;
        }

        .data-networks-box {

            .text-content {
                font-size: 17px;
                color: var(--wgray_darker);
                line-height: 22px;
            }

            .col-12 {
                height: 50px;
                background: #F4F4F6;
                display: flex;
                align-items: center;
                border-radius: 25px;
                padding: 10px;
                font-size: 17px;
                color: var(--wgray_darker);
                margin-bottom: 10px;

                img {
                    min-width: 35px;
                    width: 35px;
                    height: 35px;
                    border-radius: 17.5px;
                    margin-right: 10px;
                    object-fit: cover;

                    &.Twitter {
                        object-position: 0 0;
                    }

                    &.Facebook {
                        object-position: 0 -35px;
                    }

                    &.LinkedIn {
                        object-position: 0 calc(-35px * 2);
                    }

                    &.Instagram {
                        object-position: 0 calc(-35px * 3);
                    }

                    &.YouTube {
                        object-position: 0 calc(-35px * 4);
                    }

                    &.Spotify {
                        object-position: 0 calc(-35px * 5);
                    }
                }
            }
        }

        .data-network-adder-container {
            button {
                width: 100%;
                height: 50px;
                border-radius: 5px;
                background-color: #E8FAF0;
                border: none;
                color: #0FB855;
                font-size: 17px;
                margin-top: 20px;
                font-weight: 600;
            }
        }

        .data-recomendation-container {
            .recomendation-header {
                padding: 20px 0 10px;

                .recomendation-cant-box {
                    font-size: 15px;
                    font-weight: 600;
                    color: var(--wblue);
                }

                .recomendation-showmore-box {
                    padding: 0 0 0 5px;
                    max-width: 85px;
                    text-align: end;

                    button {
                        color: var(--wgreen);
                        font-size: 15px;
                        font-weight: 600;
                    }
                }
            }

            .recomendation-items-container {
                overflow: auto;

                .recomendation-item {
                    padding: 0 7px;
                    max-width: 266px;
                    min-width: 266px;

                    .recomendation-item-row {
                        background: #F4F4F6;
                        padding: 12px;
                        border-radius: 5px;
                        height: 175px;

                        .recomendation-details-box {
                            line-height: 20px;
                        }

                        .recomendation-text-box {
                            padding-top: 10px;
                            font-size: 17px;
                            color: var(--wgray_darker);
                            line-height: 20px;
                        }

                        .avatar-image-box-60 {

                            .likeIcn {
                                position: absolute;
                                border: none;
                                background: #fff;
                                width: 30px;
                                height: 30px;
                                bottom: 0;
                                right: -6px;
                            }
                        }
                    }
                }
            }
        }
    }

    .Profile-discount-container {
        padding-top: 24px;

        .Profile-discount-col {
            height: 80px;
            background: #DDEAFB;
            border-radius: 5px;
            display: flex;
            align-items: center;

            .discount-number {
                max-width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                font-weight: bolder;
                color: var(--wblue);
            }

            .discount-text {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: bold;
                color: var(--wblue);
            }
        }
    }

    .Profile-asociations-container {
        margin-top: 10px;
        background: #fff;
        padding: 24px 15px;

        .colTitle {
            font-size: 17px;
            font-weight: bold;
            color: var(--wblue_dark);
            padding-bottom: 14px;
        }

        .asociationBox {
            padding: 12px 14px;
            background: var(--wgray_softer);
            border-radius: 2px;
            margin-bottom: 10px;

            .asocTitle {
                font-size: 17px;
                font-weight: bold;
                color: var(--wblue);                
            }

            .asocDetails {
                font-size: 18px;
                color: var(--wgray_darker);
            }
        }

    }
}

@media screen and (min-width: 700px) {
    .container_profile {
        border-left: 1px solid var(--wgray_soft);
        border-right: 1px solid var(--wgray_soft);
    }
}
.hidden {
    overflow-y: scroll;
}


.hidden {
    display: none;
}

  svg {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }

button, .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #0FB855;
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1;
    // text-transform: capitalize;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer;
}

.center-div{
    text-align: center;
}

  button:hover, .button:hover {
    border-color: #cdd;
  }

  .share-button, .copy-link {
    padding-left: 30px;
    padding-right: 30px;
  }

  .share-button, .share-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

    .share-dialog {
        display: none;
        width: 95%;
        max-width: 500px;
        box-shadow: 0 8px 16px rgba(0,0,0,.15);
        z-index: -1;
        border: 1px solid #ddd;
        padding: 20px;
        border-radius: 4px;
        background-color: #fff;

        header {
            align-items: center;
            h3 {
                font-size: 22px;
            }
        }

        .disclaimer {
            line-height: 20px;
            font-size: 16px;
            padding-bottom: 15px;

            a {
                color: blue;
            }
        }
    }

  .share-dialog.is-open {
    display: block;
    z-index: 2;
  }

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

  }

  .dialog-body{
      font-size: 15px;
  }

  .targets {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
  }

  .close-button {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .close-button svg {
    margin-right: 0;
  }

  .link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    background-color: #eee;
  }

  .pen-url {
    margin-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    background: transparent;
  }

  .copy-link:hover {
    background: #0FB855;
    color: white;

  }

a {
    text-decoration: none;
    color: black;
}

@media screen and (min-width: 1450px) {
    .Profile-container {
        background: var(--wgray_softer);
        width: 100%;
        height: 100%;
        max-width: 100%;
        margin: 0 auto;

        .Profile-box-container {
            padding-top: 25px;
            height: calc(100vh - 100px);
            overflow: auto;

            .Profile-lateral-content {
                max-width: calc(350px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));
                padding-right: calc(calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5) - 9px);
                padding-left: 9px;

                .left {

                }

                .right {

                }
            }

            .Profile-post-box {
                max-width: 1100px;
                padding-right: calc(calc(var(--bs-gutter-x) * .5) - 9px);

                .Profile-main-row {
                    border-right: 1px solid var(--wgray_soft);

                    .Profile-small-col {
                        max-width: calc(334px + 12px + 12px);
                    }
                }
                // .Profile-post-container {
                //     width: 100%;
                //     padding: 14px 0;
                //     // height: calc(100% - 65px - 61px);
                //     height: auto;
                //     overflow: visible;
                //     background: var(--wgray_softer);
                //
                //     .Profile-loaderBox {
                //         background: #fff;
                //         box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
                //         border-radius: 5px;
                //         margin-bottom: 14px;
                //         padding: 14px;
                //     }
                // }
            }


        }
    }
}
