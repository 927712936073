.Header-container {
    background: #fff;
    height: 100px;
    box-shadow: 0 2px 15px rgba(0,0,0,0.2);
    position: relative;

    .Header-row-container {
        justify-content: space-between;

        .Header-logo-container {
            display: flex;
            justify-content: start;
            align-items: center;
            max-width: 350px;

            img {
                width: 120px;
                height: 40px;
                object-fit: contain;
            }
        }

        .Header-options-container {
            max-width: calc(700px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));
            min-width: calc(700px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));
            width: calc(700px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));
            height: 100%;

            .Header-option-item {
                padding: 0;
                max-width: 130px;
                width: 130px;

                button {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 130px;
                    height: 100px;
                    border-radius: 0;
                    border-bottom: 5px solid #fff;
                    position: relative;


                    img {
                        width: 34px;
                        height: 34px;
                    }

                    .titleSpan {
                        font-size: 15px;
                        font-weight: 500;
                        margin-top: 9px;
                        color: var(--wgray_dark);
                    }

                    &.active {
                        border-bottom: 5px solid var(--wgreen);
                        cursor: default;
                        .titleSpan {
                            color: var(--wgreen)
                        }
                    }

                    .dotSpan {
                        background: red;
                        height: 8px;
                        width: 8px;
                        position: absolute;
                        border-radius: 4px;
                        top: 20px;
                        right: calc(50% - 10px);
                        transform: translateX(calc(-50% + 10px));
                    }
                }
            }
        }

        .Header-searcher-container {
            position: relative;
            max-width: 350px;
            padding-left: 0;

            label {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 50px;
                height: 50px;

                img {
                    width: 50px;
                    height: 50px;
                    padding: 8px;
                }
            }

            input {
                height: 50px;
                background: var(--wgray_soft);
                border: none;
                border-radius: 25px;
                width: 100%;
                padding: 0 50px;
                font-size: 17px;
                font-weight: 600;
                color: var(--wblue_dark);

                &::placeholder {
                    color: var(--wgray);
                }
            }

            button {
                width: 50px;
                height: 50px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: calc(var(--bs-gutter-x) * .5);

                img {
                    width: 50px;
                    height: 50px;
                    padding: 4px;
                }
            }
        }
    }

}

@media screen and (min-width: 2100px) {
    .Header-container {
        .Header-row-container {
            justify-content: center;

            .Header-logo-container {
                display: flex;
                justify-content: start;
                align-items: center;
                max-width: 350px;

                img {
                    width: 120px;
                    height: 40px;
                    object-fit: contain;
                }
            }

            .Header-options-container {
                max-width: calc(700px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));
                min-width: calc(700px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));
                width: calc(700px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));
                height: 100%;

                .Header-option-item {
                    padding: 0;
                    max-width: 130px;
                    width: 130px;

                    button {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 130px;
                        height: 100px;
                        border-radius: 0;
                        border-bottom: 5px solid #fff;


                        img {
                            width: 34px;
                            height: 34px;
                        }

                        span {
                            font-size: 15px;
                            font-weight: 500;
                            margin-top: 9px;
                            color: var(--wgray_dark);
                        }

                        &.active {
                            border-bottom: 5px solid var(--wgreen);
                            cursor: default;
                            span {
                                color: var(--wgreen)
                            }
                        }
                    }
                }
            }

            .Header-searcher-container {
                position: relative;
                max-width: 350px;
                padding-left: 0;

                label {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    width: 50px;
                    height: 50px;

                    img {
                        width: 50px;
                        height: 50px;
                        padding: 8px;
                    }
                }

                input {
                    height: 50px;
                    background: var(--wgray_soft);
                    border: none;
                    border-radius: 25px;
                    width: 100%;
                    padding: 0 50px;
                    font-size: 17px;
                    font-weight: 600;
                    color: var(--wblue_dark);

                    &::placeholder {
                        color: var(--wgray);
                    }
                }

                button {
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: calc(var(--bs-gutter-x) * .5);

                    img {
                        width: 50px;
                        height: 50px;
                        padding: 4px;
                    }
                }
            }
        }

    }
}
