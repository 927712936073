.container_edit_company {
    max-height: 100vh;
    overflow: auto;
    width: 100%;
    color: #9A9BA0;
    font-size: 16px;
    position: relative;
    padding-bottom: 40px;
    max-width: 700px!important;
    margin: 0 auto;
    border-left: 1px solid var(--wgray_soft);
    border-right: 1px solid var(--wgray_soft);
    background: #fff;

    .container_header {
        background-color: #fff;
    }

    img {
        object-fit: contain;
    }

    &_photo {
        width: 100%;
        label {
            color: green;
            font-weight: bold;
            text-align: center;
            margin-top: -20px;
            display: block;
        }

        img {
            display: block;
            margin: 0 auto;
            border-radius: 50%;
            margin-bottom: 10px;
            margin-top: 20px;
        }

        input {
            visibility: hidden;
        }
    }

    &_gender {
        padding: 15px 15px 0px 15px;

        p {
            margin: 10px 0px 10px 0px;
        }

        h3 {
            color: #0D458D;
            font-size: 17px;
            font-weight: bold;
            margin: 0;
            margin-bottom: 0px;
        }

        &_container {
            display: flex;
            justify-content: start;

            &_option {

                &_field {
                    position: relative;
                    input {
                        height: 50px;
                        text-align: center;
                        background-color: white;
                        border: none;
                        border: 1px solid #CDCED4;
                        color: #9A9BA0;
                        font-weight: bold;

                        &::placeholder{
                            color: #ADB0BE;
                            font-size: 17px;
                        }
                        border: 1px solid var(--wgray_semisoft);
                        border-radius: 3px;

                        &.percent {
                            background-color: #DDEAFB;
                            color: var(--wblue);

                            border: 1px solid var(--wblue);
                        }
                    }

                    .percentSymbol {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: calc(50% + 16px);
                        font-size: 16px;
                        font-weight: bold;
                        color: var(--wblue);
                    }

                }

                .h {
                    input {
                        width: 170px;
                        margin-right: 5px;
                    }

                    .black {
                        color: black;
                    }
                }

                .m {
                    input {
                        width: 170px;
                        margin-left: 5px;
                    }
                    .black {
                        color: black;
                    }
                }
            }
        }
    }

    &_input{
        padding: 15px 15px 0px 15px;

        .filters {
            position: relative;
            // margin-bottom: 50px;
            .Filters-container {
                height: auto;
                // position: absolute!important;
                padding: 0!important;

            }
        }

        .salto {
            margin-bottom: 15px;
            margin-top: 15px;
        }

        p {
            margin: 0px 0px 10px 0px;
        }

        h3 {
            color: #0D458D;
            font-size: 17px;
            font-weight: bold;
            margin: 0;
            margin-bottom: 0px;
        }



        &_field {

            .icon2 {
                padding-left: 50px;
            }

            img {
                position: absolute;
                margin: 18px 5px 18px 15px ;
                width: 20px;
                height: 15px;
                &.datepicker-icn {
                    z-index: 1;
                }
            }

            input {
                width: 100%;
                height: 50px;
                padding-left: 40px;
                border: 1px solid var(--wgray_semisoft);
                border-radius: 3px;
            }

            textArea {
                padding: 15px;
                width: 100%;
                font-size: 17px;
                border: 1px solid var(--wgray_semisoft);
                border-radius: 3px;
                resize: none;

                &::placeholder {
                    color: #ADB0BE;
                    font-size: 17px;
                }
            }

            .field-btn {
                height: 50px;
                text-align: center;
                background-color: white;
                border: none;
                width: 100%;
                justify-content: start!important;
                padding-left: 10px;
                border: 1px solid #CDCED4;
                color: #9A9BA0;
                font-weight: bold;
            }

            // .react-datepicker__navigation {
            //     border: none!important;
            //     background-color: transparent!important;
            //     width: 40%;
            //     padding-left: 0;
            //     z-index: 2;
            // }
            //
            // .react-datepicker__month-container {
            //     background-color: white;
            //     z-index: 3;
            // }


            .right {
                right: 20px;
                width: 40px;
                height: 50px;
                margin: 0;
            }

            .black {
                color: black;
            }
        }

        &_switch {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #F8F8FB;
            padding: 8px 10px 8px 10px;

            .switch {
                position: relative;
                display: inline-block;
                width: 58px;
                height: 30px;
              }

              .switch input {
                opacity: 0;
                width: 0;
                height: 0;
              }

              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: .4s;
                transition: .4s;
              }

              .slider:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 2px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
              }

              input:checked + .slider {
                background-color: green;
              }

              input:focus + .slider {
                box-shadow: 0 0 1px green;
              }

              input:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
              }

              /* Rounded sliders */
              .slider.round {
                border-radius: 34px;
              }

              .slider.round:before {
                border-radius: 50%;
              }
        }
    }

    .container-lada {

        padding: 15px 15px 0 15px;

        p {
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
        }

        .Login-input-box-lada {
            height: 50px;
            width: 100%;
            border: 1px solid var(--wgray_semisoft);
            border-radius: 5px;
            padding: 5px;

            background: transparent;
            &.input-alert {
                border: 1px solid red;
                background: #ffefef;
            }

            .lada-btn-box {
                max-width: 100px;
                width: 100px;

                button {
                    width: 100%;
                    background: transparent;
                    outline: none !important;
                    border: none;
                    border-right: 1px solid var(--wgray_dark);
                    font-size: 17px;
                    font-weight: 600;
                    color: var(--wgreen);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .input-box {
                input {
                    width: 100%;
                    border: none;
                    color: var(--wblue_dark);
                    font-size: 17px;
                    font-weight: 600;
                    outline: none !important;
                    padding: 6px;
                    background: transparent;
                    &::placeholder {
                        color: var(--wgray_semisoft);
                    }
                }
            }
        }
    }

}

.btn-save {
    width: 95%;
    display: block;
    margin: 40px auto 40px auto;
    background-color: var(--wblue);
    border: none;
    border-radius: 5px;
    height: 55px;
    color: white;
    font-weight: bold;
}
