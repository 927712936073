.NotificationsList-container {
    background: var(--wgray_softer);
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0 auto;

    .NotificationsList-box-container {
        padding-top: 25px;
        height: calc(100vh - 100px);
        overflow: auto;

        .NotificationsList-lateral-content {
            max-width: calc(350px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));

            .left {

            }

            .right {

            }
        }

        .icn_tutorial{
            width: (50px);
        }

        .NotificationsList-post-box {
            max-width: 700px;
            min-width: 700px;
            border-left: 1px solid var(--wgray_semisoft);
            border-right: 1px solid var(--wgray_semisoft);

            .NotificationsList-content {
                background: #fff;
                padding: 0;
                border-radius: 10px;
                box-shadow: 0 1px 10px rgba(0,0,0,0.14);

                .NotificationsList-item-container {
                    // height: 100px;
                    border-bottom: 1px solid #d9d6d6;
                    display: flex;
                    align-items: center;
                    padding: 10px 14px;
                    background: #F1F7FF;

                    &.readed {
                        background: #fff;
                    }

                    &.isFirst {
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }

                    &.isLast {
                        border-bottom: 1px solid transparent;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }

                    .NotificationsList-icon-container {
                        padding: 0;
                        max-width: 50px;
                        max-height: 50px;
                        width: 50px;
                        height: 50px;
                        background: #EDEDED;
                        border-radius: 50px;

                        img {
                            max-width: 50px;
                            max-height: 50px;
                            width: 50px;
                            height: 50px;
                        }
                    }

                    .NotificationsList-details-container {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        padding-top: 20px;
                        padding-bottom: 15px;

                        .hour-badge {
                            position: absolute;
                            top: 10px;
                            right: 0;
                            font-size: 12px;
                            color: #9A9BA0;
                        }

                        .title {
                            font-size: 18px;
                            font-weight: bold;
                            color: #272C40;
                        }

                        .subtitle {
                            font-size: 18px;
                            color: #272C40;
                        }
                    }
                }

                .NotificationsList-no-results-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 38px;

                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        object-position: 0 -50px;
                    }

                    span {
                        padding-top: 10px;
                        font-size: 18px;
                        text-align: center;
                        line-height: 20px;
                        color: var(--wgray_dark);
                    }
                }
            }
        }


    }

    .container-tutorial{

        margin-top: 20px;
        margin-bottom: 20px;
        .title-tutorial{
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            /* or 122% */
            letter-spacing: -0.432px;
            color: #272C40;
        }
    
        .container-body-tutorial{
            margin-top: 15px!important;
            background: #DDEAFB;
            border-radius: 10px;
            max-height: 100px;
        }
    
        .container-icn-tutorial{
            background-color: #3173C9;
            border-radius:  10px 0 0 10px;
            max-width: 100px;
            max-height: 100px;
        }
    
        .icn_tutorial{
            margin-left : calc(25px - 12px);
            margin-right : calc(25px - 12px);
            margin-top: 22px;
            margin-bottom: 22px;
            width: 55px;
        }
    
        .container-text-tutorial{
            margin-top: 14px;
            margin-left: calc(14px - 12px);
        }
    
        .main-text-tutorial{
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.432px;
            color: #0D458D;
        }
    
        .link-tutorial{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.384px;
            color: #0D458D;
            text-decoration:#1C65C2 ;
        }
        .icn_arrow{
            width: 15px;
            height: 15px; 
            -webkit-transform:rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    
       
    
    }

    .sample-notification {
        border-bottom: lighten( #0FB855, 50%) 3px solid ;
        cursor: pointer;
    }

    .amount-not-read{
        background-color: var(--wgreen);
        color: white;
        border-radius: 5px;
        padding-left: 4px;
        padding-right: 4px;
        margin-left: 7px;
    }


    .selected-notification{
        color: var(--wgreen);
        border-bottom: var(--wgreen) 3px solid;
    }

    

    


}

.modalRefereal{

    .modal-dialog {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        width: 30vw!important;
    }
    .title-refeal{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #0D458D;
        margin-bottom: 30px;
    
    }
    .close-refereal{
        margin-top: 10px;
        margin-right: 15px;
        margin-bottom: 5px;
        cursor: pointer;
    }

    .icn-close-refereal{
        width: 20px;
    }
    
    .detail-refeal{
        background: #F4F4F6;
        border: 1px solid #CDCED4; 
    }
    
    .icn_refeal{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 15px;
    }
    
    .container-user-info-refereal{
        margin-left: 15px;
        margin-top: 15px;
        margin-bottom: 35px;
    }
    
    .name-refereal{
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.432px;
        color: #272C40;
    }
    
    .position-business-refereal{
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.36px;
        color: #585C6E;
        
    }
    
    .location-refereal{
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.36px;
        color: #585C6E;
        
    }
    
    .container-user-description-refereal{
        margin-left: 15px;
        margin-right: 15px;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.432px;
        color: #272C40;
    }
    
    .container-view-profile-refereal{
        margin-top: 5px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 25px;
        padding-right: 36px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    
    .view-profile-refereal{
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #0FB855;
    }
    
    .container-buttons-refeal{
        margin-top: 20px!important;
        margin-left: 15px!important;
        margin-right: 15px!important;
        margin-bottom: 20px!important;
    }
    
    .btn-cancel-refereal{
        border: 2px solid #0FB855;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #0FB855;
        width: 70%
    }
    .btn-cancel-refereal:hover{
        border: 2px solid #0FB855;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #0FB855;
        width: 70%
    }
    
    .btn-ok-refereal{
        background: #0FB855;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
        width: 70%
    }
    .btn-ok-refereal:hover{
        background: #0FB855;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
        width: 70%
    }
    
    .disabled-refereal{
        cursor: default!important;
    }
}



@media screen and (min-width: 1450px) {
    .NotificationsList-container {
        background: var(--wgray_softer);
        width: 100%;
        height: 100%;
        max-width: 100%;
        margin: 0 auto;

        .NotificationsList-box-container {
            padding-top: 25px;
            height: calc(100vh - 100px);
            overflow: auto;

            .NotificationsList-lateral-content {
                max-width: calc(350px + calc(var(--bs-gutter-x) * .5) + calc(var(--bs-gutter-x) * .5));

                .left {

                }

                .right {

                }
            }

            .NotificationsList-post-box {
                max-width: 700px;
                min-width: 700px;
                border-left: 1px solid var(--wgray_semisoft);
                border-right: 1px solid var(--wgray_semisoft);

                .NotificationsList-post-container {
                    width: 100%;
                    padding: 14px 0;
                    // height: calc(100% - 65px - 61px);
                    height: auto;
                    overflow: visible;
                    background: var(--wgray_softer);

                    .NotificationsList-loaderBox {
                        background: #fff;
                        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
                        border-radius: 5px;
                        margin-bottom: 14px;
                        padding: 14px;
                    }
                }
            }


        }


    }
}
