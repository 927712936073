.ConfigSheet-container {


    .ConfigSheet-header-container {
        display: flex;
        align-items: center;
        background: #fff;
        height: 64px;
        border-radius: 10px;
        box-shadow: 0 1px 10px rgba(0,0,0,0.14);
        justify-content: space-between;
        cursor: pointer;
        padding-left: 17px;
        padding-right: 20px;

        .titleSpan {
            padding-left: 14px;
            font-size: 20px;
            color: var(--wblue_dark);
        }
    }

    .ConfigSheet-content-container {
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 1px 10px rgba(0,0,0,0.14);
        margin-top: 14px;

        .ConfigSheet-content-header {
            height: 84px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--wgray_soft);
            padding-left: 20px;
            font-size: 20px;
            font-weight: bold;
            color: var(--wblue_dark);
        }

        .ConfigSheet-content-option {
            height: 55px;
            border-bottom: 1px solid var(--wgray_soft);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            font-size: 18px;
            color: var(--wblue_dark);
            cursor: pointer;
        }
    }
}
